/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Step from './Step';
import styles from './Steps.module.css';

function Steps({ current = 0, items = [], ...props }) {
  return (
    <div className={styles.containerStep} {...props}>
      <div className={styles.stepsShell}>
        {items.map((step, index) => (
          <Step active={current >= index} key={index}>
            {step}
          </Step>
        ))}
      </div>
    </div>
  );
}

export default Steps;
