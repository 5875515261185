import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface IProps {
  showModalRestore: boolean;
  isLoadingRestore: boolean;
  onCancelRestore: () => void;
  onConfirmRestore: () => void;
}

const RestoreFactoryDefaultModal = ({
  showModalRestore,
  isLoadingRestore,
  onCancelRestore,
  onConfirmRestore
}: IProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'captivePortal' });

  return (
    <ConfirmModal
      showModal={showModalRestore}
      isLoadingAction={isLoadingRestore}
      onConfirm={onConfirmRestore}
      onCancel={onCancelRestore}
      width="460px"
      height="190px">
      <span>{t('Deseja restaurar os dados do formulário?')}</span>
    </ConfirmModal>
  );
};

export { RestoreFactoryDefaultModal };
