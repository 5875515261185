import React, { ReactNode } from 'react';
import styles from './NoBarsTemplate.module.css';

interface ITemplateProps {
  children: ReactNode;
}
const NoBarsTemplate = ({ children }: ITemplateProps) => {
  return <div className={`${styles.container}`}>{children}</div>;
};

export { NoBarsTemplate };
