import React from 'react';
import classNames from 'classnames';
import styles from './Label.module.css';

const Label = ({
  children,
  element = '',
  className = '',
  hasRequiredIndicator = false
}) => {
  return (
    <>
      <label
        className={classNames([
          styles.label,
          element == 'checkbox' ? styles.checkbox : '',
          element == 'radiobutton' ? styles.radiobutton : '',
          element == 'toggle' ? styles.toggle : '',
          hasRequiredIndicator && styles.labelWithRequiredIndicator,
          className
        ])}>
        {children}
        {hasRequiredIndicator && (
          <span className={styles.requiredIndicator}>*</span>
        )}
      </label>
    </>
  );
};

export default Label;
