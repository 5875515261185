import classNames from 'classnames';
import { Tooltip } from 'inmaster-ui';
import React from 'react';
import styles from './Tab.module.css';

const Tabs = ({ tabs = [], activeTabIndex = 0, ...props }) => {
  const [idActiveTab, setIdActiveTab] = React.useState(
    tabs[activeTabIndex].id || ''
  );

  const checkClassesNames = (tab) => {
    const classes = [];
    if (tab.disabled) {
      classes.push(styles.disabled);
    }
    if (tab.id === idActiveTab) {
      classes.push(styles.active);
    }
    return classes.join(' ');
  };

  const handleChangeTabs = (newTabIndex) => {
    if (tabs[newTabIndex].disabled || tabs[newTabIndex].id === idActiveTab) {
      return;
    }
    setIdActiveTab(tabs[newTabIndex].id);
  };

  const renderTabBody = () => {
    return tabs.map((tab) => {
      if (tab.id !== idActiveTab) {
        return null;
      }
      return (
        <div key={`tab-body-${tab.id}`} id={`tab-body-${tab.id}`} {...props}>
          {tab.component}
        </div>
      );
    });
  };

  const renderHeaderTab = () => {
    return (
      <ul className={styles.nav}>
        {tabs.map((tab, index) => (
          <li
            key={`tab-head-${tab.id}`}
            id={`tab-head-${tab.id}`}
            className={classNames([checkClassesNames(tab), 'text-uppercase'])}
            disabled={tab.disabled}
            onClick={() => {
              handleChangeTabs(index);
              tab.onClick ? tab.onClick() : null;
            }}>
            <span>
              <Tooltip
                content={tab.tooltip}
                disabled={tab.tooltip === undefined || tab.tooltip === ''}
                place="bottom"
                id={`tooltip-tab-${tab.id}`}>
                {tab.label}
              </Tooltip>
            </span>
            <span>{tab.badge}</span>
          </li>
        ))}
      </ul>
    );
  };

  React.useEffect(() => {
    if (activeTabIndex < 0) return;
    handleChangeTabs(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <div className={styles.Tabs}>
      <div>{renderHeaderTab()}</div>
      <div>{renderTabBody()}</div>
    </div>
  );
};

export default Tabs;
