import React from 'react';
import getClass from '../classNames';
import type { IRow } from './types';

const rowKeys: Array<
  keyof Pick<
    IRow,
    | 'start'
    | 'center'
    | 'end'
    | 'top'
    | 'middle'
    | 'bottom'
    | 'around'
    | 'between'
  >
> = ['start', 'center', 'end', 'top', 'middle', 'bottom', 'around', 'between'];

function getRowClassNames(props: Omit<IRow, 'children'>) {
  const modificators = [props.className, getClass('row')];

  for (let i = 0; i < rowKeys.length; i += 1) {
    const key = rowKeys[i];
    const value = props[key];
    if (value) {
      modificators.push(getClass(`${key}-${value}`));
    }
  }

  if (props.reverse) {
    modificators.push(getClass('reverse'));
  }

  return modificators.filter(Boolean).join(' ');
}

const Row = ({ children, style, ...props }: IRow) => {
  const className = getRowClassNames(props);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Row.defaultProps = {
  reverse: false,
  start: undefined,
  center: undefined,
  end: undefined,
  top: undefined,
  middle: undefined,
  bottom: undefined,
  around: undefined,
  between: undefined,
  className: undefined,
  children: undefined,
  style: {}
};

export default Row;
