import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import styles from './NoWirelessWarnModal.module.css';

interface IProps {
  show: boolean;
  onClose: () => void;
}

const NoWirelessWarnModal = ({ onClose, show }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists'
  });

  return (
    <Modal
      width="465px"
      onClose={onClose}
      show={show}
      actions={[
        {
          label: 'OK',
          action: onClose,
          primary: true
        }
      ]}>
      <div className={styles.contentModal}>
        <span className={styles.textModal}>
          {t('Não há')} <span className="text-bold">{t('rede wireless')}</span>{' '}
          {t(
            'disponível para associar a uma nova lista. Você pode criar uma nova wireless ou editar as wireless associadas a outra lista já existente'
          )}
          .
        </span>
      </div>
    </Modal>
  );
};

export default NoWirelessWarnModal;
