import React from 'react';
import {
  MdCheckCircle,
  MdWatchLater,
  MdWarning,
  MdCancel
} from 'react-icons/md';
import styles from './ResponseMessage.module.css';

const ResponseMessage = ({ status = 'default', fontSm = false, ...props }) => {
  const iconOptions = {
    success: (
      <MdCheckCircle color="var(--color-brand-primary-base)" size="24px" />
    ),
    error: <MdCancel color="var(--color-status-critical-base)" size="24px" />,
    warning: <MdWarning color="var(--color-status-alert-base)" size="24px" />,
    pending: <MdWatchLater color="#3C5B97" size="24px" />
  };

  return (
    <span className={styles.responseMessageContainer} {...props}>
      {status !== 'default' ? iconOptions[status] : null}
      {props.children.length ? (
        <span style={{ marginLeft: 'var(--spacing-inline-4xs)' }}>
          {props.children}
        </span>
      ) : null}
    </span>
  );
};

export default ResponseMessage;
