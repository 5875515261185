import classNames from 'classnames';
import { React, useEffect, useState } from 'react';
import styles from './Tag.module.css';

const Tag = ({
  color = '',
  outlined = false,
  wide = false,
  fullWidth = false,
  ...props
}) => {
  const [variant, setVariant] = useState({
    borderColor: '',
    backgroundColor: ''
  });

  useEffect(() => {
    switch (color) {
      case 'green':
        setVariant({
          borderColor: 'var(--color-status-ok-darkest)',
          backgroundColor: 'var(--color-status-ok-lightest)'
        });
        break;

      case 'red':
        setVariant({
          borderColor: 'var(--color-status-critical-base)',
          backgroundColor: 'var(--color-status-critical-lightest)'
        });
        break;

      case 'yellow':
        setVariant({
          borderColor: 'var(--color-status-alert-dark)',
          backgroundColor: 'var(--color-status-alert-lightest)'
        });
        break;

      case 'gray':
        setVariant({
          borderColor: 'var(--color-neutral-dark-4)',
          backgroundColor: 'var(--color-neutral-light-4)'
        });
        break;

      case 'wine':
        setVariant({
          borderColor: 'var(--color-status-critical-darkest)',
          backgroundColor: 'var(--color-status-critical-lightest)'
        });
        break;

      case 'lightblue':
        setVariant({
          borderColor: 'var(--color-product-lightest)',
          backgroundColor: 'var(--color-neutral-light-4)'
        });
        break;

      case 'mustard':
        setVariant({
          borderColor: 'var(--color-status-alert-darkest)',
          backgroundColor: 'var(--color-status-alert-lightest)'
        });
        break;
      case 'blue':
        setVariant({
          borderColor: 'var(--color-complementary-information)',
          backgroundColor: 'var(--color-complementary-information)'
        });
        break;
      default:
        setVariant({
          borderColor: 'var(--color-neutral-dark-3)',
          backgroundColor: 'var(--color-neutral-light-5)'
        });
    }
  }, [color]);

  return (
    <div
      className={classNames([
        styles.tag,
        wide && styles.wide,
        fullWidth && styles.fullWidth
      ])}
      style={
        outlined
          ? {
              background: 'var(--color-neutral-light-5)',
              borderColor: variant.borderColor,
              color: variant.borderColor
            }
          : {
              borderColor: variant.borderColor,
              color: variant.borderColor,
              background: variant.backgroundColor
            }
      }
      {...props}>
      {props.children}
    </div>
  );
};

export default Tag;
