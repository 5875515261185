import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { MdWarning } from 'react-icons/md';
import { Tooltip } from 'src/components/Tooltip';
import { Checkbox } from 'src/components/Checkbox';
import { Loading } from 'src/components/Loading';
import styles from './Table.module.css';
import type { IRowTable, ITable } from './types';

export const Table = ({
  columns,
  rows,
  hasRowSelection,
  onSelectRows,
  selectedRows = [],
  hasStatus,
  disableSelection,
  className,
  ...props
}: ITable) => {
  const undisabledRows = rows.filter((row) => !row?.isDisabled);

  const selectRow = (row: IRowTable) => {
    if (!onSelectRows) {
      return;
    }

    if (selectedRows.some((item) => item.id === row.id)) {
      onSelectRows(selectedRows.filter((item) => item.id !== row.id));
      return;
    }
    onSelectRows([...selectedRows, row]);
  };

  const verifyAllRowsAreSelected = (
    arrayA: IRowTable[],
    arrayB: IRowTable[]
  ) => {
    return (
      _.difference(
        arrayA.map((item) => item.id),
        arrayB.map((item) => item.id)
      ).length === 0
    );
  };

  const verifyIsFullChecked = () => {
    if (rows.length === 0 && selectedRows.length === 0) {
      return false;
    }

    return verifyAllRowsAreSelected(undisabledRows, selectedRows);
  };

  const selectAllRows = () => {
    if (!onSelectRows) {
      return;
    }

    if (verifyAllRowsAreSelected(undisabledRows, selectedRows)) {
      onSelectRows([]);
      return;
    }
    onSelectRows(undisabledRows);
  };

  const verifyRowIsInactive = (row: IRowTable): boolean => {
    if (row?.isInactive || row?.isDisabled) {
      return true;
    }

    if (hasStatus && row.typeStatus === 'loading') {
      return true;
    }

    return false;
  };

  const iconStatusOptions = {
    loading: <Loading value={40} indeterminate className="d-flex align-end" />,
    warning: <MdWarning color="var(--alert-base)" size={20} />
  };

  const verifyIsIndeterminate = () => {
    return selectedRows.length >= 1 && !verifyIsFullChecked();
  };

  const renderHeaderCheckbox = () => {
    if (!hasRowSelection) {
      return null;
    }

    return (
      <th aria-label="Caixa de seleção">
        <Checkbox
          checked={verifyIsFullChecked()}
          onChange={() => selectAllRows()}
          disabled={disableSelection}
          id="check-all"
          indeterminate={verifyIsIndeterminate()}
        />
      </th>
    );
  };

  const renderHeaderStatus = () => {
    if (!hasStatus) {
      return null;
    }

    return (
      <th aria-label="Sem caixa de seleção">
        <span />
      </th>
    );
  };

  const renderColumns = () => {
    return (
      <tr>
        {renderHeaderStatus()}
        {renderHeaderCheckbox()}
        {columns.map((column) => {
          if (column.invisible) {
            return null;
          }

          return (
            <th key={column.field}>
              <div
                className={classNames([
                  'common-body-1',
                  column.align && styles[`align${column.align.toUpperCase()}`]
                ])}>
                {column.headerName}
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRowSelection = (row: IRowTable, isRowSelected: boolean) => {
    if (!hasRowSelection) {
      return null;
    }

    const selectionIsDisabled = row?.isDisabled || disableSelection;
    const tooltipTextWhenIsDisabled =
      (selectionIsDisabled && row?.tooltipTextToDisabledRow) || '';

    return (
      <td aria-label="Caixa de seleção unico" id={`checkbox@${row.id}`}>
        <Tooltip
          content={tooltipTextWhenIsDisabled}
          id={`tooltip-disable-row@${row.id}`}
          place="left-start">
          <Checkbox
            checked={isRowSelected}
            onChange={() => selectRow(row)}
            disabled={selectionIsDisabled}
            id={`check@${row.id}`}
          />
        </Tooltip>
      </td>
    );
  };

  const renderRowStatus = (row: IRowTable) => {
    if (!hasStatus) {
      return null;
    }

    return (
      <td
        className={classNames([
          styles.statusContent,
          row.onStatusClick && styles.statusClickable
        ])}>
        <Tooltip
          id={`tooltip-status@${row.id}`}
          place="bottom"
          content={row.tooltipTextToStatus || ''}>
          <div
            onClick={row.onStatusClick && row.onStatusClick}
            className="d-flex align-center"
            aria-hidden>
            {iconStatusOptions[row.typeStatus!]}
          </div>
        </Tooltip>
      </td>
    );
  };

  const renderRows = () => {
    return rows.map((row) => {
      const isRowSelected = hasRowSelection
        ? selectedRows.some((item) => item.id === row.id)
        : false;
      const isRowInactive = verifyRowIsInactive(row);

      return (
        <tr
          key={row.id}
          className={classNames([
            'common-body-1',
            isRowSelected && styles.selectedRow,
            !isRowInactive && styles.activatedRow
          ])}>
          {renderRowStatus(row)}
          {renderRowSelection(row, isRowSelected)}
          {columns.map((column) => {
            if (column.invisible) {
              return null;
            }

            return (
              <td
                key={`${column.field}@${row.id}`}
                className={classNames([
                  isRowInactive && styles.inactive,
                  isRowInactive && isRowSelected && styles.inactiveSelectedRow
                ])}>
                <div id={`${column.field}@${row.id}`}>{row[column.field]}</div>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <table
      className={classNames([
        styles.table,
        hasRowSelection && styles.hasSelection,
        hasStatus && hasRowSelection && styles.hasSelectionWithStatus,
        className
      ])}
      {...props}>
      <thead>{renderColumns()}</thead>

      <tbody>{renderRows()}</tbody>
    </table>
  );
};

Table.defaultProps = {
  hasRowSelection: false,
  onSelectRows: undefined,
  hasStatus: false,
  disableSelection: false,
  className: '',
  selectedRows: []
};
