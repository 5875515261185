import React from 'react';
import { Row } from 'inmaster-ui';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetError,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithValidation from 'src/components/InputWithValidation';
import { AclType } from 'src/services/api/urls/devices/types';
import { Button } from 'ui-components/Button';
import Label from 'ui-components/Label';
import RadioButton from 'ui-components/RadioButton';
import api from 'src/services/api';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import styles from './InfoStep.module.css';

interface IProps {
  control: Control<
    {
      acl: {
        name: string;
        type: AclType;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  errors: FieldErrors<{
    acl: {
      name: string;
      type: AclType;
    };
  }>;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  nextStep: () => void;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  watch: UseFormWatch<{
    acl: {
      name: string;
      type: AclType;
    };
  }>;
  setError: UseFormSetError<{
    acl: {
      name: string;
      type: AclType;
    };
  }>;
  isValid: boolean;
}

const InfoStep = ({
  control,
  errors,
  setShowCancelModal,
  nextStep,
  handleRadioChange,
  watch,
  setError,
  isValid
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });
  const { id: idSite } = useParams();

  const getAclExists = () => {
    return api.devices.acls.getAclExists(watch('acl.name'), idSite);
  };

  const { refetch: fetchAclExists, isFetching } = useQuery(
    'getAclExistsQuery',
    getAclExists,
    {
      onSuccess: ({ data }) => {
        const errorText = t('Já existe uma Lista de Acesso com esse nome.');

        if (data.acl_exists) {
          setError('acl.name', {
            types: {
              request: errorText
            }
          });
        } else {
          nextStep();
        }
      },
      enabled: false
    }
  );

  return (
    <div>
      <Row className="mb-4">
        <Label>{t('Nome da lista')}:</Label>
        <InputWithValidation
          inputProps={{
            id: 'input-name-list'
          }}
          placeholder={t('Ex.: Impressoras')}
          control={control}
          controllerProps={{
            name: 'acl.name',
            rules: {
              required: {
                value: true,
                message: t('Campo obrigatório')
              },
              maxLength: {
                value: 32,
                message: t(
                  t('O nome da lista precisa ter entre 1 e 32 caracteres')
                )
              },
              validate: {
                notWhitespaceOnly: (value) =>
                  value.trim().length > 0 || (t('Campo obrigatório') as string),
                notStartsOrEndsWithWhitespace: (value) =>
                  (!value.startsWith(' ') && !value.endsWith(' ')) ||
                  (t(
                    'O nome da lista não pode começar nem terminar com um espaço'
                  ) as string)
              }
            }
          }}
          errors={errors.acl?.name}
        />
      </Row>

      <Row className="mb-2">
        <Label>{t('Selecione o tipo de lista')}:</Label>
      </Row>

      <Row className="mb-7">
        <Controller
          control={control}
          name="acl.type"
          render={({ field: { value } }) => (
            <div className="d-flex aling-center fit-width">
              <div className={styles.spacingTypeOfList}>
                <RadioButton
                  checked={value === AclType.ALLOW_LIST}
                  value={AclType.ALLOW_LIST}
                  id="btn-allow-list"
                  onChange={handleRadioChange}
                />
                <div className="d-flex flex-column">
                  <Label element="radiobutton" className="text-sm-sm">
                    {t('Lista de Permissão')}
                  </Label>

                  <Label
                    element="radiobutton"
                    className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                    {t(
                      'Clientes cadastrados nesta lista acessarão as Redes Wireless vinculados à ela'
                    )}
                  </Label>
                </div>
              </div>

              <div>
                <RadioButton
                  checked={value === AclType.DENY_LIST}
                  value={AclType.DENY_LIST}
                  id="btn-deny-list"
                  onChange={handleRadioChange}
                />
                <div className="d-flex flex-column">
                  <Label element="radiobutton" className="text-sm-sm">
                    {t('Lista de Bloqueio')}
                  </Label>

                  <Label
                    element="radiobutton"
                    className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                    {t(
                      'Clientes cadastrados nesta lista não acessarão as Redes Wireless vinculados à ela'
                    )}
                  </Label>
                </div>
              </div>
            </div>
          )}
        />
      </Row>

      <div className="d-flex justify-end">
        <Button
          ghost
          className="mr-3"
          id="btn-cancel"
          onClick={() => setShowCancelModal(true)}>
          {t('CANCELAR')}
        </Button>

        <Button
          id="button-info-next-step"
          onClick={() => {
            fetchAclExists();
          }}
          isLoading={isFetching}
          disabled={!isValid}>
          {t('PRÓXIMO')}
        </Button>
      </div>
    </div>
  );
};

export default InfoStep;
