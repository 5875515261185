import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSimplePassword from 'src/components/FormSimplePassword';
import { IFormSimplePassword } from 'src/pages/CaptivePortal/SimplePassword/DrawerFormSimplePassword/types';
import Card from 'ui-components/Card';
import { useFormCompare } from 'src/hooks/useFormCompare';
import TitleAndDescription from '../../../TitleAndDescription';
import stepStyles from '../../Steps.module.css';
import ButtonsActionsWizard from '../../../ButtonsActions';
import { IDataOfCaptiveMethodForms } from '../../../types';

interface IPropsStepSimplePasswordWizard {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setDataOfCaptiveMethodForms: React.Dispatch<
    React.SetStateAction<IDataOfCaptiveMethodForms>
  >;
  dataOfCaptiveMethodForms: IDataOfCaptiveMethodForms;
}

const StepSimplePasswordWizard = ({
  setCurrentStep,
  currentStep,
  setDataOfCaptiveMethodForms,
  dataOfCaptiveMethodForms
}: IPropsStepSimplePasswordWizard) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, defaultValues },
    watch,
    setValue,
    getValues,
    trigger,
    resetField
  } = useForm<IFormSimplePassword>({
    defaultValues: {
      simplePassword: {
        confirmPassword: '',
        password: '',
        periodType: '',
        periodInDays: [null, null],
        periodInTime: undefined
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  useEffect(() => {
    if (dataOfCaptiveMethodForms.password) {
      setValue(
        'simplePassword',
        dataOfCaptiveMethodForms.password?.simplePassword
      );
    }
  }, []);

  const formIsEqualToInitValues = useFormCompare({
    initialValues: defaultValues,
    currentValues: watch()
  });

  const onSubmit = handleSubmit((data: IFormSimplePassword) => {
    setDataOfCaptiveMethodForms({ password: data });
  });

  const checkIfPasswordsIsSame = () => {
    const values = getValues();
    return (
      values.simplePassword.password === values.simplePassword.confirmPassword
    );
  };

  const handleContinue = async () => {
    if (!checkIfPasswordsIsSame()) {
      setError('simplePassword.confirmPassword', {
        types: {
          request: t('As senhas não coincidem. Tente novamente') as string
        }
      });
      setError('simplePassword.password', {
        types: {
          request: t('As senhas não coincidem. Tente novamente') as string
        }
      });
      return false;
    }

    await onSubmit();

    const canContinue = Object.values(errors.simplePassword || {}).length === 0;

    return canContinue && !formIsEqualToInitValues;
  };

  return (
    <div>
      <TitleAndDescription
        title={t('Configurar método - Senha simples')}
        description={
          <span className={stepStyles.subtitle}>
            {t(
              'Para acesso simples e rápido dos usuários à rede sem fio, defina uma senha padrão e determine o tempo máximo de uso dos usuários conectados nessa rede.'
            )}
          </span>
        }
      />
      <Card className={stepStyles.cardForm}>
        <FormSimplePassword
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          resetField={resetField}
        />
      </Card>
      <ButtonsActionsWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        handleContinue={handleContinue}
      />
    </div>
  );
};

export default StepSimplePasswordWizard;
