import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import {
  ICreateOrUpdateWireless,
  IDataWireless,
  IWireless,
  IAdvancedWirelessData,
  IUpdateAdvancedWireless,
  IUpdateAssociatedWirelessCaptivePortal
} from './types';

export class WirelessAPI implements IRequestMethods {
  url = 'devices/wireless/';

  url_advanced = 'devices/wireless/advanced/';

  url_advanced_options = (siteId: string, placeId: string) =>
    `devices/sites/${siteId}/places/${placeId}/wireless/advanced/`;

  get(id = '') {
    return axiosInstance.get<IDataWireless>(this.url + id, {
      headers: getAuthHeaders()
    });
  }

  post(data: IWireless | ICreateOrUpdateWireless) {
    return axiosInstance.post(this.url, data, {
      headers: getAuthHeaders()
    });
  }

  put(data: ICreateOrUpdateWireless, id = '') {
    return axiosInstance.put(`${this.url}${id}/`, data, {
      headers: getAuthHeaders()
    });
  }

  deleteList(params = [] as string[]) {
    const urlString = `${this.url}?ids=${params.join('&ids=')}`;
    return axiosInstance.delete(urlString, {
      headers: getAuthHeaders(),
      params
    });
  }

  getWirelessOrderByDevice(deviceId: string) {
    return axiosInstance.get<IDataWireless>(`${this.url}`, {
      headers: getAuthHeaders(),
      params: {
        query_is_associated_device_id: deviceId
      }
    });
  }

  getWirelessByEnableCaptive(enableCaptive: boolean) {
    return axiosInstance.get<IDataWireless>(`${this.url}`, {
      headers: getAuthHeaders(),
      params: {
        is_captive_portal_enabled: enableCaptive
      }
    });
  }

  putAssociateWirelessCaptivePortal(
    data: IUpdateAssociatedWirelessCaptivePortal
  ) {
    return axiosInstance.put(`${this.url}`, data, {
      headers: getAuthHeaders()
    });
  }

  advanced = {
    get: () => {
      return axiosInstance.get<IAdvancedWirelessData>(this.url_advanced, {
        headers: getAuthHeaders()
      });
    },

    put: (data: IUpdateAdvancedWireless) => {
      return axiosInstance.put(this.url_advanced, data, {
        headers: getAuthHeaders()
      });
    },

    putFactoryStandard: (siteId: string, placeId: string) => {
      return axiosInstance.put(
        `${this.url_advanced_options(siteId, placeId)}reset/`,
        {},
        {
          headers: getAuthHeaders()
        }
      );
    }
  };
}
