import React, { HTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldError,
  MultipleFieldErrors,
  UseControllerProps
} from 'react-hook-form';
import { MdCancel } from 'react-icons/md';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Select from 'ui-components/Select';

interface ISelectWithValidationProps {
  errors: FieldError | undefined;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
  controllerProps: UseControllerProps;
  placeholder?: string;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  handleOnChange?: () => void;
  id?: string;
  options: {
    label: string;
    value: unknown;
  }[];
}

const SelectWithValidation = ({
  label,
  controllerProps,
  placeholder,
  control,
  errors,
  wrapperProps,
  handleOnChange,
  id,
  options,
  ...props
}: ISelectWithValidationProps) => {
  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <InputWrapper invalid={Boolean(errors)} {...wrapperProps}>
      <Label>{label}</Label>
      <Controller
        control={control}
        name={controllerProps.name}
        rules={controllerProps.rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            isInvalid={Boolean(errors)}
            id={id}
            onChange={(changes) => {
              if (handleOnChange) {
                handleOnChange();
              }
              onChange(changes);
            }}
            value={value}
            options={options}
            onBlur={onBlur}
            placeholder={placeholder}
            {...props}
          />
        )}
      />
      {getInputErrorMessage(errors?.types)}
    </InputWrapper>
  );
};

SelectWithValidation.defaultProps = {
  label: '',
  placeholder: '',
  wrapperProps: {},
  id: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOnChange: () => {}
};

export default SelectWithValidation;
