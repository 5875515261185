import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdDownload,
  MdSearch,
  MdSignalWifi1Bar,
  MdSignalWifi2Bar,
  MdSignalWifi3Bar,
  MdSignalWifi4Bar,
  MdUpload
} from 'react-icons/md';
import { useQuery } from 'react-query';

import { Col, Row, Tooltip } from 'inmaster-ui';
import Skeleton from 'react-loading-skeleton';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import TableWithPagination from 'src/components/TableWithPagination';
import { useAuth } from 'src/hooks/useAuth';
import { useTemplate } from 'src/hooks/useTemplate';
import api from 'src/services/api';
import { generateColumns } from 'src/utils/tableUtils';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import { useDebounce } from 'use-debounce';
import {
  IPagination,
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from '../Devices/types';
import { CustomerData, CustomerRow, IClientsData } from './types';

const Customers = () => {
  useTemplate('menuAndFullNavbar');

  const { accessToken } = useAuth();

  const { t } = useTranslation('translations', { keyPrefix: 'customers' });

  const [search, setSearch] = useState('');

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  const [paginationObject, setPaginationObject] = useState<IClientsData>(
    {} as IClientsData
  );

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const getCustomersPagination = () => {
    return api.clients.get(paginationRequestDataDebounce);
  };

  const { refetch: refetchCustomers, isFetching: isFetchingCustomers } =
    useQuery(
      ['getCustomers', paginationRequestDataDebounce, accessToken?.place_id],
      getCustomersPagination,
      {
        retry: false,
        onSuccess: ({ data }: { data: IClientsData }) => {
          if (!isInitialLoadingComplete) {
            setIsInitialLoadingComplete(true);
          }
          setPaginationObject(data);
        }
      }
    );

  useEffect(() => {
    refetchCustomers();
  }, [paginationRequestDataDebounce, refetchCustomers]);

  useEffect(() => {
    setPaginationRequestData((prev) => ({
      ...prev,
      search_param: search,
      page_number: 1
    }));
  }, [search]);

  const isResultNotFound =
    !isFetchingCustomers &&
    isInitialLoadingComplete &&
    paginationObject?.pagination?.total_filtered_items === 0;

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('mac', t('MAC dispositivo'), 'left', 0),
    generateColumns('ipv4', t('IP dispositivo'), 'left', 0),
    generateColumns('equipament', t('Equipamento'), 'left', 0),
    generateColumns('wireless_with_radio', 'Wireless', 'left', 0),
    generateColumns('connection_time', t('Tempo de conexão'), 'left', 0),
    generateColumns('signal', t('Sinal'), 'left', 0),
    generateColumns('upload', 'Upload', 'left', 0),
    generateColumns('download', 'Download', 'left', 0)
  ];

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  const generateSignalIcon = (signal: number) => {
    if (signal >= -67) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi4">
          <MdSignalWifi4Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    if (signal >= -73) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi3">
          <MdSignalWifi3Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    if (signal >= -80) {
      return (
        <Tooltip
          content={`${signal}dBm`}
          place="bottom-end"
          id="tooltip-signal-wifi2">
          <MdSignalWifi2Bar
            size={24}
            style={{ color: 'var(--color-brand-secondary-light)' }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        content={`${signal}dBm`}
        place="bottom-end"
        id="tooltip-signal-wifi1">
        <MdSignalWifi1Bar
          size={24}
          style={{ color: 'var(--color-brand-secondary-light)' }}
        />
      </Tooltip>
    );
  };

  const formatTime = (seconds: number) => {
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds - hour * 3600) / 60);

    if (hour === 0) {
      return `${minute}min`;
    }

    return `${hour}h${minute}min`;
  };

  const createRowToDisplay = (rowsArray: CustomerData[]) => {
    if (!rowsArray) {
      return [];
    }
    const auxRows: CustomerRow[] = [];
    for (const row of rowsArray) {
      auxRows.push({
        id: row.node_id,
        device: row.hardware_alias,
        mac: row.node_id,
        ipv4: row.node_ipv4 || '-',
        equipament: (
          <Tooltip
            content={row.hardware_alias}
            place="bottom-start"
            id="tooltip-hardware-alias">
            <div>{row.hardware_alias}</div>
          </Tooltip>
        ),
        wireless_with_radio: (
          <div>
            <Tooltip
              content={row.wifi_name}
              place="bottom-start"
              id="tooltip-wifi-name">
              <div>{row.wifi_name}</div>
            </Tooltip>

            <span>{row.wifi_radio}</span>
          </div>
        ),
        connection_time: formatTime(row.node_uptime),
        signal: generateSignalIcon(row.node_signal),
        upload: (
          <div className="d-flex align-center">
            <MdUpload size={23} />
            <span className="ml-2">{formatBytes(row.node_upload)}</span>
          </div>
        ),
        download: (
          <div className="d-flex align-center">
            <MdDownload size={23} />
            <span className="ml-2">{formatBytes(row.node_download)}</span>
          </div>
        )
      });
    }
    return auxRows;
  };

  const skeletonRows = (numberOfSkeletons: number) => {
    const auxRows = [];
    for (let i = 0; i < numberOfSkeletons; i += 1) {
      auxRows.push(
        <div
          className="py-5 d-flex justify-between"
          key={`${i}-skeleton-customers`}>
          <Skeleton width={160} height={19} />
          <Skeleton width={130} height={19} />
          <Skeleton width={130} height={19} />
          <Skeleton width={40} height={19} />
          <Skeleton width={40} height={19} />
          <Skeleton width={40} height={40} />
          <Skeleton width={50} height={19} />
          <Skeleton width={50} height={19} />
        </div>
      );
    }
    return auxRows;
  };

  const renderCustomers = () => {
    if (
      isInitialLoadingComplete &&
      paginationObject?.pagination?.total_items === 0
    ) {
      return (
        <EmptyMessage
          height="calc(100vh - 255px)"
          id="no-customers"
          title={t('Nenhum cliente')}
          subtitle={t('Ainda não há cliente conectado neste ambiente')}
        />
      );
    }
    return (
      <div>
        <Card className="mb-4">
          <div className="mb-2">
            {isInitialLoadingComplete ? (
              <Label>Pesquisar cliente:</Label>
            ) : (
              <Skeleton width={120} height={19} />
            )}
          </div>
          {isInitialLoadingComplete ? (
            <InputIconInside
              iconLeft={
                isFetchingCustomers ? (
                  <Loading value={40} indeterminate />
                ) : (
                  <MdSearch />
                )
              }>
              <Input
                id="search-input"
                value={search}
                iconInsideLeft
                placeholder={t('Digite no mínimo 3 caracteres')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />
            </InputIconInside>
          ) : (
            <Skeleton height={51.1} />
          )}
        </Card>
        <Card>
          {isResultNotFound ? (
            <ResultsNotFoundMessage
              id="customers"
              minHeight="190px"
              height="calc(100vh - 420px)"
            />
          ) : (
            <TableWithPagination<CustomerRow>
              noSelection
              isChangingPage={isChangingPage}
              columns={columns}
              setPaginationObject={setPaginationObject}
              loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
              rows={createRowToDisplay(paginationObject.now_connected_nodes)}
              isFetchingData={isFetchingCustomers}
              paginationData={paginationObject.pagination as IPagination}
              firstFetchWasMade={isInitialLoadingComplete}
              paginationRequestData={paginationRequestDataDebounce}
              renderSkeletonsOfTable={skeletonRows}
              setIsChangingPage={setIsChangingPage}
              setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
              setPaginationRequestData={setPaginationRequestData}
              minRowsToShowPagination={10}
              keyOfItemsOfPaginationTable="now_connected_nodes"
            />
          )}
        </Card>
      </div>
    );
  };

  return (
    <div>
      <Row>
        <Col xs={12} className="mb-7">
          <h2
            className="title-xl-base text-uppercase"
            style={{ color: 'var(--color-neutral-dark-3)' }}>
            {t('CLIENTES CONECTADOS')}
          </h2>
        </Col>
      </Row>
      {renderCustomers()}
    </div>
  );
};

export default Customers;
