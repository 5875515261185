import React, { HTMLAttributes } from 'react';
import {
  InputWrapper as InputWrapperConectese,
  InputIconInside as InputIconInsideConectese,
  InputFile as InputFileConectese
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@conecte-se/ui-components-reactjs';
import '@conecte-se/ui-components-reactjs/dist/index.css'; // todo: move to index file after mvp
import '@conecte-se/ui-components-reactjs/src/styles/theme/external_lib_themes.scss'; // todo: move to index file after mvp
import InputHelper from 'ui-components/InputHelper';
import Label from 'ui-components/Label';

interface IInputFileProps {
  file: File | null;
  label?: string;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  multipleFiles?: boolean;
  disabledField?: boolean;
  hasError?: boolean;
  messageError?: string;
  divProps?: HTMLAttributes<HTMLDivElement>;
  placeholder?: string;
  acceptFileTypes?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  onChange: (file: File) => void;
}
const InputFile = ({
  file,
  label,
  iconLeft,
  iconRight,
  multipleFiles,
  disabledField,
  hasError,
  messageError,
  divProps,
  placeholder,
  inputProps,
  acceptFileTypes,
  onChange
}: IInputFileProps) => {
  const updateFile = (fileUpdate: File) => {
    file = fileUpdate;
    onChange(fileUpdate);
  };
  return (
    <div {...divProps}>
      <InputWrapperConectese invalid={hasError}>
        <Label>{label}</Label>
        <InputIconInsideConectese iconRight={iconRight} iconLeft={iconLeft}>
          <InputFileConectese
            placeholder={placeholder}
            files={file}
            onChange={updateFile}
            disabled={disabledField}
            multipleFiles={multipleFiles}
            accept={acceptFileTypes}
            {...inputProps}
          />
        </InputIconInsideConectese>
        <InputHelper value={messageError} />
      </InputWrapperConectese>
    </div>
  );
};

InputFile.defaultProps = {
  label: '',
  iconRight: null,
  iconLeft: null,
  hasError: false,
  multipleFiles: false,
  disabledField: false,
  messageError: '',
  placeholder: '',
  acceptFileTypes: '',
  divProps: {},
  inputProps: {}
};

export { InputFile };
