import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTemplate } from 'src/hooks/useTemplate';

const Home = () => {
  useTemplate('menuAndFullNavbar');
  return <Navigate to="/devices" replace />;
};

export { Home };
