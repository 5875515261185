import classNames from 'classnames';
import React from 'react';
import ResponseMessage from 'ui-components/ResponseMessage';
import styles from './ProgressBar.module.css';
function ProgressBar({
  type = '', // default, success, error, warning
  message = '',
  width = '',
  value = 0,
  className = '',
  ...props
}) {
  return (
    <div className={styles.progressBarContainer}>
      <div
        className={classNames([className, styles.progressBarMain])}
        {...props}>
        <div
          style={{ width: width ? width : '100%' }}
          className={classNames([
            styles.progressBarShell,
            type === 'default' ? styles.success : styles[type]
          ])}>
          <div
            style={{ width: `${value}%` }}
            className={styles.progressBarInside}
          />
        </div>
        {message.length ? (
          <ResponseMessage className={styles.responseMessage} status={type}>
            {message}
          </ResponseMessage>
        ) : null}
      </div>
    </div>
  );
}

export default ProgressBar;
