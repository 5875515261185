import { QueryClient } from 'react-query';
import { IErrorResponse } from '../api/types';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_failureCount, error) => {
        const typedError = error as IErrorResponse;
        if (typedError.response.status === 404) return false;

        return true;
      }
    }
  }
});
