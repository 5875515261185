import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

// Página resposável por receber o redirect do conta unica
const LogoutRedirect = () => {
  const params = useParams();
  const { handleLogout } = useAuth();

  const checkLogout = useCallback(() => {
    // tipos de logout: logout | unauthorized | unauthorized_401
    if (
      params.typeOfLogout?.includes('logout') || // Verifica o tipo de logout
      params.typeOfLogout?.includes('unauthorized')
    ) {
      // Realiza o logout do backend, após o conta unica deslogar
      handleLogout(params.typeOfLogout as string);
    }
  }, [handleLogout, params.typeOfLogout]);

  useEffect(() => {
    checkLogout();
  }, [checkLogout]);

  return <div>.</div>;
};

export { LogoutRedirect };
