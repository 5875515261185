import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery
} from 'react-query';
import { useParams } from 'react-router-dom';
import { useArrayCompare } from 'src/hooks/useFormCompare';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IErrorResponse } from 'src/services/api/types';
import {
  IDeviceProperties,
  IRadioData,
  IRadioRules,
  IUpdateRadioData
} from 'src/services/api/urls/devices/types';
import { eventGA } from 'src/utils/analytics';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import Modal from 'ui-components/Modal';
import { IDeviceOnScreen } from '../../types';
import RadioCard from '../RadioCard';

interface IProps {
  deviceOnScreen: IDeviceOnScreen;
  radios: IRadioData[] | null;
  hasWirelessAssociated: boolean;
  refetchDeviceInfo: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined
  ) => Promise<
    QueryObserverResult<
      {
        data: IDeviceOnScreen;
      },
      unknown
    >
  >;
  isFetchedAfterMountDeviceInfo: boolean;
  radiosUpdatedData: IRadioData[] | null;
  setRadios: React.Dispatch<React.SetStateAction<IRadioData[] | null>>;
  setRadiosUpdatedData: React.Dispatch<
    React.SetStateAction<IRadioData[] | null>
  >;
}

const RadioSettings = ({
  deviceOnScreen,
  radios,
  isFetchedAfterMountDeviceInfo,
  hasWirelessAssociated,
  refetchDeviceInfo,
  radiosUpdatedData,
  setRadios,
  setRadiosUpdatedData
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });
  const { triggerNotificationAnimationWithText } = useNotification();
  const params = useParams();
  const { addToast } = useToast();

  const [isEditingRadios, setIsEditingRadios] = useState(false);
  const [radioRules, setRadioRules] = useState<IRadioRules | null>(null);

  const updateRadios = () => {
    const radiosUpdated: IUpdateRadioData = { radio_list: [] };
    if (radiosUpdatedData) {
      radiosUpdated.radio_list = [...radiosUpdatedData];
    }

    return api.devices.radio.put(params.id as string, radiosUpdated);
  };

  const isRadiosChanged = !useArrayCompare(
    deviceOnScreen?.radio_list || [],
    radiosUpdatedData || []
  );

  const editRadioMutation = useMutation(updateRadios, {
    onSuccess: () => {
      setRadios(radiosUpdatedData);
      triggerNotificationAnimationWithText(t('CONFIGURANDO EQUIPAMENTO'));
      setIsEditingRadios(false);
      refetchDeviceInfo();
      addToast('success', t('Configurações de Rádio editadas com sucesso'));
      setIsEditingRadios(false);
    },
    onError(error: IErrorResponse) {
      if (error.response.status === 304) {
        addToast(
          'error',
          t(
            'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
          )
        );
      } else {
        addToast(
          'error',
          t(
            'Estamos passando por uma instabilidade, tente novamente mais tarde'
          )
        );
      }
    }
  });

  const getRadioRules = (model: string) => {
    return api.devices.properties.get(model);
  };

  useQuery(
    ['radioRulesQuery', deviceOnScreen?.device],
    () => getRadioRules(deviceOnScreen?.device?.model),
    {
      enabled: !!deviceOnScreen?.device?.model,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: IDeviceProperties }) => {
        setRadioRules(data.properties.radio_rules);
      }
    }
  );

  const handleSubmitEditRadios = () => {
    eventGA('Equipamentos', 'Click', 'Salvar edições de rádio');
    editRadioMutation.mutate();
  };

  const [isModalWithoutWirelessOpen, setIsModalWithoutWirelessOpen] =
    useState(false);

  const actionsOfModalWithoutWireless = [
    {
      label: 'OK',
      action: () => setIsModalWithoutWirelessOpen(false)
    }
  ];

  const handleIsPossibleToEditRadios = () => {
    if (hasWirelessAssociated) {
      setIsEditingRadios(true);
    } else {
      setIsModalWithoutWirelessOpen(true);
    }
  };

  return (
    <div>
      {!isEditingRadios ? (
        <div className="fit-width d-flex justify-end mb-3">
          {isFetchedAfterMountDeviceInfo ? (
            <>
              <Modal
                width="460px"
                show={isModalWithoutWirelessOpen}
                actions={actionsOfModalWithoutWireless}>
                <p>
                  {t(
                    'Para editar as configurações deste equipamento é necessário que haja, pelo menos, uma'
                  )}
                  <span className="text-bold"> wireless associada</span>{' '}
                  {t('a ele')}.
                </p>
              </Modal>
              <ButtonTextOutside value={t('EDITAR CONFIGURAÇÕES')}>
                <Button
                  id="btn-edit-configurations"
                  rounded
                  onClick={() => {
                    eventGA('Equipamentos', 'Click', 'Tentar editar rádios');
                    handleIsPossibleToEditRadios();
                  }}>
                  <MdEdit size={22} />
                </Button>
              </ButtonTextOutside>
            </>
          ) : (
            <Skeleton width={247} height={48} />
          )}
        </div>
      ) : (
        ''
      )}

      {isFetchedAfterMountDeviceInfo ? (
        radios?.map((radio) => {
          return (
            <RadioCard
              key={radio.band}
              title={`${radio.band} GHz`}
              isEditing={isEditingRadios}
              radioData={radio}
              setRadiosData={setRadiosUpdatedData}
              radioBandRules={radioRules?.[radio.band] || null}
              isLoading={false}
            />
          );
        })
      ) : (
        <>
          <RadioCard
            title=""
            isEditing={false}
            radioData={null}
            setRadiosData={() => null}
            radioBandRules={null}
            isLoading={!isFetchedAfterMountDeviceInfo}
          />
          <RadioCard
            title=""
            isEditing={false}
            radioData={null}
            setRadiosData={() => null}
            radioBandRules={null}
            isLoading={!isFetchedAfterMountDeviceInfo}
          />
        </>
      )}
      {isEditingRadios && (
        <div className="d-flex justify-end mt-6">
          <Button
            disabled={editRadioMutation.isLoading}
            className="mr-2"
            id="btn-cancel-edit-configurations"
            ghost
            onClick={() => {
              setIsEditingRadios(false);
              setRadiosUpdatedData(radios);
            }}>
            {t('CANCELAR')}
          </Button>
          <Button
            disabled={!isRadiosChanged}
            isLoading={editRadioMutation.isLoading}
            className="mr-2"
            id="btn-save-configurations"
            onClick={handleSubmitEditRadios}>
            {t('SALVAR')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RadioSettings;
