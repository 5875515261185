import React from 'react';
import classNames from 'classnames';
import styles from './InputWrapper.module.css';

const InputWrapper = ({
  children,
  iconLeft = false,
  iconRight = false,
  valid = false,
  invalid = false,
  alert = false,
  className = ''
}) => {
  return (
    <div
      className={classNames([
        styles.inputwrapper,
        valid && styles.valid,
        invalid && styles.invalid,
        alert && styles.alert,
        className
      ])}>
      {iconLeft && (
        <span className={classNames([iconLeft && styles.icon_inside_left])}>
          {iconLeft}
        </span>
      )}
      {iconRight && (
        <span className={classNames([iconRight && styles.icon_inside_right])}>
          {iconRight}
        </span>
      )}
      {children}
    </div>
  );
};

const InputIconInside = ({ children, iconLeft, iconRight }) => {
  return (
    <div className={classNames([styles.inputiconinside])}>
      {children}
      {iconLeft && (
        <span className={classNames([iconLeft && styles.icon_inside_left])}>
          {iconLeft}
        </span>
      )}
      {iconRight && (
        <span className={classNames([iconRight && styles.icon_inside_right])}>
          {iconRight}
        </span>
      )}
    </div>
  );
};

export { InputWrapper, InputIconInside };
