import React from 'react';
import { Button } from 'ui-components/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './StepImage.module.css';

interface IStepImageProps {
  text: string;
  image: string;
  title: string;
  onNextStep: () => void;
  onBackStep?: () => void;
  onSkip?: () => void;
  // eslint-disable-next-line react/require-default-props
  textSkip?: string;
  step: number;
  noSkip?: boolean;
  noBackStep?: boolean;
}

const StepImage = ({
  text,
  image,
  title,
  onNextStep,
  onBackStep,
  onSkip,
  textSkip,
  noSkip,
  noBackStep,
  step
}: IStepImageProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'firstAccess' });
  return (
    <div>
      <div
        className={classNames([
          'd-flex justify-center align-center fit-width',
          styles.imageShell
        ])}>
        <img
          src={image}
          alt="Imagem de apresentação"
          className={styles.image}
        />
      </div>
      <div>
        <h1
          id="step-title"
          className={
            step === 0 ? 'title-xl-xl text-bold' : 'title-xl-lg text-bold'
          }>
          {title}
        </h1>
      </div>
      <div className="mt-3">
        <p className="text-xl-base">{text}</p>
      </div>
      <div
        className={classNames([
          'd-flex justify-between align-center',
          styles.buttonsShell
        ])}>
        <div>
          {noSkip ? null : (
            <Button
              type="button"
              ghost
              id={
                textSkip?.length && textSkip.length > 0
                  ? 'back-to-explanation'
                  : 'btn-skip'
              }
              onClick={() => (onSkip ? onSkip() : null)}>
              {textSkip || t('Pular')}
            </Button>
          )}
        </div>
        <div className="d-flex">
          {noBackStep ? null : (
            <Button
              outline
              id="btn-back"
              type="button"
              onClick={onBackStep ? () => onBackStep() : () => null}
              className="mr-3">
              {t('Voltar')}
            </Button>
          )}
          <Button
            type="button"
            onClick={() => onNextStep()}
            id={step === 5 ? 'btn-start' : 'btn-next'}>
            {step === 5 ? t('Começar') : t('Próximo')}
          </Button>
        </div>
      </div>
    </div>
  );
};

StepImage.defaultProps = {
  onBackStep: null,
  onSkip: null,
  noSkip: false,
  noBackStep: false
};

export default StepImage;
