import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const MobileAuthRedirect = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const message = {
      code: searchParams.get('code'),
      session_state: searchParams.get('session_state'),
      redirect_uri: String(
        process.env.REACT_APP_CONTA_INTELBRAS_APP_REDIRECT_URI
      )
    };

    if (searchParams.get('code')) {
      window.opener.postMessage(JSON.stringify(message), '*');
    }

    window.close();
  }, [searchParams]);

  return <div>.</div>;
};

export { MobileAuthRedirect };
