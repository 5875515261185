import React from 'react';
import { Col, Row, Tooltip } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdArticle, MdDelete, MdEdit, MdSearch } from 'react-icons/md';
import { IOption } from 'src/components/InputTime/types';
import {
  IIndividualRecordFormType,
  IIndividualRecordValue,
  IIndividualRecordFormValues
} from 'src/components/FormAddIndividualRecord/types';
import { DeleteIndividualRecordModal } from 'src/pages/CaptivePortal/IndividualRecord/DeleteIndividualRecordModal';
import classNames from 'classnames';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { generateColumns } from 'src/utils/tableUtils';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import { useAuth } from 'src/hooks/useAuth';
import api from 'src/services/api';
import { ICaptivePortal } from 'src/pages/CaptivePortal/types';
import {
  IGetExpiredIndividualRecords,
  IIndividualRecord as IIndividualRecordResponse
} from 'src/services/api/urls/captivePortal/types';
import { useQuery } from 'react-query';
import Table from 'ui-components/Table';
import { IIndividualRecord } from 'src/pages/CaptivePortal/IndividualRecord/types';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import { Alert } from 'ui-components/Alert';
import { DateCalculator } from 'src/utils/Dates/DateCalculator';
import stepStyles from '../../Steps.module.css';
import { IDataOfCaptiveMethodForms } from '../../../types';
import ButtonsActionsWizard from '../../../ButtonsActions';
import TitleAndDescription from '../../../TitleAndDescription';
import { MoreInformationModal } from './MoreInformationModal';
import IndividualRecordFormWizardDrawer from './IndividualRecordFormWizardDrawer';

interface IPropsStepIndividualRecordWizard {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setDataOfCaptiveMethodForms: React.Dispatch<
    React.SetStateAction<IDataOfCaptiveMethodForms>
  >;
  defaultCaptivePortal: ICaptivePortal;
  dataOfCaptiveMethodForms: IDataOfCaptiveMethodForms;
}

const StepIndividualRecordWizard = ({
  currentStep,
  setCurrentStep,
  defaultCaptivePortal,
  dataOfCaptiveMethodForms,
  setDataOfCaptiveMethodForms
}: IPropsStepIndividualRecordWizard) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive.individualRecord'
  });

  const { accessToken } = useAuth();

  const [showAddIndividualRecordDrawer, setShowAddIndividualRecordDrawer] =
    React.useState(false);

  const [selectedRecords, setSelectedRecords] = React.useState<
    IIndividualRecord[]
  >([]);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [selectedRecordToShowInformation, setSelectedRecordToShowInformation] =
    React.useState<IIndividualRecordValue>({} as IIndividualRecordValue);

  const [showMoreInformationModal, setShowMoreInformationModal] =
    React.useState(false);

  const [typeOfDrawer, setTypeOfDrawer] =
    React.useState<IIndividualRecordFormType>('add_wizard');

  const [search, setSearch] = React.useState('');

  const [selectedRecordToUpdate, setSelectedRecordToUpdate] =
    React.useState<IIndividualRecordValue>({} as IIndividualRecordValue);

  const [individualRecordsExpired, setIndividualRecordsExpired] =
    React.useState<IIndividualRecordResponse[]>();

  const individualRecordsListInWizard =
    dataOfCaptiveMethodForms.individualRecord
      ? dataOfCaptiveMethodForms.individualRecord.map(
          (record) => record.individualRecord
        )
      : [];

  const hasIndividualRecord = dataOfCaptiveMethodForms.individualRecord
    ? dataOfCaptiveMethodForms.individualRecord.length > 0
    : false;

  const getAllExpiredRecords = () => {
    return api.captivePortal.individualRecord.getExpiredHistoric(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      defaultCaptivePortal.id || '',
      {
        page_number: 1,
        page_size: 99999,
        search_param: ''
      }
    );
  };

  useQuery(
    ['getAllExpiredRecords', defaultCaptivePortal.id],
    getAllExpiredRecords,
    {
      enabled: Object.keys(defaultCaptivePortal).length > 0,
      onSuccess: ({ data }: { data: IGetExpiredIndividualRecords }) => {
        setIndividualRecordsExpired(data.history_individual_records);
      }
    }
  );

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onDeleteRecord = () => {
    const newListOfRecordsWithoutSelects =
      dataOfCaptiveMethodForms.individualRecord?.filter((record) => {
        return !selectedRecords.find((selectedRecord) => {
          return selectedRecord.document === record.individualRecord.docNumber;
        });
      });

    setDataOfCaptiveMethodForms({
      ...dataOfCaptiveMethodForms,
      individualRecord: newListOfRecordsWithoutSelects
    });

    setShowDeleteModal(false);
    setSelectedRecords([]);
  };

  const handleUpdateRecord = (newRecord: IIndividualRecordFormValues) => {
    const newListWithRecordUpdated =
      dataOfCaptiveMethodForms.individualRecord?.map((record) => {
        if (
          record.individualRecord.docNumber === selectedRecordToUpdate.docNumber
        ) {
          return newRecord;
        }

        return record;
      });

    setDataOfCaptiveMethodForms({
      ...dataOfCaptiveMethodForms,
      individualRecord: newListWithRecordUpdated
    });

    setSelectedRecordToUpdate({} as IIndividualRecordValue);
  };

  const handleCreateRecord = (newRecord: IIndividualRecordFormValues) => {
    const oldList = dataOfCaptiveMethodForms.individualRecord || [];

    setDataOfCaptiveMethodForms({
      ...dataOfCaptiveMethodForms,
      individualRecord: [...oldList, newRecord]
    });
  };

  const onSubmit = (newIndividualRecord: IIndividualRecordFormValues) => {
    if (typeOfDrawer === 'add_wizard') {
      handleCreateRecord(newIndividualRecord);
    } else if (typeOfDrawer === 'edit_wizard') {
      handleUpdateRecord(newIndividualRecord);
    }
    setShowAddIndividualRecordDrawer(false);
  };

  const handleContinue = () => {
    return hasIndividualRecord;
  };

  const recordsFilteredBySearch =
    dataOfCaptiveMethodForms.individualRecord?.filter((record) => {
      const { individualRecord } = record;

      return (
        individualRecord.docNumber
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        individualRecord.name.toLowerCase().includes(search.toLowerCase()) ||
        individualRecord.phone.toLowerCase().includes(search.toLowerCase()) ||
        individualRecord.email.toLowerCase().includes(search.toLowerCase())
      );
    });

  const columnsTableIndividualRecord = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('authorizedStatus', t('Autorizado'), 'left', 0),
    generateColumns('document', t('Nº Documento'), 'left', 0),
    generateColumns('time', t('Tempo'), 'left', 0),
    generateColumns('actions', t('Ações'), 'left', 0)
  ];

  const getFormattedTime = (
    periodType: string | undefined,
    periodInDays: [Date | null, Date | null],
    periodInTime: IOption | undefined
  ): string => {
    if (periodType === 'date') {
      const dates = periodInDays;

      if (dates[0] && dates[1]) {
        return new DateCalculator(
          dates[0],
          dates[1]
        ).getDiffBetweenStartAndEndDate();
      }
    }

    if (periodType === 'time' && periodInTime) {
      const time = periodInTime;

      if (time.label && time.value) {
        return time.label;
      }
    }

    return '--';
  };

  const onClickToOpenShowMoreInformationModal = (
    selectedRecord: IIndividualRecordValue
  ) => {
    setShowMoreInformationModal(true);
    setSelectedRecordToShowInformation(selectedRecord);
  };

  const onCloseMoreInformationModal = () => {
    setShowMoreInformationModal(false);
    setSelectedRecordToShowInformation({} as IIndividualRecordValue);
  };

  const generateRows = (rows: IIndividualRecordFormValues[]) => {
    return rows.map((row) => {
      const { individualRecord } = row;

      return {
        id: individualRecord.docNumber,
        name: individualRecord.name,
        authorizedStatus: individualRecord.authorized ? t('Sim') : t('Não'),
        document: individualRecord.docNumber,
        time: getFormattedTime(
          individualRecord.periodType,
          individualRecord.periodInDays,
          individualRecord.periodInTime
        ),
        actions: (
          <div className={classNames('d-flex justify-between')}>
            <Tooltip
              id="tooltip-more-information"
              content={t('Mais informações')}
              place="top">
              <ButtonIcon
                id="button-more-information"
                onClick={() => {
                  onClickToOpenShowMoreInformationModal(individualRecord);
                }}>
                <MdArticle size={18} />
              </ButtonIcon>
            </Tooltip>

            <ButtonIcon
              id="button-edit-record"
              onClick={() => {
                setTypeOfDrawer('edit_wizard');
                setSelectedRecordToUpdate(individualRecord);
                setShowAddIndividualRecordDrawer(true);
              }}>
              <MdEdit size={18} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const renderEmptyMessageCard = () => {
    return (
      <Card className={stepStyles.cardForm}>
        <EmptyMessage
          id="empty-add-individual-record"
          title={t('Nenhum cadastro adicionado')}
          subtitle={`${t(
            'Ainda não há nenhum cadastro individual disponível neste ambiente. Experimente adicionar um novo'
          )}.`}
          buttonText={t('Adicionar novo')}
          action={() => {
            setTypeOfDrawer('add_wizard');
            setShowAddIndividualRecordDrawer(true);
          }}
          minHeight="190px"
          height="calc(100vh - 500px)"
        />
      </Card>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Card className="px-7 py-6">
          {selectedRecords.length === 0 ? (
            <Row between="md" className="mb-1">
              <Col xs={12}>
                <Label>{t('Pesquisar na lista')}:</Label>
              </Col>
              <Row className="mt-1 fit-width">
                <Row className="align-center justify-between flex-nowrap fit-width">
                  <Col xs={9} className="fit-width">
                    <InputIconInside iconRight={<MdSearch />}>
                      <Input
                        id="search-input"
                        disabled={false}
                        iconInsideRight
                        placeholder={t(
                          'Pesquise pelo nº documento, nome, telefone ou e-mail'
                        )}
                        value={search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSearch(e.target.value)
                        }
                      />
                    </InputIconInside>
                  </Col>

                  <Col>
                    <ButtonTextOutside
                      id="text-btn-open-modal-to-add-individual-record"
                      value={t('Adicionar novo')}
                      className="text-uppercase">
                      <Button
                        id="btn-open-modal-to-add-individual-record"
                        disabled={false}
                        onClick={() => {
                          setTypeOfDrawer('add_wizard');
                          setShowAddIndividualRecordDrawer(true);
                        }}
                        rounded>
                        <MdAdd size={18} />
                      </Button>
                    </ButtonTextOutside>
                  </Col>
                </Row>
              </Row>
            </Row>
          ) : (
            <Row className="mb-1">
              <Col xs={12} className="d-flex">
                <ButtonTextOutside
                  value={`${t('Excluir')} (${selectedRecords.length})`}
                  className="text-uppercase mt-6 mb-6 ml-7">
                  <Button
                    onClick={() => setShowDeleteModal(true)}
                    id="button-delete-record"
                    rounded
                    color="var(--color-status-critical-base)">
                    <MdDelete size={22} />
                  </Button>
                </ButtonTextOutside>
              </Col>
            </Row>
          )}
        </Card>

        {recordsFilteredBySearch?.length === 0 ? (
          <ResultsNotFoundMessage id="result-not-found-individual-record" />
        ) : (
          <Card className={classNames([stepStyles.cardForm, 'mt-5'])}>
            <Alert type="warning">
              {t(
                'Atenção, os cadastros dessa tabela terão sua data de validade contabilizada a partir do momento em que a ativação do método estiver finalizada'
              )}
            </Alert>
            <Table
              columns={columnsTableIndividualRecord}
              rows={generateRows(recordsFilteredBySearch || [])}
              selectedRows={selectedRecords}
              onSelectRows={(selectedRows: IIndividualRecord[]) => {
                setSelectedRecords(selectedRows);
              }}
            />
          </Card>
        )}
      </>
    );
  };

  return (
    <div>
      <TitleAndDescription
        title={t('Configurar método - Cadastro individual')}
        infoModal={{
          description: t(
            'Este método é indicado para quem deseja cadastrar e identificar todos os clientes conectados à rede, de forma a autorizar e desautorizar seus acessos, delimitando o período de uso de forma individual.'
          ),
          title: t('Cadastro individual')
        }}
      />

      <DeleteIndividualRecordModal
        countSelectedRecords={selectedRecords.length}
        showDeleteModal={showDeleteModal}
        hasUsedRecords={false}
        isLoadingDeleteMutation={false}
        onDeleteRecord={onDeleteRecord}
        onCloseDeleteModal={onCloseDeleteModal}
      />

      <IndividualRecordFormWizardDrawer
        show={showAddIndividualRecordDrawer}
        onClose={() => {
          setShowAddIndividualRecordDrawer(false);
          setTypeOfDrawer('add_wizard');
          setSelectedRecordToUpdate({} as IIndividualRecordValue);
        }}
        onSubmit={onSubmit}
        type={typeOfDrawer}
        individualRecordsListInWizard={individualRecordsListInWizard}
        selectedRecordToUpdate={selectedRecordToUpdate}
        individualRecordsExpired={individualRecordsExpired}
      />

      <MoreInformationModal
        selectedIndividualRecord={{
          ...selectedRecordToShowInformation,
          period: getFormattedTime(
            selectedRecordToShowInformation.periodType,
            selectedRecordToShowInformation.periodInDays,
            selectedRecordToShowInformation.periodInTime
          )
        }}
        onCloseMoreInformationModal={() => onCloseMoreInformationModal()}
        showMoreInformationModal={showMoreInformationModal}
        onClickEditInformation={() => {
          setTypeOfDrawer('edit_wizard');
          setSelectedRecordToUpdate(selectedRecordToShowInformation);
          setShowAddIndividualRecordDrawer(true);
          setShowMoreInformationModal(false);
          setSelectedRecordToShowInformation({} as IIndividualRecordValue);
        }}
      />

      {hasIndividualRecord ? renderContent() : renderEmptyMessageCard()}

      <ButtonsActionsWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        handleContinue={handleContinue}
        disabledContinue={!hasIndividualRecord}
        disableTooltip={!hasIndividualRecord}
      />
    </div>
  );
};

export default StepIndividualRecordWizard;
