import React from 'react';
import classNames from 'classnames';
import { Col, Grid, Row } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui-components/Alert';
import InfoCaptiveAndFirmwareModal from 'src/components/InfoCaptiveAndFirmwareModal';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { IResumedWireless } from '../AssociatedWireless/types';
import { ICaptivePortal } from '../types';
import { IMethodsTypes, IMethodTypeName } from './types';
import { CardOfCaptivePortalType } from './CardOfCaptivePortalType';
import styles from './SetUpMethod.module.css';
import ActivateCaptiveInfoModal from './ActivateInfoModal';
import { DisableCaptiveButton } from '../DisableCaptiveButton';
import BlockActiveCaptiveModal from '../BlockActiveCaptiveModal';

interface ISetUpMethod {
  defaultCaptivePortal: ICaptivePortal;
  methodsTypes: IMethodsTypes;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  setDefaultCaptivePortal: React.Dispatch<React.SetStateAction<ICaptivePortal>>;
}

const SetUpMethod = ({
  defaultCaptivePortal,
  methodsTypes,
  isFetchedCaptivePortal,
  isLoadingFormCaptive,
  setDefaultCaptivePortal
}: ISetUpMethod) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.setUpMethod'
  });

  const navigate = useNavigate();

  const [showDeprecatedFirmwareModal, setShowDeprecatedFirmwareModal] =
    React.useState(false);

  const [showActivateInfoModal, setShowActivateInfoModal] =
    React.useState(false);

  const [showBlockActiveCaptiveModal, setShowBlockActiveCaptiveModal] =
    React.useState(false);

  const [methodToActivate, setMethodToActivate] =
    React.useState<IMethodTypeName>();

  const getWireless = async () => {
    return api.wireless.get();
  };

  const [hasOneOrMoreWireless, setHasOneOrMoreWireless] = React.useState(false);
  const [disableCaptivePortalIsLoading, setDisableCaptivePortalIsLoading] =
    React.useState(false);

  const { isFetchedAfterMount: isFetchedWirelessList } = useQuery(
    ['hasOneOrMoreWirelessQuery'],
    getWireless,
    {
      onSuccess: ({ data }: { data: { wireless: IResumedWireless[] } }) => {
        if (data.wireless.length > 0) {
          setHasOneOrMoreWireless(true);
        } else {
          setHasOneOrMoreWireless(false);
        }
      }
    }
  );

  const renderInfoNoCaptive = () => {
    if (isFetchedCaptivePortal) {
      if (!defaultCaptivePortal.mode) {
        return (
          <div className={styles.warningNoWireless}>
            <Alert type="info">
              <div>
                <span>
                  {t(
                    'Você não tem nenhum Captive ativo neste ambiente. O Captive só pode ser ativado se os equipamentos estiverem com o firmware atualizado'
                  )}
                </span>
                {'. '}
                <span
                  className={classNames([styles.customLink])}
                  aria-hidden
                  onClick={() => {
                    setShowDeprecatedFirmwareModal(true);
                  }}>
                  {t('Saiba Mais')}
                </span>
              </div>
            </Alert>
          </div>
        );
      }
      return null;
    }
    return (
      <Skeleton className={styles.warningNoWireless} width={925} height={36} />
    );
  };

  const renderWarningNoWireless = () => {
    if (isFetchedWirelessList) {
      if (!hasOneOrMoreWireless) {
        return (
          <div className={styles.warningNoWireless}>
            <Alert type="warning">
              <span>
                {t(
                  'Para ativar o Captive Portal você deve ter ao menos uma rede wireless ativa neste ambiente.'
                )}
              </span>
            </Alert>
          </div>
        );
      }
      return null;
    }
    return (
      <Skeleton
        className={styles.warningNoWireless}
        width={572.28}
        height={36}
      />
    );
  };

  const handleOnClickMethod = (methodName: IMethodTypeName) => {
    if (defaultCaptivePortal.is_blocked_to_activate) {
      setShowBlockActiveCaptiveModal(true);
      return;
    }

    if (methodsTypes[methodName].statusMethod) {
      if (methodName === 'password') {
        navigate(`/captive-portal/${defaultCaptivePortal.id}/simple-password/`);
        return;
      }

      const methodNameFormatted = methodName.replace('_', '-');

      navigate(
        `/captive-portal/${defaultCaptivePortal.id}/${methodNameFormatted}/`
      );
      return;
    }

    setShowActivateInfoModal(true);
    setMethodToActivate(methodName);
  };

  const activeMethod = Object.values(methodsTypes).find(
    (method) => method.statusMethod
  );

  return (
    <Grid fluid>
      <InfoCaptiveAndFirmwareModal
        showModal={showDeprecatedFirmwareModal}
        setShowModal={setShowDeprecatedFirmwareModal}
      />
      <BlockActiveCaptiveModal
        showModal={showBlockActiveCaptiveModal}
        setShowModal={setShowBlockActiveCaptiveModal}
      />
      <ActivateCaptiveInfoModal
        show={showActivateInfoModal}
        setShow={setShowActivateInfoModal}
        captiveType={methodToActivate as string}
        activeMethod={activeMethod}
        captiveDefaultId={defaultCaptivePortal?.id || ''}
      />
      <Row>
        <Col xs={12} className="mb-5 d-flex flex-column">
          <span className={classNames(['text-base-lg', styles.subtitlePage])}>
            {t(
              "O método escolhido, quando ativo, determinará a forma de autenticação dos clientes nas redes wireless deste ambiente definidas em 'Wireless vinculadas'."
            )}
          </span>
          {renderWarningNoWireless()}
          {renderInfoNoCaptive()}
        </Col>
      </Row>
      <Row className="d-flex">
        <Col xs={6} className="pr-2 pb-4">
          <CardOfCaptivePortalType
            {...methodsTypes?.password}
            descriptionType={t(
              'Gere uma senha única como método de autenticação para todos seus clientes'
            )}
            id="password"
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            isLoadingFormCaptive={isLoadingFormCaptive}
            onClick={() => {
              handleOnClickMethod('password');
            }}
            showWarningFirmware={
              !defaultCaptivePortal.mode &&
              defaultCaptivePortal.is_blocked_to_activate
            }
            noWirelessBlock={!hasOneOrMoreWireless}
            hasActiveMethod={!!activeMethod}
            disableCaptivePortalIsLoading={disableCaptivePortalIsLoading}
          />
        </Col>
        <Col xs={6} className="pl-2 pb-4">
          <CardOfCaptivePortalType
            {...methodsTypes?.individual_record}
            descriptionType={t(
              'Crie cadastros individuais detalhados como método de autenticação e gerencie seu acesso à rede'
            )}
            id="individual"
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            isLoadingFormCaptive={isLoadingFormCaptive}
            onClick={() => {
              handleOnClickMethod('individual_record');
            }}
            showWarningFirmware={
              !defaultCaptivePortal.mode &&
              defaultCaptivePortal.is_blocked_to_activate
            }
            noWirelessBlock={!hasOneOrMoreWireless}
            hasActiveMethod={!!activeMethod}
            disableCaptivePortalIsLoading={disableCaptivePortalIsLoading}
          />
        </Col>
      </Row>
      {/* <Row className="d-flex">
        <Col xs={6} className="pr-2 pb-4">
          <CardOfCaptivePortalType
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            {...methodsTypes?.cpf}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/cpf-record/`}
            isLoadingFormCaptive={isLoadingFormCaptive}
          />
        </Col>
        <Col xs={6} className="pl-2 pb-4 ">
          <CardOfCaptivePortalType
            isFetchedCaptivePortal={isFetchedCaptivePortal}
            {...methodsTypes?.voucher}
            redirectTo={`/captive-portal/${defaultCaptivePortal?.id}/voucher/`}
            isLoadingFormCaptive={isLoadingFormCaptive}
          />
        </Col>
      </Row> */}
      <DisableCaptiveButton
        defaultCaptivePortal={defaultCaptivePortal}
        isFetchedCaptivePortal={isFetchedCaptivePortal}
        setDefaultCaptivePortal={setDefaultCaptivePortal}
        setDisableCaptivePortalIsLoading={setDisableCaptivePortalIsLoading}
      />
    </Grid>
  );
};

export { SetUpMethod };
