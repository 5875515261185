import { IFullWirelessInfos } from '../wireless/types';

interface IPaginationResponse {
  total_pages: number;
  current_page: number;
  total_items: number;
  total_filtered_items: number;
}

export interface IAddDevice {
  mac: string;
  alias: string;
  password: string;
}

export interface IRadioData {
  id: string;
  band: '2.4' | '5';
  power: number;
  ieee_standard_80211: string;
  channel_bandwidth: number;
  channel_extension: string;
  channel_value: number;
}

export interface IDevice {
  id: string;
  created_at: string;
  updated_at: string;
  password?: string;
  mac: string;
  alias: string;
  username: string;
  firmware_version: string;
  model: string;
  status: 'online' | 'offline';
  clients: number;
  environment: string;
  sync: 'adding' | 'not-added' | 'synced' | 'syncing' | 'not-synced';
  is_associated_wireless_id: boolean;
  has_associated_wireless: boolean;
  frequency: '2.4' | '5' | 'dualband';
  radio_5: IRadioData;
  radio_24: IRadioData;
  image: string;
  clients_connecteds: number;
  type_equipment: 'CFTV' | 'AP';
  ssh_port: number | null;
}

export interface IDevicePagination {
  devices: IDevice[];
  pagination: IPaginationResponse;
}

export interface IDeleteConfigurationResponse {
  group_id: string;
}

export interface IDataStatusConfiguration {
  data: {
    running: number;
    blocked: number;
    error: number;
    success: number;
  };
}

export interface IDataDeviceDetail {
  device: IDevice;
}

export interface IDataDeviceUpdate {
  wireless_list_add_ids: string[];
  wireless_list_remove_ids: string[];
}

export interface IDeviceUpdated {
  site: string;
  device_id: string;
  wireless_list_added: IFullWirelessInfos[];
  wireless_list_removed: IFullWirelessInfos[];
}

export interface IRetryDataRequest {
  device_id: string;
}

export interface ISiteHasDevice {
  has_devices_in_site: boolean;
}

export interface IUpdateRadioData {
  radio_list?: IRadioData[];
}

export interface IRadioBand {
  supported_phy_modes: string[];
  phy_modes: {
    [key: string]: {
      bandwidth: string[];
    };
  };
  channel_list: {
    [key: number]: {
      min_tx_power: number;
      max_tx_power: number;
      phy_modes: string[];
    };
  };
}

export interface IRadioRules {
  '2.4': IRadioBand;
  '5': IRadioBand;
}

export interface IDeviceProperties {
  properties: {
    model: string;
    signature: string;
    frequency: string;
    image: string;
    radio_rules: IRadioRules;
  };
}

export interface IDataFirmware {
  changelog: string;
  created_at: string;
  device_model: string;
  is_supported: boolean;
  version: string;
  updated_at: string;
}

export interface IDataFirmwareList {
  firmwares: IDataFirmware[];
}

export interface IDisassociatedWireless {
  name: string;
}

export interface IGetDisassociatedWirelessResponse {
  wireless: IDisassociatedWireless[];
}

// eslint-disable-next-line no-shadow
export enum AclType {
  ALLOW_LIST = 'allow_list',
  DENY_LIST = 'deny_list'
}

interface IAcl {
  id?: string;
  created_at?: string;
  updated_at?: string;
  name: string;
  type: AclType;
  site_id?: string;
  client_count?: number;
  wireless_count?: number;
}
export interface IAddAcl {
  acl: IAcl;
  client_list: {
    mac: string;
    name?: string;
  }[];
  wireless_list: IDisassociatedWireless[];
}

export interface IAclExistsResponse {
  acl_exists: boolean;
}

export interface IGetAcl {
  acl_list: IAcl[];
  pagination: IPaginationResponse;
}

export interface IUpdateSSH {
  device: {
    ssh_port: number | null;
  };
}
