import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

const WebAuthRedirect = () => {
  const { handleLogin } = useAuth();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const message = {
      code: searchParams.get('code'),
      session_state: searchParams.get('session_state'),
      redirect_uri: String(process.env.REACT_APP_CONTA_INTELBRAS_REDIRECT_URI)
    };

    if (searchParams.get('code') && window.opener) {
      window.opener.postMessage(JSON.stringify(message), '*');
      window.close();
    } else {
      handleLogin({
        data: JSON.stringify(message),
        origin: String(process.env.REACT_APP_CONTA_INTELBRAS_ORIGIN)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <div>.</div>;
};

export { WebAuthRedirect };
