import { isNull, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'inmaster-ui';
import {
  Controller,
  MultipleFieldErrors,
  useForm,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel, MdLock } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { PasswordVisibilityIcon } from 'src/components/PasswordVisibilityIcon';
import { useAuth } from 'src/hooks/useAuth';
import { useArrayCompare, useFormCompare } from 'src/hooks/useFormCompare';
import { useNotification } from 'src/hooks/useNotification';
import { useTemplate } from 'src/hooks/useTemplate';
import { useToast } from 'src/hooks/useToast';
import { IDevicePaginationTable, IPagination } from 'src/pages/Devices/types';
import api from 'src/services/api';
import { IDevice } from 'src/services/api/urls/devices/types';
import {
  ICreateOrUpdateWireless,
  IFullWirelessInfos
} from 'src/services/api/urls/wireless/types';
import { eventGA } from 'src/utils/analytics';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputIconInside, InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import RadioButton from 'ui-components/RadioButton';
import Select from 'ui-components/Select';
import Tabs from 'ui-components/Tab';
import Toggle from 'ui-components/Toggle';
import { Alert } from 'ui-components/Alert';
import classNames from 'classnames';
import {
  IPlace,
  IPlaceResponseUnique
} from 'src/services/api/urls/sites/types';
import { IErrorResponse } from 'src/services/api/types';
import AdvancedOption from './AdvancedOption';
import DeviceOption from './DeviceOption';
import ModalConfirmEditWireless from './ModalEdit';
import styles from './WirelessSettings.module.css';
import ModalConfirmAssociateCaptive from './ModalConfirmAssociateCaptive';
import { IWirelessDevices } from './types';

const WirelessSettings = () => {
  // hooks
  useTemplate('menuAndFullNavbar');
  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { accessToken, resetSession } = useAuth();
  const location = useLocation();
  const params = useParams();
  const { triggerNotificationAnimationWithText } = useNotification();

  // States
  const [showPassword, setShowPassword] = useState(false);

  const [search, setSearch] = useState('');

  const [selectedDevices, setSelectedDevices] = useState<IWirelessDevices[]>(
    []
  );
  const [page, setPage] = useState(1);

  const rowsPerPage = 9999;

  const [devicesPagination, setDevicesPagination] =
    useState<IDevicePaginationTable>({
      devices: [],
      pagination: {} as IPagination
    } as IDevicePaginationTable);

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [
    showModalConfirmAssociateCaptive,
    setShowModalConfirmAssociateCaptive
  ] = useState(false);

  const [ableToChangePassword, setAbleToChangePassword] = useState(false);

  const [isClientSensivityStandart, setIsClientSensivityStandart] =
    useState(true);

  const [devicesAssociatedToThisWireless, setDevicesAssociatedToThisWireless] =
    useState<IWirelessDevices[]>([]);

  const [firstFetch, setFirstFetch] = useState(true);

  const [oldDevices, setOldDevices] = useState<IDevice[]>([]);

  const [wirelessDataToCreateOrUpdate, setWirelessDataToCreateOrUpdate] =
    useState<ICreateOrUpdateWireless>({} as ICreateOrUpdateWireless);

  const [place, setPlace] = useState<IPlace>({} as IPlace);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    resetField
  } = useForm<ICreateOrUpdateWireless>({
    defaultValues: {
      wireless: {
        name: '',
        password: '',
        vlan: '',
        bandwidth_download: '',
        bandwidth_upload: '',
        security: '',
        enabled: true,
        frequency: 'dualband',
        enterprise_config: {
          ip: '',
          port: ''
        },
        sensibility: -90,
        client_isolate: false,
        network_isolate: false,
        hidden: false,
        enableVlan: false,
        band_limit: false,
        is_captive_portal_enabled: false
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  const securityObserver = useWatch({ control, name: 'wireless.security' });

  const bandwidthDownloadObserver = useWatch({
    control,
    name: 'wireless.bandwidth_download'
  });

  const bandwidthUploadObserver = useWatch({
    control,
    name: 'wireless.bandwidth_upload'
  });

  const getTypeOfPage = () => {
    if (Object.keys(params).length === 0) {
      return location.pathname.split('/').at(-1);
    }
    return location.pathname.split('/').at(-2);
  };

  const typeOfPage: 'edit' | 'add' = getTypeOfPage() as 'edit' | 'add';

  const isEditPage = typeOfPage === 'edit';

  const isDisabledInputBecauseIsDisabledWireless =
    !watch('wireless.enabled') && isEditPage;

  const securityOptions = [
    {
      label: 'WPA2-PSK',
      value: 'WPA2-PSK',
      id: 'downshift-0-item-wpa2-psk'
    },
    {
      label: 'WPA-PSK',
      value: 'WPA-PSK',
      id: 'downshift-0-item-wpa-psk'
    },
    {
      label: t('Aberta'),
      value: 'Open',
      id: 'downshift-0-item-open'
    }
  ];

  const editOrCreateTexts = {
    edit: {
      toastSuccessMessage: t('Wireless editada com sucesso'),
      pageTitle: t('Editar wireless'),
      id: 'button-edit-wireless'
    },
    add: {
      toastSuccessMessage: t('Wireless criada com sucesso'),
      pageTitle: t('Adicionar Wireless'),
      id: 'button-add-wireless'
    }
  };

  const getWirelessData = () => {
    return api.wireless.get(params.id);
  };

  // Queries
  const { isFetching, data: wirelessData } = useQuery(
    'wirelessQuery',
    getWirelessData,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: ICreateOrUpdateWireless }) => {
        const arrayWithKeyAndValues = Object.entries(data.wireless);
        for (const [name, value] of arrayWithKeyAndValues) {
          setValue(`wireless.${name as keyof IFullWirelessInfos}`, value);
        }
        if (
          !isEmpty(data.wireless) &&
          !isNull(data.wireless.vlan) &&
          data.wireless.vlan !== undefined
        ) {
          setValue('wireless.enableVlan', true);
        }
        if (data.wireless.sensibility !== -90) {
          setIsClientSensivityStandart(false);
        }
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/wireless');
        }
      }
    }
  );

  const getPlaceUnique = () => {
    return api.sites.hefesto.places.details.get(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const { isFetching: isFetchingPlace } = useQuery(
    'placeUnique',
    getPlaceUnique,
    {
      onSuccess: ({ data }: { data: IPlaceResponseUnique }) => {
        setPlace(data.place);
      }
    }
  );

  const convertDeviceDataToWirelessDeviceData = (device: IDevice) => {
    const wirelessDevice: IWirelessDevices = {
      id: device.id,
      alias: device.alias,
      mac: device.mac,
      is_associated_wireless_id: device.is_associated_wireless_id,
      frequency: device.frequency
    };
    return wirelessDevice;
  };

  const convertDevicesOfDataToSelectedDevices = (devices: IDevice[]) => {
    const auxDevicesAssociatedToThisWireless: IWirelessDevices[] = [];
    let auxSelectedDevicesWireless: IWirelessDevices[] = [];
    const valueFrequency = getValues('wireless.frequency');
    for (const device of devices) {
      if (device.is_associated_wireless_id) {
        const wirelessDevice: IWirelessDevices =
          convertDeviceDataToWirelessDeviceData(device);
        auxDevicesAssociatedToThisWireless.push(wirelessDevice);
        auxSelectedDevicesWireless.push(wirelessDevice);
      }
    }
    auxSelectedDevicesWireless = auxSelectedDevicesWireless.filter(
      (prevDevice) => {
        if (valueFrequency === '2.4') {
          return prevDevice.frequency !== '5';
        }
        if (valueFrequency === '5') {
          return prevDevice.frequency !== '2.4';
        }
        return true;
      }
    );
    setSelectedDevices(auxSelectedDevicesWireless);
    setDevicesAssociatedToThisWireless(auxDevicesAssociatedToThisWireless);
  };

  const checkIfSomeDeviceHasDeleted = (
    _oldDevices: IDevice[],
    newDevices: IDevice[]
  ) => {
    if (devicesPagination.devices && devicesPagination.devices.length === 0)
      return;
    const oldDevicesIds = _oldDevices.map((device) => device.id);
    const newDevicesIds = newDevices.map((device) => device.id);

    const deletedDevicesIds = oldDevicesIds.filter(
      (deviceId) => !newDevicesIds.includes(deviceId)
    );

    if (deletedDevicesIds.length > 0) {
      const newSelectedDevices = selectedDevices.filter(
        (device) => !deletedDevicesIds.includes(device.id)
      );
      setDevicesAssociatedToThisWireless((prev) =>
        prev.filter((item) => !deletedDevicesIds.includes(item.id))
      );
      setSelectedDevices(newSelectedDevices);
    }
  };

  const checkIfHasNewDevices = (
    _oldDevices: IDevice[],
    newDevices: IDevice[]
  ) => {
    if (devicesPagination.devices.length === 0) return;
    const oldDevicesIds = _oldDevices.map((device) => device.id);
    const newDevicesIds = newDevices.map((device) => device.id);
    const newDevicesIdsAdded = newDevicesIds.filter(
      (deviceId) => !oldDevicesIds.includes(deviceId)
    );

    if (newDevicesIdsAdded.length > 0) {
      const newDevicesObjects = newDevices.filter((device) =>
        newDevicesIdsAdded.includes(device.id)
      );
      convertDevicesOfDataToSelectedDevices(newDevicesObjects);
    }
  };

  const getPaginationDevices = () => {
    return api.devices.get({
      order_by: isEditPage ? 'is_associated_wireless_id' : 'alias',
      query_is_associated_wireless_id: isEditPage ? params.id : null,
      page_number: page,
      page_size: rowsPerPage > 0 ? rowsPerPage : 1
    });
  };

  const { refetch: refetchPagination } = useQuery(
    'devicePagination',
    getPaginationDevices,
    {
      onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
        setDevicesPagination(data);
        checkIfSomeDeviceHasDeleted(oldDevices, data.devices);
        checkIfHasNewDevices(oldDevices, data.devices);
        if (selectedDevices.length > 0 || oldDevices.length > 0) return;
        convertDevicesOfDataToSelectedDevices(data.devices);
        if (firstFetch) {
          setOldDevices(data.devices);
          setFirstFetch(false);
        }
      }
    }
  );

  const getDevicesBySearch = async () => {
    return api.devices.get({
      order_by: isEditPage ? 'is_associated_wireless_id' : 'alias',
      query_is_associated_wireless_id: isEditPage ? params.id : null,
      search_param: search
    });
  };

  const { refetch: refetchSearch, isFetching: isFetchingSearch } = useQuery(
    'deviceSearch',
    getDevicesBySearch,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
        setPage(1);
        setDevicesPagination(data);
      }
    }
  );
  const isDisabledInputBecauseIsDisabledWirelessOrCaptiveActive = () => {
    if (typeOfPage !== 'edit') return false;
    return (
      isDisabledInputBecauseIsDisabledWireless ||
      wirelessData?.data.wireless.is_captive_portal_enabled
    );
  };

  const getVlanInitialStatus = () => {
    if (!wirelessData || !wirelessData.data || !wirelessData.data.wireless) {
      return false;
    }

    return (
      !isNull(wirelessData.data.wireless.vlan) &&
      wirelessData.data.wireless.vlan !== undefined
    );
  };

  const isFormWirelessChanged = useFormCompare({
    initialValues: {
      ...wirelessData?.data.wireless,
      enableVlan: getVlanInitialStatus()
    },
    currentValues: watch('wireless')
  });

  const isFormDevicesChanged = useArrayCompare(
    devicesAssociatedToThisWireless || [],
    selectedDevices,
    ['id', 'mac']
  );

  const hasSomeDevicesAssociated = () => {
    return (
      selectedDevices.length > 0 ||
      devicesPagination.devices.some(
        (device) => device.is_associated_wireless_id
      )
    );
  };

  const checkDevicesAssociations = () => {
    const devicesDesassociated = [];
    const devicesAssociated = [];
    const newDevicesAssociated = [];

    for (const device of devicesPagination.devices) {
      const isDeviceAssociated = selectedDevices.find(
        (item) => item.id === device.id
      );
      if (!isDeviceAssociated && device.is_associated_wireless_id) {
        devicesDesassociated.push(device);
        // eslint-disable-next-line no-continue
        continue;
      } else if (isDeviceAssociated && device.is_associated_wireless_id) {
        devicesAssociated.push(device);
        // eslint-disable-next-line no-continue
        continue;
      } else if (isDeviceAssociated && !device.is_associated_wireless_id) {
        newDevicesAssociated.push(device);
      }
    }

    return {
      devicesDesassociated,
      devicesAssociated,
      newDevicesAssociated
    };
  };

  const isWirelessDataHasChangeAfterGet = () => {
    const inputAfterGetValues = getValues().wireless;
    for (const input of [
      'name',
      'password',
      'vlan',
      'security',
      'client_isolate',
      'network_isolate',
      'hidden'
    ]) {
      if (
        wirelessData?.data.wireless[input as keyof IFullWirelessInfos] !==
        inputAfterGetValues[input as keyof IFullWirelessInfos]
      ) {
        return true;
      }
    }
    return false;
  };

  const genereteSingularOrPluralAnimationText = () => {
    const { newDevicesAssociated, devicesDesassociated, devicesAssociated } =
      checkDevicesAssociations();
    const devicesChanged =
      newDevicesAssociated.length + devicesDesassociated.length;
    const sumDevicesAssociated = devicesChanged + devicesAssociated.length;

    if (
      devicesChanged > 1 ||
      (isWirelessDataHasChangeAfterGet() && sumDevicesAssociated > 1)
    ) {
      return t('CONFIGURANDO EQUIPAMENTOS');
    }
    return t('CONFIGURANDO EQUIPAMENTO');
  };

  // Mutations
  const AddWirelessMutation = useMutation(
    (data: ICreateOrUpdateWireless) => {
      if (typeOfPage === 'add') {
        return api.wireless.post(data);
      }
      return api.wireless.put(data, params.id);
    },
    {
      onSuccess: () => {
        addToast('success', editOrCreateTexts[typeOfPage].toastSuccessMessage);
        if (typeOfPage === 'add') {
          eventGA('Wireless', 'Click', 'Criar wireless');
        } else {
          eventGA('Wireless', 'Click', 'Editar wireless');
        }
        navigate('/wireless');
        setShowModalEdit(false);
        if (hasSomeDevicesAssociated()) {
          triggerNotificationAnimationWithText(
            genereteSingularOrPluralAnimationText()
          );
        }
        reset();
      },
      onError: (error: IErrorResponse) => {
        setShowModalEdit(false);
        if (error.response.status === 401) {
          resetSession();
        } else if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status === 304 ||
          error.response.status === 404
        ) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status === 400 &&
          error.response.data.message[0].attr.includes('frequency')
        ) {
          addToast('error', error.response.data.message[0].msg);
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as
              | 'wireless.name'
              | 'wireless.password'
              | 'wireless.vlan'
              | 'wireless.bandwidth_download'
              | 'wireless.bandwidth_upload';
            setError(`wireless.${field as keyof IFullWirelessInfos}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }
        }
      }
    }
  );

  // Functions
  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notOutOfASCIIPattern) {
      message = errorTypes.notOutOfASCIIPattern;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.min) {
      message = errorTypes.min;
    } else if (errorTypes.max) {
      message = errorTypes.max;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.startsWithZero) {
      message = errorTypes.startsWithZero;
    } else if (errorTypes.justIntegerNumbers) {
      message = errorTypes.justIntegerNumbers;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  const validateNumberField = (message: string) => {
    return {
      startsWithZero: (value: string | number | null) => {
        if (value === null) {
          return true;
        }

        const textValue = String(value);

        return !textValue.startsWith('0') || message;
      },
      justIntegerNumbers: (value: string | number | null) => {
        if (value === null) {
          return true;
        }

        const textValue = String(value);

        return !!textValue.match(/^[0-9]*$/) || message;
      }
    };
  };

  const handleIsClientSensivityStandartChange = (value: boolean) => {
    if (value) {
      setValue('wireless.sensibility', -90);
    }
    setIsClientSensivityStandart(value);
  };

  const organizeSelectedDevicesByFrequency = () => {
    const value = watch('wireless.frequency');
    if (value === '5') {
      setSelectedDevices(
        devicesAssociatedToThisWireless.filter(
          (device) => device.frequency !== '2.4'
        )
      );
    } else if (value === '2.4') {
      setSelectedDevices(
        devicesAssociatedToThisWireless.filter(
          (device) => device.frequency !== '5'
        )
      );
    } else {
      setSelectedDevices(devicesAssociatedToThisWireless);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('wireless.frequency', event.target.defaultValue);
    organizeSelectedDevicesByFrequency();
  };

  const onSubmit = handleSubmit((data: ICreateOrUpdateWireless) => {
    data.devices = selectedDevices.map((device) => device.id);
    if (!data.wireless.band_limit) {
      data.wireless.bandwidth_download = null;
      data.wireless.bandwidth_upload = null;
    }

    if (data.wireless.vlan === '' || !data.wireless.enableVlan) {
      data.wireless.vlan = null;
    }

    if (isClientSensivityStandart) {
      setValue('wireless.sensibility', -90);
    }

    if (typeOfPage === 'add' && place.captive_portal_mode) {
      setWirelessDataToCreateOrUpdate(data);
      setShowModalConfirmAssociateCaptive(true);
      return;
    }
    AddWirelessMutation.mutate(data);
  });

  const checkIfEditModeAndCallModal = () => {
    const errorText = t('Campo obrigatório');
    if (isEditPage) {
      if (
        watch('wireless.security') !== 'Open' &&
        watch('wireless.password') === ''
      ) {
        setError('wireless.password', {
          types: {
            request: errorText
          }
        });
      }

      if (watch('wireless.enableVlan') && !watch('wireless.vlan')) {
        setError('wireless.vlan', {
          types: {
            request: errorText
          }
        });
      }

      const hasDeviceToApplyConfiguration =
        devicesAssociatedToThisWireless.length > 0 ||
        selectedDevices.length > 0;

      if (!errors.wireless) {
        if (hasDeviceToApplyConfiguration) {
          setShowModalEdit(true);
          return;
        }

        onSubmit();
      }
    }
  };

  const onConfirmAssociateCaptive = () => {
    AddWirelessMutation.mutate({
      ...wirelessDataToCreateOrUpdate,
      wireless: {
        ...wirelessDataToCreateOrUpdate.wireless,
        is_captive_portal_enabled: true
      }
    });
  };

  // Definição das tabs
  const categories = [
    {
      label: t('Configurações avançadas'),
      id: 'advancedConfigsTab',
      component: (
        <AdvancedOption
          control={control}
          watch={watch}
          isDisabledInputBecauseIsDisabledWireless={
            isDisabledInputBecauseIsDisabledWireless
          }
          isClientSensivityStandart={isClientSensivityStandart}
          setIsClientSensivityStandart={handleIsClientSensivityStandartChange}
          bandwidthDownloadObserver={bandwidthDownloadObserver}
          bandwidthUploadObserver={bandwidthUploadObserver}
          errors={errors}
          getInputErrorMessage={getInputErrorMessage}
          trigger={trigger}
          validateNumberField={validateNumberField}
          setValue={setValue}
          resetField={resetField}
        />
      )
    },
    {
      label: t('Equipamentos'),
      id: 'devicesTab',
      component: (
        <DeviceOption
          devicesPagination={devicesPagination}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
          isDisabledInputBecauseIsDisabledWireless={
            isDisabledInputBecauseIsDisabledWireless
          }
          search={search}
          setSearch={setSearch}
          isFetchingSearch={isFetchingSearch}
          frequencyValue={watch('wireless.frequency')}
        />
      )
    }
  ];

  const checkHasCaptivePortal = () => {
    if (!isEditPage) return securityOptions;
    if (
      wirelessData?.data.wireless.is_captive_portal_enabled &&
      !securityOptions.find((item) => item.value === 'Captive Portal')
    ) {
      securityOptions.push({
        label: t('Captive Portal'),
        value: 'Captive Portal',
        id: 'downshift-0-item-captive-portal'
      });
    }
    return securityOptions;
  };

  const getSecurityValueIfCaptiveEnabled = (value: string) => {
    if (!isEditPage) return value;
    if (wirelessData?.data.wireless.is_captive_portal_enabled) {
      return 'Captive Portal';
    }
    return value;
  };

  const isCaptivePortalEnabled = () => {
    if (!isEditPage) return false;
    return wirelessData?.data.wireless.is_captive_portal_enabled;
  };

  const generateInputsBySecurityType = () => {
    if (['WPA-PSK', 'WPA2-PSK'].includes(watch('wireless.security'))) {
      return (
        <InputWrapper invalid={Boolean(errors?.wireless?.password)}>
          <Label>{t('Senha')}:</Label>
          <Controller
            control={control}
            name="wireless.password"
            rules={{
              required: {
                value: true,
                message: t('Campo obrigatório')
              },
              minLength: {
                value: 8,
                message: t('A senha precisa ter entre 8 e 63 caracteres.')
              },
              maxLength: {
                value: 63,
                message: t('A senha precisa ter entre 8 e 63 caracteres.')
              },
              validate: {
                notOutOfASCIIPattern: (value) => {
                  const isOutOfASCIIPattern = /[^\x20-\x7E]/.test(
                    value as string
                  );

                  return (
                    !isOutOfASCIIPattern ||
                    (t(
                      'Apenas caracteres de Aa-Zz; 1-9 e caracteres especiais no padrão ASCII são permitidos'
                    ) as string)
                  );
                },
                notWhitespaceOnly: (value) => {
                  if (value) {
                    return (
                      value.trim().length > 0 ||
                      (t('Campo obrigatório') as string)
                    );
                  }
                  return t('Campo obrigatório') as string;
                },
                notStartsOrEndsWithWhitespace: (value) =>
                  (!value?.startsWith(' ') && !value?.endsWith(' ')) ||
                  (t(
                    'A senha não pode começar nem terminar com um espaço'
                  ) as string)
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputIconInside
                iconLeft={<MdLock />}
                iconRight={
                  <PasswordVisibilityIcon
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    disabled={isDisabledInputBecauseIsDisabledWirelessOrCaptiveActive()}
                  />
                }>
                <Input
                  onChange={onChange}
                  disabled={isDisabledInputBecauseIsDisabledWirelessOrCaptiveActive()}
                  onBlur={onBlur}
                  value={value}
                  type={showPassword ? 'text' : 'password'}
                  id="password-input"
                  placeholder="*******"
                  iconInsideLeft
                  iconInsideRight
                  autoComplete="off"
                />
              </InputIconInside>
            )}
          />
          {getInputErrorMessage(errors?.wireless?.password?.types)}
        </InputWrapper>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const renderSkeletonForm = () => {
    return (
      <div>
        <Skeleton height={20} width={200} />

        <div className="my-4">
          <Skeleton height={10} width={120} />
          <Skeleton height={50} />
        </div>

        <div className="mb-4">
          <Skeleton height={10} width={120} />
          <Skeleton height={50} />
        </div>

        <div className="mb-4">
          <Skeleton height={10} width={120} />
          <Skeleton height={50} />
        </div>

        <Skeleton height={20} width={100} className="mb-4" />

        <div className="mb-4">
          <Skeleton height={10} width={120} />
          <Skeleton height={50} />
        </div>

        <Skeleton count={1} height={70} borderRadius={10} className="mb-3" />
        <Skeleton count={1} height={70} borderRadius={10} className="mb-3" />
      </div>
    );
  };

  useEffect(() => {
    if (!isEditPage) {
      setAbleToChangePassword(true);
      setValue('wireless.security', 'WPA2-PSK');
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditPage) {
      if (securityObserver !== '') {
        setAbleToChangePassword(true);
      }
    }

    if (ableToChangePassword) {
      if (securityObserver === 'Open') {
        resetField('wireless.password');
        setValue('wireless.password', null);
      } else {
        setValue('wireless.password', '');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityObserver]);

  useEffect(() => {
    if (watch('wireless.bandwidth_download') === '') {
      setValue('wireless.bandwidth_download', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bandwidthDownloadObserver]);

  useEffect(() => {
    if (watch('wireless.bandwidth_upload') === '') {
      setValue('wireless.bandwidth_upload', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bandwidthUploadObserver]);

  // Effects
  useEffect(() => {
    refetchPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (search.length > 2) {
      refetchSearch();
    } else if (search.length <= 2) {
      refetchPagination();
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div>
      <Grid fluid>
        <ModalConfirmEditWireless
          onSubmit={onSubmit}
          setShowModalEdit={setShowModalEdit}
          showModalEdit={showModalEdit}
          isEditing={AddWirelessMutation.isLoading}
        />
        <ModalConfirmAssociateCaptive
          isLoading={AddWirelessMutation.isLoading}
          onConfirm={() => onConfirmAssociateCaptive()}
          onCancel={() =>
            AddWirelessMutation.mutate(wirelessDataToCreateOrUpdate)
          }
          showModalConfirm={showModalConfirmAssociateCaptive}
          setShowModalConfirm={setShowModalConfirmAssociateCaptive}
        />
        <Row>
          <Col xs={12} className="mb-7">
            <h2
              className="title-xl-base text-uppercase"
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {editOrCreateTexts[typeOfPage].pageTitle}
            </h2>
          </Col>
          <Col xs={12}>
            <Card className="fit-width pa-7">
              {!isFetchingPlace ? (
                place.captive_portal_mode && (
                  <div className="d-flex align-center mb-7">
                    <span className={classNames(styles.ball)} />
                    <span
                      className={classNames(
                        styles.labelActivePortal,
                        'd-flex align-center ml-2 text-xl-sm'
                      )}>
                      {t('Captive Portal Ativo neste ambiente')}
                    </span>
                  </div>
                )
              ) : (
                <div className="d-flex align-center mb-7">
                  <Skeleton height={20} width={250} />
                </div>
              )}
              {!isFetching || !isEditPage ? (
                <form
                  className="fit-width"
                  id="form-create-wireless"
                  autoComplete="off"
                  onSubmit={onSubmit}>
                  <Row className="d-flex align-center mb-7">
                    <Col xs={12}>
                      <Row>
                        {isEditPage ? (
                          <Col xs={12}>
                            <div className="d-flex">
                              <Controller
                                control={control}
                                name="wireless.enabled"
                                render={({ field: { onChange, value } }) => (
                                  <Toggle
                                    color="var(--color-status-ok-base)"
                                    checked={value}
                                    id="enable-wireless"
                                    onChange={onChange}
                                  />
                                )}
                              />
                              <span>{t('Rede Wireless Habilitada')}</span>
                            </div>
                          </Col>
                        ) : null}

                        <Col xs={12} className={isEditPage ? 'mt-4' : ''}>
                          <InputWrapper
                            invalid={Boolean(errors?.wireless?.name)}>
                            <Label>{t('Nome da wireless')}:</Label>
                            <Controller
                              control={control}
                              name="wireless.name"
                              rules={{
                                required: {
                                  value: true,
                                  message: t('Campo obrigatório')
                                },
                                maxLength: {
                                  value: 32,
                                  message: t(
                                    t(
                                      'O nome da rede wireless precisa ter entre 1 e 32 caracteres'
                                    )
                                  )
                                },
                                validate: {
                                  notWhitespaceOnly: (value) =>
                                    value.trim().length > 0 ||
                                    (t('Campo obrigatório') as string),
                                  notStartsOrEndsWithWhitespace: (value) =>
                                    (!value.startsWith(' ') &&
                                      !value.endsWith(' ')) ||
                                    (t(
                                      'O nome da rede wireless não pode começar nem terminar com um espaço'
                                    ) as string),
                                  notOutOfASCIIPattern: (value) => {
                                    const isOutOfASCIIPattern =
                                      /[^\x20-\x7E]/.test(value as string);

                                    return (
                                      !isOutOfASCIIPattern ||
                                      (t(
                                        'Permitido no mínimo 1 caractere, sendo do tipo A-Z; a-z; 0-9 e caracteres especiais (.\'?!@#$%"&*)'
                                      ) as string)
                                    );
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, onBlur, value }
                              }) => (
                                <Input
                                  onChange={onChange}
                                  disabled={
                                    isDisabledInputBecauseIsDisabledWireless
                                  }
                                  onBlur={onBlur}
                                  value={value}
                                  id="name-wireless"
                                  placeholder={t('Nome da wireless')}
                                />
                              )}
                            />
                            {getInputErrorMessage(
                              errors?.wireless?.name?.types
                            )}
                          </InputWrapper>
                        </Col>
                        <Col xs={12} className="mt-4">
                          <Label className="d-flex">
                            {t('Segurança da wireless')}:
                          </Label>
                          <Controller
                            control={control}
                            name="wireless.security"
                            render={({ field: { onChange, value } }) => (
                              <Select
                                id="security"
                                options={checkHasCaptivePortal()}
                                value={getSecurityValueIfCaptiveEnabled(value)}
                                onChange={onChange}
                                disabled={isDisabledInputBecauseIsDisabledWirelessOrCaptiveActive()}
                              />
                            )}
                          />
                        </Col>
                        {isCaptivePortalEnabled() && (
                          <Col xs={12} className="mt-2">
                            <Alert type="info">
                              {t(
                                'Para modificar a segurança dessa rede wireless, vá em Captive portal e acesse a aba de Wireless vinculadas, para poder desvincular a rede e editar livremente a segurança da wireless'
                              )}
                            </Alert>
                          </Col>
                        )}
                        <Col xs={12} className="mt-4">
                          <div>{generateInputsBySecurityType()}</div>
                        </Col>
                        <Col xs={12} className="mt-4">
                          <div className="d-flex">
                            <Controller
                              control={control}
                              name="wireless.frequency"
                              render={({ field: { value } }) => (
                                <Toggle
                                  color="var(--color-status-ok-base)"
                                  checked={value === 'dualband'}
                                  id="dual-band"
                                  onChange={() => {
                                    // Recarrega a seleção dos equipamentos
                                    if (value === 'dualband') {
                                      setValue('wireless.frequency', '2.4');
                                    } else {
                                      setValue(
                                        'wireless.frequency',
                                        'dualband'
                                      );
                                    }
                                    organizeSelectedDevicesByFrequency();
                                  }}
                                  disabled={
                                    isDisabledInputBecauseIsDisabledWireless
                                  }
                                />
                              )}
                            />
                            <span>Dual band</span>
                          </div>
                        </Col>
                        {watch('wireless.frequency') !== 'dualband' ? (
                          <Col xs={12} className="mt-4">
                            <Controller
                              control={control}
                              name="wireless.frequency"
                              render={({ field: { value } }) => (
                                <div className="d-flex">
                                  <RadioButton
                                    checked={value === '2.4'}
                                    value="2.4"
                                    onChange={handleRadioChange}
                                    id="btn-radio-2.4"
                                    disabled={
                                      isDisabledInputBecauseIsDisabledWireless
                                    }
                                  />
                                  <Label className="mr-3">2.4GHz</Label>
                                  <RadioButton
                                    checked={value === '5'}
                                    value="5"
                                    id="btn-radio-5"
                                    onChange={handleRadioChange}
                                    disabled={
                                      isDisabledInputBecauseIsDisabledWireless
                                    }
                                  />
                                  <Label>5GHz</Label>
                                </div>
                              )}
                            />
                          </Col>
                        ) : (
                          ''
                        )}
                        <Col xs={12}>
                          <Tabs tabs={categories} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className={styles.bottomActionsButton}>
                    <Button
                      ghost
                      type="button"
                      disabled={AddWirelessMutation.isLoading}
                      className="mr-4"
                      id="button-cancel"
                      onClick={() => navigate('/wireless/')}>
                      {t('Cancelar')}
                    </Button>
                    <Button
                      type={isEditPage ? 'button' : 'submit'}
                      disabled={
                        (isFormWirelessChanged &&
                          isFormDevicesChanged &&
                          isEditPage) ||
                        !isEmpty(errors.wireless)
                      }
                      isLoading={AddWirelessMutation.isLoading}
                      onClick={() => checkIfEditModeAndCallModal()}
                      id={editOrCreateTexts[typeOfPage].id}>
                      {t('Salvar')}
                    </Button>
                  </div>
                </form>
              ) : (
                renderSkeletonForm()
              )}
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export { WirelessSettings };
