import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import styles from './SidebarAction.module.css';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

const classesToFullMenuTransition = {
  enterActive: styles.itemsEnterActive,
  enterDone: styles.itemsEnterDone,
  exitActive: styles.itemsEnterExitActive,
  exitDone: styles.itemsDrawerExitDone
};

const classesToMinimizedMenuTransition = {
  enterActive: styles.itemsMinimizedEnterActive,
  enterDone: styles.itemsMinimizedEnterDone,
  exitActive: styles.itemsMinimizedEnterExitActive,
  exitDone: styles.itemsMinimizedDrawerExitDone
};

function SidebarAction({ label = '', icon, items = [], minimized = false }) {
  // States
  const [showItems, setShowItems] = useState(false);
  const [heightOfElement, setHeightOfElement] = useState('0px');

  //Hooks
  const location = useLocation();

  // Refs
  const itemsRef = useRef(null);

  // Variables
  let duringMinimizingTimeout;
  const itemMatchWithPath = items.find(
    (item) => item.url === location.pathname
  );
  const [inSelectingItem, setInSelectingItem] = useState(false);

  // Functions
  const toggleShowItems = () => {
    setShowItems((prevState) => !prevState);
  };

  const setActiveActionByPath = () => {
    if (itemMatchWithPath) {
      setShowItems(true);
    } else {
      setHeightOfElement(`-${itemsRef.current.offsetHeight}px`);
    }
  };

  const addClassToHiddenItemsDuringMinimizing = () => {
    itemsRef.current.classList.add(styles.itemsDuringMinimizing);
    clearTimeout(duringMinimizingTimeout);
    duringMinimizingTimeout = setTimeout(() => {
      itemsRef.current.classList.remove(styles.itemsDuringMinimizing);
    }, 500);
  };

  const canBlurButton = () => {
    if (minimized && !inSelectingItem) {
      return setShowItems(false);
    }
    if (!itemMatchWithPath && !inSelectingItem) {
      setShowItems(false);
    }
  };

  // Effects
  useEffect(() => {
    if (minimized) {
      setShowItems(false);
      addClassToHiddenItemsDuringMinimizing();
    } else {
      setActiveActionByPath();
    }
  }, [minimized]);

  useEffect(() => {
    if (!itemMatchWithPath) {
      setShowItems(false);
    }
  }, [itemMatchWithPath]);

  useEffect(() => {
    if (!minimized) {
      setActiveActionByPath();
    }
  }, []);

  useEffect(() => {
    if (!minimized) {
      setActiveActionByPath();
    }
  }, [location]);

  useEffect(() => {}, [inSelectingItem]);

  return (
    <div className={styles.shellAction}>
      <button
        onBlur={() => canBlurButton()}
        className={classNames([
          styles.sidebarAction,
          showItems ? styles.sidebarActionActive : null,
          minimized ? styles.sidebarActionMinimized : null
        ])}
        type="button"
        id={`menu-action-${label.replaceAll(' ', '-').toLowerCase()}`}
        onClick={() => toggleShowItems()}>
        <div className={styles.leftHighlight}></div>
        {!minimized ? (
          <>
            <div className={styles.shellLabel}>
              <span className={styles.icon}>{icon}</span>
              <span className={styles.label}>{label}</span>
            </div>
            {!showItems ? (
              <MdOutlineArrowDropDown size={24} />
            ) : (
              <MdOutlineArrowDropUp size={24} />
            )}
          </>
        ) : (
          <span className={styles.icon}>{icon}</span>
        )}
      </button>
      <CSSTransition
        onEntered={() => setHeightOfElement('0px')}
        onExited={() =>
          setHeightOfElement(`-${itemsRef.current.offsetHeight}px`)
        }
        in={showItems}
        timeout={100}
        classNames={
          minimized
            ? classesToMinimizedMenuTransition
            : classesToFullMenuTransition
        }
        nodeRef={itemsRef}>
        <ul
          onMouseEnter={() => setInSelectingItem(true)}
          onMouseLeave={() => setInSelectingItem(false)}
          ref={itemsRef}
          style={{ '--content-height': heightOfElement }}
          className={classNames([
            minimized ? styles.itemsMinimized : styles.items
          ])}>
          {items.map((item, index) => (
            <li
              className={classNames([
                styles.item,
                location.pathname === item.url ? styles.itemActive : null
              ])}
              key={`${item.value}${index}`}>
              <Link
                onClick={() => (minimized ? setShowItems(false) : null)}
                to={item.url}
                id={`menu-action-item-${item.label
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
}

export default SidebarAction;
