import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { eventGA } from 'src/utils/analytics';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import classNames from 'classnames';
import { generateColumns } from 'src/utils/tableUtils';
import Table from 'ui-components/Table';
import Tag from 'ui-components/Tags';
import { Tooltip } from 'inmaster-ui';
import {
  ISecurityFormat,
  IDeviceOnScreen,
  IResumedWireless,
  IResumedWirelessRow
} from '../../types';
import styles from './AssociatedWirelessList.module.css';

interface IProps {
  deviceOnScreen: IDeviceOnScreen;
  isFetchedAfterMountDeviceInfo: boolean;
  deviceWirelessList: IResumedWireless[];
  openWirelessModal: () => void;
}

const AssociatedWirelessList = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deviceOnScreen,
  isFetchedAfterMountDeviceInfo,
  deviceWirelessList,
  openWirelessModal
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const securityFormat: ISecurityFormat = {
    Open: t('Aberta'),
    'WPA-PSK': 'WPA-PSK',
    'WPA2-PSK': 'WPA2-PSK'
  };

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('security', t('Segurança'), 'left', 0),
    generateColumns('frequency', t('Frequência'), 'left', 0),
    generateColumns('isVisible', t('Visibilidade'), 'left', 0)
  ];

  const renderSkeletons = (numberOfSkeletons = 8) => {
    const skeletons = [
      <div key="skeleton-header-table-wireless-associated" className="mb-1">
        <Skeleton height={50} />
      </div>
    ];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-around my-4"
          key={`${i}-skeleton-table-wireless-associated`}>
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
        </div>
      );
    }
    return skeletons;
  };

  const renderNotSupportedFrequency = (text: string) => {
    return (
      <Tooltip
        place="bottom"
        id="tooltip-not-supported-frequency"
        content={t('Este equipamento não suporta essa frequência de rádio')}>
        <span className={classNames([styles.textDisable])}>{text}</span>
      </Tooltip>
    );
  };

  const renderLabelFrequency = (frequency: string) => {
    if (frequency === 'dualband' || frequency === '2.4GHz | 5GHz') {
      if (!deviceOnScreen.radio_list.find((radio) => radio.band === '2.4')) {
        return (
          <div className={classNames(['d-flex'])}>
            {renderNotSupportedFrequency('2.4GHz |')}
            <span> 5GHz</span>
          </div>
        );
      }

      if (!deviceOnScreen.radio_list.find((radio) => radio.band === '5')) {
        return (
          <div className={classNames(['d-flex'])}>
            <span>2.4GHz </span>
            {renderNotSupportedFrequency('| 5GHz')}
          </div>
        );
      }

      if (
        deviceOnScreen.radio_list.find((radio) => radio.band === '2.4') &&
        deviceOnScreen.radio_list.find((radio) => radio.band === '5')
      ) {
        return <span>2.4GHz | 5GHz</span>;
      }

      return (
        <span className={classNames([styles.textDisable])}>2.4GHz | 5GHz</span>
      );
    }
    return <span>{frequency}GHz</span>;
  };

  const createRowToDisplay = (
    wirelessList: IResumedWireless[]
  ): IResumedWirelessRow[] => {
    const auxWirelessListRow: IResumedWirelessRow[] = [];
    wirelessList.forEach((wireless) => {
      auxWirelessListRow.push({
        ...wireless,
        frequency: renderLabelFrequency(wireless.frequency),
        security: wireless.is_captive_portal_enabled
          ? t('Captive Portal')
          : securityFormat[wireless.security as keyof ISecurityFormat],
        isVisible: (
          <div>
            {wireless.enabled ? (
              <div>
                {wireless.hidden ? (
                  <Tag color="neutral-dark-4" outlined wide>
                    {t('Oculta')}
                  </Tag>
                ) : (
                  <Tag color="blue" outlined wide>
                    {t('Visível')}
                  </Tag>
                )}
              </div>
            ) : (
              <Tag color="lightblue" outlined wide>
                {t('Desabilitada')}
              </Tag>
            )}
          </div>
        )
      });
    });

    return auxWirelessListRow;
  };

  if (isFetchedAfterMountDeviceInfo && deviceWirelessList.length === 0) {
    return (
      <EmptyMessage
        height="calc(100vh - 620px)"
        minHeight="190px"
        disabled={
          // deviceOnScreen?.device?.status === 'offline' ||
          !isFetchedAfterMountDeviceInfo
        }
        title={t('Nenhuma wireless associada')}
        subtitle={t(
          'Ainda não há wireless associada disponível neste equipamento. Experimente associar uma nova'
        )}
        buttonText={t('ASSOCIAR WIRELESS')}
        id="btn-no-wireless"
        action={() => openWirelessModal()}
      />
    );
  }
  return (
    <div>
      <div className="d-flex flex-column">
        <div className="fit-width d-flex justify-between align-center">
          <span className="title-xl-base">{t('Wireless associadas')}</span>
          {isFetchedAfterMountDeviceInfo ? (
            <ButtonTextOutside value={t('ASSOCIAR WIRELESS')}>
              <Button
                disabled={
                  // wirelessOnScreen?.wireless?.status === 'offline' ||
                  !isFetchedAfterMountDeviceInfo
                }
                id="btn-edit-wireless"
                rounded
                onClick={() => {
                  eventGA('Equipamentos', 'Click', 'Tentar associar wireless');
                  openWirelessModal();
                }}>
                <MdAdd size={22} />
              </Button>
            </ButtonTextOutside>
          ) : (
            <Skeleton width={189} height={48} />
          )}
        </div>
        <span className="text-xl-lg mt-1 mb-7">
          {t(
            'Abaixo, você encontra as redes wireless associadas a este equipamento, que podem ser configuradas sempre que necessário'
          )}
        </span>
      </div>
      {isFetchedAfterMountDeviceInfo ? (
        <Table
          noSelection
          className={classNames([styles.table])}
          columns={columns}
          rows={createRowToDisplay(deviceWirelessList)}
        />
      ) : (
        renderSkeletons()
      )}
    </div>
  );
};

export default AssociatedWirelessList;
