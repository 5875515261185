import {
  ILoginRequestData,
  ILoginResponse,
  IPutAuth,
  IRefreshTokenData
} from './types';
import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';

export class AuthAPI implements IRequestMethods {
  url = '/access/auths/';

  login(data: ILoginRequestData) {
    return axiosInstance.post<ILoginResponse>(this.url, data);
  }

  logout() {
    return axiosInstance.delete(this.url, { headers: getAuthHeaders() });
  }

  refresh(data: IRefreshTokenData) {
    return axiosInstance.post<ILoginResponse>(`${this.url}refresh/`, data);
  }

  put(data: { place_id: string }) {
    return axiosInstance.put<IPutAuth>(`${this.url}`, data, {
      headers: getAuthHeaders()
    });
  }
}
