import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Drawer from 'ui-components/Drawer';
import { Col, Grid, Row } from 'inmaster-ui';
import classNames from 'classnames';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import { MdAdd, MdDelete, MdEdit, MdSearch } from 'react-icons/md';
import Label from 'ui-components/Label';
import { InputIconInside } from 'ui-components/InputWrapper';
import { Input } from 'ui-components/Input';
import Table from 'ui-components/Table';
import { Divider } from 'src/components/Divider';
import moment from 'moment-timezone';
import Tag from 'ui-components/Tags';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import { IMemberWithTranslatedRoleData } from 'src/services/api/urls/sites/types';
import { ModalCreateMembers } from 'src/components/ModalCreateMembers';
import { useAuth } from 'src/hooks/useAuth';
import Skeleton from 'react-loading-skeleton';
import { generateColumns } from 'src/utils/tableUtils';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import styles from './MembersDrawer.module.css';
import { IMembersDrawer, MemberRow } from './types';
import { ModalEditMember } from './ModalEditMember';
import { ModalRemoveMember } from './ModalRemoveMember';
import { ISiteItemPage } from '../types';

export const getTagText = (role: RoleMember) => {
  if (role === RoleMember.GUEST) {
    return t('convidado');
  }
  if (role === RoleMember.MANAGER) {
    return t('gerente');
  }

  return t('proprietário');
};

const MembersDrawer = ({
  showMembersDrawer = false,
  selectedSite,
  onClose,
  initMembersCount,
  refetchOwnerSitesAndSharedSites
}: IMembersDrawer) => {
  const { accessToken } = useAuth();

  const [members, setMembers] = useState<IMemberWithTranslatedRoleData[]>([]);

  const [search, setSearch] = useState('');

  const [hasAddedNewMember, setHasAddedNewMember] = useState(false);

  const [canCreateMember, setCanCreateMember] = useState(true);

  const [showModalCreateMember, setShowModalCreateMember] = useState(false);

  const [showModalEditMember, setShowModalEditMember] = useState(false);

  const [selectedMember, setSelectedMember] =
    useState<IMemberWithTranslatedRoleData>(
      {} as IMemberWithTranslatedRoleData
    );

  const [showModalRemoveMember, setShowModalRemoveMember] = useState(false);

  const [countMembers, setCountMembers] = useState(0);

  useEffect(() => {
    if (members.length < 120) {
      setCanCreateMember(true);
    } else {
      setCanCreateMember(false);
    }
  }, [members]);

  const getTagColor = (role: RoleMember) => {
    if (role === RoleMember.GUEST) return 'lightblue';
    if (role === RoleMember.MANAGER) return 'gray';

    return 'mustard';
  };

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('member', t('Membro'), 'left', 0),
    generateColumns('permission', t('Permissão'), 'left', 0),
    generateColumns('memberAge', t('Membro desde'), 'left', 0)
  ];

  const columnsWithActions = [
    ...columns,
    generateColumns('actions', t('Ações'), 'left', 0)
  ];

  const openModalEditMember = (
    memberSelected: IMemberWithTranslatedRoleData
  ) => {
    setSelectedMember(memberSelected);
    setShowModalEditMember(true);
  };

  const handleRemoveMember = (member: IMemberWithTranslatedRoleData) => {
    setSelectedMember(member);
    setShowModalRemoveMember(true);
  };

  const checkMemberRoleAndShowActions = (
    row: IMemberWithTranslatedRoleData
  ) => {
    const isCurrentUserManager =
      row.user.id === accessToken?.user_id && row.role === RoleMember.MANAGER;
    if (row.role !== RoleMember.OWNER && !isCurrentUserManager) {
      return (
        <>
          <ButtonIcon
            id={`edit-member-${row.user.email}`}
            onClick={() => {
              openModalEditMember(row);
            }}>
            <MdEdit size={24} />
          </ButtonIcon>

          <ButtonIcon
            id="button-delete-member"
            onClick={() => handleRemoveMember(row)}>
            <MdDelete size={24} />
          </ButtonIcon>
        </>
      );
    }
    return null;
  };

  const createRowToDisplay = (rowsArray: IMemberWithTranslatedRoleData[]) => {
    const auxRows: MemberRow[] = [];

    for (const row of rowsArray) {
      auxRows.push({
        id: row.id,
        member: (
          <div
            className="d-flex flex-column text-xl-base"
            id={`member-${row.user.email}-${selectedSite?.name}`}>
            <span className="text-bold">{row.user.name}</span>
            <span>{row.user.email}</span>
          </div>
        ),
        permission: (
          <Tag color={getTagColor(row.role)} outlined fullWidth wide>
            {getTagText(row.role)}
          </Tag>
        ),
        memberAge: (
          <span className="text-xl-base">
            {row.user.account_status === 'registered'
              ? moment(row.created_at).format('DD/MM/YYYY')
              : t('Pendente')}
          </span>
        ),
        actions: (
          <div className="d-flex">{checkMemberRoleAndShowActions(row)}</div>
        )
      });
    }

    return auxRows;
  };

  const { isFetchedAfterMount, refetch } = useQuery(
    ['members', selectedSite?.id],
    () => api.sites.members.get(selectedSite?.id ?? ''),
    {
      enabled: showMembersDrawer,
      cacheTime: 0,
      onSuccess: ({ data }) => {
        setCanCreateMember(data.members.length < 120);
        setCountMembers(data.members.length);
        setHasAddedNewMember(false);
        const membersWithTranslatedRole = data.members.map((member) => {
          return {
            ...member,
            user: {
              ...member.user,
              name: member.user.name || '-'
            },
            translatedRole: getTagText(member.role)
          };
        });

        setMembers(membersWithTranslatedRole);
      }
    }
  );

  const membersFiltered = members.filter(
    (member) =>
      member.user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      member.user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      member.translatedRole.toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

  useEffect(() => {
    setCountMembers(initMembersCount);
    setCanCreateMember(initMembersCount < 120);
    setTimeout(() => {
      setSearch('');
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMembersDrawer]);

  const renderSkeletonsOfMembers = (numberOfSkeletons: number) => {
    const skeletons = [];
    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4"
          key={`${i}-skeleton-user-drawer`}>
          <div>
            <Skeleton count={1} height={14} width={120} />
            <Skeleton count={1} height={14} width={220} />
          </div>
          <div>
            <Skeleton count={1} height={30} width={128} borderRadius={100} />
          </div>
          <div className="d-flex">
            <Skeleton count={1} height={45} width={45} className="mr-3" />
            <Skeleton count={1} height={45} width={45} />
          </div>
        </div>
      );
    }
    return skeletons;
  };

  const renderMembers = () => {
    return (
      <Grid fluid>
        <Row className="justify-between align-end">
          <Col
            xs={selectedSite?.member.role !== RoleMember.GUEST ? 7 : 0}
            className="fit-width">
            <div className="fit-width">
              <div className="mb-2">
                <Label>{t('Pesquisar membro')}:</Label>
              </div>
              <InputIconInside iconLeft={<MdSearch />}>
                <Input
                  id="search-input"
                  value={search}
                  iconInsideLeft
                  placeholder={t('Pesquise pelo nome, e-mail ou permissão')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                />
              </InputIconInside>
            </div>
          </Col>

          {selectedSite?.member.role !== RoleMember.GUEST && (
            <Col>
              <div className="d-flex align-center">
                <ButtonTextOutside
                  id="text-btn-open-modal-to-add-member"
                  value={t('ADICIONAR MEMBRO')}
                  className={
                    canCreateMember
                      ? classNames(['text-uppercase mr-2'])
                      : classNames(['text-uppercase mr-2', styles.textDisabled])
                  }>
                  <Button
                    id="btn-open-modal-to-add-member"
                    rounded
                    onClick={() => setShowModalCreateMember(true)}
                    disabled={!canCreateMember}>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              </div>
            </Col>
          )}
        </Row>

        <Divider />
        {membersFiltered.length === 0 ? (
          <ResultsNotFoundMessage
            id="result-empty-members"
            minHeight="100px"
            height="calc(100vh - 300px)"
          />
        ) : (
          <Table
            noSelection
            className={styles.table}
            columns={
              selectedSite?.member.role === RoleMember.OWNER ||
              selectedSite?.member.role === RoleMember.MANAGER
                ? columnsWithActions
                : columns
            }
            rows={createRowToDisplay(membersFiltered)}
          />
        )}

        {hasAddedNewMember && (
          <div className="d-flex justify-between my-4">
            <div>
              <Skeleton count={1} height={14} width={120} />
              <Skeleton count={1} height={14} width={220} />
            </div>
            <div>
              <Skeleton count={1} height={30} width={128} borderRadius={100} />
            </div>
            <div className="d-flex">
              <Skeleton count={1} height={45} width={45} className="mr-3" />
              <Skeleton count={1} height={45} width={45} />
            </div>
          </div>
        )}
      </Grid>
    );
  };

  const onRemoveMember = () => {
    setCanCreateMember(countMembers - 1 < 120);
    setCountMembers((oldCount) => oldCount - 1);
    refetchOwnerSitesAndSharedSites();
    setMembers((oldMemberList) => {
      return oldMemberList.filter((member) => member.id !== selectedMember?.id);
    });
    refetch();
  };

  return (
    <Drawer
      show={showMembersDrawer}
      onClose={onClose}
      responsiveWidth={{ lg: '60vw', xl: '40vw', sm: '80vw', md: '70vw' }}
      id="drawer-members"
      title={`${t('MEMBROS')} ${selectedSite?.name.toUpperCase()}`}>
      {!isFetchedAfterMount ? (
        <div
          className={classNames([
            'fit-width d-flex justify-start align-start mt-8',
            styles.loadingContainer
          ])}>
          <Grid>
            <Row className="justify-between align-end">
              <Col
                xs={selectedSite?.member.role !== RoleMember.GUEST ? 7 : 0}
                className="fit-width pr-3">
                <Skeleton count={1} height={14} className="mb-1 mt-2" />
                <Skeleton count={1} height={52} />
              </Col>
              <Col
                className="fit-width pl-4"
                xs={selectedSite?.member.role !== RoleMember.GUEST ? 5 : 0}>
                <Skeleton count={1} height={52} />
              </Col>
            </Row>
            <Row className="mt-10">
              <Col xs={12} className="d-flex flex-column">
                <Skeleton
                  count={1}
                  height={50}
                  borderRadius={10}
                  className="mb-3"
                />
                {renderSkeletonsOfMembers(7)}
              </Col>
            </Row>
          </Grid>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className={classNames([styles.membersMaxCountInfo, 'mb-6'])}>
            <span className="text-sm-base">
              {t(
                'O proprietário do local ou administrador pode adicionar até 120 membros'
              )}
            </span>
          </div>
          {members.length > 0 ? (
            renderMembers()
          ) : (
            <div className="fit-width d-flex align-center justify-center">
              <EmptyMessage
                title={t('Nenhum membro')}
                subtitle={t('Não há membros na sua equipe')}
                id="empty-members"
                minHeight="100px"
                height="calc(100vh - 180px)"
              />
            </div>
          )}
          <ModalCreateMembers
            setShowModalCreateMember={setShowModalCreateMember}
            showModalCreateMember={showModalCreateMember}
            site={{ ...selectedSite } as ISiteItemPage}
            onMemberCreated={(newMembersCount: number) => {
              setCountMembers(newMembersCount);
              setCanCreateMember(newMembersCount < 120);
              setHasAddedNewMember(true);
              refetch();
              refetchOwnerSitesAndSharedSites();
            }}
          />
          <ModalEditMember
            member={selectedMember || null}
            setShowModalEditMember={setShowModalEditMember}
            showModalEditMember={showModalEditMember}
            onMemberEdited={(memberEdited: IMemberWithTranslatedRoleData) => {
              setMembers((oldMemberList) => {
                return oldMemberList.map((member) => {
                  if (member.id === memberEdited.id) {
                    return memberEdited;
                  }
                  return member;
                });
              });
              refetch();
            }}
          />
          <ModalRemoveMember
            selectedMember={selectedMember || null}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedSite={selectedSite!}
            setShowModalRemoveMember={setShowModalRemoveMember}
            showModalRemoveMember={showModalRemoveMember}
            onMemberRemoved={onRemoveMember}
          />
        </div>
      )}
    </Drawer>
  );
};

export { MembersDrawer };
