import _ from 'lodash';
import i18n from 'src/i18n';
import { IIndividualRecord } from 'src/services/api/urls/captivePortal/types';
import { generateExpirationTime, getDateOrNullFormat } from './captiveDates';

const t = (key: string) => i18n.t(`captivePortal.individualRecord.${key}`);

const getStatusOfRecord = (
  hasExpiresAt: boolean,
  status: string,
  type: 'active' | 'history'
) => {
  if (type === 'history') {
    return t('Expirado');
  }
  if (!hasExpiresAt) {
    return '--';
  }

  return status === 'used' ? t('Utilizado') : t('Disponível');
};

const getAuthorizedStatus = (
  expires_at: string | null,
  type: 'active' | 'history'
) => {
  if (type === 'history') {
    return !!expires_at;
  }
  const isValidExpires_at = !_.isNil(expires_at);
  return isValidExpires_at;
};

export const generateIndividualRecordsItemsOfPagination = (
  type: 'active' | 'history',
  items: IIndividualRecord[],
  siteTimezone: string | undefined
) => {
  return items.map((individualRecord) => {
    const hasExpiresAt = !_.isNil(individualRecord.expires_at);

    return {
      ...individualRecord,
      created_at: getDateOrNullFormat(
        individualRecord.created_at,
        siteTimezone
      ),
      started_at: getDateOrNullFormat(
        individualRecord.started_at,
        siteTimezone
      ),
      expires_at: getDateOrNullFormat(
        individualRecord.expires_at,
        siteTimezone
      ),
      time: generateExpirationTime(
        individualRecord.started_at || null,
        individualRecord.expires_at || null,
        siteTimezone
      ),
      authorizedStatus: getAuthorizedStatus(
        individualRecord.expires_at || null,
        type
      ),
      status: getStatusOfRecord(
        hasExpiresAt,
        individualRecord.status_credential || 'used',
        type
      )
    };
  });
};
