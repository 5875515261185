import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit, MdFactCheck } from 'react-icons/md';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { generateColumns } from 'src/utils/tableUtils';
import { Button, ButtonIcon } from 'ui-components/Button';
import Table from 'ui-components/Table';
import { ICustomer } from '../../CustomersTab/types';
import styles from './CustomersStep.module.css';

interface IProps {
  customers: ICustomer[];
  selectedCustomers: ICustomer[];
  setSelectedCustomers: React.Dispatch<React.SetStateAction<ICustomer[]>>;
  setShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCustomerAccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  nextStep: () => void;
  renderBackAndCancelButton: () => JSX.Element;
}

const CustomersStep = ({
  customers,
  nextStep,
  selectedCustomers,
  setSelectedCustomers,
  setShowAddCustomerModal,
  setShowCustomerAccessModal,
  renderBackAndCancelButton
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const generateRows = (customersToFormat: ICustomer[]) => {
    return customersToFormat.map((customer: ICustomer) => {
      return {
        ...customer,
        actions: (
          <div className="d-flex justify-between align-center">
            <ButtonIcon
              id={`btn-list-customer-${customer.name}`}
              onClick={() => setShowCustomerAccessModal(true)}>
              <MdFactCheck size={24} />
            </ButtonIcon>

            <ButtonIcon id={`btn-edit-customer-${customer.name}`}>
              <MdEdit size={24} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const columnsTableCustomers = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('mac', t('MAC'), 'left', 0),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('actions', t('Ações'), 'center', 0)
  ];

  return (
    <div>
      {customers.length === 0 ? (
        <div>
          <EmptyMessage
            title={t('Nenhum cliente')}
            subtitle={t(
              'Ainda não há cliente disponível nesta lista. Experimente adicionar um novo'
            )}
            id="message-no-wireless"
            buttonText={t('ADICIONAR CLIENTE')}
            action={() => setShowAddCustomerModal(true)}
            minHeight="190px"
            height="calc(100vh - 575px)"
          />

          <div className="d-flex justify-end mt-3">
            {renderBackAndCancelButton()}
          </div>
        </div>
      ) : (
        <div>
          <Table
            className={classNames(['', styles.customersTable])}
            columns={columnsTableCustomers}
            rows={generateRows(customers)}
            onSelectRows={(selectedRows: ICustomer[]) => {
              setSelectedCustomers(selectedRows);
            }}
            selectedRows={selectedCustomers}
            keyToCompare="mac"
            useKeyToCompareAsId
          />

          <div className="d-flex justify-end mt-3">
            {renderBackAndCancelButton()}
            {customers.length > 0 && (
              <Button
                id="button-customer-next-step"
                onClick={nextStep}
                disabled={customers.length === 0}
                className="ml-3">
                {t('PRÓXIMO')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomersStep;
