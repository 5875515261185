import React from 'react';
import { Tooltip } from 'inmaster-ui';
import { Button } from 'ui-components/Button';
import { useTranslation } from 'react-i18next';
import styles from './ButtonsActions.module.css';
import CancelWizardModal from '../CancelWizardModal';

export type handleContinueBooleanFunction = () => boolean;

interface IPropsButtonActionsWizard {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleContinue?: (() => Promise<boolean>) | handleContinueBooleanFunction;
  disabledContinue?: boolean;
  textOfDisableTooltip?: string;
  disableTooltip?: boolean;
}

const ButtonsActionsWizard = ({
  currentStep,
  setCurrentStep,
  handleContinue,
  disabledContinue,
  textOfDisableTooltip,
  disableTooltip
}: IPropsButtonActionsWizard) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const isFirstStep = currentStep === 0;

  const [showCancelModal, setShowCancelModal] = React.useState(false);

  const lastStep = 2;
  const isLastStep = currentStep === 2;
  const canGoToNextStep = currentStep < lastStep;

  const handleOnClick = async () => {
    if (!handleContinue) return;

    const canContinue = await handleContinue();

    if (!canGoToNextStep) return;

    if (canContinue) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <>
      <CancelWizardModal show={showCancelModal} setShow={setShowCancelModal} />

      <div className={styles.shellActionsButtons}>
        <Button ghost onClick={() => setShowCancelModal(true)}>
          {t('CANCELAR')}
        </Button>
        {isFirstStep ? null : (
          <Button
            secondary
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}>
            {t('VOLTAR')}
          </Button>
        )}
        <Tooltip
          id="continue-btn-tooltip"
          content={textOfDisableTooltip}
          place="top"
          disabled={!disabledContinue || disableTooltip}>
          <Button onClick={handleOnClick} disabled={disabledContinue}>
            {isLastStep ? t('CONCLUIR') : t('CONTINUAR')}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

ButtonsActionsWizard.defaultProps = {
  handleContinue: undefined,
  disabledContinue: false,
  textOfDisableTooltip: '',
  disableTooltip: false
};

export default ButtonsActionsWizard;
