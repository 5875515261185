import React from 'react';
import { MdClose } from 'react-icons/md';
import { ButtonIcon } from 'ui-components/Button';
import Card from 'ui-components/Card';

import { Tooltip } from 'inmaster-ui';

interface IClosableCardProps {
  id: string;
  title: string;
  children: React.ReactNode;
  type?: string | undefined;
  closable?: boolean;
  closeTooltipText?: string;
  isLoadingClose?: boolean;
  onClose?: () => void;
}

const ClosableCard = ({
  title,
  type,
  id,
  closable,
  closeTooltipText,
  isLoadingClose,
  children,
  onClose,
  ...props
}: IClosableCardProps) => {
  return (
    <Card
      borderColor="var(--color-brand-secondary-light)"
      className="mb-2"
      id={`card-${type}@${id}`}>
      <div className={`d-flex justify-between align-center mb-3 ${props}`}>
        <span className="title-sm-sm">{title}</span>
        {closable ? (
          <div style={{ position: 'relative' }}>
            <Tooltip
              content={closeTooltipText || ''}
              place="bottom-end"
              id="tooltip-close-button">
              <ButtonIcon
                isLoading={isLoadingClose}
                onClick={() => (onClose ? onClose() : null)}
                id={`btn-close-card@${id}`}>
                <MdClose size={22} />
              </ButtonIcon>
            </Tooltip>
          </div>
        ) : null}
      </div>
      {children}
    </Card>
  );
};

ClosableCard.defaultProps = {
  type: '',
  closable: false,
  closeTooltipText: '',
  isLoadingClose: false,
  onClose: () => null
};

export default ClosableCard;
