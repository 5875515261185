import { useContext, useEffect } from 'react';
import { TemplatesContext, TemplateType } from 'src/contexts/TemplatesContext';

export function useTemplate(template: TemplateType = 'noBars') {
  const { setTemplate } = useContext(TemplatesContext);

  useEffect(() => {
    setTemplate(template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);
}
