import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Grid, Row } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdPerson,
  MdSearch,
  MdWifi
} from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import { generateColumns } from 'src/utils/tableUtils';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import api from 'src/services/api';
import { useQuery } from 'react-query';
import { ISiteResponseUnique } from 'src/services/api/urls/sites/types';
import { useTemplate } from 'src/hooks/useTemplate';
import { EmptyMessage } from 'src/components/EmptyMessage';
import TableWithPagination from 'src/components/TableWithPagination';
import { ButtonComeBack } from 'src/components/ButtonComeBack';
import { AclType, IGetAcl } from 'src/services/api/urls/devices/types';
import { useDebounce } from 'use-debounce';
import { useAuth } from 'src/hooks/useAuth';
import { ActiveTabSitesEnum, ISiteItemPage } from '../Sites/types';
import styles from './AccessLists.module.css';
import { IAccessList, IPaginationAccessList } from './types';
import WarningListModal from './WarningListModal';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from '../Devices/types';
import NoWirelessWarnModal from './NoWirelessWarnModal';

const AccessLists = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists'
  });

  const { accessToken } = useAuth();

  const [activeTabSiteIndex] = useLocalStorage('@manager/activeTabSite');

  const navigate = useNavigate();

  const { id: idSite } = useParams();

  const [selectedSite, setSelectedSite] = useState<ISiteItemPage>(
    {} as ISiteItemPage
  );

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [accessListPagination, setAccessListPagination] =
    useState<IPaginationAccessList>({} as IPaginationAccessList);

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  const [showWarningListModal, setShowWarningListModal] = useState(false);

  const [selectedAccessLists, setSelectedAccessLists] = useState<
    IAccessList[] | null
  >(null);

  const [hasDisassociatedWireless, setHasDisassociatedWireless] =
    useState(false);

  const [showNoWirelessWarnModal, setShowNoWirelessWarnModal] = useState(false);

  const getSelectedSite = () => {
    return api.sites.get(idSite);
  };

  const { isFetchedAfterMount: isFetchedSite } = useQuery(
    'selectedSiteQuery',
    getSelectedSite,
    {
      cacheTime: 0,
      onSuccess: ({ data }: { data: ISiteResponseUnique }) => {
        setSelectedSite({
          id: data.site.id,
          name: data.site.name,
          member: data.member
        } as ISiteItemPage);
      },
      onError: () => {
        navigate('error');
      }
    }
  );

  const getAclsList = () => {
    return api.devices.acls.get(idSite, paginationRequestDataDebounce);
  };

  const checkAclType = (type: string): string => {
    switch (type) {
      case AclType.ALLOW_LIST:
        return t('Permissão');
      case AclType.DENY_LIST:
        return t('Bloqueio');
      default:
        return '';
    }
  };

  const { isFetching: isFetchingAccessList } = useQuery(
    ['getAclListQuery', paginationRequestDataDebounce, accessToken?.site_id],
    getAclsList,
    {
      cacheTime: 0,
      onSuccess: ({ data }: { data: IGetAcl }) => {
        if (!isInitialLoadingComplete) {
          setIsInitialLoadingComplete(true);
        }

        setAccessListPagination({
          acl_list: data.acl_list.map((acl) => {
            return {
              name: acl.name,
              list_type: checkAclType(acl.type),
              clients_count: acl.client_count,
              wireless_count: acl.wireless_count,
              id: acl.id
            } as IAccessList;
          }),
          pagination: data.pagination
        });
      },
      onError: () => {
        navigate('error');
      }
    }
  );

  const getDisassociatedWireless = () => {
    return api.devices.acls.getDisassociatedWireless(idSite);
  };

  const { isFetching } = useQuery(
    'getDisassociatedwireless',
    getDisassociatedWireless,
    {
      onSuccess: ({ data }) => {
        if (data.wireless.length > 0) {
          setHasDisassociatedWireless(true);
        } else {
          setHasDisassociatedWireless(false);
        }
      }
    }
  );

  const isLoadingACLOrSite =
    !isInitialLoadingComplete || !isFetchedSite || isFetching;

  const isLoadingChangingPage =
    !isInitialLoadingComplete &&
    !isChangingPage &&
    loadingTypeOfRowsPerPage.type === 'success';

  const isEmptyACL =
    !isFetchingAccessList &&
    isInitialLoadingComplete &&
    accessListPagination.pagination.total_items === 0;

  const handleClickWarning = (acl: IAccessList) => {
    setShowWarningListModal(true);
    setSelectedAccessLists([acl]);
  };

  const comeBackSites = () => {
    navigate('/sites', { replace: true });
  };

  const generateColumnWithIcon = (
    value: number | string,
    icon: React.ReactNode
  ) => (
    <div className="d-flex align-center">
      {icon}
      <span className="ml-2 text-bold">{value}</span>
    </div>
  );

  const generateRows = (accessListsToFormat: IAccessList[]) => {
    if (!accessListsToFormat) {
      return [];
    }

    return accessListsToFormat.map((accessList: IAccessList) => {
      return {
        ...accessList,
        disableRow: accessList.status === 'loading',
        onStatusClick:
          accessList.status === 'warning'
            ? () => handleClickWarning(accessList)
            : null,
        statusText:
          accessList.status === 'loading'
            ? t('Aplicando configurações')
            : t('Existem equipamentos que não foram configurados'),
        name: (
          <span
            className="text-bold"
            id={`
          access-list-name-${accessList.id}-${accessList.name}
        `}>
            {accessList.name}
          </span>
        ),
        list_type: (
          <span
            className={styles.colListTypeText}
            id={`
          access-list-type-${accessList.id}-${accessList.list_type}
        `}>
            {accessList.list_type}
          </span>
        ),
        clients_count: (
          <div
            id={`access-list-${accessList.id}-clients-count-${accessList.clients_count}`}>
            {generateColumnWithIcon(
              accessList.clients_count,
              <MdPerson size={24} color="var(--color-brand-primary-base)" />
            )}
          </div>
        ),
        wireless_count: (
          <div
            id={`access-list-${accessList.id}-wireless-count-${accessList.wireless_count}`}>
            {generateColumnWithIcon(
              accessList.wireless_count,
              <MdWifi size={24} color="var(--color-brand-primary-base)" />
            )}
          </div>
        ),
        actions: (
          <div className="d-flex justify-between align-center">
            <ButtonIcon
              id={`btn-edit-access-list-${accessList.id}`}
              disabled={accessList.status === 'loading'}>
              <MdEdit size={24} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const columnsTableAccessLists = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('list_type', t('Tipo de lista'), 'left', 0),
    generateColumns('clients_count', t('Clientes'), 'left', 0),
    generateColumns('wireless_count', t('Wireless'), 'left', 0),
    generateColumns('actions', t('Ações'), 'center', 0)
  ];

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-around my-4 align-center"
          key={`${i}-skeleton-connection-log-table`}>
          <Skeleton count={1} height={25} width={25} className="ml-2" />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={25} width={25} />
          <Skeleton count={1} height={25} width={25} />
          <Skeleton count={1} height={25} width={25} className="mr-3" />
        </div>
      );
    }

    return skeletons;
  };

  useTemplate('avatarNavbar');

  return (
    <div className={styles.shellAccessLists}>
      <WarningListModal
        show={showWarningListModal}
        onClose={() => {
          setShowWarningListModal(false);
          setSelectedAccessLists(null);
        }}
        selectedAccessListId={
          selectedAccessLists && selectedAccessLists[0]
            ? selectedAccessLists[0].id
            : null
        }
      />

      <NoWirelessWarnModal
        show={showNoWirelessWarnModal}
        onClose={() => setShowNoWirelessWarnModal(false)}
      />

      <Grid fluid>
        {!isFetchedSite ? (
          <div className="ml-2 mb-5">
            <Skeleton width={200} height={24} />
          </div>
        ) : (
          <Breadcrumbs className="mb-6 ml-2">
            <Crumb>
              <div className="d-flex justify-start fit-width align-center">
                <ButtonComeBack onClick={() => comeBackSites()} />

                <Link to="/sites">
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    activeTabSiteIndex === ActiveTabSitesEnum.SHARED_WITH_ME
                      ? t('Compartilhados comigo')
                      : t('Meus locais')
                  }
                </Link>
              </div>
            </Crumb>
            <Crumb>{t('Listas de Acesso (ACL)')}</Crumb>
          </Breadcrumbs>
        )}

        {isEmptyACL ? (
          <div className={classNames(['fit-width'])}>
            <EmptyMessage
              title={t('Nenhuma lista de acesso')}
              subtitle={t(
                'Ainda não há lista de acesso disponível neste local. Experimente adicionar uma nova'
              )}
              id="message-no-access-lists"
              buttonText={t('ADICIONAR LISTA')}
              action={
                !hasDisassociatedWireless
                  ? () => setShowNoWirelessWarnModal(true)
                  : () => navigate('add')
              }
            />
          </div>
        ) : (
          <>
            <Row className="my-7">
              <Col xs={12} className="d-flex align-center">
                {!isInitialLoadingComplete || !isFetchedSite || isFetching ? (
                  <div className="ml-2">
                    <Skeleton width={280} height={35} />
                  </div>
                ) : (
                  <h1
                    className={classNames(['title-sm-lg', styles.title])}
                    id="access-lists-title-page">
                    {t('Listas de Acesso (ACL)')} - {selectedSite.name}
                  </h1>
                )}
              </Col>
            </Row>

            {selectedAccessLists && selectedAccessLists?.length > 0 ? (
              <Row>
                <Col xs={12} className="d-flex">
                  <ButtonTextOutside
                    value={`${
                      selectedAccessLists.length > 1
                        ? t('Excluir listas')
                        : t('Excluir lista')
                    } (${selectedAccessLists?.length})`}
                    className="text-uppercase mt-6 mb-6 ml-7">
                    <Button
                      onClick={() => console.log('Abrir modal')}
                      id="button-delete-device"
                      rounded
                      color="var(--color-status-critical-base)">
                      <MdDelete size={22} />
                    </Button>
                  </ButtonTextOutside>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={8}>
                  {isLoadingACLOrSite ? (
                    <div>
                      <Row>
                        <Skeleton count={1} height={18} width={160} />
                      </Row>
                      <Skeleton count={1} height={52} borderRadius={10} />
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Label>{t('Pesquisar na lista')}:</Label>
                      </Row>
                      <InputIconInside
                        iconLeft={
                          isLoadingChangingPage ? (
                            <Loading value={40} indeterminate />
                          ) : (
                            <MdSearch />
                          )
                        }>
                        <Input
                          id="search-input"
                          iconInsideLeft
                          placeholder={t('Digite no mínimo 3 caracteres')}
                        />
                      </InputIconInside>
                    </div>
                  )}
                </Col>
                <Col>
                  {isLoadingACLOrSite ? (
                    <Skeleton
                      className="my-6 ml-7"
                      count={1}
                      height={50}
                      width={260}
                      borderRadius={10}
                    />
                  ) : (
                    <ButtonTextOutside
                      value={t('ADICIONAR LISTA')}
                      className="text-uppercase mt-6 mb-6 ml-7">
                      <Button
                        id="button-add-device-now"
                        rounded
                        onClick={
                          !hasDisassociatedWireless
                            ? () => setShowNoWirelessWarnModal(true)
                            : () => navigate('add')
                        }
                        disabled={isFetching}>
                        <MdAdd size={22} />
                      </Button>
                    </ButtonTextOutside>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              {
                // TODO: Acrescentar mensagem de resultado vazio, ao posuir o request
              }
              {/* {search.length > 0 &&
              paginationObject.lists.length === 0 &&
              !isFetchingCustomers &&
              isInitialLoadingComplete ? (
                <ResultsNotFoundMessage
                  id="acl-list"
                  minHeight="190px"
                  height="calc(100vh - 500px)"
                />
              ) : ( */}
              <TableWithPagination<IAccessList>
                hasStatus
                isChangingPage={isChangingPage}
                columns={columnsTableAccessLists}
                setPaginationObject={setAccessListPagination}
                loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                rows={generateRows(accessListPagination.acl_list)}
                isToShowSkeletons={!isFetchedSite}
                selectedRows={selectedAccessLists || undefined}
                onSelectRows={(selectedRows: IAccessList[]) =>
                  setSelectedAccessLists(selectedRows)
                }
                isFetchingData={isFetchingAccessList}
                paginationData={accessListPagination.pagination}
                firstFetchWasMade={isInitialLoadingComplete}
                paginationRequestData={paginationRequestDataDebounce}
                setIsChangingPage={setIsChangingPage}
                setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
                setPaginationRequestData={setPaginationRequestData}
                minRowsToShowPagination={10}
                keyOfItemsOfPaginationTable="access-list"
                className="mt-11"
                renderSkeletonsOfTable={renderSkeletonsOfTable}
              />
              {/* )} */}
            </Row>
          </>
        )}
      </Grid>
    </div>
  );
};

export default AccessLists;
