import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import { isNull } from 'lodash';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FormForPreviewCaptive } from '../FormForPreviewCaptive';
import { ICaptivePortal } from '../types';
import { IPersonalizeForm } from '../PersonalizeCaptivePortal/types';
import styles from './PreViewCaptiveModal.module.css';

interface IPreViewCaptiveModal {
  showPreViewModal: boolean;
  methodCaptive: string | null;
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  onClose: () => void;
}

const PreViewCaptiveModal = ({
  showPreViewModal,
  methodCaptive,
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  onClose
}: IPreViewCaptiveModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.preViewCaptiveModal'
  });

  const navigate = useNavigate();

  const mapDefaultCaptiveToFormPersonalize = () => {
    return !isNull(defaultCaptivePortal)
      ? ({
          captivePortal: {
            header_image_url: defaultCaptivePortal.header_image_url,
            pageText: defaultCaptivePortal.preview_text,
            pageTitle: defaultCaptivePortal.name,
            urlRedirectionAfterLogin: defaultCaptivePortal.redirect_url
          }
        } as IPersonalizeForm)
      : null;
  };

  return (
    <Modal
      show={showPreViewModal}
      width="700px"
      closable
      height="600px"
      scrollable
      title={t('Visualizar Captive')}
      onClose={onClose}>
      <span className="text-sm-sm">
        {t(
          'Essa é uma versão prévia da página de login do cliente com o método Captive selecionado. Para personalizar, torne este método ATIVO e altere em'
        )}
        :
      </span>
      <span
        id="btnRedirectMethodActiveCaptivePortal"
        onClick={() => navigate('/captive-portal')}
        className={classNames(
          styles.btnRedirect,
          'ml-1',
          'text-bold',
          'text-sm-sm'
        )}
        aria-hidden="true">
        {t('Página do Captive')}
      </span>
      <FormForPreviewCaptive
        isFetchedCaptivePortal={isFetchedCaptivePortal}
        methodCaptive={methodCaptive}
        viewFormData={mapDefaultCaptiveToFormPersonalize()}
        isReadOnly
      />
    </Modal>
  );
};

export { PreViewCaptiveModal };
