/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNotification } from 'src/hooks/useNotification';
import api from 'src/services/api';
import Modal from 'ui-components/Modal';
import { ISelectedFirmwareVersion } from '../types';

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedFirmwareVersion: ISelectedFirmwareVersion | null;
  equipamentName: string;
  onSucess: () => void;
}

const ConfirmUpdateModal = ({
  onClose,
  show,
  equipamentName,
  selectedFirmwareVersion,
  onSucess
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const { triggerNotificationAnimationWithText } = useNotification();

  const updateFirmwareMutation = useMutation(
    () =>
      api.devices.firmwares.update(
        selectedFirmwareVersion!.device_id,
        selectedFirmwareVersion!.firmware_version
      ),
    {
      onSuccess: () => {
        triggerNotificationAnimationWithText(t('ATUALIZANDO EQUIPAMENTO'));
        onSucess();
      }
    }
  );

  const handleUpdate = () => {
    updateFirmwareMutation.mutate();
  };

  const updateActions = [
    {
      label: t('CANCELAR'),
      disabled: updateFirmwareMutation.isLoading,
      action: () => {
        onClose();
      }
    },
    {
      label: t('CONTINUAR'),
      isLoading: updateFirmwareMutation.isLoading,
      action: () => {
        handleUpdate();
      }
    }
  ];

  return (
    <Modal show={show} width="550px" actions={updateActions}>
      <span>
        {t('O equipamento')}
        <span className="text-bold"> {equipamentName} </span>
        {t(
          'ficará offline por alguns minutos e os clientes serão desconectados'
        )}
        .
      </span>
    </Modal>
  );
};

export default ConfirmUpdateModal;
