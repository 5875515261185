import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMethodTypeName } from 'src/pages/CaptivePortal/SetUpMethod/types';

export function useGenerateCaptiveMethodsTypesObject() {
  const { t } = useTranslation('translations', { keyPrefix: 'captivePortal' });
  return {
    password: {
      nameType: t('Senha Simples'),
      statusMethod: false
    },
    cpf: {
      nameType: t('Cadastro Pessoa Física'),
      statusMethod: false
    },
    individual_record: {
      nameType: t('Cadastro Individual'),
      statusMethod: false
    },
    voucher: {
      nameType: t('Voucher'),
      statusMethod: false
    }
  };
}

export function useGetTranslatedCaptiveMethodType(methodType: IMethodTypeName) {
  const { t } = useTranslation('translations', { keyPrefix: 'captivePortal' });
  const methodsAndTraslations = {
    password: t('Senha Simples'),
    cpf: t('Cadastro Pessoa Física'),
    individual_record: t('Cadastro Individual'),
    voucher: t('Voucher')
  };
  return methodsAndTraslations[methodType];
}

export const captiveMethods = [
  'password',
  'cpf',
  'individual_record',
  'voucher'
];
