import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import { IMemberWithTranslatedRoleData } from 'src/services/api/urls/sites/types';
import Modal from 'ui-components/Modal';
import api from 'src/services/api';
import classNames from 'classnames';
import { IErrorResponse } from 'src/services/api/types';
import { ISiteItemPage } from '../../types';

interface IModalRemoveMemberProps {
  showModalRemoveMember: boolean;
  setShowModalRemoveMember: Dispatch<SetStateAction<boolean>>;
  selectedSite: ISiteItemPage;
  selectedMember: IMemberWithTranslatedRoleData;
  onMemberRemoved: () => void;
}

const ModalRemoveMember = (props: IModalRemoveMemberProps) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', { keyPrefix: 'members' });
  const {
    selectedMember,
    selectedSite,
    onMemberRemoved,
    setShowModalRemoveMember,
    showModalRemoveMember
  } = props;
  const removeMemberMutation = useMutation(
    () => api.sites.members.delete(selectedSite.id, selectedMember.id),
    {
      onSuccess: () => {
        onMemberRemoved();
        setShowModalRemoveMember(false);
        addToast('success', t('Acesso removido com sucesso'));
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }

        setShowModalRemoveMember(false);
      }
    }
  );

  const renderFormattedMemberName = (member: IMemberWithTranslatedRoleData) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

    return member.user && member.user.name !== '-'
      ? member.user.name
      : t('esse usuário');
  };

  return (
    <Modal
      width="550px"
      show={showModalRemoveMember}
      actions={[
        {
          label: t('CANCELAR'),
          disabled: removeMemberMutation.isLoading,
          action: () => setShowModalRemoveMember(false)
        },
        {
          isLoading: removeMemberMutation.isLoading,
          label: t('REMOVER'),
          action: () => {
            removeMemberMutation.mutate();
          }
        }
      ]}>
      <span>
        {t('Ao realizar o próximo')}{' '}
        <span style={{ fontStyle: 'italic' }}>login</span>{' '}
        <span
          className={classNames([
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedMember.user &&
              selectedMember.user.name !== '-' &&
              'text-bold'
          ])}>
          {renderFormattedMemberName(selectedMember)}
        </span>{' '}
        {t('perderá o acesso ao local')} {selectedSite.name}
        {'. '}
        {t('Deseja realmente revogar as permissões dele?')}
      </span>
    </Modal>
  );
};

export { ModalRemoveMember };
