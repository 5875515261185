import React, { HTMLAttributes } from 'react';
import { Button } from 'ui-components/Button';
import ProgressBar from 'ui-components/ProgressBar';
import { Alert } from 'ui-components/Alert';
import { useTranslation } from 'react-i18next';
import styles from './SetupProgressCard.module.css';
import ClosableCard from '../CloseableCard';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  value: number;
  message: string;
  type?: string | undefined;
  title: string;
  subtitle?: string;
  warningMessage?: string;
  closable?: boolean;
  closeTooltipText?: string;
  isLoadingClose?: boolean;
  onClose?: () => void;
  onVerify?: () => void;
}

const SetupProgressCard = ({
  id,
  value,
  type,
  message,
  title,
  subtitle,
  warningMessage,
  closable,
  closeTooltipText,
  isLoadingClose,
  onClose,
  onVerify
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.drawer'
  });

  const renderSubtitle = () => {
    const subtitleList = subtitle?.split(' ');

    return (
      <span className={styles.subtitle} id={`subtitle@${id}`}>
        {subtitleList?.map((word, index) => {
          const hasWomCftv =
            (word === 'WOM' && subtitleList[index + 1] === 'CFTV') ||
            (word === 'CFTV' && subtitleList[index - 1] === 'WOM');

          if (hasWomCftv) {
            return (
              <span key={word} className="text-bold">
                {word}{' '}
              </span>
            );
          }
          return `${word} `;
        })}
      </span>
    );
  };

  return (
    <ClosableCard
      type={type}
      id={id}
      title={title}
      closable={closable}
      isLoadingClose={isLoadingClose}
      onClose={onClose}
      closeTooltipText={closeTooltipText}>
      {subtitle && subtitle.length > 0 && (
        <div className={styles.subtitleShell}>{renderSubtitle()}</div>
      )}
      <ProgressBar
        value={value}
        type={type || undefined}
        message={message}
        className={warningMessage && warningMessage.length > 0 ? 'mb-4' : ''}
      />
      {warningMessage && warningMessage.length > 0 ? (
        <div className="d-flex">
          <Alert type="warning">{warningMessage}</Alert>
          <Button
            ghost
            className="ml-3"
            id={`btn-verify@${id}`}
            onClick={() => (onVerify ? onVerify() : null)}>
            {t('VERIFICAR')}
          </Button>
        </div>
      ) : null}
    </ClosableCard>
  );
};

SetupProgressCard.defaultProps = {
  warningMessage: '',
  closable: false,
  closeTooltipText: '',
  onClose: undefined,
  type: undefined,
  onVerify: undefined,
  isLoadingClose: false,
  subtitle: ''
};

export default SetupProgressCard;
