import _ from 'lodash';

interface IFormCompare<T = any> {
  initialValues: T;
  currentValues: T;
}
export const useFormCompare = ({
  initialValues,
  currentValues
}: IFormCompare) => {
  //   eslint-disable-next-line guard-for-in
  for (const key in initialValues) {
    if (!_.isEqual(initialValues[key], currentValues[key])) {
      return false;
    }
  }
  return true;
};

export const useArrayCompare = (
  initialValues: any[],
  currentValues: any[],
  keysToCompare = [] as string[]
) => {
  if (initialValues.length !== currentValues.length) {
    return false;
  }

  const sortedInitValue = _.sortBy(initialValues, keysToCompare);
  const sortedCurrentValue = _.sortBy(currentValues, keysToCompare);

  if (keysToCompare.length > 0) {
    return sortedInitValue.every((item, index) => {
      return keysToCompare.every((key) => {
        return _.isEqual(item[key], sortedCurrentValue[index][key]);
      });
    });
  }

  return sortedInitValue.every((item, index) => {
    return _.isEqual(item, sortedCurrentValue[index]);
  });
};
