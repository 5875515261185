import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import Card from '../Card';

import styles from './ExpansionCard.module.css';

const borderColors = {
  none: '',
  default: 'var(--color-brand-secondary-light)',
  error: 'var(--color-status-critical-dark)',
  warn: 'var(--color-status-alert-dark)'
};

const ExpansionCard = ({
  header = <></>,
  children,
  readOnly = false,
  borderType = 'default',
  className = '',
  alwaysOpen = false,
  id = ''
}) => {
  const contentRef = useRef(null);
  const childrenRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentHeight, setCurrentHeight] = useState('0px');

  const toggleExpansion = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const getCurrentHeight = () => {
    return isExpanded ? `${childrenRef.current.offsetHeight}px` : '0px';
  };

  useEffect(() => {
    setCurrentHeight(getCurrentHeight());
  }, [children, isExpanded]);

  useEffect(() => {
    setIsExpanded(alwaysOpen);
  }, []);

  return (
    <Card
      borderColor={borderColors[borderType]}
      className={classNames([
        styles.container,
        className,
        alwaysOpen ? styles.contentIsExpanded : ''
      ])}
      id={id}>
      <div
        className={classNames([
          styles.header,
          isExpanded ? styles.headerIsExpanded : null,
          alwaysOpen ? styles.contentIsExpanded : ''
        ])}
        onClick={readOnly || alwaysOpen ? null : () => toggleExpansion()}
        style={{
          cursor: readOnly || alwaysOpen ? 'default' : 'pointer',
          borderBottom: isExpanded
            ? '1px solid var(--color-neutral-light-3)'
            : null
        }}>
        {header}
        {!readOnly && !alwaysOpen ? (
          isExpanded ? (
            <MdArrowDropUp
              color="var(--color-brand-secondary-light)"
              size="24"
            />
          ) : (
            <MdArrowDropDown
              color="var(--color-brand-secondary-light)"
              size="24"
            />
          )
        ) : (
          <div />
        )}
      </div>
      <CSSTransition
        in={isExpanded}
        timeout={500}
        nodeRef={contentRef}
        classNames={{
          enterActive: alwaysOpen
            ? styles.contentIsExpanded
            : styles.contentEnterActive,
          enterDone: alwaysOpen
            ? styles.contentIsExpanded
            : styles.contentEnterDone,
          exitActive: alwaysOpen
            ? styles.contentIsExpanded
            : styles.contentExitActive,
          exitDone: alwaysOpen
            ? styles.contentIsExpanded
            : styles.contentExitDone
        }}>
        <div
          ref={contentRef}
          className={styles.content}
          style={{
            '--content-height': currentHeight,
            overflow: alwaysOpen ? 'inherit' : 'hidden'
          }}>
          <div ref={childrenRef} className={styles.innerContent}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </Card>
  );
};

export { ExpansionCard };
