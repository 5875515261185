import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCustomizeCaptive from 'src/components/FormCustomizeCaptive';
import { IPersonalizeForm } from 'src/pages/CaptivePortal/PersonalizeCaptivePortal/types';
import { ICaptivePortal } from 'src/pages/CaptivePortal/types';
import Card from 'ui-components/Card';
import ButtonsActionsWizard from '../../ButtonsActions';
import TitleAndDescription from '../../TitleAndDescription';
import stylesSteps from '../Steps.module.css';

interface ICustomizeCaptiveWizardProps {
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  setCustomizeCaptiveClientPageFormData: React.Dispatch<
    React.SetStateAction<ICaptivePortal | undefined>
  >;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  customizeCaptiveClientPageFormData: ICaptivePortal | undefined;
}

const CustomizeCaptiveWizard = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  setCustomizeCaptiveClientPageFormData,
  setCurrentStep,
  currentStep,
  customizeCaptiveClientPageFormData
}: ICustomizeCaptiveWizardProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm<IPersonalizeForm>({
    defaultValues: {
      captivePortal: {
        pageTitle: '',
        pageText: '',
        urlRedirectionAfterLogin: '',
        header_image_url: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const onSubmit = handleSubmit((data: IPersonalizeForm) => {
    const captivePageData = {
      name: data.captivePortal.pageTitle,
      preview_text: data.captivePortal.pageText,
      redirect_url: data.captivePortal.urlRedirectionAfterLogin,
      mode: defaultCaptivePortal ? defaultCaptivePortal.mode : '',
      header_image_url: data.captivePortal.header_image_url || null
    } as ICaptivePortal;

    setCustomizeCaptiveClientPageFormData(captivePageData);
  });

  const handleContinue = async () => {
    await onSubmit();
    const canContinue = Object.values(errors.captivePortal || {}).length === 0;
    return canContinue;
  };

  useEffect(() => {
    if (customizeCaptiveClientPageFormData) {
      setValue('captivePortal', {
        pageTitle: customizeCaptiveClientPageFormData?.name || '',
        pageText: customizeCaptiveClientPageFormData?.preview_text || '',
        urlRedirectionAfterLogin:
          customizeCaptiveClientPageFormData?.redirect_url || '',
        header_image_url:
          customizeCaptiveClientPageFormData?.header_image_url || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasErrorOnForm = Object.values(errors.captivePortal || {}).length > 0;

  return (
    <>
      <TitleAndDescription title={t('Personalizar Página do Captive')} />
      <Card className={stylesSteps.cardForm}>
        <FormCustomizeCaptive
          currentWizardValues={customizeCaptiveClientPageFormData}
          defaultCaptivePortal={defaultCaptivePortal}
          isFetchedCaptivePortal={isFetchedCaptivePortal}
          isLoadingFormCaptive={false}
          // eslint-disable-next-line no-console
          setActiveTab={() => console.log('setActiveTab')}
          watch={watch}
          setValue={setValue}
          control={control}
          errors={errors}
          hideSaveButtons
          isWizard
        />
      </Card>
      <ButtonsActionsWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        handleContinue={handleContinue}
        disabledContinue={hasErrorOnForm}
        disableTooltip={hasErrorOnForm}
      />
    </>
  );
};

export default CustomizeCaptiveWizard;
