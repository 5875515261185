/* eslint-disable react/jsx-filename-extension */
import classNames from 'classnames';
import React from 'react';
import styles from './Steps.module.css';

function Step({ active = false, children }) {
  return (
    <div
      className={classNames([
        styles.circleStep,
        active ? styles.activeCircleStep : ''
      ])}>
      {children}
    </div>
  );
}

export default Step;
