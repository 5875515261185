import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit, MdHomeWork } from 'react-icons/md';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { generateColumns } from 'src/utils/tableUtils';
import { Button, ButtonIcon } from 'ui-components/Button';
import Table from 'ui-components/Table';
import { IWireless } from '../../WirelessTab/types';
import styles from './WirelessStep.module.css';

interface IProps {
  wirelessList: IWireless[];
  selectedWireless: IWireless[];
  setSelectedWireless: React.Dispatch<React.SetStateAction<IWireless[]>>;
  setShowAddWirelessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPlaceListModal: React.Dispatch<React.SetStateAction<boolean>>;
  nextStep: () => void;
  renderBackAndCancelButton: () => JSX.Element;
}

const WirelessStep = ({
  wirelessList,
  nextStep,
  selectedWireless,
  setSelectedWireless,
  setShowAddWirelessModal,
  setShowPlaceListModal,
  renderBackAndCancelButton
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const generateRows = (wirelessListToFormat: IWireless[]) => {
    return wirelessListToFormat.map((wireless: IWireless) => {
      return {
        ...wireless,
        actions: (
          <div className="d-flex justify-between align-center">
            <ButtonIcon
              id={`btn-home-wireless-${wireless.name}`}
              onClick={() => setShowPlaceListModal(true)}>
              <MdHomeWork size={24} />
            </ButtonIcon>

            <ButtonIcon id={`btn-edit-wireless-${wireless.name}`}>
              <MdEdit size={24} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const columnsTableWirelessList = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('actions', t('Ações'), 'center', 0)
  ];

  return (
    <div>
      {wirelessList.length === 0 ? (
        <div>
          <EmptyMessage
            title={t('Nenhuma wireless')}
            subtitle={t(
              'Ainda não há wireless disponível nesta lista. Experimente adicionar uma nova'
            )}
            id="message-no-wireless"
            buttonText={t('ADICIONAR WIRELESS')}
            action={() => setShowAddWirelessModal(true)}
            minHeight="190px"
            height="calc(100vh - 575px)"
          />

          <div className="d-flex justify-end mt-3">
            {renderBackAndCancelButton()}
          </div>
        </div>
      ) : (
        <div>
          <Table
            className={classNames([styles.wirelessTable])}
            columns={columnsTableWirelessList}
            rows={generateRows(wirelessList)}
            onSelectRows={(selectedRows: IWireless[]) => {
              setSelectedWireless(selectedRows);
            }}
            selectedRows={selectedWireless}
            keyToCompare="name"
            useKeyToCompareAsId
          />

          <div className="d-flex justify-end mt-3">
            {renderBackAndCancelButton()}
            {wirelessList.length > 0 && (
              <Button
                id="button-wireless-next-step"
                onClick={nextStep}
                disabled={wirelessList.length === 0}
                className="ml-3">
                {t('PRÓXIMO')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WirelessStep;
