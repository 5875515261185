import React from 'react';
import getClass from '../classNames';
import type { ICol } from './types';

const classMap: { [key: string]: string } = {
  xs: 'col-xs',
  sm: 'col-sm',
  md: 'col-md',
  lg: 'col-lg',
  xl: 'col-xl',
  xsOffset: 'col-xs-offset',
  smOffset: 'col-sm-offset',
  mdOffset: 'col-md-offset',
  lgOffset: 'col-lg-offset',
  xlOffset: 'col-xl-offset'
};

function isInteger(value: unknown): boolean {
  if (typeof value === 'number') {
    return Number.isInteger(value);
  }

  return false;
}

type ClassNameProps = Omit<ICol, 'children'>;

function getColClassNames(props: ClassNameProps) {
  const extraClasses = [];

  if (props.className) {
    extraClasses.push(props.className);
  }

  if (props.first) {
    extraClasses.push(getClass(`first-${props.first}`));
  }

  if (props.last) {
    extraClasses.push(getClass(`last-${props.last}`));
  }

  return Object.keys(props)
    .filter(
      (key) => classMap[key] && props[key as keyof ClassNameProps] !== undefined
    )
    .map((key) =>
      getClass(
        isInteger(props[key as keyof ClassNameProps])
          ? `${classMap[key]}-${props[key as keyof ClassNameProps]}`
          : classMap[key]
      )
    )
    .concat(extraClasses)
    .join(' ');
}

const Col = ({ children, style, ...rest }: ICol) => {
  const className = getColClassNames(rest);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Col.defaultProps = {
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  xsOffset: undefined,
  smOffset: undefined,
  mdOffset: undefined,
  lgOffset: undefined,
  xlOffset: undefined,
  first: undefined,
  last: undefined,
  className: undefined,
  children: undefined,
  style: {}
};

export default Col;
