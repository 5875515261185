import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';

interface IIndividualInfoModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const IndividualInfoModal = ({
  showModal,
  setShowModal
}: IIndividualInfoModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  return (
    <Modal
      closable
      title={t('Cadastro Individual')}
      onClose={() => {
        setShowModal(false);
      }}
      show={showModal}
      width="660px">
      <span className="text-base-lg mb-3">
        {t(
          'Este método é indicado para quem deseja cadastrar e identificar todos os clientes conectados à rede, de forma a autorizar e desautorizar seus acessos, delimitando o período de uso de forma individual.'
        )}
      </span>
    </Modal>
  );
};

export { IndividualInfoModal };
