import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/useToast';
import { ConfirmModal } from 'src/components/ConfirmModal';
import api from 'src/services/api';
import { useParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { useMutation } from 'react-query';
import { IRenewAllExpiredIndividualRecordsData } from 'src/services/api/urls/captivePortal/types';
import { DateConverter } from 'src/utils/Dates/DateConverter';

interface IUpdateRecordModalProps {
  showUpdateAllRecordsModal: boolean;
  onCloseUpdateAllRecordsModal: () => void;
  onSuccessUpdateAllRecords: () => void;
  countExpiredRecords: number;
}

const UpdateAllExpiredRecordModal = ({
  showUpdateAllRecordsModal,
  onCloseUpdateAllRecordsModal,
  onSuccessUpdateAllRecords,
  countExpiredRecords
}: IUpdateRecordModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const { accessToken, siteTimezone } = useAuth();
  const { cp_id: captiveId } = useParams();
  const { addToast } = useToast();

  const updateRecord = () => {
    const data: IRenewAllExpiredIndividualRecordsData = {
      new_started_at: new DateConverter(
        undefined,
        siteTimezone
      ).getDateWithDefaultFormat()
    };

    return api.captivePortal.individualRecord.renewAllExpiredIndividualRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      data
    );
  };

  const updateRecordMutation = useMutation(
    'updateAllExpiredRecords',
    updateRecord,
    {
      onSuccess: () => {
        addToast(
          'success',
          t(
            '{{qtySelectedIndividualRecords}} Cadastro(s) renovado(s) com sucesso',
            { qtySelectedIndividualRecords: countExpiredRecords }
          )
        );
        onSuccessUpdateAllRecords();
      },
      onError: () => {
        addToast('error', t('Não foi possível renovar'));
      },
      onSettled: () => {
        onCloseUpdateAllRecordsModal();
      }
    }
  );

  const onUpdateRecords = () => {
    updateRecordMutation.mutate();
  };

  return (
    <ConfirmModal
      showModal={showUpdateAllRecordsModal}
      isLoadingAction={updateRecordMutation.isLoading}
      onCancel={onCloseUpdateAllRecordsModal}
      height="auto"
      width="470px"
      onConfirm={onUpdateRecords}>
      <span>
        {t(
          'Todos os cadastros expirados serão renovados. Ao renovar, eles irão ser restaurados de acordo com as configurações que foram estabelecidas anteriormente. Esta ação não pode ser desfeita. Deseja continuar?'
        )}
      </span>
    </ConfirmModal>
  );
};

export { UpdateAllExpiredRecordModal };
