import { ICPFCaptiveForm } from 'src/pages/CaptivePortal/CPFRecord/DrawerCPF/types';
import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import {
  IUpdateCaptivePortal,
  ICreateSimplePassword,
  IGetActivePassword,
  IGetCaptivePortal,
  IGetSimplePasswordHistoric,
  IGetActiveIndividualRecords,
  IGetExpiredIndividualRecords,
  ICreateIndividualRecord,
  IGetIndividualRecordExists,
  ICreateIndividualRecordByWizard,
  IDeleteIndividualRecord,
  IRenewAllExpiredIndividualRecordsData,
  IRenewActiveIndividualRecords,
  IRenewExpiredIndividualRecords,
  IUpdateIndividualRecord
} from './types';

export class CaptivePortalAPI implements IRequestMethods {
  url = '/captive-portals';

  captivePortalUrl = (siteId: string, placeId: string, captiveId = '') => {
    return `${this.url}/sites/${siteId}/places/${placeId}/captive-portals/${captiveId}`;
  };

  hefestoCaptivePortalUrl = (siteId: string, placeId: string, captiveId = '') =>
    `/hefesto/captive_portals/sites/${siteId}/places/${placeId}/captive-portals${
      captiveId !== '' ? `/${captiveId}` : ''
    }`;

  simplePasswordUrl = (siteId: string, placeId: string, captiveId: string) =>
    `${this.captivePortalUrl(siteId, placeId, captiveId)}/simple-passwords`;

  individualRecordUrl = (siteId: string, placeId: string, captiveId: string) =>
    `${this.captivePortalUrl(siteId, placeId, captiveId)}/individual-records`;

  captive = {
    getOrCreate: (siteId: string, placeId: string) => {
      return axiosInstance.get<IGetCaptivePortal>(
        `${this.hefestoCaptivePortalUrl(siteId, placeId)}/first/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    put: (
      siteId: string,
      placeId: string,
      data: IUpdateCaptivePortal,
      captive_id = '',
      update_header_image = false,
      restore_header_image_to_default = false
    ) => {
      return axiosInstance.put(
        `${this.hefestoCaptivePortalUrl(siteId, placeId, captive_id)}/`,
        data,
        {
          headers: getAuthHeaders(),
          params: {
            update_header_image,
            restore_header_image_to_default
          }
        }
      );
    },
    deactivate: (siteId: string, placeId: string, captive_id = '') => {
      return axiosInstance.put(
        `${this.captivePortalUrl(siteId, placeId, captive_id)}/deactivate/`,
        {},
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  simplePassword = {
    get: (siteId: string, placeId: string, captiveId: string) => {
      return axiosInstance.get<IGetSimplePasswordHistoric>(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/history/`,
        {
          headers: getAuthHeaders()
        }
      );
    },

    deleteListOfSimplePasswords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      passwordsToDelete: string[]
    ) => {
      const urlString = `${this.simplePasswordUrl(
        siteId,
        placeId,
        captiveId
      )}/history/`;
      return axiosInstance.delete(urlString, {
        data: {
          history_ids: passwordsToDelete
        },
        headers: getAuthHeaders()
      });
    },

    getActivePassword: (siteId: string, placeId: string, captiveId: string) => {
      return axiosInstance.get<IGetActivePassword>(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/`,
        {
          headers: getAuthHeaders()
        }
      );
    },

    post: (
      data: ICreateSimplePassword,
      siteId: string,
      placeId: string,
      captiveId: string
    ) => {
      return axiosInstance.post(
        `${this.simplePasswordUrl(siteId, placeId, captiveId)}/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },

    postWizard: (
      data: ICreateSimplePassword,
      siteId: string,
      placeId: string,
      captiveId: string,
      update_header_image: boolean,
      restore_header_image_to_default: boolean
    ) => {
      return axiosInstance.post(
        `${this.hefestoCaptivePortalUrl(
          siteId,
          placeId,
          captiveId
        )}/simple-passwords/activate/wizard/`,
        data,
        {
          headers: getAuthHeaders(),
          params: {
            update_header_image,
            restore_header_image_to_default
          }
        }
      );
    },

    updateActivePassword: (
      siteId: string,
      placeId: string,
      captiveId: string,
      passwordId: string
    ) => {
      return axiosInstance.post(
        `${this.simplePasswordUrl(
          siteId,
          placeId,
          captiveId
        )}/history/${passwordId}/renew/`,
        {},
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  individualRecord = {
    postWizard: (
      data: ICreateIndividualRecordByWizard,
      siteId: string,
      placeId: string,
      captiveId: string,
      update_header_image: boolean,
      restore_header_image_to_default: boolean
    ) => {
      return axiosInstance.post(
        `${this.hefestoCaptivePortalUrl(
          siteId,
          placeId,
          captiveId
        )}/individual-records/activate/wizard/`,
        data,
        {
          headers: getAuthHeaders(),
          params: {
            update_header_image,
            restore_header_image_to_default
          }
        }
      );
    },
    getActiveHistoric: (
      siteId: string,
      placeId: string,
      captiveId: string,
      params = {}
    ) => {
      return axiosInstance.get<IGetActiveIndividualRecords>(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/`,
        {
          headers: getAuthHeaders(),
          params
        }
      );
    },
    postCreateIndividualRecord: (
      siteId: string,
      placeId: string,
      captiveId: string,
      dataIndividualRecord: ICreateIndividualRecord
    ) => {
      return axiosInstance.post(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/`,
        dataIndividualRecord,
        {
          headers: getAuthHeaders()
        }
      );
    },
    putEditIndividualRecord: (
      siteId: string,
      placeId: string,
      captiveId: string,
      individualRecordId: string,
      dataIndividualRecord: IUpdateIndividualRecord
    ) => {
      return axiosInstance.put(
        `${this.individualRecordUrl(
          siteId,
          placeId,
          captiveId
        )}/${individualRecordId}/`,
        dataIndividualRecord,
        {
          headers: getAuthHeaders()
        }
      );
    },
    deleteActiveRecords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      idRecordsToDelete: IDeleteIndividualRecord
    ) => {
      return axiosInstance.delete(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/`,
        {
          data: idRecordsToDelete,
          headers: getAuthHeaders()
        }
      );
    },
    deleteAvailableRecords: (
      siteId: string,
      placeId: string,
      captiveId: string
    ) => {
      return axiosInstance.delete(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/available/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    renewActiveRecords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      data: IRenewActiveIndividualRecords
    ) => {
      return axiosInstance.patch(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/renew/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    getExpiredHistoric: (
      siteId: string,
      placeId: string,
      captiveId: string,
      params = {}
    ) => {
      return axiosInstance.get<IGetExpiredIndividualRecords>(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/history/`,
        {
          headers: getAuthHeaders(),
          params
        }
      );
    },
    deleteAllExpiredRecords: (
      siteId: string,
      placeId: string,
      captiveId: string
    ) => {
      return axiosInstance.delete(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/history/all/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    getIndividualRecordExits: (
      siteId: string,
      placeId: string,
      captiveId: string,
      document: string
    ) => {
      return axiosInstance.get<IGetIndividualRecordExists>(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/exists/`,
        {
          headers: getAuthHeaders(),
          params: {
            document
          }
        }
      );
    },
    deleteExpiredRecords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      recordsToDelete: string[]
    ) => {
      return axiosInstance.delete(
        `${this.individualRecordUrl(siteId, placeId, captiveId)}/history/`,
        {
          data: {
            history_individual_records_ids: recordsToDelete
          },
          headers: getAuthHeaders()
        }
      );
    },
    renewAllExpiredIndividualRecords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      data: IRenewAllExpiredIndividualRecordsData
    ) => {
      return axiosInstance.patch(
        `${this.individualRecordUrl(
          siteId,
          placeId,
          captiveId
        )}/history/renew-all/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    renewExpiredRecords: (
      siteId: string,
      placeId: string,
      captiveId: string,
      data: IRenewExpiredIndividualRecords
    ) => {
      return axiosInstance.patch(
        `${this.individualRecordUrl(
          siteId,
          placeId,
          captiveId
        )}/history/renew/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  CPFcaptive(data: ICPFCaptiveForm) {
    return axiosInstance.post(`${this.url}/cpfcaptive/`, data, {
      headers: getAuthHeaders()
    });
  }
}
