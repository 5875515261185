import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { ISiteHasDevice } from 'src/services/api/urls/devices/types';
import Checkbox from 'ui-components/Checkbox';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import Modal from 'ui-components/Modal';
import { IErrorResponse } from 'src/services/api/types';
import { IDeleteSiteData, ISiteItemPage, ModalsTypesOfSites } from '../types';

interface IModalRemoveSite {
  setSites?: React.Dispatch<React.SetStateAction<ISiteItemPage[]>>;
  selectedSite: ISiteItemPage | null;
  setSelectedSite?: React.Dispatch<React.SetStateAction<ISiteItemPage | null>>;
  showModalDeleteSite: boolean;
  setActiveModalType: React.Dispatch<React.SetStateAction<ModalsTypesOfSites>>;
  onDeleteSite: (isSharedSite: boolean) => void;
  setSiteIdBeingEditedOrDeleted?: Dispatch<SetStateAction<string | null>>;
}

const ModalRemoveSite = (props: IModalRemoveSite) => {
  const [deletingSiteHasConfiguration, setDeletingSiteHasConfiguration] =
    useState(true);

  const [disableSubmitButtonDeleteForm, setDisableSubmitButtonDeleteForm] =
    useState(false);

  const { accessTokenInfo, resetSession } = useAuth();

  const { addToast } = useToast();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const {
    selectedSite,
    setSelectedSite,
    showModalDeleteSite,
    setActiveModalType,
    onDeleteSite,
    setSiteIdBeingEditedOrDeleted,
    setSites
  } = props;

  const {
    handleSubmit: handleSubmitDeleteForm,
    control: controlDeleteForm,
    setValue: setValueDeleteForm,
    watch
  } = useForm<IDeleteSiteData>({
    defaultValues: {
      checkContinueDelete: false
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { isFetching: isHasDevicesFetching } = useQuery(
    ['siteHasDevicesQuery', selectedSite],
    () => {
      return api.devices.site.getHasDevices(selectedSite?.id || '');
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: ISiteHasDevice }) => {
        setDeletingSiteHasConfiguration(data.has_devices_in_site);
      },
      retry: false,
      enabled: !!selectedSite
    }
  );

  const updateSites = () => {
    if (setSites) {
      setSites((prevSites) =>
        prevSites.filter((site) => site.id !== selectedSite?.id)
      );
    }
  };

  const resetSelectedSite = () => {
    if (setSelectedSite) {
      setSelectedSite(null);
    }
  };

  const resetSiteIdBeingEditedOrDeleted = () => {
    if (setSiteIdBeingEditedOrDeleted) {
      setSiteIdBeingEditedOrDeleted(null);
    }
  };

  const deleteSiteMutation = useMutation(
    () => {
      const data = {
        email: accessTokenInfo ? accessTokenInfo.email : ''
      };
      const { id } = selectedSite ?? { id: '' };
      return api.sites.delete(id, data);
    },
    {
      onSuccess: () => {
        updateSites();
        addToast('success', t('Local excluído com sucesso'));
        onDeleteSite(false);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 401) {
          resetSession();
        } else if (error.response.status === 404) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        }
      },
      onSettled: () => {
        resetSelectedSite();
        resetSiteIdBeingEditedOrDeleted();
        setActiveModalType(null);
        setValueDeleteForm('checkContinueDelete', false);
      }
    }
  );

  useEffect(() => {
    if (isHasDevicesFetching) {
      setDisableSubmitButtonDeleteForm(true);
    } else {
      setDisableSubmitButtonDeleteForm(false);
    }
  }, [isHasDevicesFetching]);

  const onSubmitDeleteForm = handleSubmitDeleteForm(() => {
    if (isHasDevicesFetching) {
      return;
    }

    if (setSiteIdBeingEditedOrDeleted) {
      const siteId = selectedSite?.id ?? null;
      setSiteIdBeingEditedOrDeleted(siteId);
    }

    deleteSiteMutation.mutate();
    setDisableSubmitButtonDeleteForm(true);
  });

  const cancelDelete = () => {
    setActiveModalType(null);
    setValueDeleteForm('checkContinueDelete', false);
    resetSelectedSite();
  };

  const renderModalDelete = () => {
    if (!isHasDevicesFetching) {
      if (deletingSiteHasConfiguration) {
        return (
          <>
            <span>
              {t('Todas as configurações do local ')}{' '}
              <span className="text-bold">
                {selectedSite && selectedSite.name}
              </span>{' '}
              {t(
                'serão excluídas permanentemente, os equipamentos receberão padrão de fábrica. Deseja continuar'
              )}
              ?
            </span>
            <Controller
              control={controlDeleteForm}
              name="checkContinueDelete"
              render={({ field: { value } }) => (
                <div className="d-flex mt-3">
                  <Checkbox
                    checked={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setValueDeleteForm(
                        'checkContinueDelete',
                        event.target.checked
                      );
                    }}
                  />
                  <Label className="text-base-base ml-3">
                    {t('Estou ciente e desejo continuar')}
                  </Label>
                </div>
              )}
            />
          </>
        );
      }
      return (
        <span>
          {t('Deseja realmente excluir o local')}{' '}
          <span className="text-bold">{selectedSite && selectedSite.name}</span>
          ?
        </span>
      );
    }
    return (
      <div className="fit-width d-flex justify-center align-center">
        <Loading value={40} indeterminate />
      </div>
    );
  };
  return (
    <Modal
      width="550px"
      show={showModalDeleteSite}
      actions={[
        {
          label: t('NÃO'),
          action: () => cancelDelete(),
          disabled: deleteSiteMutation.isLoading
        },
        {
          label: t('SIM'),
          action: onSubmitDeleteForm,
          disabled:
            disableSubmitButtonDeleteForm ||
            (deletingSiteHasConfiguration && !watch('checkContinueDelete')),
          isLoading: deleteSiteMutation.isLoading
        }
      ]}>
      <div>{renderModalDelete()}</div>
    </Modal>
  );
};

ModalRemoveSite.defaultProps = {
  setSites: undefined,
  setSelectedSite: undefined,
  setSiteIdBeingEditedOrDeleted: undefined
};

export { ModalRemoveSite };
