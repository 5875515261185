import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { Col, Grid, Row } from 'inmaster-ui';
import { MdQuestionMark } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import styles from './ResultsNotFoundMessage.module.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  height?: string;
  minHeight?: string;
}
const ResultsNotFoundMessage = ({
  id,
  height,
  minHeight,
  ...props
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'resultsNotFound'
  });
  return (
    <Grid fluid>
      <Row {...props} style={{ height, minHeight }}>
        <Col
          xs={12}
          className={classNames([
            styles.bodyMessage,
            height ? null : styles.fullPage
          ])}>
          <div
            className={classNames(['d-flex justify-center', styles.content])}>
            <div
              className={classNames([
                'd-flex justify-center align-center',
                styles.ball
              ])}>
              <span
                className={classNames([
                  'd-flex justify-center',
                  styles.iconInBall
                ])}>
                <MdQuestionMark size={33} />
              </span>
            </div>
            <div
              className={classNames(['d-flex flex-column align-start ml-4'])}
              id={`resultsNotFound-${id}`}>
              <h1 className="title-xl-lg">{t('Resultado não encontrado')}</h1>
              <span className={classNames(['mt-3'])}>
                {t(
                  'Não foi possível encontrar resultados com a busca realizada, experimente procurar por outros termos'
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

ResultsNotFoundMessage.defaultProps = {
  height: null,
  minHeight: null
};

export { ResultsNotFoundMessage };
