import React, { useContext } from 'react';
import {
  TemplatesContext,
  TemplateType,
  TemplatesMap
} from './contexts/TemplatesContext';
import { RoutesPaths } from './routes';
import { NoBarsTemplate } from './templates/NoBars';
import { NavbarTemplate } from './templates/Navbar';
import { MenuAndNavbarTemplate } from './templates/MenuAndNavbar';
import { NotificationProvider } from './contexts/NotificationContext';
import { NavbarWithAvatarTemplate } from './templates/NavbarWithAvatar';
import 'react-loading-skeleton/dist/skeleton.css';
import { NavbarWithPlacesSelectorTemplate } from './templates/NavbarWithPlacesSelector';

const App = () => {
  const { template } = useContext(TemplatesContext);

  const templates: TemplatesMap = {
    noBars: (
      <NoBarsTemplate>
        <RoutesPaths />
      </NoBarsTemplate>
    ),
    fullNavbar: (
      <NavbarTemplate
        navbarActions={{
          config: true,
          notification: false,
          avatar: true
        }}>
        <RoutesPaths />
      </NavbarTemplate>
    ),
    fullNavbarWithPlacesSelector: (
      <NotificationProvider>
        <NavbarWithPlacesSelectorTemplate
          navbarActions={{
            config: true,
            notification: true,
            avatar: true,
            helper: true
          }}>
          <RoutesPaths />
        </NavbarWithPlacesSelectorTemplate>
      </NotificationProvider>
    ),
    menuAndFullNavbar: (
      <NotificationProvider>
        <MenuAndNavbarTemplate
          navbarActions={{
            config: true,
            notification: true,
            avatar: true,
            helper: true
          }}>
          <RoutesPaths />
        </MenuAndNavbarTemplate>
      </NotificationProvider>
    ),
    multiLanguageNavbar: (
      <NavbarTemplate>
        <RoutesPaths />
      </NavbarTemplate>
    ),
    avatarNavbar: (
      <NavbarWithAvatarTemplate
        navbarActions={{
          avatar: true,
          helper: true
        }}>
        <RoutesPaths />
      </NavbarWithAvatarTemplate>
    )
  };

  function renderTemplate(templateType: TemplateType) {
    return templates[templateType];
  }

  return <>{renderTemplate(template)}</>;
};

export default App;
