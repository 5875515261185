import React, { ReactNode, useMemo, useState, createContext } from 'react';
import { HelperDrawerSectionAndItem, IDrawerState } from './types';

export const NavbarDrawersContext = createContext({} as IDrawerState);

export const NavbarDrawersContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isHelperDrawerOpen, setIsHelperDrawerOpen] = useState(false);
  const [isAddDeviceDrawerOpen, setIsAddDeviceDrawerOpen] = useState(false);
  const [topicToShowInHelperDrawer, setTopicToShowInHelperDrawer] =
    useState<HelperDrawerSectionAndItem>({} as HelperDrawerSectionAndItem);

  const toggleHelperDrawer = () => {
    setIsHelperDrawerOpen((prevState) => !prevState);
  };

  const toggleAddDeviceDrawer = () => {
    setIsAddDeviceDrawerOpen((prevState) => !prevState);
  };

  const contextValue = useMemo(
    () => ({
      isHelperDrawerOpen,
      isAddDeviceDrawerOpen,
      topicToShowInHelperDrawer,
      setTopicToShowInHelperDrawer,
      toggleHelperDrawer,
      toggleAddDeviceDrawer
    }),
    [isHelperDrawerOpen, isAddDeviceDrawerOpen, topicToShowInHelperDrawer]
  );

  return (
    <NavbarDrawersContext.Provider value={contextValue}>
      {children}
    </NavbarDrawersContext.Provider>
  );
};
