import React, { useRef } from 'react';
import { Controller, MultipleFieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import InputWithValidation from 'src/components/InputWithValidation';
import { useToast } from 'src/hooks/useToast';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';
import InputMask from 'react-input-mask';
import styles from './AddCustomersModal.module.css';
import { ICustomer } from '../CustomersTab/types';

interface IProps {
  show: boolean;
  onClose: () => void;
  listName: string;
  customers: ICustomer[];
  setCustomers: React.Dispatch<React.SetStateAction<ICustomer[]>>;
}

const AddCustomersModal = ({
  show,
  onClose,
  listName,
  setCustomers,
  customers
}: IProps) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });
  const inputRef = useRef(null);

  const {
    control,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      customer: {
        mac: '',
        name: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const onSubmit = handleSubmit(({ customer }) => {
    addToast('success', t('Cliente adicionado com sucesso'));
    setCustomers((prevCustomers) => [...prevCustomers, customer]);
    reset();
    onClose();
  });

  const mask = [
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ':',
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ':',
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ':',
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ':',
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ':',
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/
  ];

  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.isUnique) {
      message = errorTypes.isUnique;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <Modal
      closable={false}
      show={show}
      title={t('ADICIONAR CLIENTE')}
      width="650px"
      actions={[
        {
          label: t('CANCELAR'),
          action: () => {
            clearErrors();
            setValue('customer.mac', '');
            setValue('customer.name', '');
            onClose();
          }
        },
        {
          label: t('SALVAR'),
          action: onSubmit
        }
      ]}>
      <span className={styles.subtitle}>
        {t('Adicione um novo cliente a lista')}{' '}
        <span className="text-bold">{listName}</span>
      </span>

      <div className={styles.formContainer}>
        <InputWrapper invalid={Boolean(errors?.customer?.mac)}>
          <Label>{t('Endereço MAC')}:</Label>
          <Controller
            control={control}
            name="customer.mac"
            rules={{
              required: {
                value: true,
                message: t('Campo obrigatório')
              },
              pattern: {
                value:
                  /^((([0-9A-F]{2}:){5})|(([0-9A-F]{2}-){5})|([0-9A-F]{10}))([0-9A-F]{2})$/i,
                message: t('MAC inválido')
              },
              validate: {
                isUnique: (value) =>
                  !customers.some((customer) => customer.mac === value) ||
                  (t(
                    'Este MAC já está adicionado nesta Lista de Acesso'
                  ) as string)
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputMask
                mask={mask}
                maskPlaceholder={null}
                onChange={onChange}
                onBlur={onBlur}
                ref={inputRef}
                value={value.toUpperCase()}>
                <Input
                  id="mac-input"
                  placeholder={t('Insira o endereço MAC do cliente')}
                  autoComplete="off"
                />
              </InputMask>
            )}
          />
          {getInputErrorMessage(errors.customer?.mac?.types)}
        </InputWrapper>

        <div className="mt-4">
          <InputWithValidation
            inputProps={{
              id: 'input-customer-name'
            }}
            placeholder={t('Insira o nome do cliente')}
            control={control}
            controllerProps={{
              name: 'customer.name',
              rules: {
                maxLength: {
                  value: 32,
                  message: t(
                    'O nome do cliente precisa ter entre 1 e 32 caracteres'
                  )
                },
                validate: {
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome do cliente não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }
            }}
            errors={errors.customer?.name}
            label={t('Nome do cliente (opcional)')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddCustomersModal;
