import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'inmaster-ui';
import { useTemplate } from 'src/hooks/useTemplate';
import { useTranslation } from 'react-i18next';
import Tabs from 'ui-components/Tab';
import api from 'src/services/api';
import { useQuery } from 'react-query';
import { IGetCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import { useAuth } from 'src/hooks/useAuth';
import { MdInfo } from 'react-icons/md';
import styles from './IndividualRecord.module.css';
import { ActiveRecordTab } from './ActiveRecordTab';
import { ExpiredRecordTab } from './ExpiredRecordTab';
import { ICaptivePortal } from '../types';
import { IndividualInfoModal } from './IndividualInfoModal';

const IndividualRecord = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const { accessToken } = useAuth();

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [defaultCaptivePortal, setDefaultCaptivePortal] =
    React.useState<ICaptivePortal>({} as ICaptivePortal);

  const [showIndividualInfoModal, setShowIndividualInfoModal] =
    React.useState(false);

  const getCaptivePortal = () => {
    return api.captivePortal.captive.getOrCreate(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  useQuery('getCaptivePortal', getCaptivePortal, {
    onSuccess: ({ data: captiveResponse }: { data: IGetCaptivePortal }) => {
      if (captiveResponse) {
        setDefaultCaptivePortal({
          ...captiveResponse.captive_portal,
          is_blocked_to_activate: captiveResponse.is_blocked_to_activate
        } as ICaptivePortal);
      } else {
        setDefaultCaptivePortal({} as ICaptivePortal);
      }
    },
    onError: () => {
      setDefaultCaptivePortal({} as ICaptivePortal);
    }
  });

  const individualRecordTabs = [
    {
      label: t('Ativos'),
      id: 'activeIndividualRecordTab',
      component: (
        <ActiveRecordTab defaultCaptivePortal={defaultCaptivePortal} />
      ),
      onClick: () => {
        setActiveTabIndex(0);
      }
    },

    {
      label: t('Expirados'),
      id: 'expiredIndividualRecordTab',
      component: (
        <ExpiredRecordTab defaultCaptivePortal={defaultCaptivePortal} />
      ),
      onClick: () => {
        setActiveTabIndex(1);
      }
    }
  ];

  const handleOpenInfoModal = () => {
    setShowIndividualInfoModal(true);
  };

  useTemplate('menuAndFullNavbar');

  return (
    <>
      <IndividualInfoModal
        showModal={showIndividualInfoModal}
        setShowModal={setShowIndividualInfoModal}
      />
      <Row className="mb-5">
        <Col xs={12} className="d-flex flex-column">
          <div className="mt-3">
            <h2 className="d-flex align-center">
              <span
                className={classNames([
                  'title-xl-base text-uppercase',
                  styles.titles
                ])}>
                {t('Cadastro Individual')}
              </span>
              <span
                className={classNames([
                  'd-flex align-center ml-4 mr-4',
                  styles.iconInfo
                ])}
                aria-hidden="true"
                onClick={handleOpenInfoModal}>
                <MdInfo size={24} />
              </span>
              {defaultCaptivePortal.mode === 'individual_record' && (
                <span
                  className={classNames(
                    styles.activeLabel,
                    'd-flex align-center text-xl-base'
                  )}>
                  <div className={classNames(styles.ball, 'mr-2')} />{' '}
                  {t('Ativo')}
                </span>
              )}
            </h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Tabs tabs={individualRecordTabs} activeTabIndex={activeTabIndex} />
        </Col>
      </Row>
    </>
  );
};

export { IndividualRecord };
