import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface IModalConfirmAssociateCaptiveProps {
  showModalConfirm: boolean;
  setShowModalConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ModalConfirmAssociateCaptive = ({
  setShowModalConfirm,
  showModalConfirm,
  onConfirm,
  onCancel,
  isLoading
}: IModalConfirmAssociateCaptiveProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.wireless'
  });

  return (
    <ConfirmModal
      width="600px"
      height="auto"
      showModal={showModalConfirm}
      isLoadingAction={isLoading}
      onCancel={() => {
        onCancel();
        setShowModalConfirm(false);
      }}
      onConfirm={() => {
        onConfirm();
        setShowModalConfirm(false);
      }}>
      <div>
        <p>
          {t(
            "O método de autenticação Captive Portal está ativo neste ambiente. É possível gerenciar as redes que foram vinculadas, no menu Captive Portal em 'Wireless vinculadas'"
          )}
          .
        </p>

        <p className="mt-4">
          {t(
            'Deseja prosseguir e vincular essa nova rede wireless ao Captive?'
          )}
        </p>
      </div>
    </ConfirmModal>
  );
};

export default ModalConfirmAssociateCaptive;
