import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InputImage.module.css';

interface IProps {
  onChange: (file: File | null) => void;
  value: File | null;
  id?: string;
  isInvalid?: boolean;
}

const InputImage = ({ onChange, value, id, isInvalid }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'inputImage'
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files[0]) {
      onChange(files[0]);
    }

    // clear input value
    event.target.value = '';
  };

  const handleRemoveImage = () => {
    onChange(null);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <div
      className={classNames([
        'd-flex align-end',
        styles.inputImage,
        isInvalid && styles.inputImageInvalid,
        value && !isInvalid
          ? styles.inputImageWithImage
          : styles.inputImageWithoutImage
      ])}>
      {!value || isInvalid ? (
        <label htmlFor={id} className={classNames(styles.inputFile)}>
          <input
            type="file"
            id={id}
            accept=".jpg, .jpeg, .png"
            required
            onChange={handleOnChange}
            style={{
              display: 'none'
            }}
          />
          <span className="fit-width fit-height">{t('ADICIONAR IMAGEM')}</span>
        </label>
      ) : (
        <div
          className={classNames(styles.inputFile)}
          aria-hidden
          onClick={handleRemoveImage}>
          <span>{t('REMOVER IMAGEM')}</span>
        </div>
      )}
    </div>
  );
};

InputImage.defaultProps = {
  id: 'inputFile',
  isInvalid: false
};

export { InputImage };
