import React, { Children } from 'react';
import classNames from 'classnames';
import styles from './Breadcrumbs.module.css';
import { MdNavigateNext } from 'react-icons/md';

const Breadcrumbs = ({ children, id = '', className = '' }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <ol
      id={id}
      aria-label="breadcrumb"
      className={classNames([styles.breadcrumbs, className])}>
      {Children.map(arrayChildren, (child, index) => {
        const isLast = index === arrayChildren.length - 1;

        return (
          <>
            {isLast ? (
              child
            ) : (
              <>
                {child}
                <MdNavigateNext
                  size="16"
                  color="var(--breadcrumb-option-text-color)"
                />
              </>
            )}
          </>
        );
      })}
    </ol>
  );
};

const Crumb = ({ children, id = '' }) => {
  return (
    <li id={id} className={styles.crumb}>
      {children}
    </li>
  );
};

export { Breadcrumbs, Crumb };
