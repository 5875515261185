import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFile } from 'src/components/InputFile';
import Modal from 'ui-components/Modal';
import { MdPublish } from 'react-icons/md';
import classNames from 'classnames';
import { Button } from 'ui-components/Button';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from 'src/hooks/useToast';
import { isEmpty } from 'lodash';
import { IImportRecordsModal, IImportRecordsModalProps } from './types';
import styles from './ImportRecordsModal.module.css';

const ImportRecordsModal = ({
  showImportModal,
  onCloseImportModal,
  onSubmitImportModal
}: IImportRecordsModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const { addToast } = useToast();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm<IImportRecordsModal>({
    defaultValues: {
      file: null
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const onSubmit = handleSubmit((data: IImportRecordsModal) => {
    console.log(`enviar form de importar ${data}`);
    addToast('success', t('Arquivo importado com sucesso'));
    setValue('file', null);
    onSubmitImportModal();
  });

  const isFormChanged = !watch('file');

  return (
    <Modal
      title={t('Importar Backup')}
      height="300px"
      width="610px"
      closable
      onClose={() => {
        setValue('file', null);
        onCloseImportModal();
      }}
      show={showImportModal}>
      <span>
        {t(
          'O arquivo inserido deve substituir a sua atual tabela de cadastros, experimente fazer uma exportação antes'
        )}
        .
      </span>
      <form onSubmit={onSubmit} id="form-import-file">
        <div
          className={classNames([
            'd-flex',
            'align-start',
            'mt-3',
            'fit-width'
          ])}>
          <Controller
            name="file"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('Campo obrigatório')
              }
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <InputFile
                  iconRight={<MdPublish />}
                  divProps={{ className: classNames([styles.inputDownload]) }}
                  label={t('Importar Arquivo')}
                  file={value}
                  placeholder={t('Selecione o arquivo backup Excel')}
                  hasError={Boolean(errors.file)}
                  messageError={errors.file?.message}
                  acceptFileTypes=".xltx, .xltm, .xlt, .xlam, .xla, .xls, .xlt, .xlsb, .xlsm, .xlsx, .csv"
                  onChange={onChange}
                />
              );
            }}
          />
          <Button
            type="submit"
            className={classNames(['ml-4 mt-4', styles.buttonSave])}
            variant="primary"
            disabled={!isEmpty(errors.file) || isFormChanged}>
            {t('Salvar')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ImportRecordsModal };
