import classNames from 'classnames';
import React from 'react';
import styles from './Badge.module.css';

interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  color: string;
  backgroundColor?: string;
  size?: 'small';
  className?: string;
}
const Badge = ({
  backgroundColor,
  color,
  title,
  size,
  className,
  ...props
}: IBadgeProps) => {
  return (
    <div
      className={classNames([
        styles.container,
        size && styles[size],
        'b-1 d-flex align-center justify-center',
        className
      ])}
      style={{
        borderColor: color,
        backgroundColor
      }}
      {...props}>
      <span
        className={styles.title}
        style={{
          color
        }}>
        {title}
      </span>
    </div>
  );
};

Badge.defaultProps = {
  className: '',
  size: 'medium',
  backgroundColor: ''
};

export default Badge;
