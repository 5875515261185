import { Select as MuiSelect } from '@mui/base';
import classNames from 'classnames';
import React from 'react';

import { Option } from 'src/components/Option';
import styles from './Select.module.css';
import type { ISelect } from './types';

const Select = React.forwardRef(function Select(
  props: ISelect,
  ref: React.ForwardedRef<HTMLButtonElement> | null
) {
  const { children, slotProps, options, className, ...rest } = props;
  const { listbox, popper, ...restSlotProps } = slotProps || {};

  const getListboxClassName = () => {
    const parsedListbox = listbox as { className: string };
    if (!parsedListbox || !parsedListbox.className) return '';
    return parsedListbox.className;
  };

  const getPopperClassName = () => {
    const parsedPopper = popper as { className: string };
    if (!parsedPopper || !parsedPopper.className) return '';
    return parsedPopper.className;
  };

  const hasChildren = !!children;

  const generateOptions = () => {
    return options?.map((option) => (
      <Option key={option.label} value={option.value}>
        {option.label}
      </Option>
    ));
  };

  const checkIfDisplayOptionsOrChildren = () => {
    if (hasChildren) return children;
    if (options) return generateOptions();
    return null;
  };

  return (
    <MuiSelect
      {...rest}
      ref={ref}
      className={classNames(styles.select, 'common-body-1', className)}
      slotProps={{
        listbox: {
          ...listbox,
          className: classNames([
            styles['select-listbox'],
            getListboxClassName()
          ])
        },
        popper: {
          ...popper,
          className: classNames([styles['select-popper'], getPopperClassName()])
        },
        ...restSlotProps
      }}>
      {checkIfDisplayOptionsOrChildren()}
    </MuiSelect>
  );
});

export default Select;
