import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { generateColumns } from 'src/utils/tableUtils';
import Modal from 'ui-components/Modal';
import Table from 'ui-components/Table';
import styles from './PlaceListModal.module.css';
import { IPlace } from './types';

interface IProps {
  show: boolean;
  onClose: () => void;
}

const PlaceListModal = ({ show, onClose }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists'
  });

  const isFetchedAfterMount = true;

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex my-4 justify-between align-center py-4"
          key={`${i}-skeleton-wireless-table`}>
          <Skeleton count={1} height={14} width={100} className="mr-11" />
        </div>
      );
    }

    return skeletons;
  };

  const placeList: IPlace[] = [
    {
      id: 1,
      name: 'Ambiente 1'
    },
    {
      id: 2,
      name: 'Ambiente 2'
    },
    {
      id: 3,
      name: 'Ambiente 3'
    },
    {
      id: 4,
      name: 'Ambiente 4'
    },
    {
      id: 5,
      name: 'Ambiente 5'
    },
    {
      id: 6,
      name: 'Ambiente 6'
    }
  ];

  const columnsTablePlaceList = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('placeName', t('Nome'), 'left', 0)
  ];

  const generateRows = (placeListToFormat: IPlace[]) => {
    return placeListToFormat.map((place) => {
      return {
        ...place,
        placeName: (
          <span
            id={` 
          place-name-${place.id}-${place.name}
        `}
            className="text-bold">
            {place.name}
          </span>
        )
      };
    });
  };

  return (
    <Modal
      closable={false}
      show={show}
      title={t('Listas de ambientes')}
      width="700px"
      actions={[
        {
          label: t('FECHAR'),
          action: onClose,
          primary: true
        }
      ]}>
      <span className={styles.subtitle}>
        {t('Atualmente a rede Wireless está associado aos seguintes ambientes')}
        :
      </span>

      <div className={styles.modalContent}>
        <div className="mt-4">
          {isFetchedAfterMount ? (
            <Table
              noSelection
              columns={columnsTablePlaceList}
              rows={generateRows(placeList)}
            />
          ) : (
            <div>
              <Skeleton
                count={1}
                height={45}
                borderRadius={10}
                className="mb-2"
              />
              {renderSkeletonsOfTable(3)}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PlaceListModal;
