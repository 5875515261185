export const timezones = [
  // {
  //   label: 'Etc/GMT-12 (GMT-12:00)',
  //   value: 'Etc/GMT-12'
  // },
  // {
  //   label: 'Etc/GMT-12 (GMT-12:00)',
  //   value: 'Etc/GMT-12'
  // },
  // {
  //   label: 'Etc/GMT-11 (GMT-11:00)',
  //   value: 'Etc/GMT-11'
  // },
  // {
  //   label: 'Pacific/Midway (GMT-11:00)',
  //   value: 'Pacific/Midway'
  // },
  // {
  //   label: 'America/Adak (GMT-10:00)',
  //   value: 'America/Adak'
  // },
  // {
  //   label: 'America/Anchorage (GMT-09:00)',
  //   value: 'America/Anchorage'
  // },
  // {
  //   label: 'Pacific/Gambier (GMT-09:00)',
  //   value: 'Pacific/Gambier'
  // },
  // {
  //   label: 'America/Dawson Creek (GMT-08:00)',
  //   value: 'America/Dawson_Creek'
  // },
  // {
  //   label: 'America/Ensenada (GMT-08:00)',
  //   value: 'America/Ensenada'
  // },
  // {
  //   label: 'America/Los Angeles (GMT-08:00)',
  //   value: 'America/Los_Angeles'
  // },
  // {
  //   label: 'America/Chihuahua (GMT-07:00)',
  //   value: 'America/Chihuahua'
  // },
  // {
  //   label: 'America/Denver (GMT-07:00)',
  //   value: 'America/Denver'
  // },
  // {
  //   label: 'America/Belize (GMT-06:00)',
  //   value: 'America/Belize'
  // },
  // {
  //   label: 'America/Cancun (GMT-06:00)',
  //   value: 'America/Cancun'
  // },
  // {
  //   label: 'America/Chicago (GMT-06:00)',
  //   value: 'America/Chicago'
  // },
  // {
  //   label: 'Chile/EasterIsland (GMT-06:00)',
  //   value: 'Chile/EasterIsland'
  // },
  // {
  //   label: 'America/Bogota (GMT-05:00)',
  //   value: 'America/Bogota'
  // },
  // {
  //   label: 'America/Havana (GMT-05:00)',
  //   value: 'America/Havana'
  // },
  {
    label: 'America/Rio Branco (GMT-05:00)',
    value: 'America/Rio_Branco'
  },
  // {
  //   label: 'America/New York (GMT-05:00)',
  //   value: 'America/New_York'
  // },
  // {
  //   label: 'America/Caracas (GMT-04:30)',
  //   value: 'America/Caracas'
  // },
  {
    label: 'America/Manaus (GMT-04:00)',
    value: 'America/Manaus'
  },
  // {
  //   label: 'America/Glace Bay (GMT-04:00)',
  //   value: 'America/Glace_Bay'
  // },
  // {
  //   label: 'America/Goose Bay (GMT-04:00)',
  //   value: 'America/Goose_Bay'
  // },
  // {
  //   label: 'America/Santiago (GMT-04:00)',
  //   value: 'America/Santiago'
  // },
  // {
  //   label: 'America/La Paz (GMT-04:00)',
  //   value: 'America/La_Paz'
  // },
  // {
  //   label: 'America/Argentina/Buenos Aires (GMT-03:00)',
  //   value: 'America/Argentina/Buenos_Aires'
  // },
  // {
  //   label: 'America/Montevideo (GMT-03:00)',
  //   value: 'America/Montevideo'
  // },
  // {
  //   label: 'America/Araguaina (GMT-03:00)',
  //   value: 'America/Araguaina'
  // },
  // {
  //   label: 'America/Godthab (GMT-03:00)',
  //   value: 'America/Godthab'
  // },
  // {
  //   label: 'America/Miquelon (GMT-03:00)',
  //   value: 'America/Miquelon'
  // },
  {
    label: 'America/Sao Paulo (GMT-03:00)',
    value: 'America/Sao_Paulo'
  },
  // {
  //   label: 'America/St Johns (GMT-03:30)',
  //   value: 'America/St_Johns'
  // },
  {
    label: 'America/Noronha (GMT-02:00)',
    value: 'America/Noronha'
  }
  // {
  //   label: 'Atlantic/Cape Verde (GMT-01:00)',
  //   value: 'Atlantic/Cape_Verde'
  // },
  // {
  //   label: 'Europe/Belfast (GMT)',
  //   value: 'Europe/Belfast'
  // },
  // {
  //   label: 'Africa/Abidjan (GMT)',
  //   value: 'Africa/Abidjan'
  // },
  // {
  //   label: 'Europe/Dublin (GMT)',
  //   value: 'Europe/Dublin'
  // },
  // {
  //   label: 'Europe/Lisbon (GMT)',
  //   value: 'Europe/Lisbon'
  // },
  // {
  //   label: 'Europe/London (GMT)',
  //   value: 'Europe/London'
  // },
  // {
  //   label: 'UTC (UTC)',
  //   value: 'UTC'
  // },
  // {
  //   label: 'Africa/Algiers (GMT+01:00)',
  //   value: 'Africa/Algiers'
  // },
  // {
  //   label: 'Africa/Windhoek (GMT+01:00)',
  //   value: 'Africa/Windhoek'
  // },
  // {
  //   label: 'Atlantic/Azores (GMT+01:00)',
  //   value: 'Atlantic/Azores'
  // },
  // {
  //   label: 'Atlantic/Stanley (GMT+01:00)',
  //   value: 'Atlantic/Stanley'
  // },
  // {
  //   label: 'Europe/Amsterdam (GMT+01:00)',
  //   value: 'Europe/Amsterdam'
  // },
  // {
  //   label: 'Europe/Belgrade (GMT+01:00)',
  //   value: 'Europe/Belgrade'
  // },
  // {
  //   label: 'Europe/Brussels (GMT+01:00)',
  //   value: 'Europe/Brussels'
  // },
  // {
  //   label: 'Africa/Cairo (GMT+02:00)',
  //   value: 'Africa/Cairo'
  // },
  // {
  //   label: 'Africa/Blantyre (GMT+02:00)',
  //   value: 'Africa/Blantyre'
  // },
  // {
  //   label: 'Asia/Beirut (GMT+02:00)',
  //   value: 'Asia/Beirut'
  // },
  // {
  //   label: 'Asia/Damascus (GMT+02:00)',
  //   value: 'Asia/Damascus'
  // },
  // {
  //   label: 'Asia/Gaza (GMT+02:00)',
  //   value: 'Asia/Gaza'
  // },
  // {
  //   label: 'Asia/Jerusalem (GMT+02:00)',
  //   value: 'Asia/Jerusalem'
  // },
  // {
  //   label: 'Africa/Addis Ababa (GMT+03:00)',
  //   value: 'Africa/Addis_Ababa'
  // },
  // {
  //   label: 'Asia/Riyadh89 (GMT+03:00)',
  //   value: 'Asia/Riyadh89'
  // },
  // {
  //   label: 'Europe/Minsk (GMT+03:00)',
  //   value: 'Europe/Minsk'
  // },
  // {
  //   label: 'Asia/Tehran (GMT+03:30)',
  //   value: 'Asia/Tehran'
  // },
  // {
  //   label: 'Asia/Dubai (GMT+04:00)',
  //   value: 'Asia/Dubai'
  // },
  // {
  //   label: 'Asia/Yerevan (GMT+04:00)',
  //   value: 'Asia/Yerevan'
  // },
  // {
  //   label: 'Europe/Moscow (GMT+04:00)',
  //   value: 'Europe/Moscow'
  // },
  // {
  //   label: 'Asia/Kabul (GMT+04:30)',
  //   value: 'Asia/Kabul'
  // },
  // {
  //   label: 'Asia/Tashkent (GMT+05:00)',
  //   value: 'Asia/Tashkent'
  // },
  // {
  //   label: 'Asia/Kolkata (GMT+05:30)',
  //   value: 'Asia/Kolkata'
  // },
  // {
  //   label: 'Asia/Katmandu (GMT+05:45)',
  //   value: 'Asia/Katmandu'
  // },
  // {
  //   label: 'Asia/Dhaka (GMT+06:00)',
  //   value: 'Asia/Dhaka'
  // },
  // {
  //   label: 'Asia/Yekaterinburg (GMT+06:00)',
  //   value: 'Asia/Yekaterinburg'
  // },
  // {
  //   label: 'Asia/Rangoon (GMT+06:30)',
  //   value: 'Asia/Rangoon'
  // },
  // {
  //   label: 'Asia/Bangkok (GMT+07:00)',
  //   value: 'Asia/Bangkok'
  // },
  // {
  //   label: 'Asia/Novosibirsk (GMT+07:00)',
  //   value: 'Asia/Novosibirsk'
  // },
  // {
  //   label: 'Etc/GMT+8 (GMT+08:00)',
  //   value: 'Etc/GMT+8'
  // },
  // {
  //   label: 'Asia/Hong Kong (GMT+08:00)',
  //   value: 'Asia/Hong_Kong'
  // },
  // {
  //   label: 'Asia/Krasnoyarsk (GMT+08:00)',
  //   value: 'Asia/Krasnoyarsk'
  // },
  // {
  //   label: 'Australia/Perth (GMT+08:00)',
  //   value: 'Australia/Perth'
  // },
  // {
  //   label: 'Australia/Eucla (GMT+08:45)',
  //   value: 'Australia/Eucla'
  // },
  // {
  //   label: 'Asia/Irkutsk (GMT+09:00)',
  //   value: 'Asia/Irkutsk'
  // },
  // {
  //   label: 'Asia/Seoul (GMT+09:00)',
  //   value: 'Asia/Seoul'
  // },
  // {
  //   label: 'Asia/Tokyo (GMT+09:00)',
  //   value: 'Asia/Tokyo'
  // },
  // {
  //   label: 'Australia/Adelaide (GMT+09:30)',
  //   value: 'Australia/Adelaide'
  // },
  // {
  //   label: 'Australia/Darwin (GMT+09:30)',
  //   value: 'Australia/Darwin'
  // },
  // {
  //   label: 'Pacific/Marquesas (GMT+09:30)',
  //   value: 'Pacific/Marquesas'
  // },
  // {
  //   label: 'Etc/GMT+10 (GMT+10:00)',
  //   value: 'Etc/GMT+10'
  // },
  // {
  //   label: 'Australia/Brisbane (GMT+10:00)',
  //   value: 'Australia/Brisbane'
  // },
  // {
  //   label: 'Australia/Hobart (GMT+10:00)',
  //   value: 'Australia/Hobart'
  // },
  // {
  //   label: 'Asia/Yakutsk (GMT+10:00)',
  //   value: 'Asia/Yakutsk'
  // },
  // {
  //   label: 'Australia/Lord Howe (GMT+10:30)',
  //   value: 'Australia/Lord_Howe'
  // },
  // {
  //   label: 'Asia/Vladivostok (GMT+11:00)',
  //   value: 'Asia/Vladivostok'
  // },
  // {
  //   label: 'Pacific/Norfolk (GMT+11:30)',
  //   value: 'Pacific/Norfolk'
  // },
  // {
  //   label: 'Etc/GMT+12 (GMT+12:00)',
  //   value: 'Etc/GMT+12'
  // },
  // {
  //   label: 'Asia/Anadyr (GMT+12:00)',
  //   value: 'Asia/Anadyr'
  // },
  // {
  //   label: 'Asia/Magadan (GMT+12:00)',
  //   value: 'Asia/Magadan'
  // },
  // {
  //   label: 'Pacific/Auckland (GMT+12:00)',
  //   value: 'Pacific/Auckland'
  // },
  // {
  //   label: 'Pacific/Chatham (GMT+12:45)',
  //   value: 'Pacific/Chatham'
  // },
  // {
  //   label: 'Pacific/Tongatapu (GMT+13:00)',
  //   value: 'Pacific/Tongatapu'
  // },
  // {
  //   label: 'Pacific/Kiritimati (GMT+14:00)',
  //   value: 'Pacific/Kiritimati'
  // }
];
