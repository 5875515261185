import React from 'react';
import classNames from 'classnames';
import styles from './Avatar.module.css';

const Avatar = ({ children, clickable = false, className = '', ...props }) => {
  return (
    <button
      {...props}
      className={classNames([
        styles.avatar,
        clickable ? styles.clickable : '',
        className
      ])}>
      {children}
    </button>
  );
};

export { Avatar };
