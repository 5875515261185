import React from 'react';
import { t as translate } from 'i18next';
import classNames from 'classnames';
import home from 'src/assets/help-images/tela-inicial.png';
import homeWithContaUnica from 'src/assets/help-images/tela-inicial-com-conta-unica.png';
import contaUnicaWindow from 'src/assets/help-images/tela-conta-unica.png';
import site from 'src/assets/help-images/tela-locais.png';
import siteWithMenu from 'src/assets/help-images/tela-local-menu-destacado.png';
import siteWithMenuOpen from 'src/assets/help-images/tela-local-menu-aberto-com-excluir-destacado.png';
import placeWithMenu from 'src/assets/help-images/tela-ambiente-com-menu-destacado.png';
import placeWithMenuOpen from 'src/assets/help-images/tela-ambiente-com-menu-aberto-excluir-destacado.png';
import place from 'src/assets/help-images/tela-ambientes.png';
import modalAddMember from 'src/assets/help-images/modal-add-membro.png';
import siteWithMenuOpenOnAddMember from 'src/assets/help-images/tela-locais-menu-aberto-add-membro-destacado.png';
import modalSuccessAddMemberWithoutRegister from 'src/assets/help-images/modal-sucesso-add-membro-sem-cadastro.png';
import modalSuccessAddMemberWithRegister from 'src/assets/help-images/modal-sucesso-add-membro-com-cadastro.png';
import sharedSiteWithNewSharedSite from 'src/assets/help-images/tela-locais-compatilhados-com-tag-novo-local.png';
import siteWithMenuOpenOnViewMember from 'src/assets/help-images/tela-local-menu-aberto-visualizar-membro-destacado.png';
import drawerMembers from 'src/assets/help-images/drawer-membros.png';
import modalEditMember from 'src/assets/help-images/modal-edicao-membro.png';
import modalConfirmEditMember from 'src/assets/help-images/modal-confirmacao-edicao-membro.png';
import drawerMembersWithEditOption from 'src/assets/help-images/drawer-membros-opcao-edicao-destacado.png';
import modalConfirmDeleteMember from 'src/assets/help-images/modal-confirmacao-exclusao-membro.png';
import drawerMembersWithDeleteOption from 'src/assets/help-images/drawer-membros-opcao-deletar-destacada.png';
import addNewDevice from 'src/assets/help-images/form-add-equipamento.png';
import viewAllDevices from 'src/assets/help-images/visualizacao-equipamentos.png';
import viewDetailsDeviceWithEditName from 'src/assets/help-images/tela-detalhes-equipamento-com-editar-nome-destacado.png';
import viewAllDevicesWithConfigurationOption from 'src/assets/help-images/tela-visualizacao-equipamentos-com-configuracao-destacada.png';
import viewDetailsDeviceWithReboot from 'src/assets/help-images/tela-detalhes-equipamento-com-opcao-reiniciar-destacada.png';
import viewDetailsDeviceWithRadioConfiguration from 'src/assets/help-images/tela-detalhes-equipamento-opcao-edicoes-radios.png';
import viewDetailsDeviceWithRadioConfigurationEnable from 'src/assets/help-images/tela-detalhes-equipamento-edicoes-radios-habilitado.png';
import viewDetailsDeviceWithUpdateFirmware from 'src/assets/help-images/tela-detalhes-equipamento-atualizar-firmware.png';
import modalUpdateFirmware from 'src/assets/help-images/modal-atualizar-firmware.png';
import modalUpdateFirmwareWithLatestVersion from 'src/assets/help-images/modal-atualizar-firmware-versao-atualizada.png';
import modalUpdateFirmwareWithChooseVersion from 'src/assets/help-images/modal-atualizar-firmware-versao-desatualizada.png';
import viewDetailsDeviceWithAssociatedWireless from 'src/assets/help-images/tela-detalhes-equipamento-wireless-associado.png';
import modalToChooseWireless from 'src/assets/help-images/modal-escolha-wireless-associado.png';
import viewAllDevicesWithOneChecked from 'src/assets/help-images/tela-visualizacao-equipamentos-um-equipamento-selecionado.png';
import viewDetailsDeviceWithDelete from 'src/assets/help-images/tela-detalhes-equipamento-com-excluir-destacado.png';
import viewAllDevicesWithTwoChecked from 'src/assets/help-images/tela-visualizacao-equipamentos-dois-equipamentos-selecionados.png';
import viewWirelessListEmpty from 'src/assets/help-images/tela-visualizacao-wireless-sem-wireless.png';
import createNewWireless from 'src/assets/help-images/tela-criacao-wireless.png';
import selectorFrequency from 'src/assets/help-images/seletor-frequencia.png';
import viewNewWirelessWithDevices from 'src/assets/help-images/tela-criacao-wireless-equipamentos-associados.png';
import viewWirelessListWithEditButton from 'src/assets/help-images/tela-listagem-wireless-editar-destacado.png';
import viewWirelessWithDevices from 'src/assets/help-images/tela-edicao-wireless-equipamentos-associados.png';
import viewWirelessWithSensitivity from 'src/assets/help-images/tela-edicao-wireless-sensibilidade-destacada.png';
import viewWirelessWithHide from 'src/assets/help-images/tela-edicao-wireless-ocultar-destacada.png';
import viewWirelessWithIsolateOption from 'src/assets/help-images/tela-edicao-wireless-isolamento-destacada.png';
import viewWirelessWithVLAN from 'src/assets/help-images/tela-edicao-wireless-vlan-destacada.png';
import viewWirelessWithQoS from 'src/assets/help-images/tela-edicao-wireless-limite-banda-destacado.png';
import fieldQoSEnable from 'src/assets/help-images/campo-limite-banda-ativo.png';
import viewWirelessListWithOneChecked from 'src/assets/help-images/tela-visualizacao-wireless-uma-selecionada.png';
import modalDeleteOneWireless from 'src/assets/help-images/modal-exclusao-uma-wireless.png';
import viewWirelessListWithTwoChecked from 'src/assets/help-images/tela-visualizacao-wireless-duas-selecionadas.png';
import modalDeleteTwoWireless from 'src/assets/help-images/modal-exclusao-duas-wireless.png';
import viewAdvancedWirelessWithRoaming from 'src/assets/help-images/tela-wireless-avancado-roaming.png';
import viewAdvancedWirelessWithButtonSave from 'src/assets/help-images/tela-wireless-avancado-botao-salvar.png';
import viewAdvancedWirelessWithBandSteering from 'src/assets/help-images/tela-wireless-avancado-band-steering.png';
import viewAdvancedWirelessWithBeamforming from 'src/assets/help-images/tela-wireless-avancado-beamforming.png';
import viewAdvancedWirelessWithMUMIMO from 'src/assets/help-images/tela-wireless-avancado-mu-mimo.png';
import viewConnectedClients from 'src/assets/help-images/tela-clientes-conectados.png';
import viewConnectedClientsEmpty from 'src/assets/help-images/tela-clientes-conectados-vazio.png';
import siteWithMenuOpenOnConnectionsLog from 'src/assets/help-images/tela-local-menu-aberto-registro-conexao.png';
import viewConnectionsLogEmpty from 'src/assets/help-images/tela-registro-conexao-vazio.png';
import viewConnectionsLog from 'src/assets/help-images/tela-registro-conexao.png';
import viewConnectionsLogWithDownloadOption from 'src/assets/help-images/tela-registro-conexao-botao-download-destacado.png';
import viewConnectionsLogWithLoadingMessage from 'src/assets/help-images/loadin-download-registro.png';
import captiveMethodConfigScreen from 'src/assets/help-images/tela-configurar-metodo-captive.png';
import captiveWithoutLockConfigScreen from 'src/assets/help-images/tela-configurar-metodo-captive-sem-travas.png';
import captiveMethodConfirmModal from 'src/assets/help-images/modal-de-confirmação-de-método-captive.png';
import simplePasswordWizardFirstStep from 'src/assets/help-images/tela-senha-simples-wizard-primeiro-passo.png';
import simplePasswordDurationForm from 'src/assets/help-images/duração-da-senha-form-senha-simples-wizard.png';
import simplePasswordWizardSecondStep from 'src/assets/help-images/tela-senha-simples-segundo-passo.png';
import simplePasswordWizardThirdStep from 'src/assets/help-images/tela-senha-simples-wizard-terceiro-passo.png';
import modalConfirmSimplePasswordWizard from 'src/assets/help-images/modal-captive-senha-simples-wizard-confirmar.png';
import activatedSimplePasswordAtConfigMethod from 'src/assets/help-images/tela-configurar-metodo-senha-simples-ativada.png';
import notificationDrawerAfterSimplePasswordActivate from 'src/assets/help-images/drawer-notificação-apos-aplicar-senha-simples.png';
import captiveMicroFrontSimplePassword from 'src/assets/help-images/tela-captive-senha-simples-microfront.png';
import placeCardCaptiveActivated from 'src/assets/help-images/card-ambientes-captive-ativo.png';
import connectedUserToCaptive from 'src/assets/help-images/tela-cliente-conectado-captive.png';
import connectionLogCaptiveUser from 'src/assets/help-images/tela-registro-de-cliente-conectado-captive.png';
import simplePasswordHistory from 'src/assets/help-images/tela-senha-simples-historico.png';
import simplePasswordHistoryDelete from 'src/assets/help-images/tela-senha-simples-historico-apagar.png';
import simplePasswordHistoryRenew from 'src/assets/help-images/tela-senha-simples-historico-renovar.png';
import simplePasswordMethodActivate from 'src/assets/help-images/tela-senha-simples-metodo-ativo.png';
import drawerCreateSimplePassword from 'src/assets/help-images/tela-senha-simples-drawer-criação.png';
import modalSimplePasswordAlreadyExists from 'src/assets/help-images/modal-ja-existe-senha-simples.png';
import simplePasswordScreenAfterCreateNewPassword from 'src/assets/help-images/tela-senha-simples-detalhes-apos-criacao-de-senha.png';
import individualRegistrationDefinition from 'src/assets/help-images/Modal de Definição de Cadastro Individual.png';
import newIndividualRegistrationForm from 'src/assets/help-images/Formulário de novo cadastro individual.png';
import clientAccessAndExpirationDefinition from 'src/assets/help-images/Definição de acesso e expiração para o cliente.png';
import accessExpirationForecast from 'src/assets/help-images/Previsão de expiração do acesso.png';
import tableOfCreatedIndividualRegistrations from 'src/assets/help-images/Tabela com cadastros individuais criados.png';
import moreInformationAndChangesOptionsInRegistration from 'src/assets/help-images/Opções de mais informações e alterações no cadastro.png';
import captivePortalPageCustomization from 'src/assets/help-images/Personalização da página do Captive Portal.png';
import wirelessChoicesWizard from 'src/assets/help-images/Escolha das redes wireless no wizard de cadastro individual.png';
import captivePortalIndividualRegistrationConfigurationFinalization from 'src/assets/help-images/Finalização da configuração do Captive Portal Cadastro Individual.png';
import individualRegistrationActiveInConfigureMethodScreen from 'src/assets/help-images/Cadastro individual ativo na tela de configurar método.png';
import configurationApplicationNotification from 'src/assets/help-images/Notificação de aplicação da configuração.png';
import formToInsertIndividualRegistrationAfterAccessing from 'src/assets/help-images/Formulário para inserir o cadastro individual após acessar.jpeg';
import activeCaptiveIndicationInEnvironmentCard from 'src/assets/help-images/Indicação de Captive ativo no card de ambiente.png';
import captiveMethodsWithIndividualRecordActive from 'src/assets/help-images/Tela de métodos do captive com cadastro individual ativo.png';
import captiveIndividualRecordManagement from 'src/assets/help-images/Interface de gerenciamento de Cadastro Individual.png';
import formOfNewIndividualRecords from 'src/assets/help-images/Formulário para adicionar novos cadastros individuais.png';
import processToRemoveIndividualRecords from 'src/assets/help-images/Processo de exclusão de cadastros individuais.png';
import detailsOfOneIndividualRecord from 'src/assets/help-images/Detalhes de um cadastro individual.png';
import formEditOneIndividualRecord from 'src/assets/help-images/Form de edição de um cadastro individual.png';
import modalWithQtdOfIndividualRecordsToRenew from 'src/assets/help-images/Modal com quantidade de cadastros a ser renovados.png';
import modalToConfirmRenewIndividualRecords from 'src/assets/help-images/Modal de confirmar renovação de cadastros.png';
import modalConfirmDeleteIndividualRecordAvailable from 'src/assets/help-images/Modal de confirmar limpar cadastros disponíveis.png';
import modalConfirmDeleteExpiredIndividualRecord from 'src/assets/help-images/Modal de confirmação de exclusão de cadastros individuais expirados.png';
import modalConfirmDeactivateCaptive from 'src/assets/help-images/Modal de confirmação de desativação do captive.png';
import placesCardWithCaptiveDisabled from 'src/assets/help-images/Card de ambientes captive desativado.png';
import screenOfPersonalizeCaptivePortal from 'src/assets/help-images/Tela de personalizar captive portal.png';
import modalCropImageCaptivePortal from 'src/assets/help-images/Modal de cortar imagem do captive portal.png';
import screenOfPersonalizeCaptivePortalWithNewImage from 'src/assets/help-images/tela de personalizar captive portal com imagem alterada.png';
import captiveFrontendWithChangedImage from 'src/assets/help-images/Tela do captive frontend com imagem alterada.png';
import screenOfVinculatedWirelessToCaptive from 'src/assets/help-images/Wireless vinculadas no Captive Portal.png';
import screenOfVinculatedWirelessToCaptiveWithSelectedWireless from 'src/assets/help-images/Wireless associadas ao captive com uma wireless marcada.png';
import modalChangesWirelessAssociatedToCaptive from 'src/assets/help-images/Modal de salvar mudanças de wireless associadas ao captive.png';
import saveChangesToastWirelessAssociatedCaptive from 'src/assets/help-images/Toast de salvar mudanças de wireless associadas ao captive.png';
import captivePortalEnabledWirelessListingScreen from 'src/assets/help-images/Tela de listagem de wireless com captive portal ativado.png';
import captivePortalBlockedFieldsWirelessEditScreen from 'src/assets/help-images/Tela de edição de wireless com campos bloqueadas pelo captive portal.png';
import captiveActiveWirelessNetworkCreationModal from 'src/assets/help-images/Modal de criação de rede wireless com captive ativo.png';
import captiveLinkedWirelessScreenWithTwoMarkedNetworks from 'src/assets/help-images/Tela de wireless vinculadas ao captive com 2 redes marcadas.png';
import captivePortalSystemFailureAlert from 'src/assets/help-images/Alerta de falha no sistema de captive portal.png';
import individualRegistrationExpirationDateSelection from 'src/assets/help-images/Seleção de data de expiração de cadastro individual.png';
import captivePortalMethodChangeModal from 'src/assets/help-images/modal de troca de métodos no Captive Portal.png';

import { IHelpText } from './types';

const t = (text: string, params?: Record<string, string>) => {
  return translate(`helperDrawer.${text}`, params);
};

const Strong = ({ children }: { children: React.ReactNode }) => (
  <span className={classNames(['text-bold'])}>{children}</span>
);

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <p className={classNames(['mt-4'])}>{children}</p>
);

const MIN_FIRMWARE_CAPTIVE_V3 =
  process.env.REACT_APP_MIN_SUPPORTED_CAPTIVE_V3_FIRMWARE_VERSION || '';
const MIN_FIRMWARE_CAPTIVE_V4 =
  process.env.REACT_APP_MIN_SUPPORTED_CAPTIVE_V4_FIRMWARE_VERSION || '';

const MIN_FIRMWARE_INMASTER_V3 =
  process.env.REACT_APP_MIN_V3_FIRMWARE_VERSION || '';
const MIN_FIRMWARE_INMASTER_V4 =
  process.env.REACT_APP_MIN_V4_FIRMWARE_VERSION || '';

export const helpTexts: IHelpText[] = [
  {
    section: t('Acessando o InMaster'),
    items: [
      {
        title: t('Guia para adição de equipamentos no InMaster'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar corretamente o seu equipamento no InMaster, certifique-se sobre os seguintes pontos:'
              )}
            </p>
            <ol className={classNames(['mt-4 px-4'])}>
              <li>
                {t(
                  'O firmware do equipamento deve estar atualizado com a versão compatível com o InMaster*;'
                )}
              </li>
              <li>
                {t(
                  'Aplique o reset padrão de fábrica no equipamento antes de iniciar a adição no InMaster. Caso o seu equipamento já possua alguma configuração aplicada, o reset de fábrica é necessário para que a adição ao InMaster seja realizada com sucesso;'
                )}
              </li>
              <li>
                {t(
                  'O MAC do equipamento tenha sido corretamente digitado no InMaster;'
                )}
              </li>
              <li>
                {t(
                  'A senha do equipamento esteja corretamente digitada no InMaster. Lembrando que, com a aplicação do reset padrão de fábrica no equipamento, a sua senha será a senha padrão "admin";'
                )}
              </li>
              <li>
                {t(
                  'O equipamento esteja devidamente energizado no momento da adição;'
                )}
              </li>
              <li>
                {t(
                  'O equipamento só poderá estar adicionado em um único ambiente de uma conta específica, portanto é importante verificar se o mesmo já não tenha sido adicionado em algum outro local/ambiente do InMaster'
                )}
                .
              </li>
            </ol>
            <p className={classNames(['mt-4'])}>
              *{' '}
              {t(
                'O InMaster não suporta o gerenciamento e configurações dos APs no modo roteador. Apenas APs no modo bridge são suportados pelo InMaster.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              *{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à {{minimumVersionV3}}.',
                {
                  minimumVersionV3: MIN_FIRMWARE_INMASTER_V3
                }
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              *{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à {{minimumVersionV4}}',
                {
                  minimumVersionV4: MIN_FIRMWARE_INMASTER_V4
                }
              )}
              .
            </p>
          </div>
        )
      },
      {
        title: t('Como fazer uso da solução?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O InMaster é uma solução cloud, multisite voltada para gerenciar os equipamentos Access Point (APs) da Intelbras e monitorar os diversos locais e ambientes configurados.'
              )}
            </p>
            <p className={classNames('mt-4')}>
              {t('Para fazer uso da solução você deve acessar o endereço')}{' '}
              <a
                className={classNames(['text-bold'])}
                style={{ color: 'var(--color-brand-primary-dark)' }}
                href="https://inmaster.intelbras.com.br"
                target="_blank"
                rel="noreferrer">
                https://inmaster.intelbras.com.br
              </a>{' '}
              {t(
                'e seguir os passos de cadastro e login recomendados neste manual.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para utilizar o aplicativo do InMaster basta acessar a loja de seu interesse e baixar o app. O aplicativo do InMaster está disponível na Google Play e App Store da Apple.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'o InMaster é compatível com todos os APs da Intelbras*; exceto: AP300, HS300 e linha BSPRO.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à {{minimumVersionV3}}.',
                {
                  minimumVersionV3: MIN_FIRMWARE_INMASTER_V3
                }
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à {{minimumVersionV4}}',
                {
                  minimumVersionV4: MIN_FIRMWARE_INMASTER_V4
                }
              )}
            </p>
          </div>
        )
      },
      {
        title: t(
          'As configurações feitas nos meus equipamentos serão perdidas quando eu os adicionar no InMaster?'
        ),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Todas as configurações inerentes ao equipamento serão automaticamente carregadas para o InMaster assim que o equipamento em questão tenha o seu processo de adoção concluído.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Importante, as redes wireless configuradas nesses equipamentos não serão consideradas, pois a estrutura do InMaster – hierarquizado por meio de locais e seus ambientes – possuem detalhes e restrições que necessitam que as redes wireless sejam novamente estruturadas na ferramenta.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como fazer login no InMaster (Conta Única Intelbras)?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O login no InMaster é feito via Conta Única Intelbras. Para tanto, basta acessar o botão Conta Intelbras e, caso ainda não tenha cadastro, criar uma nova conta. Além disso, deve-se lembrar de permitir a exibição de pop-ups por parte do Conta Única, visando o correto funcionamento do login e do logout.'
              )}
            </p>
            <img
              src={home}
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela inicial do InMaster')}
              width="100%"
            />
            <img
              src={homeWithContaUnica}
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela inicial do InMaster com a pop-up de login do conta única aberta'
              )}
              width="100%"
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'para acesso ao InMaster você deve aceitar os termos de uso e a política de privacidade. Ambos conteúdo podem ser acessados pelos links disponíveis na tela.'
              )}
            </p>
            <div className={classNames(['d-flex justify-center fit-width'])}>
              <img
                src={contaUnicaWindow}
                width="50%"
                className={classNames(['mt-4'])}
                alt={t(
                  'Imagem mais detalhada do pop-up do conta única, no qual os links dos termos de uso e política de privacidade estão destacados'
                )}
              />
            </div>
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus locais'),
    items: [
      {
        title: t('O que é um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Um local, ou um site, corresponde ao maior nível hierárquico pelo qual você poderá organizar a sua instalação.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Por exemplo, vamos supor que eu deseje instalar 50 APs na fábrica da Intelbras. Eu poderia nomear este meu local como 'Intelbras' e depois configurar os ambientes aos quais esses meus APs serão instalados."
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Agora, vamos supor que além da Intelbras eu também queira gerenciar os equipamentos instalados no shopping do município vizinho. Eu poderia então configurar um segundo local nomeado como 'Shopping Pedra Azul'."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como criar um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Logo no seu primeiro acesso ao InMaster você será orientado para criar o seu primeiro local de instalação.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Uma vez criado o primeiro local na ferramenta, você poderá criar facilmente novos locais (no máximo 32) por meio do seguinte acesso.'
              )}
            </p>
            <img
              src={site}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de criar local'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'não esqueça de determinar o time zone correto de cada local criado. Por se tratar de um sistema com acesso remoto e cujo acesso pode ser compartilhado, essa determinação poderá influenciar no entendimento de dados demonstrados que fazem uso de horário, por exemplo.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como excluir um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um local, clique no menu em forma de reticências que fica na parte direita do card de local. Em seguida serão exibidas algumas opções, basta clicar em "EXCLUIR LOCAL" e confirma no modal que será exibido.'
              )}
            </p>
            <img
              src={siteWithMenu}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão do menu em um dos locais'
              )}
            />
            <img
              src={siteWithMenuOpen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais com um dos menus dos locais aberto, no qual está destacado o botão de excluir o local'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'Caso haja somente um local cadastrado, não é possível excluí-lo, sendo necessário criar um novo para habilitar a opção de excluir.'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus ambientes'),
    items: [
      {
        title: t('O que é um ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O ambiente é o segundo nível hierárquico que você utilizará para organizar a sua instalação. Todo local cadastrado deverá conter, pelo menos, um ambiente vinculado a ele.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                "Por exemplo, vamos supor que eu criei um local denominado 'Intelbras' para instalar 50 APs na fábrica. Dentro deste local eu poderia criar ambientes diversos a fim de facilitar e organizar a instalação dos meus equipamentos, tais como: 'Recepção', 'Sala de RH', 'Área de produção' e etc."
              )}
            </p>
          </div>
        )
      },
      {
        title: 'Como criar um ambiente?',
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Sempre que criar um novo local será solicitado a você criar o primeiro ambiente para este local.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Uma vez criado o primeiro ambiente do local, você poderá criar novos ambientes (máximos 32 ambientes por local) para lhe ajudar na organização da sua instalação.'
              )}
            </p>
            <img
              src={place}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado o botão de criar ambiente'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como excluir um ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um ambiente, clique no menu em forma de reticências que fica na parte direita do card de ambiente. Em seguida serão exibidas algumas opções, basta clicar em "EXCLUIR AMBIENTE" e confirma no modal que será exibido.'
              )}
            </p>
            <img
              src={placeWithMenu}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado o botão de menu de um ambiente'
              )}
            />
            <img
              src={placeWithMenuOpen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos ambientes de um local, no qual está destacado a opção de excluir ambiente do menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'Caso haja somente um ambiente cadastrado no local, não é possível excluí-lo, sendo necessário criar um novo ambiente para habilitar a opção de excluir.'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Membros'),
    items: [
      {
        title: t('O que é um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O Membro nada mais é que um usuário do sistema que terá acesso ao local de outro usuário, podendo ser do tipo Gerente ou Convidado.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Membro Gerente tem acesso a gerencia completa do local, adição e remoção de membros.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Membro Convidado tem acesso a gerencia completa dos ambientes.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como adicionar um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar um membro a um local, deve-se estar na tela de meus locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após isso, clicar em "ADICIONAR MEMBRO".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnAddMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de adicionar um membro no menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, deve aparecer um modal o qual deve pedir o e-mail do usuário e selecionar a opção de permissão para o membro.'
              )}
            </p>
            <img
              src={modalAddMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de adição de um membro')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Ao preencher o modal e finalizar o cadastro do membro, será exibido uma mensagem de alerta caso o e-mail informado não tenha conta no InMaster'
              )}
            </p>
            <img
              src={modalSuccessAddMemberWithoutRegister}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de êxito na adição de um membro que não possua cadastro no InMaster'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se o usuário já tiver conta no InMaster ao finalizar o cadastro do membro, deve aparecer uma mensagem informando que ele foi adicionado com sucesso.'
              )}
            </p>
            <img
              src={modalSuccessAddMemberWithRegister}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de êxito na adição de um membro que possua cadastro no InMaster'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Quando o membro logar no sistema vai se deparar com uma tag \'1 novo\' na aba de "COMPARTILHADOS COMIGO"'
              )}
            </p>
            <img
              src={sharedSiteWithNewSharedSite}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais compatilhados comigo, no qual possui uma notificação de que possui um novo local compartilhado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como visualizar os membros de um local?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para visualizar os membros de um local, deve-se estar na tela de locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após, clicar em "VISUALIZAR MEMBROS".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnViewMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos locais, no qual está destacado o botão de visualizar os membros no menu'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, deve aparecer um drawer o qual deve conter uma listagem com os membros.'
              )}
            </p>
            <img
              src={drawerMembers}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de visualização dos membros do local')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                "A listagem dos membros deve exibir algumas informações como dados do membro, tipo de permissão, data de adição do membro. Quando o sistema não informar a data de adição do membro exibindo-a como 'Pendente' e mostrar o nome do usuário com '-', significa que o usuário foi adicionado ao local, mas não possui conta no InMaster (como ilustrado na imagem anterior)."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como editar a permissão de um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para editar a permissão de um membro, deve-se estar na tela de listagem de membros e clicar no ícone de lápis.'
              )}
            </p>
            <img
              src={drawerMembersWithEditOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de visualização dos membros do local com a opção de editar um membro destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no ícone, deve aparecer um modal o qual deve permitir a troca de permissão do membro.'
              )}
            </p>
            <img
              src={modalEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de edição de um membro')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após realizar a alteração de permissão, o botão "CONTINUAR" deve ser habilitado.'
              )}
            </p>
            <img
              src={modalEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de edição de um membro com o nível de permissão editado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, será exibido uma mensagem de aviso sobre a atualização da permissão, então clique em "EDITAR" para finalizar.'
              )}
            </p>
            <img
              src={modalConfirmEditMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de confirmação de alteração do membro')}
            />
          </div>
        )
      },
      {
        title: t('Como excluir um membro?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para excluir um membro, deve-se estar na tela de listagem de membros e clicar no ícone de lixeira.'
              )}
            </p>
            <img
              src={drawerMembersWithDeleteOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da janela de visualização dos membros do local com a opção de excluir um membro destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no ícone, deve aparecer um modal para concluir a ação de excluir o membro.'
              )}
            </p>
            <img
              src={modalConfirmDeleteMember}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da janela de confirmação de exclusão do membro')}
            />
          </div>
        )
      }
    ]
  },
  {
    section: t('Meus equipamentos'),
    items: [
      {
        title: t('Como adicionar um equipamento no InMaster?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para adicionar um equipamento você deve estar dentro de um ambiente previamente cadastrado no InMaster e preencher o MAC e senha do equipamento que você deseja vincular a este ambiente'
              )}
            </p>
            <img
              src={addNewDevice}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de cadastro de um novo equipamento')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Tenha atenção para os seguintes pontos para realizar com sucesso a adição de um equipamento:'
              )}
            </p>
            <ul className={classNames(['mt-4 px-4'])}>
              <li>
                {t(
                  'Certifique-se que o AP esteja atualizado com a versão de firmware compatível com o InMaster e que ele seja um dos modelos suportados pela solução;'
                )}
              </li>
              <li>
                {t(
                  'Para que o processo de adição seja concluído é necessário que o equipamento esteja ligado - devidamente energizado - e disponível para comunicação;'
                )}
              </li>
              <li>
                {t(
                  'Um mesmo equipamento não pode ser adicionado em mais de um local ou em mais de um ambiente. Certifique-se de que o MAC do equipamento que você está querendo adicionar já não tenha sido adicionado pelo InMaster.'
                )}
              </li>
            </ul>
            <p className={classNames(['mt-4'])}>
              {t(
                'Após o equipamento ser adicionado no ambiente, ele aparecerá na tela de lista de equipamentos.'
              )}
            </p>
            <img
              src={viewAllDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos adicionados com sucesso'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'o InMaster é compatível com todos os APs da Intelbras*; exceto: AP300, HS300 e linha BSPRO.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'Para ser compatível com o InMaster, a versão de firmware do AP deve ser igual ou superior à {{minimumVersionV3}}.',
                {
                  minimumVersionV3: MIN_FIRMWARE_INMASTER_V3
                }
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'Para o AP da linha AX a versão de firmware deve ser igual ou superior à {{minimumVersionV4}}',
                {
                  minimumVersionV4: MIN_FIRMWARE_INMASTER_V4
                }
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como editar o nome de um equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Todo equipamento adicionado no InMaster pode ter o seu nome editado pelo usuário. Para tanto, acesse o local e ambiente desejado, selecione o equipamento e edite a descrição do seu nome.'
              )}
            </p>
            <img
              src={viewAllDevicesWithConfigurationOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de configuração do equipamento destacada'
              )}
            />
            <img
              src={viewDetailsDeviceWithEditName}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual a opção de editar o nome do equipamento está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como reiniciar um equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para reiniciar um equipamento, acesse o local e ambiente ao qual o equipamento que você deseja reiniciar esteja adicionado e selecione a opção para reiniciar o equipamento.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithReboot}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de reiniciar o equipamento destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como editar as configurações de rádio do equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para proceder com qualquer ação que envolva configuração de um determinado equipamento, primeiramente acesse o local e o ambiente onde o equipamento em questão esteja adicionado e posteriormente acesse a aba de "CONFIGURAÇÕES POR RÁDIO" e em seguida "EDITAR CONFIGURAÇÕES"'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithRadioConfiguration}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual a opção de configurações por rádio e editar configurações estão destacadas'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'As seguintes ações se encontram disponíveis para serem configuradas:'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithRadioConfigurationEnable}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes de equipamento, no qual mostra as opções de edição dos rádios habilitados'
              )}
            />

            <ul className={classNames(['mt-4 px-4'])}>
              <li>
                <span className={classNames(['text-bold'])}>{t('Canal')}:</span>{' '}
                {t(
                  'Frequência de rádio que o AP usa para transmitir e receber dados sem fio. A seleção do canal correto é importante para garantir que o sinal sem fio seja forte e estável, especialmente em ambientes onde muitos dispositivos sem fio estão em uso simultaneamente.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Padrão IEEE 802.11')}:
                </span>{' '}
                {t(
                  'Emendas do padrão IEEE 802.11 que incluem especificações para as taxas de transferência de dados, a modulação do sinal de rádio, os canais de frequência, os métodos de autenticação e criptografia de segurança e muito mais.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Largura de Banda')}:
                </span>{' '}
                {t(
                  'A largura de banda é uma medida da quantidade de dados que podem ser transmitidos em uma conexão de rede em um determinado período de tempo.'
                )}
              </li>
              <li>
                <span className={classNames(['text-bold'])}>
                  {t('Potência')}:
                </span>{' '}
                {t(
                  'Medida da força do sinal de rádio que ele emite para transmitir dados sem fio em uma rede. A potência é medida em decibéis (dBm) e indica a intensidade do sinal transmitido pelo AP.'
                )}
              </li>
            </ul>
          </div>
        )
      },
      {
        title: t('Como atualizar o firmware do equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado e acesse a opção para atualização de firmware.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithUpdateFirmware}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com a opção de atualizar firmware do equipamento destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Acessando a opção será demonstrada a versão atual do seu equipamento e também se há uma nova versão disponível para ser configurada.'
              )}
            </p>
            <img
              src={modalUpdateFirmware}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de atualização de firmware do equipamento desatualizado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Para atualizar, basta clicar sobre a opção disponível e acompanhar o status do processo de atualização do seu equipamento até sua efetivação.'
              )}
            </p>
            <img
              src={modalUpdateFirmwareWithLatestVersion}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de atualização de firmware do equipamento com firmware na última versão'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível escolher uma versão de firmware anterior, caso deseje regredir o firmware do equipamento. Para tanto, basta acessar a opção e verificar a lista de versões de firmwares compatíveis disponíveis para tanto.'
              )}
            </p>
            <img
              src={modalUpdateFirmwareWithChooseVersion}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de escolha de uma versão de firmware especifica para o equipamento ser atualizado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                'recomendamos manter os equipamentos sempre atualizados com a última versão de firmware disponível para garantir o acesso a todos os recursos e para o seu melhor funcionamento.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como associar um wireless ao equipamento?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado, selecione o equipamento, acesse a aba de "WIRELESS ASSOCIADAS" e clique em "ASSOCIAR WIRELESS", posteriormente a lista de wireless já configuradas aparecerá. Então marque o checkbox da wireless desejada e clique em "SALVAR".'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithAssociatedWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes do equipamento, com a opção de wireless associado destacada'
              )}
            />
            <img
              src={modalToChooseWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem do modal de escolher os wireless a serem associados'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS:')}</span>{' '}
              {t(
                "você poderá criar uma nova rede wireless caso não haja nenhuma criada ou caso as que já estejam configuradas não abrangerem sua necessidade. Para tanto, se tiver dúvidas quanto a este processo, favor acessar o tópico 'Wireless' deste manual."
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como excluir um equipamento do ambiente?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente onde o equipamento em questão esteja adicionado, selecione o equipamento e posteriormente a opção excluir equipamento.'
              )}
            </p>
            <img
              src={viewDetailsDeviceWithDelete}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de detalhes do equipamento, com a opção de excluir equipamento destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 1:')}</span>{' '}
              {t(
                'É possível também deletar pela listagem de equipamentos, bastando clicar no checkbox ao lado do equipamento que se desejar deletar, e depois clicar em "EXCLUIR EQUIPAMENTO (1)".'
              )}
            </p>
            <img
              src={viewAllDevicesWithOneChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com um equipamento selecionado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível excluir vários equipamentos de uma vez, basta selecionar múltiplos checkbox e apertar no botão vermelho "EXCLUIR EQUIPAMENTOS (2)", onde o valor entre parênteses representa a quantidade de equipamentos selecionadas para exclusão.'
              )}
            </p>
            <img
              src={viewAllDevicesWithTwoChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos equipamentos, com dois equipamentos selecionados'
              )}
            />
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>{t('OBS 2:')}</span>{' '}
              {t(
                'ao excluir um equipamento este será totalmente desvinculado do local e ambiente, deixará de ser monitorado pelo InMaster e voltará para o padrão de fábrica.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <span className={classNames(['text-bold'])}>
                {t('Importante:')}
              </span>{' '}
              {t(
                'por questão de segurança, um equipamento só pode ser adotado em um único local/ambiente do usuário. Para que este equipamento possa ser utilizado em uma outra conta ou local, ele deve ser obrigatoriamente excluído do ambiente ao qual está adotado'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Wireless'),
    items: [
      {
        title: t('Como criar uma nova wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Acesse o local e o ambiente desejado, clique em "WIRELESS" e acesse a opção para criar uma nova wireless. Se não existir nenhuma rede wireless neste ambiente, a tela abaixo aparecerá. Para criar uma rede, deve-se clicar no botão de "+" em adicionar wireless.'
              )}
            </p>
            <img
              src={viewWirelessListEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela vázia da visualização dos wireless')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após isso, uma nova tela aparecerá com um formulário com os campos de nome, segurança e senha. A opção de dualband e duas abas: uma de configurações avançadas e outra de equipamentos.'
              )}
            </p>
            <img
              src={createNewWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela do formulário de criação de um novo wireless'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Nesta tela, deve-se obrigatoriamente preencher os campos de Nome e Senha. No campo de segurança da wireless há três opções: WPA2-PSK, WPA-PSK e Aberta. Caso seja escolhida a segurança aberta, o campo de senha desaparecerá.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Se o equipamento for dual band, há a possibilidade de escolher uma banda única para aquela rede, basta desativar o seletor de banda, Dual band, que fica abaixo do campo de senha, que duas opções aparecem, a de 2.4 GHz e de 5 GHz, selecionando uma dessas opções a rede criada irá transmitir o sinal apenas na frequência escolhida.'
              )}
            </p>
            <div
              className={classNames(['fit-width d-flex justify-center mt-4'])}>
              <img
                src={selectorFrequency}
                width="30%"
                alt={t('Imagem do seletor da frequência do wireless')}
              />
            </div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Então, basta clicar no botão "ADICIONAR WIRELESS" em verde que a rede será criada no ambiente. Só podem ser criadas 8 redes wireless por ambiente.'
              )}
            </p>
          </div>
        )
      },
      {
        title: t('Como associar equipamentos a uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Na mesma tela de criação da rede wireless, ao clicar na aba "EQUIPAMENTOS", uma lista contendo os equipamentos cadastrados no ambiente aparecerá, basta selecionar o marcador localizado a esquerda do nome da rede e clicar no botão de adicionar wireless.'
              )}
            </p>
            <img
              src={viewNewWirelessWithDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de criação de uma wireless na aba de equipamentos com um selecionado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                "Um equipamento pode ser associado depois da criação da rede. Para isso, deve-se estar na tela de listagem de wireless e clicar no botão em formato de lápis a direita da rede, na coluna 'Ações'."
              )}
            </p>
            <img
              src={viewWirelessListWithEditButton}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, com o botão de editar destacado'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Logo um formulário semelhante ao de criação de wireless aparecerá. Deve-se, então, clicar em "EQUIPAMENTOS", selecionar o equipamento desejado e após clicar no botão "EDITAR WIRELESS" em verde.'
              )}
            </p>
            <img
              src={viewWirelessWithDevices}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de equipamentos com um selecionado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar a sensibilidade dos clientes?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'A sensibilidade determina o limite de sensibilidade em que ocorre a desconexão do cliente.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithSensitivity}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de sensibilidade de clientes destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como ocultar uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Ocultar rede wireless significa ocultar a visibilidade da rede, ou seja, deixar a rede oculta, com isso aumentando a privacidade e segurança.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithHide}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de ocultar wireless destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como isolar uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Isolar uma rede wireless é uma forma de aumentar a a privacidade e segurança da rede. Existem duas possibilidades de isolamento que podem ser ativadas juntas: isolar clientes e somente internet. Na configuração de isolar clientes o equipamento impede que clientes conectados dentro da mesma wireless possam se comunicar entre si.  Na opção de somente internet, os clientes conectados na rede somente acessarão a internet, não podendo acessar domínios de dentro da rede local.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithIsolateOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de opções de isolamento destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar uma VLAN?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Virtual Local Area Network (VLAN) é uma rede independente que se comunica com a rede física funcionando como uma forma de separação lógica para melhorar a administração, segurança e desempenho. Serve para segmentar e isolar as redes. Ao habilitar a VLAN, deve-se inserir um valor entre 1 e 4096 que se refere ao id da VLAN.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithVLAN}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de VLAN destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o limite de banda?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Os limites de banda servem para definir os limites de download e/ou upload em Mbps para os clientes na rede. Caso desabilitado, a banda da rede será ilimitada.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'A funcionalidade é configurada no formulário de criação/edição de rede wireless na aba de "CONFIGURAÇÕES AVANÇADAS". Após configurar, deve-se apertar no botão "ADICIONAR"/"EDITAR" em verde para salvar a configuração.'
              )}
            </p>
            <img
              src={viewWirelessWithQoS}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de edição de uma wireless na aba de configurações avançadas com o campo de limite de banda destacado'
              )}
            />
            <img
              src={fieldQoSEnable}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do campo de limite de banda habilitado')}
            />
          </div>
        )
      },
      {
        title: t('Como excluir uma wireless?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Dentro do ambiente que possui a rede wireless que deseja excluir, clique em "WIRELESS" no menu lateral esquerdo e, após, selecione o checkbox da rede a ser excluída.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Com o checkbox selecionado, o botão "EXCLUIR  WIRELESS (1)" aparecerá na tela, clicando-o uma janela de confirmação abrirá e para efetuar a exclusão basta apertar no botão verde "EXCLUIR".'
              )}
            </p>
            <img
              src={viewWirelessListWithOneChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, no qual está selecionada uma wireless'
              )}
            />
            <img
              src={modalDeleteOneWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do modal de exclusão de uma wireless')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Também é possível excluir várias redes de uma vez, basta selecionar múltiplos checkbox e apertar no botão vermelho "EXCLUIR WIRELESS (2)", o valor entre parênteses representa a quantidade de redes selecionadas, para excluir e depois no botão de "EXCLUIR" na janela de confirmação.'
              )}
            </p>
            <img
              src={viewWirelessListWithTwoChecked}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização das wireless, no qual estão selecionadas duas wireless'
              )}
            />
            <img
              src={modalDeleteTwoWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem do modal de exclusão de duas ou mais wireless')}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Roaming?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Com o Roaming, o equipamento irá sugerir a transição para os dispositivos quando for conveniente. Opera somente em redes com criptografia WPA (WPA - PSK) ou WPA2 (WPA2 - PSK) e requer que o dispositivo do cliente tenha suporte a este protocolo.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithRoaming}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de roaming está destacada'
              )}
            />
            <img
              src={viewAdvancedWirelessWithButtonSave}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual o botão salvar está destacado'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Band Steering?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                "Com o Band Steering o equipamento irá escolher a melhor frequência para o cliente baseado em métricas de rede e qualidade de sinal. Ocorre apenas entre redes wireless de mesmo nome. Há duas opções: 'balanceamento de clientes entre frequências' e 'preferencialmente 5 GHz'. Na opção de balanceamento, o equipamento irá realizar o balanceamento de carga entre as frequências. Na opção de preferencialmente 5 GHz, os clientes com suporte 802.11ac terão preferência para conexão em 5 GHz."
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithBandSteering}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de band steering está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Beamforming?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O Beamforming garante que o sinal do WI-FI seja transmitido para dispositivos específicos dos clientes, evitando perdas durante a transmissão e melhorando o nível do sinal na área de 360º ao redor do equipamento.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithBeamforming}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de beamforming está destacada'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como configurar o Multi-User MIMO (MU-MIMO)?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'O MU-MIMO garante maior performance aos clientes conectados na rede, realizando múltiplas transmissões de dados aos clientes simultaneamente. A funcionalidade requer que o dispositivo do cliente tenha suporte a esta tecnologia. Para o MU-MIMO ser ativado é necessário que o Beamforming também esteja ativo.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para configurar esta funcionalidade, deve-se acessar o ambiente no qual deseja a configuração e abrir a tela de "OPÇÕES AVANÇADAS", acessível via menu lateral. Após a configuração, apertar no botão "SALVAR" em verde, que aparecerá abaixo das opções da tela.'
              )}
            </p>
            <img
              src={viewAdvancedWirelessWithMUMIMO}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de opções avançadas de wireless, no qual a opção de mu-mimo está destacada'
              )}
            />
          </div>
        )
      }
    ]
  },
  {
    section: t('Clientes'),
    items: [
      {
        title: t('Como verificar os clientes conectados?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Clientes conectados são as atuais conexões da rede wireless, ou seja, os dispositivos que estão conectados a certa rede wireless naquele instante de tempo com atualização dos dados em até 30 segundos.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para verificar os clientes conectados, deve-se acessar o ambiente no qual há as redes wireless que se deseja saber as atuais conexões e clicar no submenu de "CLIENTES CONECTADOS", do menu "CLIENTES" presente no menu lateral à esquerda da tela. Como indicado na imagem abaixo:'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Se há um ou mais clientes conectados, a tela abaixo é mostrada com os dados de MAC do cliente, o equipamento ao qual ele está conectado, a rede wireless, a frequência da rede, o tempo de conexão, a potência do sinal e quanto de dados foram consumidos em download e upload.'
              )}
            </p>
            <img
              src={viewConnectedClients}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de visualização dos clientes conectados')}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se naquele instante não houver nenhum cliente conectado, a seguinte tela é mostrada ao usuário:'
              )}
            </p>
            <img
              src={viewConnectedClientsEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos clientes conectados vazio'
              )}
            />
          </div>
        )
      },
      {
        title: t('Como acessar o registro de conexões?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Os registros de conexões são os dados da conexão completa do cliente naquela rede wireless, ou seja, o registro só é feito após a desconexão do cliente na rede. Os registros são agrupados por local, portanto todas as conexões registradas nas redes presentes nos ambientes de um local serão mostradas na mesma tela.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para acessar a tela de registro de conexões, deve-se estar na tela de locais e clicar no menu em forma de reticências, como mostrado em marrom na imagem abaixo. Após, clicar em "RESGISTRO DE CONEXÕES".'
              )}
            </p>
            <img
              src={siteWithMenuOpenOnConnectionsLog}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de locais no qual a opção de registro de conexão está destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se não foi registrada nenhuma conexão nas redes wireless presentes nos ambientes desse local, a tela abaixo é mostrada ao cliente:'
              )}
            </p>
            <img
              src={viewConnectionsLogEmpty}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos registro de conexão vazia'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Se há um ou mais registros, a tela abaixo é mostrada ao usuário com os dados de IP do cliente, MAC do cliente, o nome do cliente, o ambiente da rede wireless que houve a conexão, o MAC do equipamento associado a essa rede wireless, data e hora de início e fim da conexão, e, também um botão para realizar o download do dados.'
              )}
            </p>
            <img
              src={viewConnectionsLog}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da tela de visualização dos registros de conexão')}
            />
          </div>
        )
      },
      {
        title: t('Como exportar os registros de conexões?'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Para exportar os registros de conexões é necessário clicar no botão "DOWNLOAD DOS DADOS" para baixar um arquivo csv contendo todas as informações presentes em tela.'
              )}
            </p>
            <img
              src={viewConnectionsLogWithDownloadOption}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Imagem da tela de visualização dos registros de conexão, com a opção de download destacada'
              )}
            />
            <p className={classNames(['mt-4'])}>
              {t(
                'Após clicar no botão, uma mensagem irá aparecer na tela durante o download do arquivo exportado.'
              )}
            </p>
            <img
              src={viewConnectionsLogWithLoadingMessage}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Imagem da mensagem de aviso que irá baixar os registro')}
            />
          </div>
        )
      }
    ]
  },
  {
    section: t('Captive Portal: Métodos de configuração'),
    items: [
      {
        title: t('Introdução aos Métodos de Configuração'),
        content: (
          <div>
            <p className={classNames(['mt-4'])}>
              {t(
                'Captive Portal é o modo de autenticação de usuários na rede e este pode ser gerenciado e configurado para que o usuário se conecte a rede wi-fi de acordo com o tipo e as definições estabelecidas. Nesta parte do manual é possível '
              )}
              <Strong>{t('Configurar o Método ')}</Strong>
              {t('escolhido (')}
              <Strong>{t('Senha Simples ou Cadastro Individual')}</Strong>
              {t('), personalizá-lo em ')}
              <Strong>{t('Página do Captive')}</Strong>
              {t(', e gerenciar as redes ')}
              <Strong>{t('Wireless Vinculadas')}</Strong>
              {t(' ao método.')}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'Ao configurar o Captive Portal, você pode escolher um dentre os dois métodos de configuração possíveis: Senha Simples ou Cadastro Individual.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'No método de Senha Simples, você configura as redes para que os usuários utilizem uma única senha de acesso (por ambiente) de acordo com as autorizações configuradas neste método. Dessa forma, ocorre a gestão de uma senha única como método de autenticação para todos seus usuários.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              {t(
                'No método de Cadastro Individual, você pode criar diversos cadastros com acessos distintos configurados. Neste método, ocorre a criação de cadastros individuais detalhados como método de autenticação e gerência do acesso à rede.'
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <Strong>{t('Importante:')}</Strong>{' '}
              {t(
                'os equipamentos da linha AC precisam estar no firmware {{minimumVersionV3}} em diante. Já os equipamentos da linha AX precisam estar no {{minimumVersionV4}} em diante. Se algum equipamento estiver com uma versão anterior, o captive não poderá ser ativado.',
                {
                  minimumVersionV3: MIN_FIRMWARE_CAPTIVE_V3,
                  minimumVersionV4: MIN_FIRMWARE_CAPTIVE_V4
                }
              )}
            </p>
            <p className={classNames(['mt-4'])}>
              <Strong>{t('Importante:')}</Strong>{' '}
              {t(
                'após a ativação do captive, não será possível adicionar nenhum equipamento do tipo AX ou da linha AC com versão inferior às mencionadas.'
              )}
            </p>
          </div>
        )
      }
    ]
  },
  {
    section: t('Captive Portal: Senha Simples'),
    items: [
      {
        title: t('Ativar Senha Simples'),
        content: (
          <div>
            <Paragraph>
              {t('Ao acessar pela primeira vez o ')}
              <em>{t('menu')}</em>
              {t(
                ' de Captive Portal, o método de senha simples apresenta-se desativado e para ativá-lo é necessário criar uma senha primeiro. Para que seja possível ativar o Captive Portal, é necessário ter uma rede wireless ativa no ambiente. Caso não haja, o sistema apresenta uma mensagem informando esta necessidade e apenas habilita a opção para definir as senhas simples quando esse pré-requisito for resolvido.'
              )}
            </Paragraph>
            <img
              src={captiveMethodConfigScreen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela com os métodos do captive bloqueada, não há redes')}
            />
            <Paragraph>
              {t(
                'Após a ativação de uma rede wireless, note que as abas “Página do Captive” e “Wireless Vinculadas” estão desabilitadas para acesso. Somente após a ativação do método, as abas ficam disponíveis. No '
              )}
              <em>{t('card')}</em>
              {t(
                ' de configuração do Captive Senha Simples, clique em “Definir”.'
              )}
            </Paragraph>
            <img
              src={captiveWithoutLockConfigScreen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela com os métodos do captive desbloqueada, há redes criadas'
              )}
            />
            <Paragraph>
              {t(
                'Após acessar o método de senha simples, o sistema pede para confirmar e informa que ao continuar, você irá passar por 3 etapas para ativar este método de autenticação para todas as wireless deste ambiente.'
              )}
            </Paragraph>
            <img
              src={captiveMethodConfirmModal}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Modal após selecionar o método senha simples, informando que irá entrar na configuração do Wizard'
              )}
            />
            <Paragraph>
              {t(
                'Ao confirmar, o sistema inicia o processo, apresentando a etapa 1 de configuração, informando que para acesso simples e rápido dos usuários à rede sem fio, deve-se definir uma senha padrão e determinar o tempo máximo de uso dos usuários conectados nessa rede.'
              )}
            </Paragraph>
            <img
              src={simplePasswordWizardFirstStep}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela do primeiro passo do Wizard')}
            />
            <Paragraph>
              {t(
                'Deve-se informar uma senha válida, confirmar esta senha no campo seguinte, escolher o período de duração (dias ou horas) e informar o tempo de duração de validade desta ativação.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'A senha simples pode ter entre 8 e 63 caracteres e possuir apenas caracteres de Aa-Zz; 1-9. Caracteres especiais no padrão ASCII são permitidos também.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'O período de duração indica o tempo que a senha estará válida, ou seja, o período em que será usada aquela senha para se autenticar no Captive, podendo ser de dois tipos: em horas ou em dias. O período em horas é uma senha que vai durar menos de 24 horas, pode-se escolher um tempo de duração entre 5 minutos até 23h e 55 min, com intervalos de 5 em 5 minutos. O período em dias é uma senha que vai durar mais de 24 horas. No caso, se escolhe um intervalo de dias que se inicia na data e hora atual e vai até a data final selecionada.'
              )}
            </Paragraph>
            <img
              src={simplePasswordDurationForm}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela da seleção de duração da senha no Wizard')}
            />
            <Paragraph>
              {t(
                'Após preencher os campos de senha e selecionar o período de duração, basta clicar em continuar. O período de duração da senha é iniciado no momento que o método é ativado. Por exemplo, se escolher no calendário a data de início sendo 7/2 e a data final sendo 13/2 e clicar em salvar às 9h, o intervalo de duração da senha é de 6 dias iniciando às 9h do dia 7/2 e finalizando às 9h do dia 13/2.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao confirmar, o sistema passará para a etapa 2, que personaliza a página do Captive. Nesta etapa, o usuário poderá manter a imagem exemplo e textos para compor uma página padrão ou fazer mudanças na imagem de cabeçalho, título da página, texto de boas vindas, personalizando a página do Captive Portal de acordo com sua necessidade. Você poderá, também, definir a página de redirecionamento padrão após a efetivação do login no Captive Portal. O sistema apresenta um esboço de como a página será montada, considerando as personalizações feitas.'
              )}
            </Paragraph>
            <img
              src={simplePasswordWizardSecondStep}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela do segundo passo do Wizard')}
            />
            <Paragraph>
              {t(
                'Após realizar a personalização, ou não, você poderá cancelar a operação, retornar para a etapa anterior ou continuar para a etapa 3. Ao continuar o processo, o sistema pede para que vincule as redes wireless deste ambiente ao método de autenticação Captive Portal, para alterar a segurança das redes selecionadas. É necessário que pelo menos uma rede wireless seja vinculada.'
              )}
            </Paragraph>
            <img
              src={simplePasswordWizardThirdStep}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela do terceiro passo do Wizard')}
            />
            <Paragraph>
              {t(
                'Feita as escolhas das redes wireless vinculadas, deve-se concluir a etapa 3. O sistema informa que o Captive Portal Senha Simples será aplicado nos equipamentos do ambiente configurado e que enquanto este processo não for concluído, não será possível alterar as configurações deste método.'
              )}
            </Paragraph>
            <img
              src={modalConfirmSimplePasswordWizard}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Modal de confirmação de aplicação do senha simples')}
            />
            <Paragraph>
              {t(
                'Deve-se optar por continuar o processo. Feito isso, o sistema retornará para a tela de Captive Portal, com as abas de “Página do Captive e” e “Wireless Vinculadas” habilitadas para que possa ser possível reconfigurar o Captive novamente. Além disso, o card do método de Senha Simples deverá conter um indicativo que está ativo.'
              )}
            </Paragraph>
            <img
              src={activatedSimplePasswordAtConfigMethod}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Card do senha simples ativo, após a configuração do wizard'
              )}
            />
            <Paragraph>
              {t(
                'Ao fim deste processo, uma notificação irá aparecer na central de notificações, informando que a configuração está sendo aplicada nos equipamentos do ambiente.'
              )}
            </Paragraph>
            <img
              src={notificationDrawerAfterSimplePasswordActivate}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Drawer de notificação após a aplicação do captive senha simples'
              )}
            />
            <Paragraph>
              <Strong>{t('OBS:')} </Strong>
              {t(
                'só aparecerá a notificação caso algum equipamento esteja com wireless vinculada.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao término da aplicação da configuração, as redes wireless vinculadas aos equipamentos que estão no ambiente que foram vinculadas no momento da ativação, tornam-se redes do tipo captive. Ao tentar acessar as redes pelo dispositivo, uma janela irá se abrir com um formulário para inserir a senha simples criada.'
              )}
            </Paragraph>
            <img
              src={captiveMicroFrontSimplePassword}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela de um celular tentando se conectar ao captive senha simples'
              )}
            />
            <Paragraph>
              {t(
                'No card de ambiente, há uma notação que mostra que o captive está ativo:'
              )}
            </Paragraph>
            <img
              src={placeCardCaptiveActivated}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Indicativo de que o captive está ativo')}
            />
          </div>
        )
      },
      {
        title: t('Desautorização de Clientes'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Após um cliente se conectar em alguma rede wireless do tipo captive que esteja vinculada a algum equipamento, o registro de cliente conectado irá aparecer na página de clientes conectados.'
              )}
            </Paragraph>
            <img
              src={connectedUserToCaptive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Registro de cliente conectado')}
            />
            <Paragraph>
              {t(
                'A desautorização dos clientes conectados ocorre automaticamente de duas formas: ao desativar o método ou após expirar a senha simples em uso. Dessas duas formas, todos os clientes serão desconectados e seus registros de conexões serão gerados.'
              )}
            </Paragraph>
            <img
              src={connectionLogCaptiveUser}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Processo de desautorização de clientes')}
            />
          </div>
        )
      },
      {
        title: t('Excluir Senha Simples do Histórico'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Após a senha em uso expirar, ela irá para o histórico de senhas. O histórico fica dentro da página do método de captive.'
              )}
            </Paragraph>
            <Paragraph>
              <Strong>{t('Importante:')}</Strong>{' '}
              {t('o histórico só guarda as últimas 10 senhas.')}
            </Paragraph>
            <img
              src={simplePasswordHistory}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Histórico de senhas no método Captive')}
            />
            <Paragraph>
              {t(
                'Pode-se excluir uma senha do histórico ao selecionar o checkbox correspondente da senha, clicar no botão de “EXCLUIR” e depois confirmar no modal de confirmação. Pode-se selecionar várias senhas do histórico de uma vez para uma exclusão em massa.'
              )}
            </Paragraph>
            <img
              src={simplePasswordHistoryDelete}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Processo de exclusão de senhas do histórico')}
            />
          </div>
        )
      },
      {
        title: t('Renovar Senhas Simples'),
        content: (
          <div>
            <Paragraph>
              {t(
                'As senhas que estão no histórico podem ser renovadas a qualquer momento. Ou seja, ao renovar uma senha, esta senha volta a ficar em uso com a mesma configuração de antes. Portanto, se uma senha foi configurada para demorar dois dias, ao renová-la terá uma duração de dois dias contando a partir do instante que é renovada.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Para renovar uma senha, basta selecionar o checkbox corresponde, clicar no botão de “RENOVAR” e depois confirmar no modal.'
              )}
            </Paragraph>
            <img
              src={simplePasswordHistoryRenew}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Processo de renovação de senha')}
            />
            <Paragraph>
              <Strong>{t('OBS:')}</Strong>{' '}
              {t('só se pode renovar uma senha por vez.')}
            </Paragraph>
            <Paragraph>
              <Strong>{t('Importante')}:</Strong>{' '}
              {t(
                'se já há uma senha em uso, a senha renovada irá sobrepor a atual e a senha em uso irá para o histórico.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Sobrepor Senha Simples'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Pode-se a qualquer momento sobrepor uma senha em uso, ou seja, alterar a senha sem que ela tenha expirado ainda. Como explicado na seção acima, uma das maneiras de sobrepor uma senha é renovando alguma senha do histórico. Porém, outra forma é adicionando uma senha nova. Para isso, basta clicar no sinal de “+” do botão de “ADICIONAR NOVA”. O drawer de criação de senha aparecerá no lado direito da tela.'
              )}
            </Paragraph>
            <img
              src={simplePasswordMethodActivate}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela de detalhes de senha simples')}
            />
            <img
              src={drawerCreateSimplePassword}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Drawer de criação de senha')}
            />
            <Paragraph>
              {t(
                'Após preencher os campos e clicar em salvar, um modal de confirmação irá abrir informando que existe uma senha em uso e perguntando se deseja substituir a senha. Para substituir basta clicar em “SIM”.'
              )}
            </Paragraph>
            <img
              src={modalSimplePasswordAlreadyExists}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Modal de confirmação de substituição de senha')}
            />
            <Paragraph>
              {t(
                'A senha criada passa a ser a senha em uso e a senha anterior vai para o histórico.'
              )}
            </Paragraph>
            <img
              src={simplePasswordScreenAfterCreateNewPassword}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Senha anterior indo para o histórico')}
            />
            <Paragraph>
              <Strong>{t('Importante:')}</Strong>{' '}
              {t(
                'ao sobrepor uma senha, seja criando uma nova ou renovando uma do histórico, os clientes conectados permanecem conectados e autorizados. Ou seja, a sobreposição de senha NÃO desautoriza nenhum cliente conectado. O período de duração passa a ser a da senha em uso. Exemplo: se antes tinha uma senha em uso com período de duração de dois dias e foi sobreposta por uma senha de 15 minutos, os clientes que já estavam conectados, permanecem conectados e autorizados, porém o tempo que ficarão autorizados foi reduzido para 15 minutos agora.'
              )}
            </Paragraph>
          </div>
        )
      }
    ]
  },
  {
    section: t('Captive Portal: Cadastro Individual'),
    items: [
      {
        title: t('Ativar cadastro individual'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Ao acessar pela primeira vez, ou quando não houver Captive Portal ativado, o método de cadastro individual estará desativado. Para definir o Cadastro Individual como o método de autenticação do Captive Portal, deve-se clicar em “Definir” no respectivo card e o sistema irá pedir para confirmar o processo. Ao continuar, o usuário irá passar por 3 etapas para ativar este método de autenticação para todas as wireless deste ambiente.'
              )}
            </Paragraph>
            <img
              src={individualRegistrationDefinition}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Definição de Cadastro Individual')}
            />
            <Paragraph>
              {t(
                'A criação do cadastro individual passa por 3 etapas. Na primeira etapa, você deve adicionar um novo cadastro individual. Este método é indicado para quem deseja cadastrar e identificar todos os clientes conectados à rede, de forma a autorizar e desautorizar seus acessos, delimitando o período de uso de forma individual.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao escolher a opção para adicionar um novo cadastro individual, o sistema pede para informar os dados do cliente: Nº de documento, Nome, E-mail e Telefone.'
              )}
            </Paragraph>
            <img
              src={newIndividualRegistrationForm}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Formulário de novo cadastro individual')}
            />
            <Paragraph>
              {t(
                'Também pode-se escolher se o cliente deve ter acesso à internet e quando este acesso expira. O período de expiração pode ser dado em dias ou horas. Para definir essa etapa, clique em “acesso autorizado”.'
              )}
            </Paragraph>
            <img
              src={clientAccessAndExpirationDefinition}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Definição de acesso e expiração para o cliente')}
            />
            <img
              src={individualRegistrationExpirationDateSelection}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Seleção de data de expiração de cadastro individual')}
            />
            <Paragraph>
              {t(
                'Após escolher o tempo irá aparecer uma estimativa da duração na parte de “previsão de expiração”.'
              )}
            </Paragraph>
            <img
              src={accessExpirationForecast}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Previsão de expiração do acesso')}
            />
            <Paragraph>
              {t(
                'Os cadastros individuais possuem sua data de validade contabilizada a partir do momento em que a ativação do método estiver finalizada. Ao finalizar o cadastro, o usuário passará a fazer parte da lista de cadastros.'
              )}
            </Paragraph>
            <img
              src={tableOfCreatedIndividualRegistrations}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tabela com cadastros individuais criados')}
            />
            <Paragraph>
              {t(
                'Neste momento, você pode visualizar as informações do cadastro, realizar alterações no cadastro, cancelar todo o processo ou continuar para a etapa 2. Para visualizar as informações do cadastro, basta acionar a ação para “Mais informações”. Para realizar alterações, basta acionar a ação expressa na imagem de um lápis (o mesmo comportamento ocorre na tela de “Mais informações” do cadastro).'
              )}
            </Paragraph>
            <img
              src={moreInformationAndChangesOptionsInRegistration}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Opções de mais informações e alterações no cadastro')}
            />
            <Paragraph>
              {t(
                'Ao escolher continuar, o sistema passa para a etapa 2 da configuração do método. Nesta etapa, pode-se personalizar a página do Captive Portal. Assim, poderá manter a imagem exemplo e textos para compor uma página padrão ou fazer mudanças na imagem de cabeçalho, título da página e texto de boas vindas, personalizando a página do Captive Portal de acordo com sua necessidade. Poderá, também, definir a página de redirecionamento padrão após a efetivação do login no Captive Portal. O sistema apresenta um esboço de como a página será montada, considerando as personalizações feitas.'
              )}
            </Paragraph>
            <img
              src={captivePortalPageCustomization}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Personalização da página do Captive Portal')}
            />
            <Paragraph>
              {t(
                'Após realizar a personalização, ou não, você poderá cancelar a operação, retornar para a etapa anterior ou continuar para a etapa 3. Ao continuar o processo, o sistema pede para que vincule as redes wireless deste ambiente ao método de autenticação Captive Portal, para alterar a segurança das redes selecionadas. É necessário que pelo menos uma wireless seja vinculada.'
              )}
            </Paragraph>
            <img
              src={wirelessChoicesWizard}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Escolha das redes wireless no wizard de cadastro individual'
              )}
            />
            <Paragraph>
              {t(
                'Feita as escolhas das redes wireless vinculadas, deve-se concluir a etapa 3. O sistema informa que o Captive Portal Cadastro Individual será aplicado nos equipamentos do ambiente configurado e que enquanto este processo não for concluído, não será possível alterar as configurações deste método.'
              )}
            </Paragraph>
            <img
              src={captivePortalIndividualRegistrationConfigurationFinalization}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Finalização da configuração do Captive Portal Cadastro Individual'
              )}
            />
            <Paragraph>
              {t(
                'Deve-se optar por continuar o processo. Feito isso, o sistema deve retornar para a tela de Captive Portal, com as abas de “Página do Captive” e “Wireless Vinculadas” habilitadas para que possa ser possível reconfigurar todo o captive novamente. Além disso, o card do método de Cadastro Individual deverá conter um indicativo que está ativo.'
              )}
            </Paragraph>
            <img
              src={individualRegistrationActiveInConfigureMethodScreen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Cadastro individual ativo na tela de configurar método')}
            />
            <Paragraph>
              {t(
                'Ao fim deste processo, uma notificação irá aparecer na central de notificações, informando que a configuração está sendo aplicada nos equipamentos do ambiente.'
              )}
            </Paragraph>
            <img
              src={configurationApplicationNotification}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Notificação de aplicação da configuração')}
            />
            <Paragraph>
              <Strong>{t('OBS:')} </Strong>
              {t(
                'só aparecerá a notificação caso algum equipamento esteja com wireless vinculada.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao término da aplicação da configuração, as redes wireless vinculadas aos equipamentos que estão no ambiente que foram vinculadas no momento da ativação, tornam-se redes do tipo captive. Ao tentar acessar as redes pelo dispositivo, uma janela irá se abrir com um formulário para inserir o cadastro individual.'
              )}
            </Paragraph>
            <img
              src={formToInsertIndividualRegistrationAfterAccessing}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Formulário para inserir o cadastro individual após acessar'
              )}
            />
            <Paragraph>
              {t(
                'No card de ambiente, há uma notação que mostra que o captive está ativo:'
              )}
            </Paragraph>
            <img
              src={activeCaptiveIndicationInEnvironmentCard}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Indicação de Captive ativo no card de ambiente')}
            />
          </div>
        )
      },
      {
        title: t('Gerenciar o Método de Configuração'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Quando há um Captive Portal ativo no método de configuração de Cadastro Individual, ao entrar na tela de configuração do Captive Portal, tem acesso às opções de desativar o Captive Portal, trocar para outro método de configuração ou gerenciar o método ativo de Cadastro Individual.'
              )}
            </Paragraph>
            <img
              src={captiveMethodsWithIndividualRecordActive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Opções de gerenciamento do Cadastro Individual')}
            />
            <Paragraph>
              {t(
                'Para gerenciar o Cadastro Individual, deve-se clicar em “Gerenciar” no respectivo card. Ao fazer isso, o sistema apresentará uma tela com a lista de cadastros ativos e expirados. Você pode pesquisar por um cadastro, informando número de documento, nome, telefone ou e-mail. Além disso, o usuário pode adicionar novos cadastros, consultar detalhes das informações dos cadastros existentes, excluir, renovar ou alterar informações desses cadastros.'
              )}
            </Paragraph>
            <img
              src={captiveIndividualRecordManagement}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Interface de gerenciamento de Cadastro Individual')}
            />
            <Paragraph>
              {t(
                'A pesquisa por cadastros é feita através de um filtro que utiliza a informação colocada dentro da caixa para “Pesquisar na lista:”. Ao entrar com parte do texto do número do documento, nome, telefone ou email, o sistema realiza uma busca filtrando os resultados considerando o texto colocado. Caso a caixa esteja vazia, o sistema apresentará todos os registros.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Adicionar novos cadastros'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Para adicionar novos cadastros, deve-se acionar a opção de “Adicionar Novo” na aba de cadastro ativo e preencher os dados do novo cadastro a ser adicionado: número de documento, nome, e-mail, telefone e a configuração da autorização.'
              )}
            </Paragraph>
            <img
              src={formOfNewIndividualRecords}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Formulário para adicionar novos cadastros')}
            />
          </div>
        )
      },
      {
        title: t('Remover cadastros ativos ou expirados'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Para remover um ou mais cadastros, o usuário deve selecionar o(s) cadastro(s) e acionar a opção para Excluir.'
              )}
            </Paragraph>
            <img
              src={processToRemoveIndividualRecords}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Processo de exclusão de cadastros')}
            />
            <Paragraph>
              {t(
                'O sistema irá pedir para confirmar a operação. Ao confirmar, o cadastro será excluído. Esta função está presente tanto para os cadastros ativos como os expirados.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Visualizar detalhes do cadastro ativo ou expirado'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Para visualizar os detalhes de um cadastro ativo ou expirado, aciona-se a ação de “Mais informações” presente na coluna de “ações” ao lado de cada cadastro. Ao fazer isso o sistema irá apresentar os detalhes das informações do cadastro em questão: nome, número de documento, telefone, e-mail, se o cadastro possui autorização ou não, o status, a data e hora do início da autorização, o tempo da autorização, a data e hora de expiração da autorização e a data e hora de criação deste cadastro.'
              )}
            </Paragraph>
            <img
              src={detailsOfOneIndividualRecord}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Detalhes do cadastro')}
            />
          </div>
        )
      },
      {
        title: t('Editar cadastros ativos'),
        content: (
          <div>
            <Paragraph>
              {t(
                'A opção para edição de um cadastro está disponível apenas para cadastros ativos. Para editar um cadastro ativo, aciona-se a ação de edição (“lápis”) presente ao lado de cada cadastro, ou na tela de “Mais informações” do cadastro.'
              )}
            </Paragraph>
            <img
              src={formEditOneIndividualRecord}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela de edição de cadastro ativo')}
            />
            <Paragraph>
              {t(
                'Ao acionar a opção de edição, o sistema apresenta a tela com os dados do cadastro, permitindo que o usuário modifique seu número de documento, nome, e-mail, telefone, autorização e o período de expiração desta autorização. Após as mudanças, o usuário deve acionar a opção “Salvar” para efetivar as mudanças.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Renovar cadastros ativos ou expirados'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Ao renovar um cadastro, sendo este ativo ou expirado, a autorização do cadastro será restaurada de acordo com a configuração de dias ou horas que foi estabelecida anteriormente. Para realizar esta operação, deve-se selecionar o cadastro, ativo ou expirado na tabela, e acionar a opção “Renovar”.'
              )}
            </Paragraph>
            <img
              src={modalWithQtdOfIndividualRecordsToRenew}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Processo de renovação de cadastro')}
            />
            <Paragraph>
              {t(
                'O sistema apresenta uma tela pedindo para confirmar a operação. Ao confirmar, a autorização do cadastro é restaurada de acordo com a configuração de dias ou horas que foi estabelecida previamente.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Você também pode acionar a opção de “Renovar expirados” para renovar todos os cadastros presentes na aba de expirados. Feito isso, o sistema apresentará um modal para confirmar a ação.'
              )}
            </Paragraph>
            <img
              src={modalToConfirmRenewIndividualRecords}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Modal de confirmação para renovação de cadastros expirados'
              )}
            />
            <Paragraph>
              {t(
                'Ao renovar, eles são restaurados de acordo com as configurações que foram estabelecidas anteriormente. Esta ação não pode ser desfeita.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Limpar cadastros disponíveis'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Pode-se optar por limpar todos os cadastros ativos com status de disponível. Para isso, deve-se acionar a opção presente nos três pontinhos, “Limpar disponíveis”. Ao fazer isso, o sistema irá apresentar uma tela de confirmação.'
              )}
            </Paragraph>
            <img
              src={modalConfirmDeleteIndividualRecordAvailable}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela de confirmação para limpar cadastros disponíveis')}
            />
            <Paragraph>
              {t(
                'Ao confirmar esta operação, todos os cadastros disponíveis serão excluídos.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Limpar cadastros expirados'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Pode-se optar por limpar todos os cadastros expirados. Para isso, deve-se acionar a opção presente nos três pontinhos, “Limpar expirados”. Ao fazer isso, o sistema irá apresentar uma tela de confirmação.'
              )}
            </Paragraph>
            <img
              src={modalConfirmDeleteExpiredIndividualRecord}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela de confirmação para limpar cadastros expirados')}
            />
            <Paragraph>
              {t(
                'Ao confirmar esta operação, todos os cadastros expirados serão excluídos. Caso exista um ou mais cadastros em processamento, a exclusão não será imediata. Se necessário, é aconselhável reiniciar o equipamento para efetivar a operação.'
              )}
            </Paragraph>
          </div>
        )
      }
    ]
  },
  {
    section: t('Captive Portal: Configurações gerais'),
    items: [
      {
        title: t('Troca de Método'),
        content: (
          <div>
            <Paragraph>
              {t(
                'O sistema permite trocar os métodos de configuração do Captive Portal, de Senha Simples para Cadastro Individual e vice-versa. A opção de troca de métodos fica presente no método que se encontra não ativado. Para realizar esta operação basta o usuário acionar a opção de “Trocar”.'
              )}
            </Paragraph>
            <img
              src={captivePortalMethodChangeModal}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Troca de métodos no Captive Portal')}
            />
            <Paragraph>
              {t(
                'Após acionar esta opção, o sistema informa o método que será ativado, e que ao trocar de método, os clientes serão desconectados da rede. Ao confirmar, o sistema inicia o processo de configuração do método a ser ativado. Se durante o processo de ativação do novo método, se optar por cancelar o processo, o sistema retornará para o método antigo, cancelando toda a operação.'
              )}
            </Paragraph>
          </div>
        )
      },
      {
        title: t('Desativar Captive Portal'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Você pode optar por desativar o captive acionando a opção de “Desativar Captive” presente na tela principal de Captive Portal. Esta opção está ativa somente se houver algum método de configuração do captive ativo.'
              )}
            </Paragraph>
            <img
              src={modalConfirmDeactivateCaptive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Opção de desativar o Captive Portal')}
            />
            <Paragraph>
              {t(
                'Ao acionar esta opção, todas as wireless vinculadas deste ambiente retornam às suas seguranças anteriores, os clientes são desconectados da rede e este processo pode levar alguns minutos até ser concluído. Durante esse processo, não é possível fazer alterações no Captive Portal.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'O sistema alerta sobre essas informações, pede ciência e sua confirmação para prosseguir com o procedimento.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao prosseguir com o procedimento, o Captive é desativado. No card de ambiente aparecerá que o captive está inativo.'
              )}
            </Paragraph>
            <img
              src={placesCardWithCaptiveDisabled}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Indicação de Captive Portal inativo')}
            />
          </div>
        )
      },
      {
        title: t('Personalização da Página do Captive'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Ao ter algum método Captive ativo no ambiente, a aba de “Página do Captive” fica habilitada. Ao clicar nessa aba, tem-se um componente para edição do formulário que aparece para o cliente no momento que ele clica na rede wireless para se conectar. Essa personalização é a mesma feita no momento de ativação de um método captive, portanto esta tela refletirá o que foi personalizado na ativação.'
              )}
            </Paragraph>
            <img
              src={screenOfPersonalizeCaptivePortal}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Edição da página do Captive Portal')}
            />
            <Paragraph>
              {t(
                'Pode-se alterar a imagem que vem no formulário, o título, o texto de apresentação e a URL para onde o cliente será redirecionado ao se autenticar na rede. As mudanças são apresentadas em tempo real no modal de visualização que representa o formulário que aparece para o cliente.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Para alterar a imagem, clique em “REMOVER IMAGEM”, note que a imagem padrão desaparece, depois clique em “ADICIONAR IMAGEM”. Será aberto o modal para selecionar o arquivo do sistema (só são aceitos arquivos do tipo .jpg, .jpeg e .png com limite máximo de 100KB). Após o upload da imagem, um modal irá aparecer para que se possa configurar a parte desejada da imagem.'
              )}
            </Paragraph>
            <img
              src={modalCropImageCaptivePortal}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Alteração de imagem no formulário do Captive Portal')}
            />
            <Paragraph>
              {t('Após essa etapa, basta clicar em “SALVAR E VISUALIZAR”.')}
            </Paragraph>
            <Paragraph>
              {t(
                'Feitas as alterações nos outros campos do formulário, basta clicar em “SALVAR”. Um toast aparece informando que as configurações foram salvas.'
              )}
            </Paragraph>
            <img
              src={screenOfPersonalizeCaptivePortalWithNewImage}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Confirmação de salvamento das configurações')}
            />
            <Paragraph>
              {t(
                'A qualquer instante pode-se clicar em “RESTAURAR” para voltar para a configuração padrão do formulário.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Quando o cliente for se conectar a rede wireless do tipo captive, o formulário abrirá da forma configurada.'
              )}
            </Paragraph>
            <img
              src={captiveFrontendWithChangedImage}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Formulário configurado no Captive Portal')}
            />
          </div>
        )
      },
      {
        title: t('Alteração das Wireless Vinculadas no Captive'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Ao ter algum método Captive ativo no ambiente, a aba “Wireless Vinculadas” fica habilitada. Esta tela reflete o que foi modificado no momento da ativação do método Captive.'
              )}
            </Paragraph>
            <img
              src={screenOfVinculatedWirelessToCaptive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Tela de redes wireless vinculadas ao Captive')}
            />
            <Paragraph>
              {t(
                'Nesta aba irão aparecer todas as wireless do ambiente, as que estão em cinza e com checkbox marcado significa que estão vinculadas ao captive portal.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Para remover uma wireless do captive, basta desmarcá-la e clicar em “SALVAR” e depois em “SIM” no modal de confirmação. Lembrar que deve-se ter pelo menos uma wireless vinculada ao captive, portanto não será possível desvincular todas as wireless.'
              )}
            </Paragraph>
            <img
              src={screenOfVinculatedWirelessToCaptiveWithSelectedWireless}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela de redes wireless vinculadas ao Captive com redes selecionadas'
              )}
            />
            <img
              src={modalChangesWirelessAssociatedToCaptive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Modal de alterações em redes wireless associadas ao Captive'
              )}
            />
            <Paragraph>
              {t(
                'Os equipamentos serão configurados e os clientes que estavam conectados às redes desvinculadas serão desconectados.'
              )}
            </Paragraph>
            <img
              src={saveChangesToastWirelessAssociatedCaptive}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Toast de salvar alterações em redes wireless associadas ao Captive'
              )}
            />
            <Paragraph>
              <Strong>{t('Importante:')} </Strong>
              {t(
                'as redes desvinculadas voltam a ser do mesmo tipo de segurança configurado anteriormente (WPA-PSK, WPA2-PSK ou ABERTA) e com a mesma senha configurada antes, para o caso das redes fechadas.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Ao acessar a tela de WIRELESS note que a segurança das redes na listagem estão como sendo do tipo “Captive Portal” para as wireless que ainda estão vinculadas ao captive. Já as que foram desvinculadas do captive aparecem com sua segurança normal.'
              )}
            </Paragraph>
            <img
              src={captivePortalEnabledWirelessListingScreen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela de listagem de redes wireless com Captive Portal ativado'
              )}
            />
            <Paragraph>
              <Strong>{t('Importante:')} </Strong>
              {t(
                'redes do tipo captive, não podem ter sua segurança editada pela tela de edição de wireless. A única forma de mudar a segurança de uma rede captive é indo na aba de “Wireless Vinculadas” da página do captive portal, desmarcar a rede e salvar.'
              )}
            </Paragraph>
            <img
              src={captivePortalBlockedFieldsWirelessEditScreen}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela de edição de redes wireless com campos bloqueados pelo Captive Portal'
              )}
            />
            <Paragraph>
              {t(
                'Como o captive está ativo nesse ambiente, ao criar uma nova rede wireless, um modal de confirmação aparecerá, perguntando se deseja já vincular essa rede ao captive ou não.'
              )}
            </Paragraph>
            <img
              src={captiveActiveWirelessNetworkCreationModal}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Modal de criação de rede wireless com Captive ativo')}
            />
            <Paragraph>
              {t(
                'Ao clicar em SIM, a rede será automaticamente vinculada ao captive. Ao clicar em NÃO ela permanece de acordo com a segurança selecionada.'
              )}
            </Paragraph>
            <Paragraph>
              {t(
                'Pode-se vincular qualquer wireless a qualquer momento ao captive. Basta ir em “Wireless Vinculadas” na página de captive portal, selecionar a rede e clicar em SALVAR.'
              )}
            </Paragraph>
            <img
              src={captiveLinkedWirelessScreenWithTwoMarkedNetworks}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t(
                'Tela de redes wireless vinculadas ao Captive com duas redes marcadas'
              )}
            />
          </div>
        )
      },
      {
        title: t('Situações Adversas'),
        content: (
          <div>
            <Paragraph>
              {t(
                'Caso o sistema apresente falha ao enviar uma requisição durante a etapa de ativação de um método do Captive Portal, se faz necessário aguardar alguns minutos, pois o sistema pode estar passando por alguma atualização ou instabilidade.'
              )}
            </Paragraph>
            <img
              src={captivePortalSystemFailureAlert}
              width="100%"
              className={classNames(['mt-4'])}
              alt={t('Alerta de falha no sistema de captive portal')}
            />
            <Paragraph>
              {t(
                'Caso o problema persista, contacte o responsável pela gestão da rede.'
              )}
            </Paragraph>
          </div>
        )
      }
    ]
  }
];
