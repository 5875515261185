import { AuthAPI } from './urls/auth/index';
import { CaptivePortalAPI } from './urls/captivePortal';
import { ClientsAPI } from './urls/clients';
import { DevicesAPI } from './urls/devices';
import { SitesAPI } from './urls/sites';
import { StatisticsAPI } from './urls/statistics';
import { WirelessAPI } from './urls/wireless';

export const api = {
  sites: new SitesAPI(),
  wireless: new WirelessAPI(),
  auth: new AuthAPI(),
  devices: new DevicesAPI(),
  statistics: new StatisticsAPI(),
  clients: new ClientsAPI(),
  captivePortal: new CaptivePortalAPI()
};

export default api;
