import React, { useState } from 'react';
import { Col, Row } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdDelete, MdEdit, MdFactCheck, MdSearch } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { generateColumns } from 'src/utils/tableUtils';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import classNames from 'classnames';
import TableWithPagination from 'src/components/TableWithPagination';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from 'src/pages/Devices/types';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { useDebounce } from 'use-debounce';
import { ICustomer, IPaginationCustomerTab } from './types';
import styles from './CustomersTab.module.css';
import CustomerAcessModal from './CustomerAccessModal';

interface IProps {
  customers: ICustomer[];
  selectedCustomers: ICustomer[];
  setSelectedCustomers: React.Dispatch<React.SetStateAction<ICustomer[]>>;
  setShowDeleteCustomersModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomersTab = ({
  customers,
  selectedCustomers,
  setSelectedCustomers,
  setShowDeleteCustomersModal,
  setShowAddCustomerModal
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(true);

  // const isFetchingCustomers = false;

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [paginationObject, setPaginationObject] =
    useState<IPaginationCustomerTab>({} as IPaginationCustomerTab);

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [showCustomerAccessModal, setShowCustomerAccessModal] = useState(false);

  const handleClickViewCustomerAccess = () => {
    setShowCustomerAccessModal(true);
  };

  const generateRows = (customersToFormat: ICustomer[]) => {
    return customersToFormat.map((customer: ICustomer) => {
      return {
        ...customer,
        actions: (
          <div className="d-flex justify-between align-center">
            <ButtonIcon
              id={`btn-list-customer-${customer.mac}`}
              onClick={handleClickViewCustomerAccess}>
              <MdFactCheck size={24} />
            </ButtonIcon>

            <ButtonIcon id={`btn-edit-customer-${customer.mac}`}>
              <MdEdit size={24} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const columnsTableCustomers = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('mac', t('MAC'), 'left', 0),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('actions', t('Ações'), 'center', 0)
  ];

  // const isResultNotFound =
  //   paginationObject.pagination.total_filtered_items === 0 &&
  //   !isFetchingCustomers &&
  //   isInitialLoadingComplete;

  if (customers.length === 0) {
    return (
      <EmptyMessage
        title={t('Nenhum cliente')}
        subtitle={t(
          'Ainda não há cliente disponível nesta lista. Experimente adicionar um novo'
        )}
        id="message-no-wireless"
        buttonText={t('ADICIONAR CLIENTE')}
        action={() => setShowAddCustomerModal(true)}
        minHeight="190px"
        height="calc(100vh - 750px)"
      />
    );
  }

  return (
    <>
      <CustomerAcessModal
        show={showCustomerAccessModal}
        onClose={() => setShowCustomerAccessModal(false)}
      />

      <Row className="d-flex justify-between align-center fit-width flex-end">
        {selectedCustomers.length === 0 ? (
          <>
            <Col xl={8}>
              {isInitialLoadingComplete ? (
                <Label>{t('Pesquisar por MAC')}:</Label>
              ) : (
                <Skeleton width={120} height={19} />
              )}
              {isInitialLoadingComplete ? (
                <InputIconInside
                  iconLeft={
                    false ? <Loading value={40} indeterminate /> : <MdSearch />
                  }>
                  <Input
                    id="search-input-customer-by-mac"
                    disabled={false}
                    iconInsideLeft
                    placeholder={t('Digite o número do MAC')}
                  />
                </InputIconInside>
              ) : (
                <Skeleton height={51.1} />
              )}
            </Col>

            <Col>
              {isInitialLoadingComplete ? (
                <ButtonTextOutside
                  id="text-btn-open-modal-to-create-customer"
                  value={t('ADICIONAR CLIENTE')}
                  className="text-uppercase mt-4 ">
                  <Button
                    id="btn-open-modal-to-create-customer"
                    onClick={() => setShowAddCustomerModal(true)}
                    disabled={false}
                    rounded>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              ) : (
                <Skeleton count={1} width={156} height={45} />
              )}
            </Col>
          </>
        ) : (
          <Col>
            <ButtonTextOutside
              value={`${
                selectedCustomers.length > 1
                  ? t('Excluir clientes')
                  : t('Excluir cliente')
              } (${selectedCustomers?.length})`}
              className="text-uppercase mt-4">
              <Button
                onClick={() => setShowDeleteCustomersModal(true)}
                id="button-delete-customer"
                rounded
                color="var(--color-status-critical-base)">
                <MdDelete size={22} />
              </Button>
            </ButtonTextOutside>
          </Col>
        )}
      </Row>

      <Row>
        {
          // TODO: Acrescentar mensagem de resultado vazio, ao realizar a pesquisa
        }
        {/* {isResultNotFound ? (
                <ResultsNotFoundMessage
                  id="customers-acl"
                  minHeight="190px"
                  height="calc(100vh - 500px)"
                />
              ) : ( */}
        <TableWithPagination<ICustomer>
          keyToCompare="mac"
          useKeyToCompareAsId
          className={classNames(['mt-10', styles.table])}
          isChangingPage={isChangingPage}
          columns={columnsTableCustomers}
          setPaginationObject={setPaginationObject}
          loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
          rows={generateRows(customers)}
          isFetchingData={false} // todo adicionar quando inserir a query
          paginationData={paginationObject.pagination}
          firstFetchWasMade={isInitialLoadingComplete}
          paginationRequestData={paginationRequestDataDebounce}
          setIsChangingPage={setIsChangingPage}
          setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
          setPaginationRequestData={setPaginationRequestData}
          selectedRows={selectedCustomers}
          onSelectRows={(selectedRows: ICustomer[]) => {
            setSelectedCustomers(selectedRows);
          }}
          minRowsToShowPagination={10}
          keyOfItemsOfPaginationTable="custumers" // todo adicionar quando inserir a query
        />
      </Row>
      {/* )} */}
    </>
  );
};

export default CustomersTab;
