import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { eventGA } from 'src/utils/analytics';
import classNames from 'classnames';
import { isNull } from 'lodash';
import api from 'src/services/api';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import { IUpdateAssociatedWirelessCaptivePortal } from 'src/services/api/urls/wireless/types';
import { useNavigate } from 'react-router-dom';
import { useArrayCompare } from 'src/hooks/useFormCompare';
import { Button } from 'ui-components/Button';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useNotification } from 'src/hooks/useNotification';
import { queryClient } from 'src/services/queryClient';
import { getListOfIdsAssociatedWithWireless } from 'src/utils/associatedWirelessUtils';
import { Alert } from 'ui-components/Alert';
import { IResumedWireless } from './types';
import styles from './AssociatedWireless.module.css';
import { ICaptivePortal } from '../types';
import CaptiveTableAssociatedWireless from './CaptiveTableAssociatedWireless';

interface IProps {
  defaultCaptivePortal: ICaptivePortal | null;
  isFetchedCaptivePortal: boolean;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const AssociatedWireless = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  setActiveTab
}: IProps) => {
  const METHOD_TAB = 0;

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.associatedWireless'
  });

  const { addToast } = useToast();

  const navigate = useNavigate();

  const { triggerNotificationAnimationWithText } = useNotification();

  const [associatedWireless, setAssociatedWireless] = useState<
    IResumedWireless[]
  >([]);

  const [selectedWireless, setSelectedWireless] = useState<IResumedWireless[]>(
    []
  );

  const [wirelessList, setWirelessList] = useState<IResumedWireless[]>([]);

  const [isFetchedWirelessList, setIsFetchedWirelessList] = useState(false);

  const [
    confirmUpdateAssociatedWireless,
    setShowConfirmUpdateAssociatedWireless
  ] = useState(false);

  const [
    isLoadingUpdateAssociatedWirelessCaptive,
    setIsLoadingUpdateAssociatedWirelessCaptive
  ] = useState(false);

  const isFormEquals = useArrayCompare(
    associatedWireless ?? [],
    selectedWireless,
    ['id', 'name']
  );

  const getNotAssociatedWireless = () => {
    const selectedWirelessSet = new Set(selectedWireless.map((w) => w.id));

    const disassociatedWirelessList = [
      ...wirelessList.filter(
        (wireless) => !selectedWirelessSet.has(wireless.id)
      )
    ];

    return disassociatedWirelessList.filter((selectedWirelessToDisassociate) =>
      associatedWireless.find(
        (wireless) =>
          wireless.id === selectedWirelessToDisassociate.id &&
          wireless.is_captive_portal_enabled
      )
    );
  };

  const getAssociatedWireless = () => {
    return selectedWireless.filter(
      (selectedWirelessToAssociate) =>
        !associatedWireless.find(
          (wireless) =>
            wireless.id === selectedWirelessToAssociate.id &&
            wireless.is_captive_portal_enabled
        )
    );
  };

  const putUpdateAssociatedWireless = () => {
    const data: IUpdateAssociatedWirelessCaptivePortal = {
      wireless_list: []
    };

    const changedAssociatedWireless = getAssociatedWireless();

    const changedDisassociatedWireless = getNotAssociatedWireless();

    if (changedAssociatedWireless.length > 0) {
      data.wireless_list.push({
        ids: changedAssociatedWireless.map((wireless) => wireless.id),
        data: {
          is_captive_portal_enabled: true
        }
      });
    }

    if (changedDisassociatedWireless.length > 0) {
      data.wireless_list.push({
        ids: changedDisassociatedWireless.map((wireless) => wireless.id),
        data: {
          is_captive_portal_enabled: false
        }
      });
    }
    return api.wireless.putAssociateWirelessCaptivePortal(data);
  };

  const changedWireless = [
    ...getAssociatedWireless(),
    ...getNotAssociatedWireless()
  ];

  const checkIfWirelessHasDevices = () => {
    return changedWireless.some((wireless) => wireless.devices_ids.length > 0);
  };

  const generatePluralOrSingular = () => {
    const hasMoreThanOneDevice =
      getListOfIdsAssociatedWithWireless(changedWireless).length > 1;

    if (hasMoreThanOneDevice) {
      return t('CONFIGURANDO EQUIPAMENTOS');
    }

    return t('CONFIGURANDO EQUIPAMENTO');
  };

  const refecthWirelessList = () => {
    queryClient.refetchQueries('wirelessToAssociateQuery');
  };

  const updateAssociatedWirelessMutation = useMutation(
    'updateAssociatedWirelessCaptive',
    putUpdateAssociatedWireless,
    {
      onSuccess: () => {
        refecthWirelessList();
        addToast(
          'success',
          t('As alterações em Wireless Vinculadas estão sendo aplicadas')
        );
        if (checkIfWirelessHasDevices()) {
          triggerNotificationAnimationWithText(generatePluralOrSingular());
        }
        setIsLoadingUpdateAssociatedWirelessCaptive(false);
      },
      onError: () => {
        addToast(
          'error',
          t('Não foi possível aplicar alterações nas Wireless.')
        );
        setIsLoadingUpdateAssociatedWirelessCaptive(false);
      }
    }
  );

  const isFetchedCaptiveAndHasNotMode =
    !isLoadingUpdateAssociatedWirelessCaptive &&
    isFetchedCaptivePortal &&
    (isNull(defaultCaptivePortal) || isNull(defaultCaptivePortal.mode));

  const isFetchedWirelessAndCaptive =
    isFetchedWirelessList &&
    isFetchedCaptivePortal &&
    !isLoadingUpdateAssociatedWirelessCaptive;

  const isFetchedWirelessAndCaptiveAndEmptyWirelessList =
    isFetchedWirelessAndCaptive && wirelessList.length === 0;

  const editAssociateWirelessOfCaptivePortal = () => {
    setIsLoadingUpdateAssociatedWirelessCaptive(true);
    eventGA(
      'Captive Portal',
      'Click',
      'Tentar salvar o associação de wireless com captive portal'
    );
    updateAssociatedWirelessMutation.mutate();
  };

  if (isFetchedCaptiveAndHasNotMode) {
    return (
      <EmptyMessage
        height="calc(100vh - 380px)"
        minHeight="190px"
        disabled={!isFetchedWirelessList}
        title={t('Nenhuma visualização')}
        subtitle={
          <div>
            <span>{t('Acesse a aba')}</span>
            <span
              id="btnRedirectSetUpMethodCaptivePortal"
              onClick={() => setActiveTab(METHOD_TAB)}
              className={classNames(styles.defaultMethod, 'ml-1', 'text-bold')}
              aria-hidden="true">
              {t('Configurar método')}{' '}
            </span>
            <span>{t('e ative um dos métodos de Captive Portal')}</span>
          </div>
        }
        id="message-no-enabled-captive"
      />
    );
  }
  return (
    <div>
      <ConfirmModal
        showModal={confirmUpdateAssociatedWireless}
        isLoadingAction={isLoadingUpdateAssociatedWirelessCaptive}
        width="540px"
        height="260px"
        onCancel={() => {
          setShowConfirmUpdateAssociatedWireless(false);
          setSelectedWireless(associatedWireless);
        }}
        onConfirm={() => {
          editAssociateWirelessOfCaptivePortal();
          setShowConfirmUpdateAssociatedWireless(false);
        }}>
        {t(
          'Ao prosseguir com a ação, a forma de autenticação desta wireless será alterada e os clientes dessa rede serão desconectados. Este processo poderá levar alguns minutos até ser configurado. Deseja continuar?'
        )}
      </ConfirmModal>

      {isFetchedWirelessAndCaptiveAndEmptyWirelessList ? (
        <EmptyMessage
          height="calc(100vh - 380px)"
          minHeight="190px"
          title={t('Nenhuma wireless')}
          subtitle={t(
            'Ainda não há wireless disponível neste ambiente. Experimente adicionar uma nova'
          )}
          buttonText={t('Adicionar wireless')}
          id="btn-no-wireless-captive-portal"
          action={() => navigate('/wireless/add')}
        />
      ) : (
        <>
          <div className="d-flex flex-column">
            <div className="fit-width d-flex align-center">
              <span className="title-xl-base">{t('Wireless vinculadas')}</span>
            </div>
            <span className="mt-3 mb-7">
              {t(
                'Abaixo, você encontra as redes wireless vinculadas ao Captive Portal, que podem ser configuradas sempre que necessário. Ao realizar esta ação, você estará aplicando o método de Captive Portal na segurança das wireless selecionadas'
              )}
            </span>
          </div>
          <CaptiveTableAssociatedWireless
            isFetchedWirelessAndCaptive={isFetchedWirelessAndCaptive}
            selectedWireless={selectedWireless}
            setSelectedWireless={setSelectedWireless}
            wirelessList={wirelessList}
            defaultCaptivePortal={defaultCaptivePortal}
            setAssociatedWireless={setAssociatedWireless}
            setWirelessList={setWirelessList}
            setIsFetchedWirelessList={setIsFetchedWirelessList}
          />
          <div className="d-flex align-center fit-width justify-between mt-4">
            <div>
              {changedWireless.length > 0 && (
                <Alert type="info" width="fit-content">
                  {t('As alterações ainda não foram salvas.')}
                </Alert>
              )}
            </div>
            {isFetchedWirelessAndCaptive ? (
              <div className="d-flex">
                {changedWireless.length > 0 && (
                  <Button
                    ghost
                    className="mr-4"
                    onClick={() => setSelectedWireless(associatedWireless)}>
                    {t('CANCELAR')}
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={isFormEquals || !isFetchedWirelessAndCaptive}
                  isLoading={isLoadingUpdateAssociatedWirelessCaptive}
                  onClick={() => setShowConfirmUpdateAssociatedWireless(true)}
                  id="btn-associate-wireless">
                  {t('Salvar')}
                </Button>
              </div>
            ) : (
              <Skeleton count={1} height={50} borderRadius={10} width={100} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AssociatedWireless;
