import React, { useState } from 'react';
import { Col, Row } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdDelete, MdEdit, MdHomeWork, MdSearch } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { generateColumns } from 'src/utils/tableUtils';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import classNames from 'classnames';
import { EmptyMessage } from 'src/components/EmptyMessage';
import TableWithPagination from 'src/components/TableWithPagination';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from 'src/pages/Devices/types';
import { useDebounce } from 'use-debounce';
import { IWireless, IWirelessTabPagination } from './types';
import styles from './WirelessTab.module.css';
import PlaceListModal from './PlaceListModal';

interface IProps {
  wirelessList: IWireless[];
  selectedWireless: IWireless[];
  setSelectedWireless: React.Dispatch<React.SetStateAction<IWireless[]>>;
  setShowDeleteWirelessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddWirelessModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const WirelessTab = ({
  wirelessList,
  selectedWireless,
  setSelectedWireless,
  setShowDeleteWirelessModal,
  setShowAddWirelessModal
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const isFetchedAfterMount = true;

  const [showPlaceListModal, setShowPlaceListModal] = useState(false);

  const handleClickViewPlaceList = () => {
    setShowPlaceListModal(true);
  };

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [paginationObject, setPaginationObject] =
    useState<IWirelessTabPagination>({} as IWirelessTabPagination);

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const generateRows = (wirelessListToFormat: IWireless[]) => {
    return wirelessListToFormat.map((wireless: IWireless) => {
      return {
        ...wireless,
        actions: (
          <div className="d-flex justify-between align-center">
            <ButtonIcon
              id={`btn-home-wireless-${wireless.name}`}
              onClick={handleClickViewPlaceList}>
              <MdHomeWork size={24} />
            </ButtonIcon>

            <ButtonIcon id={`btn-edit-wireless-${wireless.name}`}>
              <MdEdit size={24} />
            </ButtonIcon>
          </div>
        )
      };
    });
  };

  const columnsTableWirelessList = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('actions', t('Ações'), 'center', 0)
  ];

  // const isResultNotFound =
  //   paginationObject.pagination.total_filtered_items === 0 &&
  //   !isFetchingWireless &&
  //   isInitialLoadingComplete;

  if (wirelessList.length === 0) {
    return (
      <div className="fit-width">
        <EmptyMessage
          title={t('Nenhuma wireless')}
          subtitle={t(
            'Ainda não há wireless disponível nesta lista. Experimente adicionar uma nova'
          )}
          id="message-no-wireless"
          buttonText={t('ADICIONAR WIRELESS')}
          action={() => setShowAddWirelessModal(true)}
          minHeight="190px"
          height="calc(100vh - 750px)"
        />
      </div>
    );
  }

  return (
    <>
      <PlaceListModal
        show={showPlaceListModal}
        onClose={() => setShowPlaceListModal(false)}
      />

      <Row className="d-flex justify-between align-center fit-width">
        {selectedWireless.length === 0 ? (
          <>
            <Col xl={8} className="mr-3">
              {isFetchedAfterMount ? (
                <Label>{t('Pesquisar por nome')}:</Label>
              ) : (
                <Skeleton width={120} height={19} />
              )}
              {isFetchedAfterMount ? (
                <InputIconInside
                  iconLeft={
                    false ? <Loading value={40} indeterminate /> : <MdSearch />
                  }>
                  <Input
                    id="search-input-wireless-by-name"
                    disabled={false}
                    iconInsideLeft
                    placeholder={t('Digite o nome da rede Wireless')}
                  />
                </InputIconInside>
              ) : (
                <Skeleton height={51.1} />
              )}
            </Col>

            <Col>
              {isFetchedAfterMount ? (
                <ButtonTextOutside
                  id="text-btn-open-modal-to-create-wireless"
                  value={t('ADICIONAR WIRELESS')}
                  className="text-uppercase mt-4">
                  <Button
                    id="btn-open-modal-to-create-wireless"
                    onClick={() => setShowAddWirelessModal(true)}
                    disabled={false}
                    rounded>
                    <MdAdd size={22} />
                  </Button>
                </ButtonTextOutside>
              ) : (
                <Skeleton count={1} width={156} height={45} />
              )}
            </Col>
          </>
        ) : (
          <Col>
            <ButtonTextOutside
              value={`${t('Excluir wireless')} (${selectedWireless?.length})`}
              className="text-uppercase mt-4">
              <Button
                onClick={() => setShowDeleteWirelessModal(true)}
                id="button-delete-wireless"
                rounded
                color="var(--color-status-critical-base)">
                <MdDelete size={22} />
              </Button>
            </ButtonTextOutside>
          </Col>
        )}
      </Row>

      <Row>
        {
          // TODO: Acrescentar mensagem de resultado vazio, ao realizar a pesquisa
        }
        {/* {isResultNotFound ? (
                <ResultsNotFoundMessage
                  id="wireless-acl"
                  minHeight="190px"
                  height="calc(100vh - 500px)"
                />
              ) : ( */}
        <TableWithPagination<IWireless>
          keyToCompare="name"
          useKeyToCompareAsId
          className={classNames(['mt-10', styles.table])}
          isChangingPage={isChangingPage}
          columns={columnsTableWirelessList}
          setPaginationObject={setPaginationObject}
          loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
          rows={generateRows(wirelessList)}
          isFetchingData={false} // todo adicionar quando inserir a query
          paginationData={paginationObject.pagination}
          firstFetchWasMade={isFetchedAfterMount}
          paginationRequestData={paginationRequestDataDebounce}
          setIsChangingPage={setIsChangingPage}
          setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
          setPaginationRequestData={setPaginationRequestData}
          onSelectRows={(selectedRows: IWireless[]) => {
            setSelectedWireless(selectedRows);
          }}
          selectedRows={selectedWireless}
          minRowsToShowPagination={10}
          keyOfItemsOfPaginationTable="wireless" // todo adicionar quando inserir a query
        />
        {/* )} */}
      </Row>
    </>
  );
};

export default WirelessTab;
