import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { v4 as uuid } from 'uuid';
import Toast from 'ui-components/Toast';

import { IToastOptions, IToast, ToastContextType } from './types';

export const ToastContext = createContext({} as ToastContextType);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const deleteToast = (id: string) => {
    setToasts((prevState) => prevState.filter((toast) => toast.id !== id));
  };

  const dismissAllToasts = () => {
    setToasts((prevState) => {
      return prevState.map((toast) => ({
        ...toast,
        options: {
          ...toast.options,
          autoDismiss: true
        }
      }));
    });
  };

  const addToast = useCallback(
    (
      type: 'success' | 'error' | 'warning' | 'default' | 'info',
      content: ReactNode,
      options?: IToastOptions
    ) => {
      setToasts((prevState) => {
        if (
          options?.unique &&
          prevState.find((toast) => toast.content === content)
        ) {
          return prevState;
        }
        return [
          ...prevState,
          {
            id: uuid(),
            type,
            content,
            options: {
              unique: options?.unique ?? false,
              autoDismiss: options?.autoDismiss ?? true,
              closable: options?.closable ?? false
            }
          }
        ];
      });
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      addToast,
      dismissAllToasts
    }),
    [addToast]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast
        toastList={toasts}
        onDismiss={deleteToast}
        autoDismiss
        dismissTime={4000}
      />
    </ToastContext.Provider>
  );
};
