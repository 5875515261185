import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronRight, MdOutlineOpenInNew, MdSearch } from 'react-icons/md';
import Card from 'ui-components/Card';
import Drawer from 'ui-components/Drawer';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import ninaProfile from 'src/assets/help-images/nina_profile.png';
import { Button } from 'ui-components/Button';
import { useNavbarDrawers } from 'src/hooks/useNavbarDrawers';
import { Divider } from '../Divider';

import TextVisualizer from './TextVisualizer';
import { IHelpText, IPropsHelperDrawer } from './types';
import styles from './HelperDrawer.module.css';
import { helpTexts } from './texts';
import { ResultsNotFoundMessage } from '../ResultsNotFoundMessage';

const HelpDrawer = ({ show, onClose }: IPropsHelperDrawer) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'helperDrawer'
  });
  const [search, setSearch] = useState('');
  const [selectedText, setSelectedText] = useState<IHelpText | null>(null);
  const { topicToShowInHelperDrawer, setTopicToShowInHelperDrawer } =
    useNavbarDrawers();

  const filteredHelpTexts = helpTexts
    .filter((section) => {
      const searchInLowerCase = search.toLowerCase();
      if (search.length === 0) return true;

      return (
        section.items.some((item) =>
          item.title.toLowerCase().includes(searchInLowerCase)
        ) || section.section.toLowerCase().includes(searchInLowerCase)
      );
    })
    .map((section) => {
      const searchInLowerCase = search.toLowerCase();
      const items = section.items.filter((item) =>
        item.title.toLowerCase().includes(searchInLowerCase)
      );

      if (
        items.length === 0 ||
        section.section.toLowerCase().includes(searchInLowerCase)
      )
        return section;

      return {
        section: section.section,
        items
      };
    });

  const findSection = (sectionTitle: string) => {
    return helpTexts.find((section) => section.section === sectionTitle);
  };

  const findItemOfSection = (
    section: IHelpText | undefined,
    itemTitle: string
  ) => {
    if (!section) return undefined;
    return section.items.find((item) => item.title === itemTitle);
  };

  useEffect(() => {
    const { sectionTitle, itemTitle } = topicToShowInHelperDrawer;

    if (sectionTitle && itemTitle) {
      const selectedSection = findSection(sectionTitle);

      const selectedItem = findItemOfSection(selectedSection, itemTitle);

      if (selectedItem) {
        setSelectedText({
          section: sectionTitle,
          items: [
            {
              title: selectedItem.title,
              content: selectedItem.content
            }
          ]
        });
      }
    }
  }, [topicToShowInHelperDrawer, setSelectedText]);

  const renderSearchedTexts = () => {
    return filteredHelpTexts.map((section) => (
      <div key={section.section}>
        <h3 className="ml-3 text-bold">{section.section}</h3>
        <Divider color="var(--color-neutral-light-3)" />
        {section.items.map((item) => (
          <React.Fragment key={item.title}>
            <Card
              onClick={() =>
                setSelectedText({
                  section: section.section,
                  items: [item]
                })
              }
              id={`helper-card-${item.title}`}
              clickable
              className="d-flex justify-between align-center fit-width"
              key={item.title}>
              <div id={`helper-card-title-of-${item.title}`}>{item.title}</div>
              <MdChevronRight size={24} />
            </Card>
            <Divider color="var(--color-neutral-light-3)" />
          </React.Fragment>
        ))}
      </div>
    ));
  };

  const resetTextSelected = () => {
    setTopicToShowInHelperDrawer({ sectionTitle: '', itemTitle: '' });
    setSelectedText(null);
  };

  const clearSearchAfterClose = () => {
    const drawerCloseTimeInMs = 600;
    const drawerCloseTimeout = setTimeout(() => {
      setSearch('');
    }, drawerCloseTimeInMs);

    return () => clearTimeout(drawerCloseTimeout);
  };

  const handleOnClose = () => {
    onClose();
    resetTextSelected();

    if (!selectedText) {
      clearSearchAfterClose();
    }
  };

  return (
    <Drawer
      title={t('CENTRAL DE AJUDA')}
      id="drawer-helper"
      show={show}
      onClose={handleOnClose}
      responsiveWidth={{ lg: '55vw' }}>
      {selectedText ? (
        <TextVisualizer
          onBack={() => resetTextSelected()}
          title={selectedText.items[0].title}
          section={selectedText.section}
          content={selectedText.items[0].content}
        />
      ) : (
        <div className={styles.helperDrawer}>
          <div className="fit-width">
            <div className="mb-2">
              <Label>{t('Pesquisar informação:')}</Label>
            </div>
            <InputIconInside iconLeft={<MdSearch />} iconRight>
              <Input
                id="search-helper-input"
                value={search}
                iconInsideLeft
                placeholder={t('O que podemos ajudar você a encontrar?')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />
            </InputIconInside>
          </div>
          <div className={styles.helpResults}>
            <Divider color="var(--color-neutral-light-3)" />
            {filteredHelpTexts.length === 0 ? (
              <ResultsNotFoundMessage
                id="help"
                minHeight="190px"
                height="calc(100vh - 500px)"
              />
            ) : (
              renderSearchedTexts()
            )}
          </div>
          <div className={styles.ninaHelper}>
            <img
              src={ninaProfile}
              width="80"
              alt={t(
                'A Imagem da assistente virtual da Intelbras, seu nome é Nina'
              )}
            />
            <div className={styles.ninaHelperInfos}>
              <span className="text-xl-base">Ainda tem alguma dúvida?</span>
              <a
                href="https://chat.apps.intelbras.com.br/"
                target="_blank"
                rel="noreferrer">
                <Button className="d-flex align-center justify-between mt-2">
                  <span className={styles.ninaButtonText}>
                    FALE COM A NINA <MdOutlineOpenInNew size={20} />
                  </span>
                </Button>
              </a>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default HelpDrawer;
