import React from 'react';
import Modal from 'ui-components/Modal';

interface IPropsInfoModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
}

const InfoModal = ({
  showModal,
  setShowModal,
  title,
  description
}: IPropsInfoModal) => {
  return (
    <Modal
      closable
      title={title}
      onClose={() => {
        setShowModal(false);
      }}
      show={showModal}
      width="658px"
      height="253px"
      style={{
        paddingBottom: 'var(--spacing-stratch-xs)'
      }}>
      <span className="text-base-lg mb-4">{description}</span>
    </Modal>
  );
};

export { InfoModal };
