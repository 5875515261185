import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from 'ui-components/Modal';

interface IPropsCancelWizardModal {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelWizardModal = ({ show, setShow }: IPropsCancelWizardModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });
  const navigate = useNavigate();
  const actions = [
    {
      label: t('VOLTAR'),
      action: () => setShow(false)
    },
    {
      label: t('SAIR'),
      action: () => navigate('/captive-portal'),
      type: 'error'
    }
  ];
  return (
    <Modal actions={actions} show={show} width="450px">
      <p className="text-xl-base">
        {t(
          'Deseja sair do processo de ativação do Captive Portal? Ao prosseguir, você irá perder todas as alterações realizadas.'
        )}
      </p>
    </Modal>
  );
};

export default CancelWizardModal;
