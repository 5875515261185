import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { StyledEngineProvider } from '@mui/material';
import styles from './Tooltip.module.css';

import type { ITooltip } from './types';

/**
 * Componente responsável por mostrar mensagens rápidas ao usuário atráves do hover do mouse
 */
const InTooltip = ({
  children,
  content,
  htmlContent,
  id,
  place,
  isOpen,
  onOpen,
  onClose,
  disabled,
  className,
  style,
  clickable
}: ITooltip) => {
  return (
    <StyledEngineProvider injectFirst>
      <Tooltip
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={place}
        slotProps={{
          tooltip: {
            className: classNames(styles.tooltip, 'common-body-2', className),
            style: {
              ...style
            }
          },
          popper: {
            style: {
              cursor: clickable ? 'pointer' : 'default'
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8]
                }
              }
            ]
          }
        }}
        disableFocusListener={disabled}
        disableHoverListener={disabled}
        disableTouchListener={disabled}
        title={<span>{content || htmlContent}</span>}
        id={id}>
        <span>{children}</span>
      </Tooltip>
    </StyledEngineProvider>
  );
};

InTooltip.defaultProps = {
  isOpen: undefined,
  onOpen: () => {},
  onClose: () => {},
  disabled: false,
  className: '',
  style: {},
  content: '',
  htmlContent: <span />,
  clickable: false
};

export { InTooltip as Tooltip };
