import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { IOption } from 'src/components/InputTime/types';
import { useAuth } from 'src/hooks/useAuth';
import { useNotification } from 'src/hooks/useNotification';
import { useTemplate } from 'src/hooks/useTemplate';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IErrorResponse } from 'src/services/api/types';
import {
  ICreateIndividualRecordByWizard,
  ICreateSimplePassword,
  IGetCaptivePortal
} from 'src/services/api/urls/captivePortal/types';
import { removePrefixFromBase64 } from 'src/utils/canvasUtils';
import { getExpiresAtDaysOrTime } from 'src/utils/CaptivePortal/captiveDates';
import { useGenerateCaptiveMethodsTypesObject } from 'src/utils/CaptivePortal/methodsTypes';
import { DateConverter } from 'src/utils/Dates/DateConverter';
import { isUrl } from 'src/utils/regexUtils';
import Steps from 'ui-components/Steps';
import { IResumedWireless } from '../AssociatedWireless/types';
import { IMethodsTypes, IMethodTypeName } from '../SetUpMethod/types';
import { ICaptivePortal } from '../types';
import { ErrorWizardRequestModal } from './ErrorRequestModal';
import FinishWizardModal from './FinishWizardModal';
import AssociatedWirelessWizard from './Steps/AssociatedWirelessWizard';
import StepIndividualRecordWizard from './Steps/CaptiveMethods/IndividualRecord';
import StepSimplePasswordWizard from './Steps/CaptiveMethods/SimplePassword';
import CustomizeCaptiveWizard from './Steps/CustomizeCaptiveWizard';
import { IDataOfCaptiveMethodForms } from './types';
import styles from './WizardCaptive.module.css';

const WizardCaptive = () => {
  useTemplate('menuAndFullNavbar');

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const { triggerNotificationAnimationWithText } = useNotification();

  const { accessToken, siteTimezone } = useAuth();

  const { addToast } = useToast();

  const navigate = useNavigate();

  const {
    cp_id: captiveId,
    methodType
  }: { cp_id?: string; methodType?: IMethodTypeName } = useParams();

  const [currentStep, setCurrentStep] = React.useState(0);

  const [defaultCaptivePortal, setDefaultCaptivePortal] =
    React.useState<ICaptivePortal>({} as ICaptivePortal);

  const [captiveMethods, setCaptiveMethods] = React.useState<IMethodsTypes>(
    useGenerateCaptiveMethodsTypesObject()
  );

  const { nameType: captiveNameType } =
    captiveMethods[methodType as IMethodTypeName];

  const [dataOfCaptiveMethodForms, setDataOfCaptiveMethodForms] =
    React.useState<IDataOfCaptiveMethodForms>({} as IDataOfCaptiveMethodForms);

  const [showFinishModal, setShowFinishModal] = React.useState(false);

  const [showErrorMutationRequestModal, setShowErrorMutationRequestModal] =
    React.useState(false);

  const [
    customizeCaptiveClientPageFormData,
    setCustomizeCaptiveClientPageFormData
  ] = React.useState<ICaptivePortal>();

  const [isFirstCheckAndSelectWireless, setIsFirstCheckAndSelectWireless] =
    React.useState(false);

  const [wirelessList, setWirelessList] = React.useState<IResumedWireless[]>(
    []
  );

  const [selectedWireless, setSelectedWireless] = React.useState<
    IResumedWireless[]
  >([]);

  const [unSelectedWireless, setUnSelectedWireless] = React.useState<
    IResumedWireless[]
  >([]);

  const getCaptivePortal = () => {
    return api.captivePortal.captive.getOrCreate(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const { isFetchedAfterMount: isFetchedCaptivePortal } = useQuery(
    'getCaptivePortalWizard',
    getCaptivePortal,
    {
      onSuccess: ({ data }: { data: IGetCaptivePortal }) => {
        if (data) {
          setDefaultCaptivePortal({
            ...data.captive_portal,
            mode: methodType,
            is_blocked_to_activate: data.is_blocked_to_activate
          } as ICaptivePortal);

          setCaptiveMethods((previousValue) => {
            if (data.captive_portal && data.captive_portal.mode) {
              previousValue[methodType as IMethodTypeName] = {
                ...previousValue[methodType as IMethodTypeName],
                statusMethod: true
              };
            }

            return {
              ...previousValue
            };
          });
        } else {
          setDefaultCaptivePortal({} as ICaptivePortal);
        }
      },
      onError: () => {
        setDefaultCaptivePortal({} as ICaptivePortal);
      }
    }
  );

  const checkIfWirelessHasDevices = () => {
    const changedWireless = [...selectedWireless, ...unSelectedWireless];
    return changedWireless.some((wireless) => wireless.devices_ids.length > 0);
  };

  const generateToastText = () => {
    if (checkIfWirelessHasDevices()) {
      return t('A ativação do método {{nameType}} foi iniciada', {
        nameType: captiveNameType
      });
    }
    return t('Método {{nameType}} ativado com sucesso', {
      nameType: captiveNameType
    });
  };

  const checkIfWirelessListChangedAndSomeoneHasDevices = () => {
    const getPreviousAssociatedWireless = wirelessList.filter((wireless) => {
      return wireless.is_captive_portal_enabled;
    });

    const getChangedAssociatedWirelessWithDevices = selectedWireless.filter(
      (wirelessSelected) =>
        wirelessSelected.devices_ids.length > 0 &&
        !getPreviousAssociatedWireless.some(
          (wireless) => wireless.id === wirelessSelected.id
        )
    );

    const getChangedDisassociatedWirelessWithDevices =
      unSelectedWireless.filter(
        (wirelessSelected) =>
          wirelessSelected.devices_ids.length > 0 &&
          getPreviousAssociatedWireless.some(
            (wireless) => wireless.id === wirelessSelected.id
          )
      );

    return (
      [
        ...getChangedAssociatedWirelessWithDevices,
        ...getChangedDisassociatedWirelessWithDevices
      ].length > 0
    );
  };

  const onSuccessWizardMutation = () => {
    addToast('success', generateToastText());
    setShowFinishModal(false);
    navigate('/captive-portal');
    if (checkIfWirelessListChangedAndSomeoneHasDevices()) {
      triggerNotificationAnimationWithText(t('ATIVANDO CAPTIVE PORTAL'));
    }
  };

  const onErrorWizardMutation = (error: IErrorResponse) => {
    if (error.response.status >= 400 && error.response.status <= 600) {
      setShowErrorMutationRequestModal(true);
    }
  };

  const getHeaderImageValue = () => {
    const image = customizeCaptiveClientPageFormData?.header_image_url || '';
    const imageHasChanged = !isUrl(image);
    const imageToBackendWithoutPrefix = imageHasChanged
      ? removePrefixFromBase64(image)
      : null;

    return imageToBackendWithoutPrefix;
  };

  const postAddSimplePasswordByWizard = (data: ICreateSimplePassword) => {
    const headerImageValue = getHeaderImageValue();
    return api.captivePortal.simplePassword.postWizard(
      data,
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      headerImageValue !== null,
      false
    );
  };

  const addSimplePasswordMutation = useMutation(postAddSimplePasswordByWizard, {
    onSuccess: () => {
      onSuccessWizardMutation();
    },
    onError: (error: IErrorResponse) => {
      onErrorWizardMutation(error);
    }
  });

  const postAddIndividualRecordByWizard = (
    data: ICreateIndividualRecordByWizard
  ) => {
    const headerImageValue = getHeaderImageValue();
    return api.captivePortal.individualRecord.postWizard(
      data,
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      headerImageValue !== null,
      false
    );
  };

  const addIndividualRecordMutation = useMutation(
    postAddIndividualRecordByWizard,
    {
      onSuccess: () => {
        onSuccessWizardMutation();
      },
      onError: (error: IErrorResponse) => {
        onErrorWizardMutation(error);
      }
    }
  );

  const isLoadingWizardMutation =
    addSimplePasswordMutation.isLoading ||
    addIndividualRecordMutation.isLoading;

  const createCommonCaptiveWizardPayload = () => {
    const imageToBackendWithoutPrefix = getHeaderImageValue();
    const dataToMutation = {
      captive_portal_data: {
        captive_portal: {
          ...customizeCaptiveClientPageFormData,
          header_image: imageToBackendWithoutPrefix,
          header_image_url: null
        }
      },
      wireless_ids_to_disable_captive: unSelectedWireless.map(
        (wireless) => wireless.id
      ),
      wireless_ids_to_enable_captive: selectedWireless.map(
        (wireless) => wireless.id
      )
    };
    return dataToMutation;
  };

  const generateTimestampDetails = (
    periodType: string,
    periodInDays: [Date | null, Date | null],
    periodInTime: IOption | undefined
  ) => {
    return {
      expires_at: getExpiresAtDaysOrTime(
        periodType,
        periodInDays,
        periodInTime,
        false,
        siteTimezone
      ),

      started_at: new DateConverter(
        undefined,
        siteTimezone
      ).getDateWithDefaultFormat(),

      created_at: new DateConverter(
        undefined,
        siteTimezone
      ).getDateWithDefaultFormat()
    };
  };

  // TODO verificar se existe necessidade dessa função, já que por enquanto somente o captive senha simples utiliza ela
  // pois é o único método atualmente que tem o periodInType dentro do proprio formulário
  const createExpireAndCreatedAtObject = (
    captiveMethodName: keyof IDataOfCaptiveMethodForms,
    formName: keyof (typeof dataOfCaptiveMethodForms)[typeof captiveMethodName]
  ) => {
    return generateTimestampDetails(
      dataOfCaptiveMethodForms[captiveMethodName][formName].periodType,
      dataOfCaptiveMethodForms[captiveMethodName][formName].periodInDays,
      dataOfCaptiveMethodForms[captiveMethodName][formName].periodInTime
    );
  };

  const createWizardPayloadByCaptiveMethod = (
    captiveMethodName: keyof IDataOfCaptiveMethodForms,
    formName: keyof (typeof dataOfCaptiveMethodForms)[typeof captiveMethodName],
    requestKeyOfCaptiveMethod: string
  ) => {
    return {
      [requestKeyOfCaptiveMethod]: {
        // TODO verificar se colocar um booleano de criar ou nao o expiration_at e created_at é uma boa ideia
        // pois se for necessário criar um novo método que não tenha esses campos, terá que ser feito um novo if
        ...createExpireAndCreatedAtObject(captiveMethodName, formName),
        ...dataOfCaptiveMethodForms[captiveMethodName][formName]
      },
      ...createCommonCaptiveWizardPayload()
    };
  };

  const getIndividualRecordsPayload = () => {
    const individualRecordsFormatted =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataOfCaptiveMethodForms.individualRecord!.map((record) => {
        const { individualRecord } = record;

        const payload = {
          document: individualRecord.docNumber,
          name: individualRecord.name,
          email: individualRecord.email,
          phone: individualRecord.phone,
          ...generateTimestampDetails(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            individualRecord.periodType!,
            individualRecord.periodInDays,
            individualRecord.periodInTime
          )
        };

        return {
          ...payload,
          expires_at: individualRecord.authorized ? payload.expires_at : null,
          started_at: individualRecord.authorized ? payload.started_at : null
        };
      });

    return {
      individual_records: individualRecordsFormatted,
      ...createCommonCaptiveWizardPayload()
    };
  };

  const mutationsByCaptiveMethods = {
    password: () =>
      addSimplePasswordMutation.mutate(
        createWizardPayloadByCaptiveMethod(
          'password',
          'simplePassword',
          'simple_password'
        ) as ICreateSimplePassword
      ),
    individual_record: () =>
      addIndividualRecordMutation.mutate(
        getIndividualRecordsPayload() as ICreateIndividualRecordByWizard
      ),
    cpf: () => console.log('cpf mutation'),
    voucher: () => console.log('voucher mutation')
  };

  const onCancelFinishWizard = () => {
    setShowFinishModal(false);
  };

  const onConfirmFinishWizard = () => {
    mutationsByCaptiveMethods[methodType as IMethodTypeName]();
  };

  const onWaitErrorRequestModal = () => {
    setShowErrorMutationRequestModal(false);
  };

  const onExitErrorRequestModal = () => {
    setShowErrorMutationRequestModal(false);
    navigate('/captive-portal');
  };

  if (!methodType || !captiveMethods[methodType || '']) {
    return <Navigate to="/error/not_found" />;
  }

  const commonSteps = [
    <CustomizeCaptiveWizard
      isFetchedCaptivePortal={isFetchedCaptivePortal}
      defaultCaptivePortal={defaultCaptivePortal}
      setCustomizeCaptiveClientPageFormData={
        setCustomizeCaptiveClientPageFormData
      }
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
      customizeCaptiveClientPageFormData={customizeCaptiveClientPageFormData}
    />,
    <AssociatedWirelessWizard
      defaultCaptivePortal={defaultCaptivePortal}
      wirelessList={wirelessList}
      setWirelessList={setWirelessList}
      selectedWireless={selectedWireless}
      setSelectedWireless={setSelectedWireless}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      setShowFinishModal={setShowFinishModal}
      setUnSelectedWireless={setUnSelectedWireless}
      isFirstCheckAndSelectWireless={isFirstCheckAndSelectWireless}
      setIsFirstCheckAndSelectWireless={setIsFirstCheckAndSelectWireless}
    />
  ];

  const stepsByMethod = {
    password: [
      <StepSimplePasswordWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        setDataOfCaptiveMethodForms={setDataOfCaptiveMethodForms}
        dataOfCaptiveMethodForms={dataOfCaptiveMethodForms}
      />,
      ...commonSteps
    ],
    cpf: [...commonSteps],
    individual_record: [
      <StepIndividualRecordWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        defaultCaptivePortal={defaultCaptivePortal}
        setDataOfCaptiveMethodForms={setDataOfCaptiveMethodForms}
        dataOfCaptiveMethodForms={dataOfCaptiveMethodForms}
      />,
      ...commonSteps
    ],
    voucher: [...commonSteps]
  };

  return (
    <>
      <FinishWizardModal
        showFinishWizardModal={showFinishModal}
        onClose={() => onCancelFinishWizard()}
        onConfirm={() => onConfirmFinishWizard()}
        isLoadingWizardMutation={isLoadingWizardMutation}
        customizeCaptiveClientPageFormData={customizeCaptiveClientPageFormData}
        captiveNameType={captiveNameType}
        captiveId={defaultCaptivePortal.id}
        methodType={methodType}
      />
      <ErrorWizardRequestModal
        showErrorRequestModal={showErrorMutationRequestModal}
        isLoadingWizardMutation={isLoadingWizardMutation}
        setShowErrorModal={setShowErrorMutationRequestModal}
        onExit={() => onExitErrorRequestModal()}
        onWait={() => onWaitErrorRequestModal()}
      />
      <div className={styles.stepsShell}>
        <div className={styles.contentSteps}>
          <Steps
            current={currentStep}
            items={[
              <span className={styles.stepItem}>{t('Configurar método')}</span>,
              <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                {t('Página do')}
                <br />
                {t('Captive')}
              </span>,
              <span className={styles.stepItem}>
                {t('Wireless vinculadas')}
              </span>
            ]}
          />
        </div>
      </div>

      {stepsByMethod[methodType][currentStep]}
    </>
  );
};

export default WizardCaptive;
