import React, { HTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldError,
  MultipleFieldErrors,
  UseControllerProps
} from 'react-hook-form';
import { MdCancel, MdInfo } from 'react-icons/md';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';

interface IInputWithValidationProps {
  errors: FieldError | undefined;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
  controllerProps: UseControllerProps;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  placeholder?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  id?: string;
  hasRequiredIndicator?: boolean;
  isValid?: boolean;
  validMessage?: string;
}

const InputWithValidation = ({
  label,
  controllerProps,
  placeholder,
  control,
  wrapperProps,
  errors,
  disabled,
  inputProps,
  id,
  hasRequiredIndicator,
  isValid,
  validMessage
}: IInputWithValidationProps) => {
  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.notWhitespace) {
      message = errorTypes.notWhitespace;
    } else if (errorTypes.notSpecialCharacters) {
      message = errorTypes.notSpecialCharacters;
    } else if (errorTypes.notOutOfNamePattern) {
      message = errorTypes.notOutOfNamePattern;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.min) {
      message = errorTypes.min;
    } else if (errorTypes.max) {
      message = errorTypes.max;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <InputWrapper valid={isValid} invalid={Boolean(errors)} {...wrapperProps}>
      <Label hasRequiredIndicator={hasRequiredIndicator}>{label}</Label>
      <Controller
        control={control}
        name={controllerProps.name}
        rules={controllerProps.rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            disabled={disabled}
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            id={id || controllerProps.name}
            placeholder={placeholder}
            {...inputProps}
          />
        )}
      />
      {isValid && (
        <InputHelper
          id="valid-message"
          icon={<MdInfo />}
          value={validMessage}
        />
      )}
      {getInputErrorMessage(errors?.types)}
    </InputWrapper>
  );
};

InputWithValidation.defaultProps = {
  label: '',
  placeholder: '',
  wrapperProps: {},
  inputProps: {},
  disabled: false,
  id: '',
  hasRequiredIndicator: false,
  isValid: false,
  validMessage: ''
};

export default InputWithValidation;
