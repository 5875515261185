import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import Modal from 'ui-components/Modal';
import styles from './WarningListModal.module.css';

interface IProps {
  show: boolean;
  onClose: () => void;
  selectedAccessListId: string | null;
}

const WarningListModal = ({ show, onClose, selectedAccessListId }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists'
  });

  const cards = [1, 2, 3, 4, 5];

  return (
    <Modal
      width="700px"
      show={show}
      closable
      onClose={onClose}
      title={t('Lista de equipamentos')}>
      <div className={styles.modalContainer}>
        {cards.map(() => {
          return (
            <Card
              key={selectedAccessListId}
              borderColor="var(--color-status-critical-dark)"
              className="mb-2 pb-5 pt-2">
              <div className="d-flex justify-between text-center">
                <div className="d-flex justify-center align-center">
                  <div className={styles.accessListData}>
                    <strong className="text-bold mr-6">AP 06</strong>{' '}
                    <span>
                      <strong className="text-bold">{t('Ambiente')}</strong>{' '}
                      Nome do ambiente
                    </span>
                  </div>
                </div>
                <div>
                  <span
                    className={`d-flex justify-center align-center ${styles.errorIcon}`}>
                    <MdCancel size={32} />
                  </span>
                </div>
              </div>
              <hr
                className={classNames(['mb-3', 'mt-2', styles.dividerCard])}
              />
              <p className={styles.errorText}>
                Não conseguimos comunicação com o equipamento. Certifique-se que
                ele está online.
              </p>
              <div className="d-flex justify-end mt-3">
                <Button outline>{t('RESOLVIDO')}</Button>
              </div>
            </Card>
          );
        })}
      </div>
    </Modal>
  );
};

export default WarningListModal;
