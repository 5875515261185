import React, { HTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldError,
  MultipleFieldErrors,
  UseControllerProps
} from 'react-hook-form';
import { MdCancel } from 'react-icons/md';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import { InputImage } from '../InputImage';

interface IInputImageWithValidationProps {
  errors: FieldError | undefined;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
  controllerProps: UseControllerProps;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  id?: string;
  onHandleChange?: (file: File | null) => void;
}

const InputImageWithValidation = ({
  label,
  controllerProps,
  control,
  wrapperProps,
  errors,
  id,
  onHandleChange
}: IInputImageWithValidationProps) => {
  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.validateImage) {
      message = errorTypes.validateImage;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <InputWrapper invalid={Boolean(errors)} {...wrapperProps}>
      {label && <Label>{label}</Label>}
      <Controller
        control={control}
        name={controllerProps.name}
        rules={controllerProps.rules}
        render={({ field: { onChange, value } }) => (
          <InputImage
            onChange={(file) => {
              onChange(file);
              if (onHandleChange) {
                onHandleChange(file);
              }
            }}
            value={value}
            id={id || controllerProps.name}
            isInvalid={Boolean(errors)}
          />
        )}
      />
      {getInputErrorMessage(errors?.types)}
    </InputWrapper>
  );
};

InputImageWithValidation.defaultProps = {
  label: '',
  wrapperProps: {},
  inputProps: {},
  disabled: false,
  id: '',
  onHandleChange: null
};

export default InputImageWithValidation;
