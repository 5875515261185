import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGenerateCaptiveMethodsTypesObject } from 'src/utils/CaptivePortal/methodsTypes';
import Modal from 'ui-components/Modal';
import { IMethodAttributes, IMethodTypeName } from '../types';

interface IPropsActivateCaptiveInfoModal {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  captiveType: string;
  activeMethod: IMethodAttributes | undefined;
  captiveDefaultId: string;
}

const ActivateCaptiveInfoModal = ({
  show,
  setShow,
  captiveType,
  activeMethod,
  captiveDefaultId
}: IPropsActivateCaptiveInfoModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal'
  });
  const navigate = useNavigate();

  const captiveMethods = useGenerateCaptiveMethodsTypesObject();

  const actions = [
    {
      label: t('NÃO'),
      action: () => setShow(false)
    },
    {
      label: t('SIM'),
      action: () =>
        navigate(`/captive-portal/${captiveDefaultId}/wizard/${captiveType}`)
    }
  ];

  const getMessageModal = () => {
    const { nameType: nameTypeToActive } =
      captiveMethods[captiveType as IMethodTypeName];
    const hasActiveMethod = activeMethod !== undefined;

    const hasActiveMethodDifferent =
      hasActiveMethod && activeMethod.nameType !== nameTypeToActive;

    if (hasActiveMethodDifferent) {
      return t(
        'Atualmente o {{captiveMethodActive}} está ativo. Ao trocar de método, os clientes serão desconectados da rede. Deseja prosseguir e definir o {{captiveMethodToActive}} como o método de autenticação do Captive Portal?',
        {
          captiveMethodActive: activeMethod.nameType,
          captiveMethodToActive: nameTypeToActive
        }
      );
    }

    return t(
      'Deseja definir o {{captiveMethod}} como o método de autenticação do Captive Portal? Ao continuar, você irá passar por 3 etapas para ativar este método de autenticação para todas as wireless deste ambiente. Deseja prosseguir?',
      {
        captiveMethod: captiveMethods[captiveType as IMethodTypeName].nameType
      }
    );
  };

  if (!captiveType) return null;

  return (
    <Modal actions={actions} show={show} width="450px">
      <p className="text-xl-base">{getMessageModal()}</p>
    </Modal>
  );
};

export default ActivateCaptiveInfoModal;
