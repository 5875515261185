import {
  IConfigurationStatus,
  IDataConfigurationList,
  IDataDeviceAdoptingWithErrorList
} from '../../../../templates/MenuAndNavbar/AddDeviceDrawer/types';
import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import { IWireless } from '../sites/types';
import {
  IAddDevice,
  IDevice,
  IDeleteConfigurationResponse,
  IDevicePagination,
  IDataDeviceUpdate,
  IDataDeviceDetail,
  IRetryDataRequest,
  ISiteHasDevice,
  IUpdateRadioData,
  IDeviceProperties,
  IDataFirmwareList,
  IAddAcl,
  IGetDisassociatedWirelessResponse,
  IAclExistsResponse,
  IGetAcl,
  IUpdateSSH
} from './types';

export class DevicesAPI implements IRequestMethods {
  url = '/devices/';

  url_hefesto = '/hefesto/devices/';

  get(params = {}) {
    return axiosInstance.get<IDevicePagination | IDevice>(this.url, {
      headers: getAuthHeaders(),
      params
    });
  }

  post(data: IAddDevice) {
    return axiosInstance.post(this.url, data, {
      headers: getAuthHeaders()
    });
  }

  put(data: IDataDeviceDetail, id: string) {
    return axiosInstance.put(`${this.url}${id}/`, data, {
      headers: getAuthHeaders()
    });
  }

  deleteList(params = [] as string[]) {
    const urlString = `${this.url}?ids=${params.join('&ids=')}`;
    return axiosInstance.delete(urlString, {
      headers: getAuthHeaders()
    });
  }

  reboot(data: { id: string }) {
    return axiosInstance.post(`${this.url}${data.id}/reboot/`, data, {
      headers: getAuthHeaders()
    });
  }

  configurations = {
    getDevicesWithSomeError: (id: string) => {
      return axiosInstance.get<IDataDeviceAdoptingWithErrorList>(
        `${this.url}configurations/groups/${id}`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    get: () => {
      return axiosInstance.get<IDataConfigurationList>(
        `${this.url}configurations/groups/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    delete: (id: string) => {
      return axiosInstance.delete<IDeleteConfigurationResponse>(
        `${this.url}configurations/${id}/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    deleteGroup: (id: string) => {
      return axiosInstance.delete<IDeleteConfigurationResponse>(
        `${this.url}configurations/groups/${id}`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    retry: (data: IRetryDataRequest) => {
      return axiosInstance.post(`${this.url}configurations/retry/`, data, {
        headers: getAuthHeaders()
      });
    },
    getErrorsConfigurationByDevice: (
      siteId: string,
      placeId: string,
      deviceId: string,
      params = {}
    ) => {
      return axiosInstance.get<IDataDeviceAdoptingWithErrorList>(
        `${this.url}sites/${siteId}/places/${placeId}/devices/${deviceId}/configurations/`,
        {
          headers: getAuthHeaders(),
          params
        }
      );
    }
  };

  details = {
    get: (id = '') => {
      return axiosInstance.get<IDevice>(`${this.url}${id}`, {
        headers: getAuthHeaders()
      });
    },
    put: (id: string, data: IDataDeviceUpdate) => {
      return axiosInstance.put<IDevice>(`${this.url}${id}/wireless/`, data, {
        headers: getAuthHeaders()
      });
    }
  };

  status = {
    get: () => {
      return axiosInstance.get<IConfigurationStatus>(
        `${this.url}configurations/status/`,
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  wireless = {
    post: () => {
      return axiosInstance.get<IWireless>(`${this.url}wireless`, {
        headers: getAuthHeaders()
      });
    }
  };

  site = {
    getHasDevices: (id: string) => {
      return axiosInstance.get<ISiteHasDevice>(`${this.url}sites/${id}/`, {
        headers: getAuthHeaders()
      });
    }
  };

  radio = {
    put: (id: string, data: IUpdateRadioData) => {
      return axiosInstance.put(`${this.url}${id}/radios/`, data, {
        headers: getAuthHeaders()
      });
    }
  };

  ssh = {
    put: (id: string, data: IUpdateSSH) => {
      return axiosInstance.put(`${this.url}${id}/ssh/`, data, {
        headers: getAuthHeaders()
      });
    }
  };

  properties = {
    get: (model: string) => {
      return axiosInstance.get<IDeviceProperties>(`${this.url}properties`, {
        headers: getAuthHeaders(),
        params: { model }
      });
    }
  };

  hefesto = {
    get: (params = {}) => {
      return axiosInstance.get<IDevicePagination | IDevice>(this.url_hefesto, {
        headers: getAuthHeaders(),
        params
      });
    },
    details: {
      get: (id = '') => {
        return axiosInstance.get<IDevice>(`${this.url_hefesto}${id}`, {
          headers: getAuthHeaders()
        });
      }
    }
  };

  firmwares = {
    getLastFirmware: (model: string, firmwareVersion: string) => {
      return axiosInstance.get<IDataFirmwareList>(`${this.url}firmwares`, {
        headers: getAuthHeaders(),
        params: {
          device_model: model,
          firmware_version: firmwareVersion,
          only_latest: true
        }
      });
    },
    update: (deviceId: string, firmwareVersion: string) => {
      return axiosInstance.post(
        `${this.url}${deviceId}/firmwares/`,
        { firmware_version: firmwareVersion },
        {
          headers: getAuthHeaders()
        }
      );
    },
    search: (model: string, search: string) => {
      return axiosInstance.get<IDataFirmwareList>(`${this.url}firmwares`, {
        headers: getAuthHeaders(),
        params: {
          device_model: model,
          search_param: search
        }
      });
    }
  };

  acls = {
    post: (data: IAddAcl, siteId = '') => {
      return axiosInstance.post(`${this.url}sites/${siteId}/acls/`, data, {
        headers: getAuthHeaders()
      });
    },

    get: (siteId = '', params = {}) => {
      return axiosInstance.get<IGetAcl>(`${this.url}sites/${siteId}/acls/`, {
        headers: getAuthHeaders(),
        params
      });
    },

    getDisassociatedWireless: (siteId = '') => {
      return axiosInstance.get<IGetDisassociatedWirelessResponse>(
        `${this.url}sites/${siteId}/acls/wireless/disassociated/`,
        {
          headers: getAuthHeaders()
        }
      );
    },

    getAclExists: (name: string, siteId = '') => {
      return axiosInstance.get<IAclExistsResponse>(
        `${this.url}sites/${siteId}/acls/exists/`,
        {
          headers: getAuthHeaders(),
          params: {
            name
          }
        }
      );
    }
  };
}
