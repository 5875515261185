import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './Menu.module.css';

function Menu({
  children,
  items,
  positionY = 'bottom', // center, top, bottom
  positionX = 'middle', //right, left, end, start, middle
  width = '',
  show = false,
  onClose = () => {},
  menuId = '',
  ...props
}) {
  const menuRef = useRef(null);
  const renderArrayOfItems = () => {
    return (
      <>
        {items.map((item) => (
          <MenuItem key={item}>{item}</MenuItem>
        ))}
      </>
    );
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (!show) return;
        onClose();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, show]);

  return (
    <div
      id={menuId}
      className={classNames([
        styles.shellMenu,
        positionX === 'middle' ? styles.shellMenuMiddle : ''
      ])}>
      {children}
      <div>
        <CSSTransition
          in={show}
          timeout={5}
          classNames={{
            enterActive: styles.menuEnterActive,
            enterDone: styles.menuEnterDone,
            exitActive: styles.menuEnterExitActive,
            exitDone: styles.menuDrawerExitDone
          }}
          nodeRef={menuRef}>
          <div
            style={{ '--width-menu': width ? width : 'max-content' }}
            className={classNames([
              styles.menu,
              styles[positionX],
              styles[positionY]
            ])}
            ref={menuRef}
            {...props}>
            {Array.isArray(items) ? renderArrayOfItems() : items}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default Menu;
