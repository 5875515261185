import React from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.css';
import { MdCheck, MdHorizontalRule } from 'react-icons/md';

const Checkbox = ({
  id = '',
  checked = false,
  disabled = false,
  indeterminate = false,
  background = '',
  backgroundActive = '',
  color = '',
  ...props
}) => {
  const chooseCheckmarkCssByChecked = () => {
    if ((backgroundActive && checked) || indeterminate) {
      return {
        background: backgroundActive,
        borderColor: backgroundActive,
        color: color ? color : null
      };
    } else if (background && !checked) {
      return {
        background: background,
        borderColor: background,
        color: color ? color : null
      };
    } else if (!background && backgroundActive && !checked) {
      return {
        borderColor: backgroundActive
      };
    }
    return {};
  };

  return (
    <label
      id={`label-${id}`}
      className={classNames([styles.container, disabled && styles.disabled])}>
      <input
        className={indeterminate ? styles.indeterminate : null}
        {...props}
        id={id}
        disabled={disabled}
        type="checkbox"
        checked={indeterminate || checked}
      />
      <span
        id={`span-${id}`}
        className={classNames([styles.checkmark])}
        style={chooseCheckmarkCssByChecked()}>
        {checked && <MdCheck style={{ color: color ? color : null }}></MdCheck>}
        {indeterminate && (
          <MdHorizontalRule
            style={{ color: color ? color : null }}></MdHorizontalRule>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
