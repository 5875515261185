import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'inmaster-ui';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  MultipleFieldErrors,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import InputOfDatePicker from 'src/pages/CaptivePortal/SimplePassword/DrawerFormSimplePassword/InputOfDatePicker';
import { IFormSimplePassword } from 'src/pages/CaptivePortal/SimplePassword/DrawerFormSimplePassword/types';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputIconInside, InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import { formatExpirationTimeWithEndDate } from 'src/utils/CaptivePortal/captiveDates';
import DatePicker from '../DatePicker';
import { IOption } from '../InputTime/types';
import InputTimeWithValidation from '../InputTimeWithValidation';
import { PasswordVisibilityIcon } from '../PasswordVisibilityIcon';
import SelectWithValidation from '../SelectWithValidation';
import styles from './FormSimplePassword.module.css';

interface IFormSimplePasswordProps {
  errors: FieldErrors<IFormSimplePassword>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IFormSimplePassword, any>;
  watch: UseFormWatch<IFormSimplePassword>;
  setValue: UseFormSetValue<IFormSimplePassword>;
  getValues: UseFormGetValues<IFormSimplePassword>;
  trigger: UseFormTrigger<IFormSimplePassword>;
  resetField: UseFormResetField<IFormSimplePassword>;
}

const FormSimplePassword = ({
  errors,
  control,
  watch,
  setValue,
  getValues,
  trigger,
  resetField
}: IFormSimplePasswordProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [showDatepicker, setShowDatepicker] = React.useState(false);

  const basicRules = {
    required: {
      value: true,
      message: t('Campo obrigatório')
    },
    validate: {
      notWhitespaceOnly: (value: string) =>
        value.trim().length > 0 || (t('Campo obrigatório') as string),
      notWhitespace: (value: string) =>
        value.indexOf(' ') === -1 ||
        (t('A senha não pode conter espaços') as string)
    }
  };

  const passwordRules = {
    ...basicRules,
    minLength: {
      value: 8,
      message: t('A senha deve ter no mínimo 8 e no máximo 63 caracteres')
    },
    maxLength: {
      value: 63,
      message: t('A senha deve ter no mínimo 8 e no máximo 63 caracteres')
    },
    validate: {
      ...basicRules.validate,
      notOutOfASCIIPattern: (value: string) => {
        const isInOfASCIIPattern = /[^\x20-\x7E]/.test(value as string);
        return (
          !isInOfASCIIPattern ||
          (t(
            'Apenas caracteres de Aa-Zz; 1-9 e caracteres especiais no padrão ASCII são permitidos'
          ) as string)
        );
      }
    }
  };

  const handleOnChangePeriod = (dates: [Date, Date]) => {
    const [dateStart, dateEnd] = getValues('simplePassword.periodInDays');

    const dateChangesAndInputWasOpen =
      showDatepicker &&
      dates[0] &&
      dates[1] &&
      dates[0].getTime() !== dates[1].getTime();

    const checkIfHasDateSelectedAndOnlyDateEndWasChange =
      _.isNull(dates[1]) &&
      !_.isNull(dateEnd) &&
      !_.isNull(dateStart) &&
      moment(dates[0])
        // Adiciona 23:59 para que o dia seja contabilizado por completo
        .add('23:59', 'hours')
        .diff(moment(dateStart), 'days') !== 0;

    /*
     * Verifica se apenas uma data foi selecionada e se caso ja tenha sido selecionado
     * alguma data. Apenas a data final deve ser alterada
     */
    if (checkIfHasDateSelectedAndOnlyDateEndWasChange) {
      dates = [dateStart as Date, dates[0]];
    }

    if (
      dateChangesAndInputWasOpen ||
      checkIfHasDateSelectedAndOnlyDateEndWasChange
    ) {
      setShowDatepicker(false);
    }

    setValue('simplePassword.periodInDays', dates);
    trigger('simplePassword.periodInDays');
  };

  const resetPeriodWhenPeriodTypeChanges = () => {
    resetField('simplePassword.periodInDays');
    resetField('simplePassword.periodInTime');
    resetField('simplePassword.periodType');
    setShowDatepicker(false);
  };

  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespace) {
      message = errorTypes.notWhitespace;
    } else if (errorTypes.notDates) {
      message = errorTypes.notDates;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.notOutOfASCIIPattern) {
      message = errorTypes.notOutOfASCIIPattern;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <>
      <InputWrapper invalid={Boolean(errors.simplePassword?.password)}>
        <div className="d-flex align-center">
          <Label>{t('Nova Senha')}</Label>
          <span className={classNames(styles.labelInfoPassword, 'ml-1 mb-1')}>
            {t('(8 a 63 dígitos)')}
          </span>
        </div>

        <Controller
          control={control}
          name="simplePassword.password"
          rules={{
            ...passwordRules
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputIconInside
              iconRight={
                <PasswordVisibilityIcon
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              }>
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                type={showPassword ? 'text' : 'password'}
                id="password-input"
                placeholder={t('Insira a senha')}
                iconInsideRight
                autoComplete="off"
              />
            </InputIconInside>
          )}
        />
        {getInputErrorMessage(errors.simplePassword?.password?.types)}
      </InputWrapper>
      <InputWrapper invalid={Boolean(errors.simplePassword?.confirmPassword)}>
        <Label>{t('Confirme a senha')}</Label>
        <Controller
          control={control}
          name="simplePassword.confirmPassword"
          rules={{
            ...passwordRules
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputIconInside
              iconRight={
                <PasswordVisibilityIcon
                  setShowPassword={setShowConfirmPassword}
                  showPassword={showConfirmPassword}
                />
              }>
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirm-password-input"
                placeholder={t('Insira a senha novamente')}
                iconInsideRight
                autoComplete="off"
              />
            </InputIconInside>
          )}
        />
        {getInputErrorMessage(errors.simplePassword?.confirmPassword?.types)}
      </InputWrapper>
      <Row className="d-flex align-start">
        <Col xs={5} className="pr-3">
          <SelectWithValidation
            handleOnChange={() => resetPeriodWhenPeriodTypeChanges()}
            control={control}
            errors={errors.simplePassword?.periodType}
            label={t('Período de duração')}
            placeholder={t('Selecionar período')}
            controllerProps={{
              name: 'simplePassword.periodType',
              rules: { ...basicRules }
            }}
            options={[
              {
                label: t('Dias'),
                value: 'date'
              },
              {
                label: t('Horas'),
                value: 'time'
              }
            ]}
          />
        </Col>
        <Col xs={7} className="pl-3">
          {watch('simplePassword.periodType') === 'date' && (
            <InputWrapper
              invalid={Boolean(errors.simplePassword?.periodInDays)}>
              <Controller
                control={control}
                name="simplePassword.periodInDays"
                rules={{
                  required: {
                    value: true,
                    message: t('Campo obrigatório')
                  },
                  validate: {
                    notDates: (value) => {
                      const auxValue = value as [Date, Date];
                      return (
                        auxValue.every(
                          (date) => date !== undefined && !_.isNull(date)
                        ) || (t('Campo obrigatório') as string)
                      );
                    }
                  }
                }}
                render={({ field: { value } }) => {
                  const parsedValue = value as [Date, Date];
                  return (
                    <DatePicker
                      onChange={(dates: [Date, Date]) =>
                        handleOnChangePeriod(dates)
                      }
                      placeholder={t('Insira o tempo de duração')}
                      startDate={new Date()}
                      endDate={parsedValue[1]}
                      visible={showDatepicker}
                      onInputClick={() => setShowDatepicker((prev) => !prev)}
                      dateFormat="h:mm"
                      locale="ptBR"
                      selectsRange
                      disabledKeyboardNavigation
                      customInput={
                        <InputOfDatePicker
                          onClick={() => setShowDatepicker((prev) => !prev)}
                          error={
                            errors.simplePassword?.periodInDays as
                              | FieldError
                              | undefined
                          }
                          valueToDisplay={formatExpirationTimeWithEndDate(
                            parsedValue[0],
                            parsedValue[1],
                            t('Insira o tempo de duração')
                          )}
                        />
                      }
                      minDate={new Date()}
                      value={
                        errors.simplePassword?.periodInDays
                          ? 'Invalid'
                          : 'Valid'
                      }
                    />
                  );
                }}
              />
            </InputWrapper>
          )}
          {watch('simplePassword.periodType') === 'time' && (
            <InputTimeWithValidation
              control={control}
              label={t('Tempo')}
              placeholder={t('Insira o tempo de duração')}
              controllerProps={{
                name: 'simplePassword.periodInTime',
                rules: {
                  validate: {
                    notSelectedTime: (value) => {
                      const auxValue = value as IOption;

                      return (
                        auxValue !== undefined ||
                        (t('Campo obrigatório') as string)
                      );
                    }
                  }
                }
              }}
              errors={errors.simplePassword?.periodInTime as FieldError}
              inputProps={{
                id: 'period-input-time'
              }}
              stepMinute={5}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default FormSimplePassword;
