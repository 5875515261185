import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdInfo } from 'react-icons/md';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { PreViewCaptivePortal } from 'src/pages/CaptivePortal/PersonalizeCaptivePortal/PreViewCaptivePortal';
import { IPersonalizeForm } from 'src/pages/CaptivePortal/PersonalizeCaptivePortal/types';
import { ICaptivePortal } from 'src/pages/CaptivePortal/types';
import {
  convertImageToBase64,
  getMimeTypeFromBase64,
  getSizeFromBase64
} from 'src/utils/canvasUtils';
import { isUrl } from 'src/utils/regexUtils';
import { Alert } from 'ui-components/Alert';
import { Button } from 'ui-components/Button';
import Label from 'ui-components/Label';
import CroppedImageModal from '../CroppedImageModal';
import { Divider } from '../Divider';
import InfoModalWithIframe from '../InfoModalWithIframe';
import InputImageWithValidation from '../InputImageWithValidation';
import InputWithValidation from '../InputWithValidation';
import styles from './FormCustomizeCaptive.module.css';

interface IFormCustomizeCaptiveProps {
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  setActiveTab: Dispatch<SetStateAction<number>>;
  watch: UseFormWatch<IPersonalizeForm>;
  setValue: UseFormSetValue<IPersonalizeForm>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IPersonalizeForm, any>;
  errors: FieldErrors<IPersonalizeForm>;
  onSubmit?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  setRestoreFactoryDefaultModal?: React.Dispatch<React.SetStateAction<boolean>>;
  typeChange?: 'restore' | 'save';
  setTypeChange?: React.Dispatch<React.SetStateAction<'restore' | 'save'>>;
  hideSaveButtons?: boolean;
  // currentWizardValues?: IPersonalizeForm;
  isWizard?: boolean;
  currentWizardValues?: ICaptivePortal;
}

const FormCustomizeCaptive = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  isLoadingFormCaptive,
  setActiveTab,
  watch,
  setValue,
  control,
  errors,
  onSubmit,
  setRestoreFactoryDefaultModal,
  typeChange,
  setTypeChange,
  hideSaveButtons,
  currentWizardValues,
  isWizard
}: IFormCustomizeCaptiveProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.personalize'
  });

  const [showPoliticsModal, setShowPoliticsModal] = useState(false);

  const [croppedImageModal, setCroppedImageModal] = useState(false);
  const [imageIsCropped, setImageIsCropped] = useState(false);
  const MAX_IMAGE_SIZE_IN_BYTES = 100000;

  const getActualDataCaptivePortal = () => {
    return {
      ...defaultCaptivePortal,
      name: watch('captivePortal.pageTitle'),
      preview_text: watch('captivePortal.pageText'),
      redirect_url: watch('captivePortal.urlRedirectionAfterLogin'),
      header_image_url: watch('captivePortal.header_image_url')
    } as ICaptivePortal;
  };

  const willChangeFormCaptive = useFormCompare({
    initialValues: {
      ...defaultCaptivePortal,
      header_image_url: defaultCaptivePortal.header_image_url || ''
    },
    currentValues: getActualDataCaptivePortal()
  });

  function isValidImageType(type: string, permittedExtensions: string[]) {
    return permittedExtensions.includes(type);
  }

  function isValidImageSize(sizeInBytes: number) {
    return sizeInBytes <= MAX_IMAGE_SIZE_IN_BYTES;
  }

  const validateImage = (value: unknown) => {
    if (!value) return true;
    const permittedExtensions = ['image/png', 'image/jpg', 'image/jpeg'];

    try {
      if (typeof value === 'string') {
        if (isUrl(value)) return true;
        const [type, size] = [
          getMimeTypeFromBase64(value),
          getSizeFromBase64(value)
        ];

        if (
          !isValidImageType(type, permittedExtensions) ||
          !isValidImageSize(size)
        ) {
          return false;
        }
      } else if (value instanceof File) {
        const { type, size } = value;
        if (
          !isValidImageType(type, permittedExtensions) ||
          !isValidImageSize(size)
        ) {
          return false;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return true;
  };

  React.useEffect(() => {
    if (currentWizardValues) {
      if (validateImage(currentWizardValues.header_image_url)) {
        setImageIsCropped(true);
      }
      return;
    }
    setValue(
      'captivePortal.header_image_url',
      defaultCaptivePortal.header_image_url || ''
    );
    setValue('captivePortal.pageTitle', defaultCaptivePortal?.name);
    setValue('captivePortal.pageText', defaultCaptivePortal?.preview_text);
    setValue(
      'captivePortal.urlRedirectionAfterLogin',
      defaultCaptivePortal?.redirect_url
    );
    setImageIsCropped(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCaptivePortal]);

  const handleChangeImage = async (image: File | null) => {
    if (!image) return;
    const isValid = validateImage(image);
    if (!isValid) {
      setValue('captivePortal.header_image_url', '');
      return;
    }

    if (image.size <= MAX_IMAGE_SIZE_IN_BYTES) {
      setCroppedImageModal(true);
      setImageIsCropped(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const imageInBase64 = await convertImageToBase64(image!);
    setValue('captivePortal.header_image_url', imageInBase64);
  };

  const defaultImageSize = {
    width: 510,
    height: 150
  };

  const getCropProportion = (width: number, height: number) => width / height;

  const handleRestore = () => {
    if (!setTypeChange || !setRestoreFactoryDefaultModal) {
      return;
    }
    setTypeChange('restore');
    setRestoreFactoryDefaultModal(true);
  };

  return (
    <div className="d-flex justify-between fit-width">
      <CroppedImageModal
        show={croppedImageModal}
        onClose={() => setCroppedImageModal(false)}
        onChange={async (image) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const imageInBase64 = await convertImageToBase64(image!);
          setValue('captivePortal.header_image_url', imageInBase64);
          setImageIsCropped(true);
        }}
        imageSrc={watch('captivePortal.header_image_url')}
        imageType={getMimeTypeFromBase64(
          watch('captivePortal.header_image_url')
        )}
        maxZoom={10}
        minZoom={1}
        aspect={getCropProportion(
          defaultImageSize.width,
          defaultImageSize.height
        )}
        onCancel={() => {
          setValue('captivePortal.header_image_url', '');
          setCroppedImageModal(false);
          setImageIsCropped(false);
        }}
        hasCancelModal
        maxFileSizeInBytes={MAX_IMAGE_SIZE_IN_BYTES}
      />

      <InfoModalWithIframe
        show={showPoliticsModal}
        onClose={() => setShowPoliticsModal(false)}
        title={t('Política de Privacidade Intelbras')}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        href={process.env.REACT_APP_TERMO_INTELBRAS_PRIVACY_POLICY!}
      />
      <form className={styles.formInfosCaptive}>
        <div className={classNames('mb-3')}>
          <Label>{t('Imagem de cabeçalho')} </Label>
          <div className="mt-1">
            <InputImageWithValidation
              control={control}
              controllerProps={{
                name: 'captivePortal.header_image_url',
                rules: {
                  validate: {
                    validateImage: (value) =>
                      validateImage(value) ||
                      t(
                        'Extensão da imagem não permitida ou tamanho superior a 100kB'
                      )
                  }
                }
              }}
              errors={errors?.captivePortal?.header_image_url}
              id="imageInputCaptivePortal"
              onHandleChange={async (image) => handleChangeImage(image)}
            />
          </div>
          <div className="mt-2">
            <Alert type="info">
              {t(
                'Extensões permitidas: jpg, jpeg, png. Tamanho máximo permitido: 100kB'
              )}
            </Alert>
          </div>
        </div>
        <div className={classNames('mb-3')}>
          <InputWithValidation
            label={t('Título da Página')}
            hasRequiredIndicator
            key="pageTitle"
            id="pageTitle"
            control={control}
            controllerProps={{
              name: 'captivePortal.pageTitle',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                minLength: {
                  value: 3,
                  message: t('O título precisa ter entre 3 e 64 caracteres')
                },
                maxLength: {
                  value: 64,
                  message: t('O título precisa ter entre 3 e 64 caracteres')
                }
              }
            }}
            errors={errors?.captivePortal?.pageTitle}
          />
        </div>
        <div className={classNames('mb-3')}>
          <InputWithValidation
            label={t('Texto')}
            control={control}
            id="pageText"
            controllerProps={{
              name: 'captivePortal.pageText',
              rules: {
                maxLength: {
                  value: 200,
                  message: t('O campo permite até 200 caracteres')
                }
              }
            }}
            errors={errors?.captivePortal?.pageText}
          />
        </div>
        <div className={classNames('mb-3')}>
          <div className="d-flex align-start">
            <Label hasRequiredIndicator>
              <span
                className={
                  errors.captivePortal?.urlRedirectionAfterLogin &&
                  styles.labelWithError
                }>
                {t('Redirecionamento após login')}
              </span>{' '}
            </Label>
            <span className={styles.labelWarning}>
              ({t('inserir URL completa')})
            </span>
          </div>

          <InputWithValidation
            placeholder={t('ex: https://site.com.br')}
            control={control}
            id="urlRedirectionAfterLogin"
            controllerProps={{
              name: 'captivePortal.urlRedirectionAfterLogin',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                pattern: {
                  value:
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/i,
                  message: t(
                    'URL inválido. Verifique se o link possui http ou https'
                  )
                }
              }
            }}
            errors={errors?.captivePortal?.urlRedirectionAfterLogin}
          />
        </div>
        <button
          type="button"
          id="btnPrivacyPolitics"
          onClick={() => setShowPoliticsModal(true)}
          className={classNames(
            'd-flex align-center pt-2',
            styles.politicsText
          )}>
          <MdInfo size={14} color="var(--color-brand-primary-darkest)" />

          <span className="text-sm-lg ml-2 text-bold">
            {t('Política de Privacidade Intelbras')}
          </span>
        </button>
        {hideSaveButtons ? null : (
          <div
            className={classNames(
              'd-flex',
              'justify-end',
              'fit-width',
              'mt-8'
            )}>
            <Button
              className={classNames([styles.buttons])}
              id="btnRestoreFactoryDefault"
              outline
              type="button"
              disabled={isLoadingFormCaptive && typeChange !== 'restore'}
              isLoading={isLoadingFormCaptive && typeChange === 'restore'}
              onClick={() => handleRestore()}>
              {t('Restaurar')}
            </Button>
            <Button
              className={classNames([styles.buttons])}
              id="btnSave"
              type="submit"
              disabled={
                willChangeFormCaptive ||
                (isLoadingFormCaptive && typeChange !== 'save') ||
                !!errors.captivePortal
              }
              isLoading={isLoadingFormCaptive && typeChange === 'save'}
              onClick={onSubmit}>
              {t('Salvar')}
            </Button>
          </div>
        )}
      </form>
      <Divider orientation="vertical" style={{ height: 'inherit' }} />
      <div className={styles.formPreview}>
        <PreViewCaptivePortal
          defaultCaptivePortal={
            defaultCaptivePortal && getActualDataCaptivePortal()
          }
          isFetchedCaptivePortal={isFetchedCaptivePortal}
          viewFormData={{
            captivePortal: {
              ...watch().captivePortal,
              header_image_url:
                !errors?.captivePortal?.header_image_url && imageIsCropped
                  ? watch('captivePortal.header_image_url')
                  : ''
            }
          }}
          setActiveTab={setActiveTab}
          isWizard={isWizard}
        />
      </div>
    </div>
  );
};

FormCustomizeCaptive.defaultProps = {
  onSubmit: undefined,
  setRestoreFactoryDefaultModal: undefined,
  setTypeChange: undefined,
  typeChange: undefined,
  hideSaveButtons: false,
  currentWizardValues: undefined,
  isWizard: false
};

export default FormCustomizeCaptive;
