import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'ui-components/Tags';
import Card from 'ui-components/Card';
import { ButtonIcon } from 'ui-components/Button';
import { MdUpload } from 'react-icons/md';
import moment from 'moment';
import { Tooltip } from 'inmaster-ui';
import styles from './UpdateToLatestFirmwareVersionModal.module.css';
import { IFirmware } from '../types';

interface IProps {
  handleSelectFirmwareVersion: (firmwareVersion: string) => void;
  latestFirmwareVersion: IFirmware;
}

const UpdateToLatestFirmwareVersionModal = ({
  handleSelectFirmwareVersion,
  latestFirmwareVersion
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const [showChangeLog, setShowChangeLog] = useState(false);

  function formatChangeLog(changeLog: string) {
    const regex = /(\\r|\\t|\\n|»)/gm;

    const formattedChangeLog = changeLog.replace(regex, ' ');
    return formattedChangeLog;
  }

  return (
    <div className="d-flex align-center justify-center flex-column">
      <Card
        borderColor="var(--color-brand-secondary-light)"
        className="pa-1 px-4 mb-2 fit-width">
        <div className="d-flex justify-between align-center">
          <div className={styles.cardInfos}>
            <div className={styles.cardItem}>
              <span className="text-bold">{t('Versão')}:</span>
              <span
                className="ml-2"
                id={`number-of-version-${latestFirmwareVersion.version}`}>
                {latestFirmwareVersion.version}
              </span>
            </div>
            <div className={styles.cardItem}>
              <span className="text-bold">{t('Última atualização')}: </span>
              <span
                className="ml-2"
                id={`last-update-date-${latestFirmwareVersion.updated_at}`}>
                {moment(latestFirmwareVersion.updated_at).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <Tag color="mustard">Mais recente</Tag>
            </div>
            <div className={styles.cardItem}>
              <button
                className={styles.textButton}
                type="button"
                onClick={() => setShowChangeLog((prev) => !prev)}>
                {showChangeLog ? t('Ver menos') : t('Changelog')}
              </button>
              <Tooltip
                content={t('Atualizar firmware')}
                id="tooltip-upload-firmware"
                place="bottom">
                <ButtonIcon
                  className="ml-4"
                  onClick={() =>
                    handleSelectFirmwareVersion(latestFirmwareVersion.version)
                  }>
                  <MdUpload size={24} />
                </ButtonIcon>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className={
            showChangeLog ? styles.changeLogShell : styles.changeLogShellClosed
          }>
          <span>{formatChangeLog(latestFirmwareVersion.changelog)}</span>
        </div>
      </Card>
    </div>
  );
};

export default UpdateToLatestFirmwareVersionModal;
