import React from 'react';
import classNames from 'classnames';

import { Table } from 'src/components/Table';
import { TablePagination } from 'src/components/TablePagination';
import type { IRowTable } from 'src/components/Table/types';
import type { IDataTable } from './types';

const DataTable = ({
  tableProps = {
    columns: [],
    rows: [],
    selectedRows: [],
    hasRowSelection: false,
    onSelectRows: undefined,
    hasStatus: false,
    disableSelection: false,
    className: ''
  },
  paginationProps = {
    countItems: 0,
    pageIndex: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100, { label: 'Todas', value: -1 }],
    countSelectedItems: null,
    labelSelectedItems: 'Selecionado(s)',
    labelRowsPerPage: 'Linhas por página',
    labelRangePage: 'de',
    onPageChange: () => {},
    onRowsPerPageChange: () => {}
  },
  isLoadingOnChangePage,
  renderLoadingState,
  className,
  ...rest
}: IDataTable) => {
  const [selectedRows, setSelectedRows] = React.useState<IRowTable[]>(
    tableProps.selectedRows || []
  );

  // Função que gerencia a seleção das linhas da tabela em relação as páginas
  const onSelectRows = (rows: IRowTable[]) => {
    const rowsPageSet = new Set(tableProps.rows);
    const rowsSelectedSet = new Set(selectedRows);
    const newRowsSelectedSet = new Set(rows);

    /*
     * Verifica se a linha está na página atual e está selecionada
     * ou se ela está em outra página
     */
    const checkIfRowIsInPageOrAnotherPage = (row: IRowTable) => {
      return (
        (rowsPageSet.has(row) && newRowsSelectedSet.has(row)) ||
        !rowsPageSet.has(row)
      );
    };

    // Adiciona todas as linhas selecionadas ao conjunto (set)
    rows.forEach((row) => {
      rowsSelectedSet.add(row);
    });

    // Cria um novo array sem as linhas desmarcadas da pagina atual
    const newSelectedRows = Array.from(rowsSelectedSet).filter((row) => {
      return checkIfRowIsInPageOrAnotherPage(row);
    });

    setSelectedRows(newSelectedRows);
  };

  /*
   * Função para renderizar a tabela ou mostrar o loading passado por props,
   * ao mudar de página
   */
  const renderTable = () => {
    if (isLoadingOnChangePage) {
      return renderLoadingState && renderLoadingState();
    }
    return (
      <Table
        {...tableProps}
        selectedRows={selectedRows}
        onSelectRows={(rows) => onSelectRows(rows)}
      />
    );
  };

  // Evento que é disparado ao selecionar novas linhas
  React.useEffect(() => {
    if (tableProps.onSelectRows) {
      tableProps.onSelectRows(selectedRows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  return (
    <div className={classNames(['d-flex flex-column', className])} {...rest}>
      {renderTable()}

      {paginationProps.countItems > 10 && (
        <TablePagination
          {...paginationProps}
          countSelectedItems={selectedRows.length}
        />
      )}
    </div>
  );
};

DataTable.defaultProps = {
  isLoadingOnChangePage: false,
  renderLoadingState: () => {
    return <div />;
  }
};

export default DataTable;
