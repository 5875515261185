import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import {
  IConnectionLogPagination,
  IDataDeletePlace,
  IDataDeleteSite,
  IDataPlace,
  IDataSite,
  IMembersRequest,
  IMembersResponse,
  IMembersUpdateRequest,
  INewCountResponse,
  IPatchMemberData,
  IPlaceResponseUnique,
  IPlacesResponse,
  IPostMembersResponse,
  IPutMembersResponse,
  ISharedSiteResponse,
  ISiteResponse,
  ISiteResponseUnique
} from './types';

export class SitesAPI implements IRequestMethods {
  url = 'access/sites/';

  url_places = 'access/places/';

  url_hefesto = 'hefesto/sites/';

  url_hefesto_events_connection = 'hefesto/sites/';

  get(id = '', getByHefesto = false) {
    const userRolesParams = ['owner'].join('&role=');
    return axiosInstance.get<ISiteResponse | ISiteResponseUnique>(
      // eslint-disable-next-line prettier/prettier
      `${
        (getByHefesto ? this.url_hefesto : this.url) + id
      }?role=${userRolesParams}`,
      {
        headers: getAuthHeaders()
      }
    );
  }

  search(searchParam = '') {
    return axiosInstance.get<ISiteResponse>(this.url, {
      headers: getAuthHeaders(),
      params: { search_param: searchParam }
    });
  }

  post(data: IDataSite) {
    return axiosInstance.post(this.url, data, { headers: getAuthHeaders() });
  }

  put(data: IDataSite, id: string) {
    return axiosInstance.put(`${this.url}${id}/`, data, {
      headers: getAuthHeaders()
    });
  }

  delete(id: string, data: IDataDeleteSite) {
    return axiosInstance.delete(`${this.url}${id}/`, {
      headers: getAuthHeaders(),
      data
    });
  }

  connection_log = {
    get: (siteId: string, params = {}) => {
      return axiosInstance.get<IConnectionLogPagination>(
        `${this.url_hefesto_events_connection}${siteId}/events/connections`,
        {
          headers: getAuthHeaders(),
          params
        }
      );
    },
    export: (siteId: string, params = {}) => {
      return axiosInstance.get(
        `${this.url_hefesto_events_connection}${siteId}/events/connections/export`,
        {
          headers: {
            ...getAuthHeaders()
          },
          params,
          responseType: 'blob'
        }
      );
    }
  };

  shared = {
    getNewSitesCount: () => {
      return axiosInstance.get<INewCountResponse>(`${this.url}count_new/`, {
        headers: getAuthHeaders()
      });
    },

    get: (id = '', getByHefesto = false) => {
      const userRolesParams = ['guest', 'manager'].join('&role=');
      return axiosInstance.get<ISharedSiteResponse>(
        // eslint-disable-next-line prettier/prettier
        `${
          (getByHefesto ? this.url_hefesto : this.url) + id
        }?role=${userRolesParams}&include_member=true`,
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  members = {
    post: (data: IMembersRequest, siteID: string) => {
      return axiosInstance.post<IPostMembersResponse>(
        `${this.url}${siteID}/members/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    put: (data: IMembersUpdateRequest, siteID: string, memberID: string) => {
      return axiosInstance.put<IPutMembersResponse>(
        `${this.url}${siteID}/members/${memberID}/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    get: (siteID: string) => {
      return axiosInstance.get<IMembersResponse>(
        `${this.url}${siteID}/members/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    delete: (siteID: string, memberID: string) => {
      return axiosInstance.delete(`${this.url}${siteID}/members/${memberID}/`, {
        headers: getAuthHeaders()
      });
    },
    patchNewSiteStatus: (
      siteID: string,
      memberID: string,
      data: IPatchMemberData
    ) => {
      return axiosInstance.patch(
        `${this.url}${siteID}/members/${memberID}/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    }
  };

  hefesto = {
    places: {
      get: (siteId: string) => {
        return axiosInstance.get<IPlacesResponse>(
          `${this.url_hefesto}${siteId}/places`,
          {
            headers: getAuthHeaders()
          }
        );
      },
      details: {
        get: (sitedId: string, placeId: string) => {
          return axiosInstance.get<IPlaceResponseUnique>(
            `${this.url_hefesto}${sitedId}/places/${placeId}`,
            {
              headers: getAuthHeaders()
            }
          );
        }
      }
    }
  };

  places = {
    get: (siteId = '') => {
      return axiosInstance.get<IPlacesResponse>(
        `${this.url}${siteId}/places/`,
        {
          headers: getAuthHeaders()
        }
      );
    },
    search: (searchParam = '', siteId = '') => {
      return axiosInstance.get<IPlacesResponse>(
        `${this.url}${siteId}/places/`,
        {
          headers: getAuthHeaders(),
          params: { search_param: searchParam }
        }
      );
    },
    post: (data: IDataPlace, siteId = '') => {
      return axiosInstance.post(`${this.url}${siteId}/places/`, data, {
        headers: getAuthHeaders()
      });
    },
    delete: (id: string, siteId: string, data: IDataDeletePlace) => {
      return axiosInstance.delete(`${this.url}${siteId}/places/${id}/`, {
        headers: getAuthHeaders(),
        data
      });
    },
    put: (data: IDataPlace, siteId: string, placeId: string) => {
      return axiosInstance.put(
        `${this.url}${siteId}/places/${placeId}/`,
        data,
        {
          headers: getAuthHeaders()
        }
      );
    },
    details: {
      get: (id = '') => {
        return axiosInstance.get<IPlaceResponseUnique>(
          `${this.url_places}/${id}`,
          {
            headers: getAuthHeaders()
          }
        );
      }
    }
  };
}
