import React from 'react';
import { Col, Grid, Row } from 'inmaster-ui';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import InputWithValidation from 'src/components/InputWithValidation';
import { AclType } from 'src/services/api/urls/devices/types';
import { Button } from 'ui-components/Button';
import Label from 'ui-components/Label';
import RadioButton from 'ui-components/RadioButton';
import Tabs from 'ui-components/Tab';
import { ICustomer } from '../../CustomersTab/types';
import { IWireless } from '../../WirelessTab/types';
import styles from './SummaryStep.module.css';

interface IProps {
  control: Control<
    {
      acl: {
        name: string;
        type: AclType;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  errors: FieldErrors<{
    acl: {
      name: string;
      type: AclType;
    };
  }>;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  customers: ICustomer[];
  wirelessList: IWireless[];
  tabs: {
    label: string;
    component: JSX.Element;
  }[];
  onSubmit: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  isLoading: boolean;
}

const SummaryStep = ({
  control,
  errors,
  customers,
  handleRadioChange,
  isValid,
  setShowCancelModal,
  wirelessList,
  tabs,
  onSubmit,
  isLoading
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });
  const isFetchedAfterMount = true;

  return (
    <Grid fluid>
      <Row className="mb-4">
        <Col xs={12}>
          {isFetchedAfterMount ? (
            <Label>{t('Nome da lista')}:</Label>
          ) : (
            <Skeleton width={120} height={19} />
          )}
          {isFetchedAfterMount ? (
            <InputWithValidation
              inputProps={{
                id: 'input-name-list'
              }}
              placeholder={t('Ex.: Impressoras')}
              control={control}
              controllerProps={{
                name: 'acl.name',
                rules: {
                  required: {
                    value: true,
                    message: t('Campo obrigatório')
                  },
                  maxLength: {
                    value: 32,
                    message: t(
                      t('O nome da lista precisa ter entre 1 e 32 caracteres')
                    )
                  },
                  validate: {
                    notWhitespaceOnly: (value) =>
                      value.trim().length > 0 ||
                      (t('Campo obrigatório') as string),
                    notStartsOrEndsWithWhitespace: (value) =>
                      (!value.startsWith(' ') && !value.endsWith(' ')) ||
                      (t(
                        'O nome da lista não pode começar nem terminar com um espaço'
                      ) as string)
                  }
                }
              }}
              errors={errors.acl?.name}
            />
          ) : (
            <Skeleton height={51.1} />
          )}
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={12}>
          {isFetchedAfterMount ? (
            <Label>{t('Selecione o tipo de lista')}:</Label>
          ) : (
            <Skeleton width={150} height={19} />
          )}
        </Col>
      </Row>

      <Row className="mb-7">
        <Col xs={12}>
          {isFetchedAfterMount ? (
            <Controller
              control={control}
              name="acl.type"
              render={({ field: { value } }) => (
                <div className="d-flex aling-center fit-width">
                  <div className={styles.spacingTypeOfList}>
                    <RadioButton
                      checked={value === AclType.ALLOW_LIST}
                      value={AclType.ALLOW_LIST}
                      id="btn-allow-list"
                      onChange={handleRadioChange}
                    />
                    <div className="d-flex flex-column">
                      <Label element="radiobutton" className="text-sm-sm">
                        {t('Lista de Permissão')}
                      </Label>

                      <Label
                        element="radiobutton"
                        className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                        {t(
                          'Clientes cadastrados nesta lista acessarão as Redes Wireless vinculados à ela'
                        )}
                      </Label>
                    </div>
                  </div>

                  <div>
                    <RadioButton
                      checked={value === AclType.DENY_LIST}
                      value={AclType.DENY_LIST}
                      id="btn-deny-list"
                      onChange={handleRadioChange}
                    />
                    <div className="d-flex flex-column">
                      <Label element="radiobutton" className="text-sm-sm">
                        {t('Lista de Bloqueio')}
                      </Label>

                      <Label
                        element="radiobutton"
                        className={`text-sm-xs ${styles['color-text-subtitle']}`}>
                        {t(
                          'Clientes cadastrados nesta lista não acessarão as Redes Wireless vinculados à ela'
                        )}
                      </Label>
                    </div>
                  </div>
                </div>
              )}
            />
          ) : (
            <Skeleton height={51.1} />
          )}
        </Col>
      </Row>
      <Row className="mb-9">
        <Col xs={12}>
          <Tabs tabs={tabs} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex justify-end">
          <Button
            ghost
            className="mr-3"
            onClick={() => setShowCancelModal(true)}
            disabled={isLoading}>
            {t('CANCELAR')}
          </Button>
          <Button
            isLoading={isLoading}
            onClick={onSubmit}
            disabled={
              !isValid || customers.length === 0 || wirelessList.length === 0
            }>
            {t('SALVAR')}
          </Button>
        </Col>
      </Row>
    </Grid>
  );
};

export default SummaryStep;
