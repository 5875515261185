import React, { useEffect, cloneElement, useRef } from 'react';
import classNames from 'classnames';
import {
  MdCancel,
  MdCheckCircle,
  MdClose,
  MdInfo,
  MdWarning
} from 'react-icons/md';
import styles from './Toast.module.css';

const Toast = ({
  toastList = [],
  onDismiss = (id) => {},
  position = 'top-right',
  closable = false,
  autoDismiss = true,
  dismissTime = 60000,
  className = ''
}) => {
  const toastListRef = useRef([]);

  const getIcon = (toast) => {
    const iconOptions = {
      success: (
        <MdCheckCircle
          className={styles.toastIcon}
          color="var(--color-neutral-light-5)"
          size="24px"
        />
      ),
      error: (
        <MdCancel
          className={styles.toastIcon}
          color="var(--color-neutral-light-5)"
          size="24px"
        />
      ),
      warning: (
        <MdWarning
          className={styles.toastIcon}
          color="var(--color-status-alert-base)"
          size="24px"
        />
      ),
      info: (
        <MdInfo
          className={styles.toastIcon}
          color="var(--color-neutral-light-5)"
          size="24px"
        />
      ),
      default: null
    };

    if (toast.customIcon) {
      return cloneElement(toast.customIcon, { className: styles.toastIcon });
    } else {
      return iconOptions[toast.type];
    }
  };

  const hideToast = (id) => {
    const index = toastListRef.current.findIndex(
      (t) => t.getAttribute('data-id') === id
    );

    toastListRef.current[index].classList.add(styles.toastHide);

    setTimeout(() => {
      toastListRef.current[index].classList.add(styles.toastDisplayNone);
    }, 700);
  };

  const clearToastList = () => {
    for (let i = 0; i < toastList.length; i++) {
      onDismiss(toastList[i].id);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        autoDismiss &&
        toastList.length > 0 &&
        toastListRef.current.length > 0
      ) {
        const firstVisibleToast = toastListRef.current.find((toastRef) => {
          if (toastRef !== null) {
            return !toastRef.classList.contains(styles.toastHide);
          }
        });

        if (firstVisibleToast) {
          const toastId = firstVisibleToast.getAttribute('data-id');
          const toastIndex = toastList.findIndex((t) => t.id === toastId);

          if (toastList[toastIndex].options.autoDismiss) {
            hideToast(toastId);
          }
        } else {
          clearToastList();
        }
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, autoDismiss, dismissTime]);

  return (
    <div
      className={classNames([
        styles.toastContainer,
        styles[position],
        className
      ])}
      id="toast-list">
      {toastList.map((toast, i) => (
        <div
          key={i}
          className={classNames([styles.toast, styles[toast.type]])}
          id={`toast-${toast.type}-${i}`}
          ref={(el) => (toastListRef.current[i] = el)}
          data-id={toast.id}>
          {getIcon(toast)}

          <div className={styles.toastContent}>{toast.content}</div>

          {closable || toast.options.closable ? (
            <MdClose
              id={`btn-close-toast-${i}`}
              className={styles.closeButton}
              onClick={() => hideToast(toast.id)}
              color="var(--color-neutral-light-5)"
              aria-label="Dispensar tooltip"
              size="24px"
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Toast;
