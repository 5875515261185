import { Button as MUIButton } from '@mui/base/Button';
import classNames from 'classnames';
import React from 'react';
import type { IColors } from 'src/components/Loading/types';
import { Loading } from 'src/components/Loading';
import styles from './Button.module.css';
import type { IButton } from './types';

const Button = React.forwardRef(function Button(
  props: IButton,
  ref: React.ForwardedRef<HTMLButtonElement> | null
) {
  const {
    id,
    isLoading,
    children,
    category,
    shape,
    elementOutside,
    disabled,
    className,
    onClick,
    ...rest
  } = props;

  const renderColorLoading = () => {
    switch (category) {
      case 'primary':
        return {
          inside: 'var(--neutral-white-lightest)',
          outside: 'var(--primary-lightest)'
        } as IColors;
      case 'secondary':
        return {
          inside: 'var(--neutral-white-lightest)',
          outside: 'var(--secondary-lightest)'
        } as IColors;
      case 'tertiary':
        return {
          inside: 'var(--helper-base)',
          outside: 'var(--helper-light)'
        } as IColors;
      case 'icon':
        return {
          inside: 'var(--helper-base)',
          outside: 'var(--helper-light)'
        } as IColors;
      default:
        return {
          inside: 'var(--helper-base)',
          outside: 'var(--helper-light)'
        } as IColors;
    }
  };
  return (
    <div className="d-flex align-center justify-center">
      <MUIButton
        {...rest}
        className={classNames([
          styles.button,
          styles[category || 'primary'],
          shape === 'rounded' && styles.rounded,
          isLoading && styles[`${category}Loading`],
          className
        ])}
        onClick={onClick}
        disabled={disabled}
        id={id}
        ref={ref}>
        {!isLoading ? (
          children
        ) : (
          <span className="d-flex align-center justify-center">
            <Loading
              indeterminate
              value={70}
              width={16}
              height={16}
              radius={5.7}
              colors={renderColorLoading()}
            />
          </span>
        )}
      </MUIButton>
      {shape === 'rounded' && (
        <div
          className={classNames([
            'title-heading-4',
            styles.labelRoundedButton,
            disabled && styles.labelRoundedDisable
          ])}>
          {elementOutside}
        </div>
      )}
    </div>
  );
});

Button.defaultProps = {
  isLoading: false,
  disabled: false,
  category: 'default',
  shape: 'square',
  elementOutside: null,
  className: '',
  onClick: () => {}
};

export default Button;
