import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'ui-components/Checkbox';
import Label from 'ui-components/Label';
import Modal from 'ui-components/Modal';

interface IConfirmDisableMethodCaptiveModal {
  showModalDisableMethod: boolean;
  isLoadingDisableMethod: boolean;
  onConfirmDisableMethod: () => void;
  onCancelDisableMethod: () => void;
}

const ConfirmDisableMethodCaptiveModal = ({
  showModalDisableMethod,
  isLoadingDisableMethod,
  onConfirmDisableMethod,
  onCancelDisableMethod
}: IConfirmDisableMethodCaptiveModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.activeMethodCaptiveButton'
  });

  const [isConfirmed, setIsConfirmed] = React.useState(false);

  return (
    <Modal
      show={showModalDisableMethod}
      width="500px"
      height="320px"
      actions={[
        {
          label: t('VOLTAR'),
          action: () => {
            onCancelDisableMethod();
            setIsConfirmed(false);
          },
          disabled: isLoadingDisableMethod
        },
        {
          label: t('DESATIVAR CAPTIVE'),
          action: () => onConfirmDisableMethod(),
          isLoading: isLoadingDisableMethod,
          disabled: !isConfirmed,
          type: 'secondary'
        }
      ]}>
      <p>
        {t(
          'Ao desativar o Captive, todas as wireless vinculadas deste ambiente retornarão às suas seguranças anteriores, e os clientes serão desconectados da rede'
        )}
        .
      </p>
      <p>
        {t(
          'Este processo poderá levar alguns minutos até ser concluído. Enquanto isso, não será possível fazer alterações no Captive Portal'
        )}
        .
      </p>
      <div className="d-flex mt-4">
        <Checkbox
          checked={isConfirmed}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsConfirmed(event.target.checked);
          }}
        />
        <Label className="text-base-base ml-3">
          {t('Estou ciente e desejo continuar')}
        </Label>
      </div>
    </Modal>
  );
};

export { ConfirmDisableMethodCaptiveModal };
