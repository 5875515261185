/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IRadioBand, IRadioData } from 'src/services/api/urls/devices/types';
import { ExpansionCard } from 'ui-components/ExpansionCard';
import Select from 'ui-components/Select';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import ItemAndDescription from '../../ItemAndDescription';
import styles from './RadioCard.module.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  isEditing: boolean;
  radioData: IRadioData | null;
  setRadiosData: Dispatch<SetStateAction<IRadioData[] | null>>;
  radioBandRules: IRadioBand | null;
  isLoading: boolean;
}

interface IChannelExtensionFormat {
  Automatic: string;
}

interface ISelectOptions {
  value: string | number;
  label: string | number;
  id?: string;
}

interface IRadioOptions {
  ieee_standard_80211: ISelectOptions[];
  channel_value: ISelectOptions[];
  channel_bandwidth: ISelectOptions[];
  power: ISelectOptions[];
}

const RadioCard = ({
  title,
  isEditing,
  radioData,
  setRadiosData,
  radioBandRules,
  isLoading
}: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const [radioOptions, setRadioOptions] = useState<IRadioOptions>(
    {} as IRadioOptions
  );

  const channelExtensionFormat = {
    Automatic: t('Automática'),
    Below: t('Acima'),
    Above: t('Abaixo')
  };

  const { control, watch, reset } = useForm<IRadioData>({
    defaultValues: radioData ?? {}
  });

  const formatChannelValue = (value: number) => {
    if (value === 0) {
      return t('Automático');
    }

    return String(value);
  };

  useEffect(() => {
    if (isEditing) {
      setRadiosData(
        (prev) =>
          prev &&
          prev.map((radio) => (radio.id === radioData?.id ? watch() : radio))
      );
    }
  }, [
    watch('ieee_standard_80211'),
    watch('channel_value'),
    watch('band'),
    watch('channel_bandwidth'),
    watch('power'),
    isEditing
  ]);

  useEffect(() => {
    reset(radioData ?? {});
  }, [reset, radioData, isEditing]);

  useEffect(() => {
    const channelsOptions = Object.keys(radioBandRules?.channel_list || {}).map(
      (value) => {
        if (value === '0') {
          return {
            value,
            label: t('Automático')
          };
        }
        return {
          value,
          label: value
        };
      }
    );

    const powerOptions = _.range(
      Number(
        radioBandRules?.channel_list[watch('channel_value')]?.min_tx_power
      ),
      Number(
        radioBandRules?.channel_list[watch('channel_value')]?.max_tx_power
      ) + 1
    ).map((value) => {
      return {
        value: String(value),
        label: value
      };
    });

    const IEEEOptions =
      radioBandRules?.channel_list[watch('channel_value')]?.phy_modes.map(
        (value) => {
          return {
            value,
            label: value
          };
        }
      ) || [];

    const bandwidthOptions =
      radioBandRules?.phy_modes[watch('ieee_standard_80211')].bandwidth.map(
        (value) => {
          return {
            value,
            label: `${value} MHz`
          };
        }
      ) || [];

    setRadioOptions({
      ieee_standard_80211: IEEEOptions,
      channel_value: channelsOptions,
      channel_bandwidth: bandwidthOptions,
      power: powerOptions
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioBandRules, watch('channel_value'), watch('ieee_standard_80211')]);

  return (
    <ExpansionCard
      className="mt-4"
      alwaysOpen
      header={
        !isLoading ? (
          <div className="d-flex justify-between align-center fit-width">
            <span className="title-sm-base">{title}</span>
          </div>
        ) : (
          <Skeleton width={130} height={18} />
        )
      }>
      <div className={styles.innerCardRadio}>
        <div>
          {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Canal:"
              description={
                !isEditing ? (
                  formatChannelValue(radioData?.channel_value ?? 0)
                ) : (
                  <Controller
                    control={control}
                    name="channel_value"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        getLastOptionAsDefault
                        id={`${title}-channel_value`}
                        options={radioOptions.channel_value}
                        value={String(value)}
                        onChange={(e) => onChange(parseInt(e, 10))}
                      />
                    )}
                  />
                )
              }
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
          {!isLoading ? (
            <ItemAndDescription
              small
              title="Padrão IEEE 802.11:"
              description={
                !isEditing ? (
                  radioData?.ieee_standard_80211 ?? ''
                ) : (
                  <Controller
                    control={control}
                    name="ieee_standard_80211"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        getLastOptionAsDefault
                        id={`${title}-ieee_standard_80211`}
                        options={radioOptions.ieee_standard_80211}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              }
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
        <div>
          {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Extensão de canal:"
              description={
                !isEditing ? (
                  channelExtensionFormat[
                    radioData?.channel_extension as keyof IChannelExtensionFormat
                  ]
                ) : (
                  <Controller
                    control={control}
                    name="channel_extension"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        getLastOptionAsDefault
                        id={`${title}-channel_extension`}
                        options={[
                          {
                            label: t('Automático'),
                            value: 'Automatic'
                          }
                        ]}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              }
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
          {!isLoading ? (
            <ItemAndDescription
              small
              title="Largura de Banda:"
              description={
                !isEditing ? (
                  `${radioData?.channel_bandwidth} MHz`
                ) : (
                  <Controller
                    control={control}
                    name="channel_bandwidth"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        getLastOptionAsDefault
                        id={`${title}-channel_bandwidth`}
                        options={radioOptions.channel_bandwidth}
                        value={String(value)}
                        onChange={onChange}
                      />
                    )}
                  />
                )
              }
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
        <div>
          {!isLoading ? (
            <ItemAndDescription
              className="mb-4"
              small
              title="Potência:"
              description={
                !isEditing ? (
                  String(radioData?.power)
                ) : (
                  <Controller
                    control={control}
                    name="power"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        getLastOptionAsDefault
                        id={`${title}-power`}
                        options={radioOptions.power}
                        value={String(value)}
                        onChange={(e) => onChange(parseInt(e, 10))}
                      />
                    )}
                  />
                )
              }
            />
          ) : (
            <>
              <Skeleton />
              <Skeleton width="40%" />
            </>
          )}
        </div>
      </div>
    </ExpansionCard>
  );
};

export default RadioCard;
