import React, { useState } from 'react';
import { Col, Grid } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Divider } from 'src/components/Divider';
import { useTemplate } from 'src/hooks/useTemplate';
import api from 'src/services/api';
import Card from 'ui-components/Card';
import { IDeviceOnScreen } from '../DeviceDetails/types';
import { HeaderDeviceDetails } from '../HeaderDeviceDetails';
import ConnectedReceivers from './ConnectedReceivers';

const WomCFTVDetails = () => {
  const params = useParams();
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  useTemplate('menuAndFullNavbar');

  const [deviceOnScreen, setDeviceOnScreen] = useState({} as IDeviceOnScreen);

  const getDeviceOnScreen = () => {
    return api.devices.hefesto.details.get(params.id);
  };

  const {
    refetch: refetchDeviceInfo,
    isFetchedAfterMount: isFetchedAfterMountDeviceInfo
  } = useQuery(['deviceScreenQuery', params.id], getDeviceOnScreen, {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }: { data: IDeviceOnScreen }) => {
      data = { ...data, device: { ...data.device } };
      setDeviceOnScreen(data);
    }
  });

  return (
    <Grid fluid>
      <Col xs={12} className="d-flex justify-between align-center mb-7">
        <h2
          className="title-xl-base text-uppercase"
          style={{ color: 'var(--color-neutral-dark-3)' }}>
          {t('VISUALIZAR EQUIPAMENTO')}
        </h2>
      </Col>

      <Card className="pa-7">
        <HeaderDeviceDetails
          isFetchedAfterMountDeviceInfo={isFetchedAfterMountDeviceInfo}
          refetchDeviceInfo={refetchDeviceInfo}
          selectedDevice={deviceOnScreen}
          setUpdatedDevice={setDeviceOnScreen}
          hasRX
        />

        <Divider orientation="horizontal" />

        {isFetchedAfterMountDeviceInfo ? (
          <ConnectedReceivers receivers={deviceOnScreen.rx_list || []} />
        ) : (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton
                height={58}
                className="mt-2"
                // eslint-disable-next-line react/no-array-index-key
                key={`skeleton-receivers${index}}`}
              />
            ))}
          </>
        )}
      </Card>
    </Grid>
  );
};

export { WomCFTVDetails };
