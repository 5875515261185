import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { Controller, FieldError } from 'react-hook-form';
import { Col, Row } from 'inmaster-ui';
import InputOfDatePicker from 'src/pages/CaptivePortal/SimplePassword/DrawerFormSimplePassword/InputOfDatePicker';
import { Button } from 'ui-components/Button';
import Toggle from 'ui-components/Toggle';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui-components/Alert';
import { useAuth } from 'src/hooks/useAuth';
import {
  convertMomentToTimezoneWithCaptiveFormat,
  formatExpirationTimeWithEndDate,
  getExpiresAtDaysOrTime
} from 'src/utils/CaptivePortal/captiveDates';
import { DateConverter } from 'src/utils/Dates/DateConverter';
import DatePicker from '../DatePicker';
import { Divider } from '../Divider';
import InputTimeWithValidation from '../InputTimeWithValidation';
import InputWithValidation from '../InputWithValidation';
import SelectWithValidation from '../SelectWithValidation';
import styles from './FormAddIndividualRecord.module.css';
import { IFormAddIndividualRecord, IIndividualRecordFormValues } from './types';
import { IOption } from '../InputTime/types';

const FormAddIndividualRecord = ({
  type,
  formInstance,
  hasDocInExpiredRecords,
  isFetchingIndividualRecordExists,
  isRecordAuthorizedPreviously,
  isFormClosing,
  hasNotFormIndividualRecordChange,
  hasNotPeriodOfIndividualRecordChange,
  onSubmitForm
}: IFormAddIndividualRecord) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'formIndividualRecordDrawer'
  });

  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
    setValue,
    trigger,
    handleSubmit
  } = formInstance;

  const { siteTimezone } = useAuth();

  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const [isRecordToEditAuthorizationTime, setIsRecordToEditAuthorizationTime] =
    React.useState(false);

  const [docInputIsFocused, setDocInputIsFocused] = React.useState(false);

  const isEditMode = type === 'edit';

  const isEditModeAndHasNotFormChange =
    isEditMode && hasNotFormIndividualRecordChange;

  const isEditModeAndHasPeriodChange =
    type === 'edit' && !hasNotPeriodOfIndividualRecordChange;

  const isEditModeAndIsRecordAuthorizedPreviously =
    isEditMode && isRecordAuthorizedPreviously;

  const isNotEditModeOrWillRecordBeChangeAuthorizationOrIsNotRecordAuthorizedPreviously =
    isRecordToEditAuthorizationTime ||
    !isEditModeAndIsRecordAuthorizedPreviously;

  const hasSomeErrorInFormOrHasNotFormChange =
    Object.keys(errors).length > 0 || isEditModeAndHasNotFormChange;

  const disableSubmitForm =
    (docInputIsFocused && !hasDocInExpiredRecords) ||
    isFetchingIndividualRecordExists ||
    hasSomeErrorInFormOrHasNotFormChange;

  const onSubmit = handleSubmit((recordForm: IIndividualRecordFormValues) => {
    const isToResetPeriodFields = !recordForm.individualRecord.authorized;
    if (isToResetPeriodFields) {
      recordForm.individualRecord = {
        ...recordForm.individualRecord,
        periodType: undefined,
        periodInDays: [null, null],
        periodInTime: undefined
      };
    }

    if (onSubmitForm) {
      recordForm.individualRecord.startedAt =
        !_.isUndefined(recordForm.individualRecord.startedAt) &&
        hasNotPeriodOfIndividualRecordChange
          ? recordForm.individualRecord.startedAt
          : undefined;
      onSubmitForm(recordForm);
    }
    reset();
    setIsRecordToEditAuthorizationTime(false);
  });

  const handleOnChangePeriod = (dates: [Date, Date]) => {
    const [dateStart, dateEnd] = getValues('individualRecord.periodInDays');

    const dateChangesAndInputWasOpen =
      showDatePicker &&
      dates[0] &&
      dates[1] &&
      dates[0].getTime() !== dates[1].getTime();

    const checkIfHasDateSelectedAndOnlyDateEndWasChange =
      _.isNull(dates[1]) &&
      !_.isNull(dateEnd) &&
      !_.isNull(dateStart) &&
      moment(dates[0].toISOString())
        .endOf('day')
        .diff(moment(dateStart.toISOString()), 'days') !== 0;

    let newDates: [Date | null, Date | null] = [...dates];

    if (checkIfHasDateSelectedAndOnlyDateEndWasChange) {
      const newEndDate = moment(dates[0].toISOString())
        .add(moment().format('HH:mm'))
        .toDate();
      newDates = [dateStart as Date, newEndDate];
    }

    if (
      dateChangesAndInputWasOpen ||
      checkIfHasDateSelectedAndOnlyDateEndWasChange
    ) {
      setShowDatePicker(false);
    }

    newDates = [
      _.isNil(newDates[0])
        ? null
        : new DateConverter(newDates[0]).getMomentDateWithCurrentHour.toDate(),
      _.isNil(newDates[1])
        ? null
        : new DateConverter(newDates[1]).getMomentDateWithCurrentHour.toDate()
    ];

    setValue('individualRecord.periodInDays', newDates);
    trigger('individualRecord.periodInDays');
  };

  const getExpiresAtDateFormatted = (
    periodType: string,
    periodInDays: [Date | null, Date | null],
    periodInTime: IOption | undefined
  ) => {
    const isNotPeriodInDaysEmpty =
      periodType === 'date' &&
      !_.isNull(periodInDays[0]) &&
      !_.isNull(periodInDays[1]);

    const isNotPeriodInTimeEmpty =
      periodType === 'time' && !_.isUndefined(periodInTime);

    const isNotPeriodTypeEmptyAndHasSomePeriodInDaysOrTime =
      isNotPeriodInTimeEmpty || isNotPeriodInDaysEmpty;

    return isNotPeriodTypeEmptyAndHasSomePeriodInDaysOrTime
      ? `${t('Aproximadamente em')} ${convertMomentToTimezoneWithCaptiveFormat(
          getExpiresAtDaysOrTime(
            periodType,
            periodInDays,
            periodInTime,
            hasNotPeriodOfIndividualRecordChange && isNotPeriodInDaysEmpty,
            siteTimezone,
            isEditModeAndHasNotFormChange
              ? getValues('individualRecord.startedAt')
              : undefined
          ),
          siteTimezone
        )}`
      : '--';
  };

  const renderDatePicker = () => {
    return (
      <Controller
        control={control}
        name="individualRecord.periodInDays"
        rules={{
          required: {
            value: true,
            message: t('Campo obrigatório')
          },
          validate: {
            notDates: (value) => {
              const auxValue = value as [Date, Date];
              return (
                auxValue.every(
                  (date) => date !== undefined && !_.isNull(date)
                ) || (t('Campo obrigatório') as string)
              );
            }
          }
        }}
        render={({ field: { value } }) => {
          const parsedValue = value as [Date, Date];
          return (
            <DatePicker
              onChange={(dates: [Date, Date]) => handleOnChangePeriod(dates)}
              placeholder={t('Insira o tempo de duração')}
              startDate={new Date()}
              endDate={parsedValue[1]}
              visible={showDatePicker}
              onInputClick={() => setShowDatePicker((prev) => !prev)}
              dateFormat="h:mm"
              locale="ptBR"
              selectsRange
              disabledKeyboardNavigation
              customInput={
                <InputOfDatePicker
                  onClick={() => setShowDatePicker((prev) => !prev)}
                  error={errors.individualRecord?.periodInDays as FieldError}
                  valueToDisplay={formatExpirationTimeWithEndDate(
                    parsedValue[0],
                    parsedValue[1],
                    t('Insira o tempo de duração')
                  )}
                />
              }
              minDate={new Date()}
              value={
                errors.individualRecord?.periodInDays ? 'Invalid' : 'Valid'
              }
            />
          );
        }}
      />
    );
  };

  const onClickEditAuthorizationTime = () => {
    setIsRecordToEditAuthorizationTime(true);
    setValue('individualRecord.periodType', undefined);
    setValue('individualRecord.periodInDays', [null, null]);
    setValue('individualRecord.periodInTime', undefined);
  };

  const onChangePeriodType = () => {
    setValue('individualRecord.periodInDays', [null, null]);
    setValue('individualRecord.periodInTime', undefined);
    clearErrors([
      'individualRecord.periodType',
      'individualRecord.periodInDays',
      'individualRecord.periodInTime'
    ]);
  };

  const renderTimeSelect = () => {
    return (
      <InputTimeWithValidation
        id="period-input-time"
        control={control}
        label={t('Tempo')}
        placeholder={t('Insira o tempo de duração')}
        controllerProps={{
          name: 'individualRecord.periodInTime',
          rules: {
            validate: {
              notSelectedTime: (value: IOption) => {
                return (
                  value !== undefined || (t('Campo obrigatório') as string)
                );
              }
            }
          }
        }}
        errors={errors.individualRecord?.periodInTime as FieldError}
        inputProps={{
          id: 'period-input-time'
        }}
        stepMinute={5}
      />
    );
  };

  React.useEffect(() => {
    if (isFormClosing) {
      setIsRecordToEditAuthorizationTime(false);
    }
  }, [isFormClosing]);

  return (
    <form onSubmit={disableSubmitForm ? (e) => e.preventDefault() : onSubmit}>
      <div className="d-flex flex-column">
        <span
          className={classNames([
            'title-xl-base my-5',
            styles.colorTitleSubtitle
          ])}>
          {t('Dados do cliente')}
        </span>

        <div className="mb-5">
          <InputWithValidation
            label={t('Nº de documento')}
            inputProps={{
              id: 'individualRecord.docNumber',
              onBlur: () => {
                setDocInputIsFocused(false);
              },
              onFocus: () => {
                setDocInputIsFocused(true);
              }
            }}
            isValid={hasDocInExpiredRecords}
            validMessage={t('Cliente já cadastrado anteriormente.')}
            control={control}
            controllerProps={{
              name: 'individualRecord.docNumber',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                minLength: {
                  value: 3,
                  message: t(
                    'O Nº de documento precisa ter entre 3 e 100 caracteres'
                  )
                },
                maxLength: {
                  value: 100,
                  message: t(
                    'O Nº de documento precisa ter entre 3 e 100 caracteres'
                  )
                },
                pattern: {
                  value: /^([a-zA-Z0-9]+)$/,
                  message: t(
                    'O Nº de documento não pode conter caracteres especiais'
                  )
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O Nº de documento não pode começar nem terminar com um espaço'
                    ) as string)
                }
              }
            }}
            errors={errors?.individualRecord?.docNumber}
            placeholder={t('Insira o nº de documento')}
          />
        </div>

        <div className="mb-5">
          <InputWithValidation
            label={t('Nome')}
            inputProps={{
              id: 'individualRecord.name'
            }}
            control={control}
            controllerProps={{
              name: 'individualRecord.name',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                minLength: {
                  value: 3,
                  message: t('O nome precisa ter entre 3 e 128 caracteres')
                },
                maxLength: {
                  value: 128,
                  message: t('O nome precisa ter entre 3 e 128 caracteres')
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notStartsOrEndsWithWhitespace: (value) =>
                    (!value.startsWith(' ') && !value.endsWith(' ')) ||
                    (t(
                      'O nome não pode começar nem terminar com um espaço'
                    ) as string),
                  notOutOfNamePattern: (value) => {
                    const isValidName =
                      /^[a-zA-Z\xC0-\uFFFF]+([ \-'][a-zA-Z\xC0-\uFFFF]+)*([ \-']?[a-zA-Z\xC0-\uFFFF.]+)*$/.test(
                        value as string
                      );
                    return (
                      isValidName ||
                      (t(
                        'O nome não pode conter números ou caracteres especiais como $ # & *'
                      ) as string)
                    );
                  }
                }
              }
            }}
            errors={errors?.individualRecord?.name}
            placeholder={t('Insira o nome')}
          />
        </div>

        <div className="mb-5">
          <InputWithValidation
            label={t('Email')}
            inputProps={{
              itemType: 'email',
              id: 'individualRecord.email'
            }}
            control={control}
            controllerProps={{
              name: 'individualRecord.email',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                maxLength: {
                  value: 128,
                  message: t('O e-mail pode conter até 128 caracteres')
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('Endereço de email inválido')
                },
                validate: {
                  notWhitespaceOnly: (value) =>
                    value.trim().length > 0 ||
                    (t('Campo obrigatório') as string),
                  notWhitespace: (value) =>
                    value.indexOf(' ') === -1 ||
                    (t(
                      'O e-mail não pode conter espaços nem caracteres especiais'
                    ) as string),
                  notSpecialCharacters: (value) => {
                    const hasSpecialCharacters = /^[a-zA-Z0-9@._]+$/.test(
                      value
                    );
                    return (
                      hasSpecialCharacters ||
                      (t(
                        'O e-mail não pode conter espaços nem caracteres especiais'
                      ) as string)
                    );
                  }
                }
              }
            }}
            errors={errors?.individualRecord?.email}
            placeholder={t('Insira o email')}
          />
        </div>

        <div className="mb-1">
          <InputWithValidation
            label={t('Telefone')}
            inputProps={{
              id: 'individualRecord.phone'
            }}
            control={control}
            controllerProps={{
              name: 'individualRecord.phone',
              rules: {
                required: {
                  value: true,
                  message: t('Campo obrigatório')
                },
                minLength: {
                  value: 6,
                  message: t('O telefone precisa ter entre 6 e 30 dígitos')
                },
                maxLength: {
                  value: 30,
                  message: t('O telefone precisa ter entre 6 e 30 dígitos')
                },
                pattern: {
                  value: /^\d+$/,
                  message: t('O campo de telefone só aceita valores numéricos')
                }
              }
            }}
            errors={errors?.individualRecord?.phone}
            placeholder={t('Insira o telefone')}
          />
        </div>
        <Divider />
        <span
          className={classNames([
            'title-xl-base mb-2',
            styles.colorTitleSubtitle
          ])}>
          {t('Autorização')}
        </span>
        <span
          className={classNames([
            'text-xl-lg mb-5',
            styles.colorTitleSubtitle
          ])}>
          {t(
            'Controle o acesso do cliente à internet, e determine a validade do cadastro'
          )}
          .
        </span>
        <div className="d-flex mb-5">
          <Controller
            control={control}
            name="individualRecord.authorized"
            render={({ field: { onChange, value } }) => (
              <Toggle
                color="var(--color-status-ok-base)"
                checked={value}
                id="individualRecord.authorized"
                onChange={() => {
                  onChange(!value);
                  if (value) {
                    clearErrors([
                      'individualRecord.periodType',
                      'individualRecord.periodInDays',
                      'individualRecord.periodInTime'
                    ]);
                  }
                }}
              />
            )}
          />
          <span>{t('Acesso autorizado')}</span>
        </div>
        {watch('individualRecord.authorized') && (
          <>
            <Row className="mb-5 d-flex align-center">
              <Col xs={8} className="d-flex flex-column">
                <div className="text-bold mb-1">
                  {t('Previsão de expiração')}:
                </div>
                <div>
                  <span>
                    {getExpiresAtDateFormatted(
                      watch('individualRecord.periodType') || '',
                      watch('individualRecord.periodInDays'),
                      watch('individualRecord.periodInTime')
                    )}
                  </span>
                </div>
              </Col>
              <Col xs={4} className="d-flex justify-end">
                {isEditModeAndIsRecordAuthorizedPreviously && (
                  <Button
                    outline
                    disabled={isRecordToEditAuthorizationTime}
                    onClick={onClickEditAuthorizationTime}>
                    <span>{t('Alterar período')}</span>
                  </Button>
                )}
              </Col>
            </Row>
            {isNotEditModeOrWillRecordBeChangeAuthorizationOrIsNotRecordAuthorizedPreviously && (
              <Row className="d-flex align-start mb-5">
                <Col xs={5} className="pr-3">
                  <SelectWithValidation
                    control={control}
                    errors={errors.individualRecord?.periodType}
                    label={t('Período de duração')}
                    placeholder={t('Selecionar período')}
                    controllerProps={{
                      name: 'individualRecord.periodType',
                      rules: {
                        required: {
                          value: true,
                          message: t('Campo obrigatório')
                        }
                      }
                    }}
                    options={[
                      {
                        label: t('Dias'),
                        value: 'date'
                      },
                      {
                        label: t('Horas'),
                        value: 'time'
                      }
                    ]}
                    handleOnChange={onChangePeriodType}
                    id="individualRecord.periodType"
                  />
                </Col>

                <Col xs={7} className="pl-3">
                  {watch('individualRecord.periodType') === 'date' &&
                    renderDatePicker()}

                  {watch('individualRecord.periodType') === 'time' &&
                    renderTimeSelect()}
                </Col>
              </Row>
            )}
            {isEditModeAndHasPeriodChange && (
              <Row className="mb-5">
                <Alert type="info">
                  {t(
                    'As alterações serão aplicadas após serem salvas e serão consideradas na próxima solicitação de autenticação do usuário'
                  )}
                  .
                </Alert>
              </Row>
            )}
          </>
        )}
        <div className="fit-width d-flex justify-end">
          <Button type="submit" disabled={hasSomeErrorInFormOrHasNotFormChange}>
            {t('SALVAR')}
          </Button>
        </div>
      </div>
    </form>
  );
};

FormAddIndividualRecord.defaultProps = {
  hasDocInExpiredRecords: false,
  isFetchingIndividualRecordExists: false,
  isRecordAuthorizedPreviously: false,
  isFormClosing: false,
  hasNotFormIndividualRecordChange: false,
  hasNotPeriodOfIndividualRecordChange: false
};

export default FormAddIndividualRecord;
