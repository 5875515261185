import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

const BlockFirstAccessToRegisteredUsers = ({
  children
}: {
  children: JSX.Element;
}) => {
  const { accessToken } = useAuth();

  if (accessToken && accessToken.site !== null) {
    return <Navigate to="/devices" replace />;
  }

  return children;
};

export { BlockFirstAccessToRegisteredUsers };
