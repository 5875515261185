import moment from 'moment';
import i18n from 'src/i18n';
import { DateConverter } from './DateConverter';
import { capitalizeFirstLetter } from '../stringsUtils';

class DateCalculator {
  private startDateConverter: DateConverter;

  private startMomentDate: moment.Moment;

  private endDateConverter: DateConverter;

  private endMomentDate: moment.Moment;

  constructor(
    startDate?: string | Date,
    endDate?: string | Date,
    timeZone?: string
  ) {
    this.startDateConverter = new DateConverter(startDate, timeZone);
    this.startMomentDate = this.startDateConverter.getMomentDate
      .seconds(0)
      .milliseconds(0);

    this.endDateConverter = new DateConverter(endDate, timeZone);
    this.endMomentDate = this.endDateConverter.getMomentDate
      .seconds(0)
      .milliseconds(0);
  }

  private formatMinutesString = (minutes: number) => {
    let minString = '';
    if (minutes > 0) {
      minString = ` ${String(minutes).padStart(2, '0')}${i18n.t('min')}`;
    }

    return minString;
  };

  private formatHoursString = (hours: number, minutes: number) => {
    return `${hours}h${this.formatMinutesString(minutes)}`;
  };

  public getDiffBetweenStartAndEndDate() {
    const HOURSINMILLISECONDS = 3600000;
    const DAYSINMILLISECONDS = 86400000;

    const diffTime = this.endMomentDate.diff(this.startMomentDate);

    if (diffTime < HOURSINMILLISECONDS) {
      const getMinOfDiffTime = moment.duration(diffTime).minutes();
      return `${this.formatMinutesString(getMinOfDiffTime)}`;
    }

    if (diffTime < DAYSINMILLISECONDS) {
      const getHoursOfDiffTime = moment.duration(diffTime).hours();
      const getMinOfDiffTime = moment.duration(diffTime).minutes();
      return this.formatHoursString(getHoursOfDiffTime, getMinOfDiffTime);
    }

    const diffInDays = this.endMomentDate
      .endOf('day')
      .diff(this.startMomentDate.startOf('day'), 'days');

    return `${diffInDays} ${diffInDays > 1 ? i18n.t('dias') : i18n.t('dia')}`;
  }

  public getDurationWithEndDateOnFormat = () => {
    const diff = this.endMomentDate
      .endOf('day')
      .diff(this.startMomentDate.startOf('day'), 'days');

    if (diff === 1) {
      return `${diff} ${capitalizeFirstLetter(i18n.t('dia'))} (${i18n.t(
        'até'
      )} ${this.endDateConverter.getDateWithFormat('DD/MM/yyyy')})`;
    }

    return `${diff} ${capitalizeFirstLetter(i18n.t('dias'))} (${i18n.t(
      'até'
    )} ${this.endDateConverter.getDateWithFormat('DD/MM/yyyy')})`;
  };
}

export { DateCalculator };
