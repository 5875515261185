import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'inmaster-ui';
import { useTemplate } from 'src/hooks/useTemplate';
import { useTranslation } from 'react-i18next';
import Card from 'ui-components/Card';
import Label from 'ui-components/Label';
import { InputIconInside } from 'ui-components/InputWrapper';
import {
  MdAdd,
  MdDelete,
  MdMoreHoriz,
  MdSearch,
  MdUpdate
} from 'react-icons/md';
import { Input } from 'ui-components/Input';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import { generateColumns } from 'src/utils/tableUtils';
import Badge from 'src/components/Badge';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import {
  IGetSimplePasswordHistoric,
  IGetCaptivePortal,
  IGetActivePassword
} from 'src/services/api/urls/captivePortal/types';
import Toggle from 'ui-components/Toggle';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { PasswordVisibilityIcon } from 'src/components/PasswordVisibilityIcon';
import Table from 'ui-components/Table';
import { Alert } from 'ui-components/Alert';
import { useQuery } from 'react-query';
import { DateCalculator } from 'src/utils/Dates/DateCalculator';
import { convertMomentToTimezoneWithCaptiveFormat } from 'src/utils/CaptivePortal/captiveDates';
import {
  IPasswordHistoricList,
  IPassword,
  IPasswordHistoricRow
} from './types';
import styles from './SimplePassword.module.css';
import { DeletePasswordModal } from './DeletePasswordModal';
import { UpdateActivePasswordModal } from './UpdateActivePasswordModal';
import DrawerFormSimplePassword from './DrawerFormSimplePassword';
import { ICaptivePortal } from '../types';
import { PreViewCaptiveModal } from '../PreViewCaptiveModal';

const SimplePassword = () => {
  useTemplate('menuAndFullNavbar');

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  const { cp_id: captiveId } = useParams();

  const { accessToken, siteTimezone } = useAuth();

  const [passwordHistoricList, setPasswordHistoricList] =
    useState<IPasswordHistoricList>({
      simplePasswords: []
    } as IPasswordHistoricList);

  const [selectedPasswords, setSelectedPasswords] = useState<
    IPasswordHistoricRow[]
  >([]);

  const [searchValue, setSearchValue] = useState('');

  const [activePassword, setActivePassword] = useState<IPassword>(
    {} as IPassword
  );

  const [defaultCaptivePortal, setDefaultCaptivePortal] =
    useState<ICaptivePortal>({} as ICaptivePortal);

  const [showViewActivePassword, setShowViewActivePassword] = useState(false);

  const [showViewPasswordHistoric, setShowViewPasswordHistoric] =
    useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const [showDrawerFormSimplePassword, setShowDrawerFormSimplePassword] =
    useState(false);

  const [showPreViewModal, setShowPreViewModal] = useState(false);

  const columnsTablePasswordHistoric = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('created_at', t('Data de Criação'), 'left', 0),
    generateColumns('expired_at', t('Data de Expiração'), 'left', 0),
    generateColumns('time', t('Tempo'), 'left', 0),
    generateColumns('status', t('Status'), 'left', 0),
    generateColumns('password', t('Senhas'), 'left', 0)
  ];

  const getCaptivePortal = () => {
    return api.captivePortal.captive.getOrCreate(
      accessToken?.site_id || '',
      accessToken?.place_id || ''
    );
  };

  const { isFetchedAfterMount: isFetchedCaptivePortal } = useQuery(
    'getCaptivePortal',
    getCaptivePortal,
    {
      onSuccess: ({ data }: { data: IGetCaptivePortal }) => {
        if (data) {
          setDefaultCaptivePortal({
            ...data.captive_portal,
            is_blocked_to_activate: data.is_blocked_to_activate
          } as ICaptivePortal);
        } else {
          setDefaultCaptivePortal({} as ICaptivePortal);
        }
      },
      onError: () => {
        setDefaultCaptivePortal({} as ICaptivePortal);
      }
    }
  );

  const getSimplePasswordHistoric = () => {
    return api.captivePortal.simplePassword.get(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || ''
    );
  };

  const {
    isFetching: isFetchingSimplePasswordHistoric,
    refetch: refetchPasswordHistoric
  } = useQuery(['getSimplePasswordHistoric'], getSimplePasswordHistoric, {
    onSuccess: ({ data }: { data: IGetSimplePasswordHistoric }) => {
      setSelectedPasswords([]);

      setPasswordHistoricList({
        simplePasswords: data.simple_passwords.map((password) => {
          return {
            id: password.id,
            created_at: convertMomentToTimezoneWithCaptiveFormat(
              password.created_at,
              siteTimezone
            ),
            expired_at: convertMomentToTimezoneWithCaptiveFormat(
              password.expires_at,
              siteTimezone
            ),
            time: new DateCalculator(
              password.created_at,
              password.expires_at,
              siteTimezone
            ).getDiffBetweenStartAndEndDate(),
            status: t('Expirado'),
            password: password.password
          } as IPassword;
        })
      });
    },
    onError: () => {
      setPasswordHistoricList({
        simplePasswords: []
      });
    }
  });

  const getActiveSimplePassword = () => {
    return api.captivePortal.simplePassword.getActivePassword(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || ''
    );
  };

  const {
    isFetchedAfterMount: isFetchedActivePassword,
    refetch: refetchActivePassword
  } = useQuery(['getActiveSimplePassword'], getActiveSimplePassword, {
    onSuccess: ({ data }: { data: IGetActivePassword }) => {
      setActivePassword({
        id: data.simple_password.id || '',
        created_at: convertMomentToTimezoneWithCaptiveFormat(
          data.simple_password.created_at,
          siteTimezone
        ),
        expired_at: convertMomentToTimezoneWithCaptiveFormat(
          data.simple_password.expires_at,
          siteTimezone
        ),
        password: data.simple_password.password,
        status: t('Ativo'),
        time: new DateCalculator(
          data.simple_password.created_at,
          data.simple_password.expires_at,
          siteTimezone
        ).getDiffBetweenStartAndEndDate()
      });
    },
    onError: () => {
      setActivePassword({} as IPassword);
    }
  });

  const isLoadedActivePasswordAndHistoric =
    isFetchedActivePassword && !isFetchingSimplePasswordHistoric;

  const checkIfLoadDataAndHasNotHistoricAndActivePassword =
    isLoadedActivePasswordAndHistoric &&
    passwordHistoricList.simplePasswords.length === 0 &&
    !activePassword.id;

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 align-center"
          key={`${i}-skeleton-wireless-table`}>
          <div className="ml-2 d-flex align-center">
            <Skeleton count={1} height={25} width={25} />
            <Skeleton count={1} height={22} width={200} className="ml-6" />
          </div>

          <Skeleton count={1} height={22} width={200} />
          <Skeleton count={1} height={22} width={90} />
          <Skeleton count={1} height={22} width={100} />
          <Skeleton count={1} height={22} width={90} className="mr-2" />
        </div>
      );
    }

    return skeletons;
  };

  const renderPassword = (password: string, showViewPassword: boolean) => {
    const MINLENGTHPASSWORD = 6;
    if (showViewPassword) {
      return password;
    }
    return Array(MINLENGTHPASSWORD).fill('*').join('');
  };

  const generateRows = (passwordsList: IPassword[]) => {
    return passwordsList.map((passwordHistoricItem: IPassword) => {
      return {
        id: passwordHistoricItem.id,
        created_at: passwordHistoricItem.created_at,
        expired_at: passwordHistoricItem.expired_at,
        time: passwordHistoricItem.time,
        status: (
          <div className={classNames(styles.statusContent)}>
            <Badge
              title={passwordHistoricItem.status}
              color="var(--color-status-critical-base)"
              backgroundColor="var(--color-status-critical-lightest)"
            />
          </div>
        ),
        password: renderPassword(
          passwordHistoricItem.password,
          showViewPasswordHistoric
        )
      };
    });
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedPasswords([]);
  };

  const onCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedPasswords([]);
  };

  const onSuccessUpdatePassword = () => {
    refetchActivePassword();
    refetchPasswordHistoric();
    onCloseUpdateModal();
  };

  const onCreateNewPassword = () => {
    refetchActivePassword();
    refetchPasswordHistoric();
    setShowDrawerFormSimplePassword(false);
  };

  const filteredPasswordHistoricList =
    passwordHistoricList.simplePasswords.filter((password) => {
      return password.created_at.includes(searchValue.trim());
    });

  const notHasFilteredPasswordHistoricList =
    searchValue.length > 0 && filteredPasswordHistoricList.length === 0;

  return (
    <Row>
      <DrawerFormSimplePassword
        hasActivePassword={activePassword.id !== undefined}
        captiveId={captiveId || ''}
        show={showDrawerFormSimplePassword}
        onClose={onCreateNewPassword}
      />

      <DeletePasswordModal
        siteId={accessToken?.site_id || ''}
        placeId={accessToken?.place_id || ''}
        captivePortalId={captiveId || ''}
        refetchPasswordHistoric={refetchPasswordHistoric}
        selectedPasswords={selectedPasswords}
        show={showDeleteModal}
        onClose={onCloseDeleteModal}
      />

      <UpdateActivePasswordModal
        showModal={showUpdateModal}
        selectedPassword={selectedPasswords[0]}
        captiveId={captiveId || ''}
        onCancel={onCloseUpdateModal}
        onSuccess={onSuccessUpdatePassword}
      />

      <PreViewCaptiveModal
        methodCaptive="password"
        defaultCaptivePortal={defaultCaptivePortal}
        showPreViewModal={showPreViewModal}
        isFetchedCaptivePortal={isFetchedCaptivePortal}
        onClose={() => {
          setShowPreViewModal(false);
        }}
      />

      <Col xs={12} className="d-flex flex-column">
        <div className="d-flex align-center">
          <h2
            className={classNames([
              'title-xl-base text-uppercase',
              styles.titles
            ])}>
            {t('Senha simples')}
          </h2>
          {defaultCaptivePortal.mode === 'password' && (
            <span
              className={classNames(
                styles.activeLabel,
                'd-flex align-center ml-4'
              )}>
              <div className={classNames(styles.ball, 'mr-2')} /> {t('Ativo')}{' '}
            </span>
          )}
        </div>
        {defaultCaptivePortal.is_blocked_to_activate && (
          <div className="mt-1 mb-2">
            <Alert type="warning" width="420px">
              {t('Há equipamento(s) neste ambiente com firmware desatualizado')}
            </Alert>
          </div>
        )}
        <div className="mt-1 mb-3 d-flex flex-column ">
          <span className={styles.subtitle}>
            {t(
              'Defina uma senha padrão e determine o tempo máximo de uso dos usuários conectados nessa rede. Confira abaixo a tabela com o histórico de senha simples'
            )}
          </span>
        </div>
        {checkIfLoadDataAndHasNotHistoricAndActivePassword ? (
          <EmptyMessage
            id="empty-simple-password"
            title={t('Nenhuma senha adicionada')}
            subtitle={t(
              'Ainda não há senha simples disponível neste ambiente. Experimente adicionar uma nova senha para a ativação do método de Captive Portal'
            )}
            buttonText={t('Adicionar nova')}
            action={() => setShowDrawerFormSimplePassword(true)}
            minHeight="190px"
            height="calc(100vh - 350px)"
          />
        ) : (
          <>
            <Card className="pa-6 mb-7">
              {isLoadedActivePasswordAndHistoric ? (
                <div>
                  {selectedPasswords.length === 0 ? (
                    <Row between="md" className="mb-1">
                      <Col xs={12}>
                        <Label>{t('Pesquisar na lista')}:</Label>
                      </Col>

                      <Col xs={12} className="mt-1">
                        <Row className="align-center">
                          <Col xs={6}>
                            <InputIconInside iconLeft={<MdSearch />}>
                              <Input
                                id="search-input"
                                iconInsideLeft
                                placeholder={t('Pesquise pela data de criação')}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  setSearchValue(e.target.value);
                                }}
                              />
                            </InputIconInside>
                          </Col>

                          <Col xs={6} className="pl-7 d-flex align-center">
                            <ButtonTextOutside
                              id="text-btn-open-modal-to-add-simples-password"
                              value={t('Adicionar nova')}
                              className="text-uppercase mr-9">
                              <Button
                                onClick={() =>
                                  setShowDrawerFormSimplePassword(true)
                                }
                                id="btn-open-modal-to-add-simples-password"
                                disabled={false}
                                rounded>
                                <MdAdd size={24} />
                              </Button>
                            </ButtonTextOutside>
                            <Menu
                              menuId="menu-simple-password-actions"
                              width="160px"
                              positionX="end"
                              show={showMenu}
                              onClose={() => setShowMenu(false)}
                              items={
                                <MenuItem
                                  id="btn-pre-view-captive"
                                  onClick={() => setShowPreViewModal(true)}>
                                  {t('Visualizar Captive')}
                                </MenuItem>
                              }>
                              <div
                                id="btn-menu-actions"
                                className={styles.menuActions}
                                onClick={() => setShowMenu(true)}
                                aria-hidden="true">
                                <MdMoreHoriz size={24} />
                              </div>
                            </Menu>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="mb-1">
                      <Col xs={12} className="d-flex">
                        <ButtonTextOutside
                          value={`${t('Excluir')} (${
                            selectedPasswords.length
                          })`}
                          className="text-uppercase mt-6 mb-6 ml-7">
                          <Button
                            onClick={() => setShowDeleteModal(true)}
                            id="button-delete-device"
                            rounded
                            color="var(--color-status-critical-base)">
                            <MdDelete size={24} />
                          </Button>
                        </ButtonTextOutside>
                        <ButtonTextOutside
                          value={
                            <span
                              className={classNames([
                                selectedPasswords.length > 1 &&
                                  styles.textDisabled
                              ])}>
                              {t('RENOVAR')}{' '}
                              {selectedPasswords.length === 1 &&
                                `(${selectedPasswords.length})`}
                            </span>
                          }
                          className="text-uppercase mt-6 mb-6 ml-7">
                          <Button
                            onClick={() => setShowUpdateModal(true)}
                            id="button-update-password"
                            rounded
                            ghost
                            disabled={selectedPasswords.length > 1}>
                            <MdUpdate size={18} />
                          </Button>
                        </ButtonTextOutside>
                      </Col>
                    </Row>
                  )}
                </div>
              ) : (
                <Row between="md" className="mb-1">
                  <Col xs={12}>
                    <Skeleton count={1} height={18} width={160} />
                  </Col>
                  <Col xs={12} className="mt-1">
                    <Row className="align-center">
                      <Col xs={6}>
                        <Skeleton count={1} height={52} borderRadius={10} />
                      </Col>

                      <Col xs={6} className="pl-7 d-flex align-center">
                        <Skeleton
                          count={1}
                          height={50}
                          width={150}
                          borderRadius={10}
                        />
                        <Skeleton
                          className="ml-9"
                          count={1}
                          height={24}
                          width={24}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Card>

            {isFetchedActivePassword ? (
              activePassword.id && (
                <Card className="pa-6">
                  <span
                    className={classNames([
                      'text-uppercase title-sm-base',
                      styles.titles
                    ])}>
                    {t('Senha em uso')}
                  </span>
                  <Card
                    className={classNames([styles.passwordInUse, 'pa-6 pl-10'])}
                    borderColor="var(--color-brand-secondary-light)">
                    <Row between="md" className="d-flex align-center">
                      <Col>
                        <div>
                          <span
                            className={classNames([
                              'title-xl-sm',
                              styles.passwordInUseItemTitle
                            ])}>
                            {t('Data de criação')}
                          </span>
                          <span>{activePassword.created_at}</span>
                        </div>
                      </Col>

                      <Col>
                        <div>
                          <span
                            className={classNames([
                              'title-xl-sm',
                              styles.passwordInUseItemTitle
                            ])}>
                            {t('Data de expiração')}
                          </span>
                          <span>{activePassword.expired_at}</span>
                        </div>
                      </Col>

                      <Col>
                        <div>
                          <span
                            className={classNames([
                              'title-xl-sm',
                              styles.passwordInUseItemTitle
                            ])}>
                            {t('Tempo')}
                          </span>
                          <span>{activePassword.time}</span>
                        </div>
                      </Col>

                      <Col>
                        <div>
                          <span
                            className={classNames([
                              'title-xl-sm',
                              styles.passwordInUseItemTitle
                            ])}>
                            {t('Senha atual')}
                          </span>
                          <span>
                            {renderPassword(
                              activePassword.password,
                              showViewActivePassword
                            )}
                          </span>
                        </div>
                      </Col>

                      <Col>
                        <PasswordVisibilityIcon
                          id="btn-toggle-visibility"
                          size={24}
                          setShowPassword={setShowViewActivePassword}
                          showPassword={showViewActivePassword}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Card>
              )
            ) : (
              <>
                <span
                  className={classNames([
                    'text-uppercase title-sm-base',
                    styles.titles
                  ])}>
                  {t('Senha em uso')}
                </span>
                <Card
                  className={classNames([
                    styles.passwordInUse,
                    'py-6 pl-11 pr-6'
                  ])}
                  borderColor="var(--color-brand-secondary-light)">
                  <Row between="md">
                    <Col>
                      <div>
                        <span
                          className={classNames([
                            'title-xl-sm',
                            styles.passwordInUseItemTitle
                          ])}>
                          {t('Data de criação')}
                        </span>
                        <Skeleton height={24} width={100} />
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <span
                          className={classNames([
                            'title-xl-sm',
                            styles.passwordInUseItemTitle
                          ])}>
                          {t('Data de expiração')}
                        </span>
                        <Skeleton height={24} width={100} />
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <span
                          className={classNames([
                            'title-xl-sm',
                            styles.passwordInUseItemTitle
                          ])}>
                          {t('Tempo')}
                        </span>
                        <Skeleton height={24} width={100} />
                      </div>
                    </Col>

                    <Col>
                      <div>
                        <span
                          className={classNames([
                            'title-xl-sm',
                            styles.passwordInUseItemTitle
                          ])}>
                          {t('Senha atual')}
                        </span>
                        <Skeleton height={24} width={100} />
                      </div>
                    </Col>

                    <Col>
                      <Skeleton height={24} width={24} />
                    </Col>
                  </Row>
                </Card>
              </>
            )}

            {!isFetchingSimplePasswordHistoric &&
            passwordHistoricList.simplePasswords.length === 0 ? (
              <span />
            ) : (
              <div className="mt-7">
                {!isFetchingSimplePasswordHistoric &&
                notHasFilteredPasswordHistoricList ? (
                  <ResultsNotFoundMessage
                    id="result-not-found-simple-password"
                    minHeight="190px"
                    height="calc(100vh - 680px)"
                  />
                ) : (
                  <Card className="pa-6">
                    <div className="d-flex justify-between align-center mb-4">
                      <span
                        className={classNames([
                          'text-uppercase title-sm-base',
                          styles.titles
                        ])}>
                        {t('Histórico de senhas')}
                      </span>

                      {!isFetchingSimplePasswordHistoric ? (
                        <div className="d-flex align-center">
                          <Toggle
                            checked={showViewPasswordHistoric}
                            color="var(--color-status-base-ok)"
                            onChange={() => {
                              setShowViewPasswordHistoric((prev) => !prev);
                            }}
                          />
                          <span className="title-xl-sm">
                            {t('Visualizar Senhas')}
                          </span>
                        </div>
                      ) : (
                        <div style={{ width: '150px' }}>
                          <Skeleton width="100%" height={30} />
                        </div>
                      )}
                    </div>
                    <div
                      className={classNames(
                        'mb-5',
                        styles.labelInfoHistoryPassword
                      )}>
                      <Alert type="info">
                        {t(
                          'Atenção, o histórico armazena apenas as últimas 10 senhas adicionadas no Captive Portal'
                        )}
                      </Alert>
                    </div>
                    {isFetchingSimplePasswordHistoric ? (
                      <div className="d-flex flex-column">
                        <Skeleton
                          count={1}
                          height={50}
                          borderRadius={10}
                          className="mb-3"
                        />
                        {renderSkeletonsOfTable(10)}
                      </div>
                    ) : (
                      <Table
                        columns={columnsTablePasswordHistoric}
                        rows={generateRows(filteredPasswordHistoricList)}
                        selectedRows={selectedPasswords}
                        id="simple-password-table"
                        onSelectRows={(
                          selectedRows: IPasswordHistoricRow[]
                        ) => {
                          setSelectedPasswords(selectedRows);
                        }}
                      />
                    )}
                  </Card>
                )}
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export { SimplePassword };
