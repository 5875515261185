import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import Combobox, { IComboboxItem } from 'src/components/Combobox';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { Alert } from 'ui-components/Alert';
import Modal from 'ui-components/Modal';
import { useParams } from 'react-router-dom';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { IWireless } from '../WirelessTab/types';
import styles from './AddWirelessModal.module.css';

interface IProps {
  show: boolean;
  onClose: () => void;
  listName: string;
  setWirelessList: React.Dispatch<React.SetStateAction<IWireless[]>>;
  wirelessList: IWireless[];
}

const AddWirelessModal = ({
  show,
  onClose,
  listName,
  setWirelessList,
  wirelessList
}: IProps) => {
  const { addToast } = useToast();

  const { id: idSite } = useParams();

  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const [selectedItems, setSelectedItems] = useState<IComboboxItem[]>([]);
  const [comboItems, setComboItems] = useState<IComboboxItem[]>([]);

  const onSubmit = () => {
    setSelectedItems([]);

    const newWirelessList: IWireless[] = selectedItems.map(
      (newWireless, index) => ({
        id: index,
        name: newWireless.value as string
      })
    );
    setWirelessList((prev) => [...prev, ...newWirelessList]);

    addToast('success', t('Wireless adicionado com sucesso'));
    onClose();
  };

  const getDisassociatedWireless = () => {
    return api.devices.acls.getDisassociatedWireless(idSite);
  };

  const { isFetching } = useQuery('wirelessList', getDisassociatedWireless, {
    onSuccess: ({ data }) => {
      const wirelessListFormatedToComboBox = data.wireless
        .map((wireless) => ({
          label: wireless.name,
          value: wireless.name
        }))
        .filter((wireless) => {
          const wirelessAlreadyInList = wirelessList.find(
            (wirelessInList) => wirelessInList.name === wireless.value
          );

          return !wirelessAlreadyInList;
        });

      setComboItems(wirelessListFormatedToComboBox);
    },

    enabled: show
  });

  const renderCombobox = () => {
    if (comboItems.length === 0) {
      return (
        <EmptyMessage
          title={t('Nenhuma wireless')}
          subtitle={t(
            'Não há wireless disponíveis para serem exibidas no momento'
          )}
          id="message-no-wireless"
          height="160px"
        />
      );
    }

    return (
      <div>
        <Combobox
          label={`${t('Nome Wireless')}:`}
          items={comboItems}
          value={selectedItems}
          onChange={setSelectedItems}
          placeholder={t('Insira o nome do Wireless')}
          menuHeight={200}
        />
        <div className="mt-4">
          <Alert type="info">
            {t(
              'Só é possível adicionar um Wireless que não esteja inserido em outra Lista de acesso (ACL)'
            )}
          </Alert>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    return (
      <>
        <div>
          <Skeleton height={16} width={100} />
          <Skeleton height={51.1} />
        </div>
        <div className="mt-4">
          <Skeleton height={36} />
        </div>
      </>
    );
  };

  const getActions = () => {
    if (comboItems.length === 0) {
      return [
        {
          label: t('FECHAR'),
          action: () => {
            onClose();
            setSelectedItems([]);
          }
        }
      ];
    }

    return [
      {
        label: t('CANCELAR'),
        action: () => {
          onClose();
          setSelectedItems([]);
        }
      },
      {
        label: t('SALVAR'),
        action: onSubmit,
        disabled: selectedItems.length === 0
      }
    ];
  };

  return (
    <Modal
      closable={false}
      show={show}
      title={t('ADICIONAR WIRELESS')}
      width="735px"
      actions={getActions()}>
      <span className={styles.subtitle}>
        {t('Adicione um novo Wireless a lista')}{' '}
        <span className="text-bold">{listName}</span>
      </span>

      <div className={styles.formContainer}>
        <div>{isFetching ? renderSkeleton() : renderCombobox()}</div>
      </div>
    </Modal>
  );
};

export default AddWirelessModal;
