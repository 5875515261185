import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';

interface ICaptiveInfoModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaptiveInfoModal = ({ showModal, setShowModal }: ICaptiveInfoModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.infoModal'
  });

  return (
    <Modal
      closable
      title={t('Captive Portal')}
      onClose={() => {
        setShowModal(false);
      }}
      show={showModal}
      width="660px">
      <div className="text-base-lg mb-2">
        <span>
          {t(
            'Captive Portal é o modo de autenticação de usuários na rede. Gerencie e configure o Captive Portal para que o usuário se conecte a redes wi-fi de acordo com o tipo e as definições estabelecidas. Aqui você pode'
          )}{' '}
        </span>
        <span className="text-bold">{t('Configurar o Método')} </span>
        <span>{t('escolhido, personaliza-lo em')} </span>
        <span className="text-bold">{t('Página do Captive')}</span>
        <span>, {t('e gerenciar as')} </span>
        <span className="text-bold">{t('Wireless Vinculadas')} </span>
        <span>{t('ao método')}.</span>
      </div>
    </Modal>
  );
};

export { CaptiveInfoModal };
