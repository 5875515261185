import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';
import useWebSocket from 'react-use-websocket';
import { useAuth } from 'src/hooks/useAuth';
import api from 'src/services/api';
import {
  IConfigurationStatus,
  IWebSocketNotificationMessage
} from 'src/templates/MenuAndNavbar/AddDeviceDrawer/types';
import { NotificationContextType } from './types';

const socketUrl = `${process.env.REACT_APP_WS_HOST}/device/configuration/status/`;

export const NotificationContext = createContext({} as NotificationContextType);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [configurationStatus, setConfigurationStatus] =
    useState<IConfigurationStatus | null>(null);
  const [animateNotification, setAnimateNotification] = useState(false);
  const [animateNotificationWithText, setAnimateNotificationWithText] =
    useState(false);
  const [textOfNotification, setTextOfNotification] = useState('');
  const [showNotificationIndicator, setShowNotificationIndicator] =
    useState(false);
  const [firstLoadOfNotification, setFirstLoadOfNotification] = useState(false);
  const [deviceIsDeletedObserver, setDeviceIsDeletedObserver] = useState(false);

  const triggerDeviceIsDeletedObserver = useCallback(() => {
    setDeviceIsDeletedObserver((prevState) => !prevState);
  }, []);

  const triggerNotificationAnimation = useCallback(() => {
    setAnimateNotification(true);
    setTimeout(() => setAnimateNotification(false), 2000);
    setShowNotificationIndicator(true);
  }, []);

  const triggerNotificationAnimationWithText = useCallback(
    (textToNotification: string) => {
      setAnimateNotificationWithText(true);
      setTextOfNotification(textToNotification);
      setTimeout(() => setAnimateNotificationWithText(false), 4000);
    },
    []
  );

  // Queries
  const { refetch: refetchConfigurationsStatus } = useQuery(
    'configurationStatus',
    api.devices.status.get,
    {
      onSuccess: ({ data }) => setConfigurationStatus(data)
    }
  );

  const contextValue = useMemo(
    () => ({
      animateNotification,
      animateNotificationWithText,
      textOfNotification,
      deviceIsDeletedObserver,
      triggerDeviceIsDeletedObserver,
      triggerNotificationAnimationWithText,
      showNotificationIndicator,
      triggerNotificationAnimation,
      refetchConfigurationsStatus
    }),
    [
      animateNotification,
      animateNotificationWithText,
      textOfNotification,
      deviceIsDeletedObserver,
      triggerDeviceIsDeletedObserver,
      triggerNotificationAnimationWithText,
      showNotificationIndicator,
      triggerNotificationAnimation,
      refetchConfigurationsStatus
    ]
  );
  const { accessToken } = useAuth();
  const {
    lastJsonMessage
  }: {
    lastJsonMessage: IWebSocketNotificationMessage;
    readyState: number;
  } = useWebSocket(
    socketUrl,
    {
      queryParams: {
        access_token: accessToken?.access_token as string
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      shouldReconnect: (closeEvent) => true
    },
    Boolean(accessToken) && configurationStatus !== null
  );

  useEffect(() => {
    if (configurationStatus !== null && configurationStatus) {
      if (!firstLoadOfNotification) {
        if (configurationStatus.error > 0) {
          triggerNotificationAnimation();
          setFirstLoadOfNotification(true);
        }
      }

      if (configurationStatus.error === 0) {
        setShowNotificationIndicator(false);
        setFirstLoadOfNotification(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationStatus]);

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.topic === 'status') {
      setConfigurationStatus(lastJsonMessage.data);
    }
  }, [lastJsonMessage]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
