import React from 'react';
import Modal from 'ui-components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Grid, Row } from 'inmaster-ui';
import classNames from 'classnames';
import Badge from 'src/components/Badge';
import { ButtonIcon } from 'ui-components/Button';
import { MdEdit } from 'react-icons/md';
import { Divider } from 'src/components/Divider';
import { IIndividualRecord } from '../types';
import styles from './MoreInformationModal.module.css';

interface IMoreInformationModalProps {
  selectedIndividualRecord: IIndividualRecord;
  showMoreInformationModal: boolean;
  onCloseMoreInformationModal: () => void;
  onClickToEditRecord?: () => void;
}
const MoreInformationModal = ({
  selectedIndividualRecord,
  showMoreInformationModal,
  onClickToEditRecord,
  onCloseMoreInformationModal
}: IMoreInformationModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const isAuthorizedRecord = selectedIndividualRecord.authorizedStatus;

  const renderRecordStatus = () => {
    switch (selectedIndividualRecord.status) {
      case t('Utilizado'):
        return (
          <Badge
            title={selectedIndividualRecord.status}
            color="var(--color-status-ok-darkest)"
            backgroundColor="var(--color-status-ok-lightest)"
          />
        );
      case t('Disponível'):
        return (
          <Badge
            title={selectedIndividualRecord.status}
            color="var(--color-status-alert-darkest)"
            backgroundColor="var(--color-status-alert-lightest)"
          />
        );

      case t('Expirado'):
        return (
          <Badge
            title={selectedIndividualRecord.status}
            color="var(--color-status-critical-base)"
            backgroundColor="var(--color-status-critical-lightest)"
          />
        );
      default:
        return <span>--</span>;
    }
  };

  const btnEditInformation = () => {
    return (
      <ButtonIcon id="btn-edit-information" onClick={onClickToEditRecord}>
        <MdEdit />
      </ButtonIcon>
    );
  };

  const renderInformationItem = (label: string, value: string) => {
    return (
      <Col xs={4} className={classNames(['d-flex flex-column'])}>
        <span className={classNames(['text-bold'])}>{label}</span>
        <span>{value}</span>
      </Col>
    );
  };

  return (
    <Modal
      title={t('Mais informações')}
      show={showMoreInformationModal}
      width="730px"
      height="auto"
      closable
      btnTitle={
        selectedIndividualRecord.status !== t('Expirado') &&
        btnEditInformation()
      }
      onClose={onCloseMoreInformationModal}>
      <div className={styles.modalContainer}>
        <Grid fluid>
          <Row className={classNames('d-flex align-center')}>
            {renderInformationItem(t('Nome'), selectedIndividualRecord.name)}
            {renderInformationItem(
              t('Nº Documento'),
              selectedIndividualRecord.document
            )}
            {renderInformationItem(
              t('Autorizado'),
              isAuthorizedRecord ? t('Sim') : t('Não')
            )}
          </Row>
          <Row className={classNames(['mt-5 d-flex align-center'])}>
            {renderInformationItem(
              t('Telefone'),
              selectedIndividualRecord.phone
            )}
            {renderInformationItem(t('E-mail'), selectedIndividualRecord.email)}
            {selectedIndividualRecord.status !== t('Expirado') && (
              <Col xs={4} className={classNames(['d-flex flex-column'])}>
                <span className={classNames(['text-bold'])}>{t('Status')}</span>
                <div className={classNames(styles.statusContent)}>
                  {(isAuthorizedRecord && renderRecordStatus()) || '--'}
                </div>
              </Col>
            )}
          </Row>
          <Divider color="var(--color-neutral-light-3)" className="mt-5 mb-5" />
          <Row className={classNames(['d-flex align-center'])}>
            {renderInformationItem(
              t('Inicia em:'),
              (isAuthorizedRecord && selectedIndividualRecord.started_at) ||
                '--'
            )}
            {renderInformationItem(
              t('Tempo'),
              selectedIndividualRecord.time || '--'
            )}
            {renderInformationItem(
              selectedIndividualRecord.status !== t('Expirado')
                ? t('Expira em:')
                : t('Expirado em:'),
              (isAuthorizedRecord && selectedIndividualRecord.expires_at) ||
                '--'
            )}
          </Row>
          <Row className="mt-5">
            <Col
              xs={12}
              className={classNames([
                'd-flex align-center text-sm-base',
                styles.createdAtContent
              ])}>
              <span className="mr-2">Cadastro gerado em: </span>
              <span>{selectedIndividualRecord.created_at || '--'}</span>
            </Col>
          </Row>
        </Grid>
      </div>
    </Modal>
  );
};

MoreInformationModal.defaultProps = {
  onClickToEditRecord: () => {
    return null;
  }
};

export { MoreInformationModal };
