import React from 'react';
import classNames from 'classnames';
import logoImg from 'src/assets/logo-intelbras-verde.svg';
import {
  MdHelp,
  MdHome,
  MdLogout,
  MdNotifications,
  MdSettings
} from 'react-icons/md';
import { useAuth } from 'src/hooks/useAuth';
import { Button, ButtonIcon } from 'ui-components/Button';
import { Divider } from 'src/components/Divider';
import { Avatar } from 'ui-components/Avatar';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import Indicator from 'ui-components/indicator';
import { useNotification } from 'src/hooks/useNotification';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import { useTranslation } from 'react-i18next';
import Label from 'ui-components/Label';
import { eventGA } from 'src/utils/analytics';
import { IErrorResponse } from 'src/services/api/types';
import { queryClient } from 'src/services/queryClient';
import { Tooltip } from 'inmaster-ui';
import Skeleton from 'react-loading-skeleton';
import { useDebounce } from 'use-debounce';
import { useNavbarDrawers } from 'src/hooks/useNavbarDrawers';
import styles from './Navbar.module.css';
import {
  IPlaceResponseUnique,
  IPlacesResponse
} from '../../services/api/urls/sites/types';
import DropdownWithSearch from '../DropdownWithSearch';
import { IDropdownPlaces, INavbarProps } from './types';
import HelpDrawer from '../HelpDrawer';

const Navbar = ({
  fixed = false,
  hasMenu = false,
  isMenuMinimized = false,
  hasLogo,
  canChangePlace = false,
  actions = {
    config: false,
    notification: false,
    avatar: false,
    helper: false
  }
}: INavbarProps) => {
  const { toggleAddDeviceDrawer, toggleHelperDrawer, isHelperDrawerOpen } =
    useNavbarDrawers();

  const navigate = useNavigate();

  const location = useLocation();

  const { t } = useTranslation('translations', { keyPrefix: 'navbar' });

  const { logout, accessTokenInfo, accessToken, setAccessToken } = useAuth();

  const [showMenuAvatar, setShowMenuAvatar] = React.useState(false);

  const {
    animateNotification,
    showNotificationIndicator,
    animateNotificationWithText,
    textOfNotification,
    refetchConfigurationsStatus
  } = useNotification();

  const [currentPlace, setCurrentPlace] = React.useState<IDropdownPlaces>(
    {} as IDropdownPlaces
  );

  const [places, setPlaces] = React.useState<IDropdownPlaces[]>(
    [] as IDropdownPlaces[]
  );

  const [searchPlace, setSearchPlace] = React.useState('');

  const [selectedPlace, setSelectedPlace] = React.useState<IDropdownPlaces>(
    {} as IDropdownPlaces
  );

  const [debouncedSearchPlace] = useDebounce(searchPlace, 500);

  const { isLoading } = useQuery(
    'getCurrentPlace',
    () => api.sites.places.details.get(accessToken?.place_id as string),
    {
      enabled: canChangePlace && Object.keys(currentPlace).length === 0,
      onSuccess: (data) => {
        const placeData = data.data as IPlaceResponseUnique;
        setCurrentPlace({
          id: placeData.place.id,
          label: placeData.place.name,
          value: placeData.place.id,
          qtdDevices: placeData.place.device_count
        });
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );
  const { refetch: refetchPlaces } = useQuery(
    'placesQueryNavbar',
    () => {
      return api.sites.hefesto.places.get(accessToken?.site_id as string);
    },
    {
      enabled: canChangePlace,
      refetchOnWindowFocus: true,
      onSuccess: ({ data }: { data: IPlacesResponse }) => {
        setPlaces(
          data.place_list.map((place) => ({
            label: place.name,
            value: place.id,
            qtdDevices: place.device_count
          }))
        );
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );

  const getPlacesBySearch = () => {
    return api.sites.places.search(searchPlace, accessToken?.site_id as string);
  };

  useQuery(
    ['placesQuerySearchNavbar', debouncedSearchPlace],
    getPlacesBySearch,
    {
      enabled: canChangePlace,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: IPlacesResponse }) => {
        setPlaces(
          data.place_list.map((place) => ({
            id: `${place.name}@${place.id}`,
            label: place.name,
            value: place.id,
            qtdDevices: place.device_count
          }))
        );
      }
    }
  );

  const choosePlace = useMutation(
    (data: { place_id: string }) => {
      return api.auth.put({
        place_id: data.place_id
      });
    },
    {
      onSuccess: (data) => {
        setAccessToken(data.data.access_token_data);

        if (selectedPlace.qtdDevices === 0) {
          navigate('/first-device');
        } else if (location.pathname === '/devices') {
          queryClient.refetchQueries('getInfoCurrentPlace');
          queryClient.refetchQueries('getDevicesList');
        } else {
          navigate('/devices');
        }

        refetchConfigurationsStatus();
      }
    }
  );

  const onChangePlace = (place: IDropdownPlaces) => {
    if (place.value !== selectedPlace.value) {
      setSelectedPlace(place);
    }
  };

  const getClassesNavbarByType = () => {
    const classNameStyles = [];
    if (fixed) {
      classNameStyles.push(styles.navbarFixed);
    }

    if (hasMenu && !isMenuMinimized) {
      classNameStyles.push(styles.expandedMenu);
    }

    if (hasMenu && isMenuMinimized) {
      classNameStyles.push(styles.minimizedMenu);
    }

    return classNames([styles.navbar, ...classNameStyles]);
  };

  const renderItemsMenu = () => {
    return (
      <>
        <MenuItem
          aria-hidden="true"
          id="menu-item-avatar"
          style={{
            pointerEvents: 'none'
          }}>
          <div className="d-flex align-center">
            <Avatar id="avatar" className="mr-3">
              {accessTokenInfo?.first_name[0]}
              {accessTokenInfo?.last_name[0]}
            </Avatar>
            <div className="d-flex flex-column">
              <Label>
                {accessTokenInfo?.first_name} {accessTokenInfo?.last_name}
              </Label>
              <Label className={classNames([styles.emailStyles])}>
                {accessTokenInfo?.email}
              </Label>
            </div>
          </div>
        </MenuItem>
        <div className="d-flex justify-center">
          <div className={classNames(styles.dividerMenu)}>
            <Divider />
          </div>
        </div>
        <MenuItem
          onClick={() => {
            navigate('/information', { replace: true });
            setShowMenuAvatar(false);
          }}
          aria-hidden="true"
          id="menu-item-info">
          <div className="d-flex align-center">
            <MdSettings width={18} />
            <span className="ml-2 text-sm-base">{t('Informações')}</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            eventGA('Usuário', 'Click', 'Deslogar');
            logout('logout');
          }}
          aria-hidden="true"
          id="menu-item-logout">
          <div className="d-flex">
            <MdLogout color="var(--color-status-critical-base)" width={18} />
            <span
              className={classNames([styles.logoutBtn, 'ml-2 text-sm-base'])}>
              {t('Sair')}
            </span>
          </div>
        </MenuItem>
      </>
    );
  };

  React.useEffect(() => {
    if (selectedPlace.value) {
      setCurrentPlace(selectedPlace);
      choosePlace.mutate({ place_id: selectedPlace.value });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  React.useEffect(() => {
    if (searchPlace.length === 0 && !!accessToken?.site && canChangePlace) {
      refetchPlaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPlace]);

  return (
    <div className={getClassesNavbarByType()}>
      <div
        className={`d-flex align-center ${
          canChangePlace ? 'justify-end' : 'justify-between'
        } fit-width`}>
        {hasLogo && (
          <div className={classNames([styles.logoShell])}>
            <img
              src={logoImg}
              className={classNames([styles.logo])}
              alt={t('Logo da Intelbras')}
            />
          </div>
        )}

        {canChangePlace && (
          <div className="d-flex justify-start fit-width align-center">
            <Button
              type="button"
              id="btn-back-to-sites"
              ghost
              aria-label={t('Voltar para a página de "Locais"')}
              className={classNames(styles.backToSitesButton)}
              onClick={() => navigate('/sites')}>
              <span className={classNames(styles.iconBackToSitesButton)}>
                <MdHome size={30} />
              </span>
              <span className="ml-2">{t('ACESSAR LOCAIS')}</span>
            </Button>
            <Divider orientation="vertical" />
            {isLoading ? (
              <span className="ml-5">
                <Skeleton count={1} height={40} width={200} />
              </span>
            ) : (
              <DropdownWithSearch
                options={places}
                onChange={(value) => onChangePlace(value)}
                placeholder=""
                value={currentPlace}
                borderless
                id="dropdown-places"
                onBlur={() => setSearchPlace('')}
                onSearchChange={(searchValue) => setSearchPlace(searchValue)}
                searchValue={searchPlace}
                searchPlaceholder={t('Pesquisar ambiente')}
                isTitle
              />
            )}
          </div>
        )}
        <div className="d-flex align-center justify-end">
          {actions.helper ? (
            <>
              <HelpDrawer
                show={isHelperDrawerOpen}
                onClose={() => {
                  eventGA('Usuário', 'Click', 'Visualizar Central de Ajuda');
                  toggleHelperDrawer();
                }}
              />
              <Divider orientation="vertical" />
              <Tooltip
                content={t('Central de ajuda')}
                id="tooltip-button-notifications"
                place="bottom">
                <ButtonIcon
                  id="button-notifications"
                  onClick={() => toggleHelperDrawer()}>
                  <MdHelp size={30} />
                </ButtonIcon>
              </Tooltip>
            </>
          ) : null}

          {actions.notification ? (
            <div
              onClick={() =>
                animateNotificationWithText ? toggleAddDeviceDrawer() : null
              }
              aria-hidden="true"
              className={classNames([
                styles.withoutNotificationText,
                animateNotificationWithText
                  ? styles.notificationWithMessage
                  : null
              ])}>
              <div>
                <span id="text-notification">{textOfNotification}</span>
              </div>
              <ButtonIcon
                id="button-notifications"
                onClick={() => {
                  eventGA('Notification', 'Click', 'Visualizar Notificação');
                  toggleAddDeviceDrawer();
                }}>
                <div
                  className={
                    animateNotification ? styles.notificationShell : ''
                  }>
                  <Indicator
                    content=""
                    show={showNotificationIndicator}
                    className={
                      animateNotification ? styles.indicatorNotifications : ''
                    }
                    id="indicator-notifications">
                    <MdNotifications size={30} />
                  </Indicator>
                </div>
              </ButtonIcon>
            </div>
          ) : null}
          {actions.avatar ? (
            <>
              <Divider orientation="vertical" />
              <Menu
                items={renderItemsMenu()}
                positionX="end"
                width="260px"
                onClose={() => setShowMenuAvatar(false)}
                show={showMenuAvatar}>
                <Avatar
                  id="button-avatar"
                  clickable
                  onClick={() => setShowMenuAvatar(!showMenuAvatar)}>
                  {accessTokenInfo?.first_name[0]}
                  {accessTokenInfo?.last_name[0]}
                </Avatar>
              </Menu>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  fixed: false,
  sticky: false,
  hasMenu: false,
  isMenuMinimized: false,
  hasLogo: true,
  showDrawer: false,
  canChangePlace: false,
  toggleDrawer: () => null,
  actions: {
    config: false,
    notification: false,
    avatar: false,
    helper: false
  }
};

export { Navbar };
