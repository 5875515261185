import classNames from 'classnames';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'inmaster-ui';
import { Controller, MultipleFieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Toggle from 'ui-components/Toggle';
import api from 'src/services/api';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useToast } from 'src/hooks/useToast';
import { useNotification } from 'src/hooks/useNotification';
import { IErrorResponse } from 'src/services/api/types';
import styles from './DeviceManager.module.css';
import { IDeviceManager } from './types';
import { IDeviceOnScreen } from '../types';
import DefaultPortWarningModal from './DefaultPortWarningModal';

interface IProps {
  deviceOnScreen: IDeviceOnScreen;
  refetchDeviceInfo: () => void;
}

const DeviceManager = ({ deviceOnScreen, refetchDeviceInfo }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const { addToast } = useToast();

  const { triggerNotificationAnimationWithText } = useNotification();

  const [showDefaultPortWarningModal, setShowDefaultPortWarningModal] =
    useState(false);

  const [deviceManagerDataToSaveViaModal, setDeviceManagerDataToSaveViaModal] =
    useState<IDeviceManager | null>(null);

  const params = useParams();

  const deviceId = params.id as string;

  const DEFAULT_SSH_PORT = 22;

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm<IDeviceManager>({
    defaultValues: {
      deviceManager: {
        ssh: {
          enableSSH: !!deviceOnScreen.device.ssh_port,
          SSHPort: deviceOnScreen.device.ssh_port || null
        }
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const validateNumberField = (message: string) => {
    return {
      startsWithZero: (value: string | number | null) => {
        if (value === null) {
          return true;
        }

        const textValue = String(value);

        return !textValue.startsWith('0') || message;
      },
      justIntegerNumbers: (value: string | number | null) => {
        if (value === null) {
          return true;
        }

        const textValue = String(value);

        return !!textValue.match(/^[0-9]*$/) || message;
      }
    };
  };

  const updateSSH = (data: IDeviceManager) => {
    const paylaod = {
      device: {
        ssh_port: data.deviceManager.ssh.enableSSH
          ? data.deviceManager.ssh.SSHPort
          : null
      }
    };

    return api.devices.ssh.put(deviceId, paylaod);
  };

  const updateSSHMutation = useMutation(updateSSH, {
    onSuccess: () => {
      triggerNotificationAnimationWithText(t('CONFIGURANDO EQUIPAMENTO'));
      addToast('success', t('Alterado com sucesso'));
      refetchDeviceInfo();
    },
    onError(error: IErrorResponse) {
      if (error.response.status === 304) {
        addToast(
          'error',
          t(
            'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
          )
        );
      } else {
        addToast(
          'error',
          t(
            'Estamos passando por uma instabilidade, tente novamente mais tarde'
          )
        );
      }
    }
  });

  const handleSaveDeviceManagerViaModal = (data: IDeviceManager) => {
    updateSSHMutation.mutate(data);
    setShowDefaultPortWarningModal(false);
    setDeviceManagerDataToSaveViaModal(null);
  };

  const handleSubmitManagerDevice = handleSubmit((data: IDeviceManager) => {
    if (
      Number(data.deviceManager.ssh.SSHPort) !== DEFAULT_SSH_PORT &&
      data.deviceManager.ssh.enableSSH &&
      !deviceManagerDataToSaveViaModal
    ) {
      setShowDefaultPortWarningModal(true);
      setDeviceManagerDataToSaveViaModal(data);
    } else {
      updateSSHMutation.mutate(data);
    }
  });

  const isFormManagerChanged = !useFormCompare({
    initialValues: {
      ssh: {
        enableSSH: !!deviceOnScreen.device.ssh_port,
        SSHPort: deviceOnScreen.device.ssh_port || null
      }
    },
    currentValues: {
      ssh: {
        enableSSH: watch('deviceManager.ssh.enableSSH'),
        SSHPort: Number(watch('deviceManager.ssh.SSHPort')) || null
      }
    }
  });

  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notOutOfASCIIPattern) {
      message = errorTypes.notOutOfASCIIPattern;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.min) {
      message = errorTypes.min;
    } else if (errorTypes.max) {
      message = errorTypes.max;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.startsWithZero) {
      message = errorTypes.startsWithZero;
    } else if (errorTypes.justIntegerNumbers) {
      message = errorTypes.justIntegerNumbers;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  const setValueSSHPort = (value: boolean) => {
    if (value) {
      setValue('deviceManager.ssh.SSHPort', null);
      clearErrors();
    } else {
      setValue(
        'deviceManager.ssh.SSHPort',
        deviceOnScreen.device.ssh_port || DEFAULT_SSH_PORT
      );
    }
  };

  return (
    <>
      <DefaultPortWarningModal
        onClose={() => {
          setShowDefaultPortWarningModal(false);
          setDeviceManagerDataToSaveViaModal(null);
        }}
        show={showDefaultPortWarningModal}
        onSave={() => {
          handleSaveDeviceManagerViaModal(
            deviceManagerDataToSaveViaModal as IDeviceManager
          );
        }}
      />
      <div className="fit-width mb-3">
        <Card background="var(--color-neutral-light-4)">
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <Label className="d-flex title-xl-base mb-2">
                  {t('Acesso SSH')}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="text-xl-lg">
                  {t(
                    'Você pode habilitar ou desabilitar o acesso ao equipamento por uma conexão SSH'
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12}>
                <div className="d-flex mb-4">
                  <Controller
                    control={control}
                    name="deviceManager.ssh.enableSSH"
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        color="var(--color-status-ok-base)"
                        checked={value}
                        id="enable-ssh"
                        onChange={() => {
                          setValueSSHPort(value);

                          onChange(!value);
                        }}
                      />
                    )}
                  />
                  <span>
                    {watch('deviceManager.ssh.enableSSH')
                      ? t('Habilitado')
                      : t('Desabilitado')}
                  </span>
                </div>
                {watch('deviceManager.ssh.enableSSH') ? (
                  <div>
                    <InputWrapper
                      invalid={Boolean(errors?.deviceManager?.ssh?.SSHPort)}>
                      <Label className="text-xl-xs">{t('Porta')}:</Label>
                      <Controller
                        control={control}
                        name="deviceManager.ssh.SSHPort"
                        rules={{
                          required: {
                            value: true,
                            message: t(
                              'É obrigatório preencher o campo, ou desabilite o acesso SSH'
                            )
                          },
                          min: {
                            value:
                              watch('deviceManager.ssh.SSHPort') === null
                                ? 0
                                : 1,
                            message: t(
                              'O valor do campo deve ser um número inteiro entre 1 e 65535'
                            )
                          },
                          max: {
                            value: 65535,
                            message: t(
                              'O valor do campo deve ser um número inteiro entre 1 e 65535'
                            )
                          },
                          validate: validateNumberField(
                            t(
                              'O valor do campo deve ser um número inteiro entre 1 e 65535'
                            ) as string
                          ),
                          pattern: {
                            value: /^[+-]?\d+(\.\d+)?(,\d+)?$/,
                            message: t('O campo só aceita valores numéricos')
                          }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Input
                            className={classNames([styles.inputStyles])}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value === null ? '' : value}
                            id="ssh-port"
                            maxLength={5}
                          />
                        )}
                      />
                      {getInputErrorMessage(
                        errors?.deviceManager?.ssh?.SSHPort?.types
                      )}
                    </InputWrapper>
                    <Label
                      className={classNames([
                        'text-xl-base',
                        styles.portLabel
                      ])}>
                      {t('Porta padrão:')} {DEFAULT_SSH_PORT}
                    </Label>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="fit-width d-flex justify-end mt-3">
                  <Button
                    type={!isFormManagerChanged ? 'button' : 'submit'}
                    disabled={
                      !isFormManagerChanged ||
                      (watch('deviceManager.ssh.enableSSH') &&
                        !watch('deviceManager.ssh.SSHPort')) ||
                      !!errors.deviceManager?.ssh?.SSHPort
                    }
                    isLoading={updateSSHMutation.isLoading}
                    onClick={() => handleSubmitManagerDevice()}
                    id="btn-save-device-manager">
                    {t('SALVAR')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export { DeviceManager };
