import React from 'react';
import Modal from 'ui-components/Modal';
import { useTranslation } from 'react-i18next';
import { Col, Grid, Row } from 'inmaster-ui';
import classNames from 'classnames';
import { ButtonIcon } from 'ui-components/Button';
import { MdEdit } from 'react-icons/md';
import { IIndividualRecordValue } from 'src/components/FormAddIndividualRecord/types';
import styles from './MoreInformationModal.module.css';

interface ISelectedIndividualRecord
  extends Omit<IIndividualRecordValue, 'period'> {
  period: string;
}

interface IMoreInformationModalProps {
  selectedIndividualRecord: ISelectedIndividualRecord;
  showMoreInformationModal: boolean;
  onCloseMoreInformationModal: () => void;
  onClickEditInformation: () => void;
}
const MoreInformationModal = ({
  selectedIndividualRecord,
  showMoreInformationModal,
  onCloseMoreInformationModal,
  onClickEditInformation
}: IMoreInformationModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const btnEditInformation = () => {
    return (
      <ButtonIcon id="btn-edit-information" onClick={onClickEditInformation}>
        <MdEdit />
      </ButtonIcon>
    );
  };

  return (
    <Modal
      title={t('Mais informações')}
      show={showMoreInformationModal}
      width="733px"
      height="372px"
      closable
      btnTitle={btnEditInformation()}
      onClose={onCloseMoreInformationModal}>
      <div className={styles.modalContainer}>
        <Grid fluid>
          <Row className={classNames(['mt-5', 'd-flex', 'align-center'])}>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>
                {t('Nº Documento')}
              </span>
              <span id="doc-number">{selectedIndividualRecord.docNumber}</span>
            </Col>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>{t('Nome')}</span>
              <span id="name">{selectedIndividualRecord.name}</span>
            </Col>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>{t('E-mail')}</span>
              <span id="email">{selectedIndividualRecord.email}</span>
            </Col>
          </Row>
          <Row className={classNames(['mt-5', 'd-flex', 'align-center'])}>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>{t('Tempo')}</span>
              <span id="period">{selectedIndividualRecord.period}</span>
            </Col>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>
                {t('Autorizado')}
              </span>
              <span id="authorized">
                {selectedIndividualRecord.authorized ? t('Sim') : t('Não')}
              </span>
            </Col>
            <Col xs={4} className={classNames(['d-flex', 'flex-column'])}>
              <span className={classNames(['text-bold'])}>{t('Telefone')}</span>
              <span id="phone">{selectedIndividualRecord.phone}</span>
            </Col>
          </Row>
        </Grid>
      </div>
    </Modal>
  );
};

export { MoreInformationModal };
