import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'ui-components/Card';
import CaptiveTableAssociatedWireless from 'src/pages/CaptivePortal/AssociatedWireless/CaptiveTableAssociatedWireless';
import { ICaptivePortal } from 'src/pages/CaptivePortal/types';
import { IResumedWireless } from 'src/pages/CaptivePortal/AssociatedWireless/types';
import _ from 'lodash';
import TitleAndDescription from '../../TitleAndDescription';
import stepStyles from '../Steps.module.css';
import ButtonsActionsWizard, {
  handleContinueBooleanFunction
} from '../../ButtonsActions';

interface IAssociatedWirelessWizardProps {
  defaultCaptivePortal: ICaptivePortal;
  wirelessList: IResumedWireless[];
  setWirelessList: React.Dispatch<React.SetStateAction<IResumedWireless[]>>;
  selectedWireless: IResumedWireless[];
  setSelectedWireless: React.Dispatch<React.SetStateAction<IResumedWireless[]>>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowFinishModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUnSelectedWireless: React.Dispatch<
    React.SetStateAction<IResumedWireless[]>
  >;
  isFirstCheckAndSelectWireless: boolean;
  setIsFirstCheckAndSelectWireless: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const AssociatedWirelessWizard = ({
  defaultCaptivePortal,
  wirelessList,
  setWirelessList,
  selectedWireless,
  setSelectedWireless,
  setCurrentStep,
  currentStep,
  setShowFinishModal,
  setUnSelectedWireless,
  isFirstCheckAndSelectWireless,
  setIsFirstCheckAndSelectWireless
}: IAssociatedWirelessWizardProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const [isFetchedWirelessList, setIsFetchedWirelessList] =
    React.useState(false);

  const [, setAssociatedWireless] = React.useState<IResumedWireless[]>([]);

  const getWirelessWithCaptiveEnabled = () => {
    return wirelessList.filter(
      (wireless) => wireless.is_captive_portal_enabled
    );
  };

  const selectThePreviousCaptiveWirelessForTheFirstTime = () => {
    setIsFirstCheckAndSelectWireless(true);
    setSelectedWireless(getWirelessWithCaptiveEnabled());
  };

  const removeUnselectedCaptivePortalWirelessOfSelectedWireless = () => {
    const wirelessWithCaptiveEnabled = getWirelessWithCaptiveEnabled();

    setSelectedWireless((prevState) => {
      const unSelectedWirelessWithCaptivePortalEnabled = _.differenceBy(
        wirelessWithCaptiveEnabled,
        prevState,
        'id'
      );

      return _.without(
        prevState,
        ...unSelectedWirelessWithCaptivePortalEnabled
      );
    });
  };

  const hasPrevCaptive = getWirelessWithCaptiveEnabled().length > 0;
  const hasNoSelectedWireless = selectedWireless.length === 0;

  const isFirstTimeToSelectPrevCaptiveWireless =
    !isFirstCheckAndSelectWireless && hasPrevCaptive;

  const hasNoPrevCaptiveAndWirelessSelected =
    !hasPrevCaptive && hasNoSelectedWireless;

  useEffect(() => {
    if (wirelessList.length > 0) {
      if (isFirstTimeToSelectPrevCaptiveWireless) {
        selectThePreviousCaptiveWirelessForTheFirstTime();
        return;
      }
      if (
        hasNoPrevCaptiveAndWirelessSelected &&
        !isFirstCheckAndSelectWireless
      ) {
        setIsFirstCheckAndSelectWireless(true);
        setSelectedWireless(wirelessList);
        return;
      }
      removeUnselectedCaptivePortalWirelessOfSelectedWireless();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wirelessList]);

  const getUnselectedWireless = () => {
    return _.differenceBy(wirelessList, selectedWireless, 'id');
  };

  const handleContinue: handleContinueBooleanFunction = () => {
    const unSelectedWireless = getUnselectedWireless();
    setShowFinishModal(true);
    setUnSelectedWireless(unSelectedWireless);
    return true;
  };

  return (
    <>
      <TitleAndDescription
        title={t('Wireless Vinculadas')}
        description={
          <span className={stepStyles.subtitle}>
            {t(
              'Vincule as redes wireless deste ambiente ao método de autenticação Captive Portal, para alterar a segurança das redes selecionadas'
            )}
          </span>
        }
      />
      <Card className={stepStyles.cardForm}>
        <CaptiveTableAssociatedWireless
          isFetchedWirelessAndCaptive={isFetchedWirelessList}
          selectedWireless={selectedWireless || []}
          setSelectedWireless={setSelectedWireless}
          wirelessList={wirelessList}
          defaultCaptivePortal={defaultCaptivePortal}
          setAssociatedWireless={setAssociatedWireless}
          setWirelessList={setWirelessList}
          setIsFetchedWirelessList={setIsFetchedWirelessList}
          isWizardFlow
        />
      </Card>
      <ButtonsActionsWizard
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        disabledContinue={selectedWireless.length === 0}
        textOfDisableTooltip={t('Vincule uma rede para completar a ativação')}
        handleContinue={handleContinue}
      />
    </>
  );
};

export default AssociatedWirelessWizard;
