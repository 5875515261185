import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Grid, Row, Tooltip } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Crumb } from 'ui-components/Breadcrumbs';
import { Button } from 'ui-components/Button';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import { EmptyMessage } from 'src/components/EmptyMessage';
import {
  IConnectionLogPagination,
  ISiteResponseUnique
} from 'src/services/api/urls/sites/types';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import moment from 'moment';
import { generateColumns } from 'src/utils/tableUtils';
import Skeleton from 'react-loading-skeleton';
import Loading from 'ui-components/Loading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTemplate } from 'src/hooks/useTemplate';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ButtonComeBack } from 'src/components/ButtonComeBack';
import TableWithPagination from 'src/components/TableWithPagination';
import { useDebounce } from 'use-debounce';
import Card from 'ui-components/Card';
import Label from 'ui-components/Label';
import { InputIconInside } from 'ui-components/InputWrapper';
import { MdInfo, MdSearch } from 'react-icons/md';
import { Input } from 'ui-components/Input';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import { DateConverter } from 'src/utils/Dates/DateConverter';
import styles from './ConnectionLog.module.css';
import { ActiveTabSitesEnum, ISiteItemPage } from '../Sites/types';
import { IConnectionLogPaginationObject, IConnectionLogRow } from './types';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from '../Devices/types';

const ConnectionLog = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.connectionLog'
  });

  const navigate = useNavigate();

  const { id: idSite } = useParams();

  const SUPORTE_INTELBRAS =
    'https://www.intelbras.com/pt-br/contato/suporte-tecnico/';

  const [activeTabSiteIndex] = useLocalStorage('@manager/activeTabSite');

  const [selectedSite, setSelectedSite] = useState<ISiteItemPage>(
    {} as ISiteItemPage
  );

  const [connectionsLog, setConnectionsLog] = useState<IConnectionLogRow[]>([]);

  const [search, setSearch] = useState('');

  const [isChangingPage, setIsChangingPage] = useState(false);

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  const [paginationObject, setPaginationObject] =
    useState<IConnectionLogPaginationObject>(
      {} as IConnectionLogPaginationObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const getSelectedSite = () => {
    return api.sites.get(idSite);
  };

  const { isFetchedAfterMount: isFetchedSite } = useQuery(
    'selectedSiteQuery',
    getSelectedSite,
    {
      cacheTime: 0,
      onSuccess: ({ data }: { data: ISiteResponseUnique }) => {
        setSelectedSite({
          ...data.site,
          id: data.site.id,
          name: data.site.name,
          timezone: data.site.timezone,
          member: data.member
        } as ISiteItemPage);
      },
      onError: () => {
        navigate('error');
      }
    }
  );

  const generateTooltipForUnknownNodeName = (nodeName: string) => {
    return (
      <Tooltip
        content={t('Cliente Desconhecido')}
        id={`tooltip-node-name-${nodeName}`}
        disabled={nodeName !== '*'}
        place="bottom-start">
        {nodeName}
      </Tooltip>
    );
  };

  const getConnectionsLogsPagination = () => {
    return api.sites.connection_log.get(
      selectedSite.id,
      paginationRequestDataDebounce
    );
  };

  const { isFetching, refetch: refetchPagination } = useQuery(
    ['connectionLogQuery', paginationRequestDataDebounce, selectedSite.id],
    getConnectionsLogsPagination,
    {
      retry: false,
      enabled: selectedSite.id !== undefined,
      onSuccess: ({ data }: { data: IConnectionLogPagination }) => {
        if (!isInitialLoadingComplete) {
          setIsInitialLoadingComplete(true);
        }
        const arrayConnectionLogRow: IConnectionLogRow[] =
          data.connection_list.map((connectionLog) => {
            return {
              ...connectionLog,
              opened_at: new DateConverter(
                connectionLog.opened_at,
                selectedSite.timezone
              ).getDateWithFormat(),
              closed_at: new DateConverter(
                connectionLog.closed_at,
                selectedSite.timezone
              ).getDateWithFormat(),
              place_name: connectionLog.place.name,
              node_name: generateTooltipForUnknownNodeName(
                connectionLog.node_name
              )
            };
          });
        setConnectionsLog(arrayConnectionLogRow);
        setPaginationObject({
          connection_list: arrayConnectionLogRow,
          pagination: data.pagination
        });
      }
    }
  );

  const connectionDownload = () => {
    return api.sites.connection_log.export(selectedSite.id, {
      search_param: search
    });
  };

  const { refetch: downloadDataConnection, isFetching: isDownloading } =
    useQuery(['downloadConnectionLog', selectedSite.id], connectionDownload, {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: ({ data }) => {
        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;

        link.setAttribute(
          'download',
          `${selectedSite.name.replaceAll(' ', '_')} - ${moment(
            new Date()
          ).format('DD-MM-YYYY')}`
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    });

  const isFetchingLogData =
    !isInitialLoadingComplete || !isFetchedSite || isFetching;

  const isNotGuestUser =
    selectedSite && selectedSite.member?.role !== RoleMember.GUEST;

  const isResultNotFound =
    paginationObject?.pagination?.total_filtered_items === 0 &&
    !isFetchingLogData;

  const isEmptyConnectionLog =
    !isFetchingLogData && paginationObject.pagination.total_items === 0;

  const columnsTableConnectionLog = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('node_ip', t('IP do cliente'), 'left', 0),
    generateColumns('node_mac', t('MAC do cliente'), 'left', 0),
    generateColumns('node_name', t('Nome do cliente'), 'left', 0),
    generateColumns('place_name', t('Ambiente'), 'left', 0),
    generateColumns('device_mac', t('MAC do equipamento'), 'left', 0),
    generateColumns('opened_at', t('Início'), 'left', 0),
    generateColumns('closed_at', t('Fim'), 'left', 0)
  ];

  const comeBackSites = () => {
    navigate('/sites', { replace: true });
  };

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-around my-4 align-center"
          key={`${i}-skeleton-connection-log-table`}>
          <Skeleton count={1} height={30} width={100} className="ml-2" />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} className="mr-3" />
        </div>
      );
    }

    return skeletons;
  };

  useTemplate('avatarNavbar');

  useEffect(() => {
    if (selectedSite.id !== undefined) {
      refetchPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationRequestDataDebounce, selectedSite.id]);

  useEffect(() => {
    setPaginationRequestData((prev) => ({
      ...prev,
      search_param: search,
      page_number: 1
    }));
  }, [search]);

  return (
    <div className={classNames(styles.shellConnectionLog)}>
      {!isDownloading ? (
        <Grid fluid>
          <Card className={classNames(styles.bodyOfConnectionLogs)}>
            {!isFetchedSite ? (
              <div className="mb-5">
                <Skeleton width={200} height={24} />
              </div>
            ) : (
              <Breadcrumbs className="mb-6">
                <Crumb>
                  <div className="d-flex justify-start fit-width align-center">
                    <ButtonComeBack onClick={() => comeBackSites()} />

                    <Link to="/sites">
                      {activeTabSiteIndex === ActiveTabSitesEnum.SHARED_WITH_ME
                        ? t('Compartilhados comigo')
                        : t('Meus locais')}
                    </Link>
                  </div>
                </Crumb>
                <Crumb>{t('Registro de conexões')}</Crumb>
              </Breadcrumbs>
            )}

            {isEmptyConnectionLog ? (
              <div className={classNames(['fit-width'])}>
                <EmptyMessage
                  title={t('Nenhum registro de conexão')}
                  subtitle={t(
                    'Ainda não há registro de conexão disponível neste local'
                  )}
                  id="message-no-connections-log"
                />
              </div>
            ) : (
              <div>
                <Row className="mb-6">
                  <Col xs={12}>
                    {!isInitialLoadingComplete ? (
                      <div>
                        <Skeleton width={280} height={35} />
                      </div>
                    ) : (
                      <h1
                        className={classNames([
                          'title-sm-lg',
                          styles.colorTitleAndSubtitle
                        ])}
                        id="connection-log-title-page">
                        {t('Registro de conexões')} - {selectedSite.name}
                      </h1>
                    )}
                  </Col>
                  <Col xs={12}>
                    {!isInitialLoadingComplete ? (
                      <div>
                        <Skeleton width="100%" height={70} />
                      </div>
                    ) : (
                      <div
                        className={classNames([
                          'mt-1 text-xl-lg',
                          styles.colorTitleAndSubtitle
                        ])}>
                        {t(
                          'Nessa página você pode consultar os registros mais recentes de quem teve acesso a sua rede. Caso queira, é possível fazer o download desses registros clicando no botão abaixo. Se precisar acessar registros mais antigos, por favor entre em contato com o nosso'
                        )}
                        <a
                          href={SUPORTE_INTELBRAS}
                          target="_blank"
                          rel="noreferrer"
                          className={classNames(['ml-1', styles.customLink])}>
                          {t('Suporte')}
                        </a>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="d-flex justify-between align-end">
                      {!isInitialLoadingComplete ? (
                        <>
                          <Col xs={8}>
                            <Row>
                              <Skeleton count={1} height={18} width={160} />
                            </Row>
                            <Skeleton
                              width="100%"
                              count={1}
                              height={52}
                              borderRadius={10}
                            />
                          </Col>
                          {isNotGuestUser && (
                            <div className="mb-1">
                              <Skeleton width={280} height={45} />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <Col xs={8}>
                            <Row>
                              <Label>{t('Pesquisar na lista')}:</Label>
                            </Row>
                            <InputIconInside
                              iconLeft={
                                isFetchingLogData &&
                                !isChangingPage &&
                                loadingTypeOfRowsPerPage.type === 'success' ? (
                                  <Loading value={40} indeterminate />
                                ) : (
                                  <MdSearch />
                                )
                              }>
                              <Input
                                id="search-input"
                                value={search}
                                iconInsideLeft
                                placeholder={t(
                                  'Pesquise pelo IP, MAC, Nome do cliente, ambiente, MAC do equipamento e por data'
                                )}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </InputIconInside>
                          </Col>
                          <div className="mb-1">
                            {isNotGuestUser && (
                              <Button
                                className={classNames([styles.downloadButton])}
                                onClick={() => {
                                  downloadDataConnection();
                                }}
                                disabled={isResultNotFound || isFetchingLogData}
                                id="btn-download-log"
                                outline
                                width={300}>
                                {t('DOWNLOAD DOS DADOS')}
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className={classNames(['mb-4 mt-2'])}>
                  {!isInitialLoadingComplete ? (
                    <Col xs={6}>
                      <Skeleton width="100%" count={1} height={25} />
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <span
                        className={classNames([
                          styles.subtitleInput,
                          'd-flex align-center text-sm-xs'
                        ])}>
                        <MdInfo size={18} className="mr-1" />
                        {t(
                          "Para pesquisar por registros de clientes desconhecidos, insira o '*' no campo"
                        )}
                      </span>
                    </Col>
                  )}
                </Row>
                <Row>
                  {isResultNotFound ? (
                    <div className={classNames(['fit-width'])}>
                      <ResultsNotFoundMessage
                        id="connections_log_not_found"
                        minHeight="190px"
                        height="calc(100vh - 480px)"
                      />
                    </div>
                  ) : (
                    <TableWithPagination<IConnectionLogRow>
                      noSelection
                      useKeyToCompareAsId
                      isChangingPage={isChangingPage}
                      columns={columnsTableConnectionLog}
                      isFetchingData={isFetching}
                      paginationData={paginationObject.pagination}
                      firstFetchWasMade={isInitialLoadingComplete}
                      paginationRequestData={paginationRequestDataDebounce}
                      rows={connectionsLog}
                      minRowsToShowPagination={10}
                      keyOfItemsOfPaginationTable="connection_list"
                      loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                      renderSkeletonsOfTable={renderSkeletonsOfTable}
                      setPaginationObject={setPaginationObject}
                      setIsChangingPage={setIsChangingPage}
                      setLoadingTypeOfRowsPerPage={setLoadingTypeOfRowsPerPage}
                      setPaginationRequestData={setPaginationRequestData}
                    />
                  )}
                </Row>
              </div>
            )}
          </Card>
        </Grid>
      ) : (
        <Loading
          indeterminate
          fullscreen
          show={isDownloading}
          message={t(
            'Fazendo o download dos dados isso pode demorar um pouco...'
          )}
        />
      )}
    </div>
  );
};

export { ConnectionLog };
