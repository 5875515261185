import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { Alert } from 'ui-components/Alert';
import Modal from 'ui-components/Modal';
import { useNotification } from 'src/hooks/useNotification';
import _ from 'lodash';
import { IWirelessSite } from 'src/services/api/urls/sites/types';
import { ICaptivePortal } from 'src/pages/CaptivePortal/types';
import { IMethodTypeName } from 'src/pages/CaptivePortal/SetUpMethod/types';
import { useGetTranslatedCaptiveMethodType } from 'src/utils/CaptivePortal/methodsTypes';
import { IWirelessRow } from '../types';

interface IDeleteWirelessModalProps {
  showModalDelete: boolean;
  selectedWireless: IWirelessRow[];
  onSuccessDeleteWireless: () => void;
  setShowModalDelete: Dispatch<SetStateAction<boolean>>;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  listWireless: IWirelessSite[];
  defaultCaptivePortal: ICaptivePortal | undefined;
}

const DeleteWirelessModal = ({
  showModalDelete,
  selectedWireless,
  onSuccessDeleteWireless,
  setIsDeleting,
  setShowModalDelete,
  listWireless,
  defaultCaptivePortal
}: IDeleteWirelessModalProps) => {
  const { addToast } = useToast();

  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });

  const { triggerNotificationAnimationWithText } = useNotification();

  const checkIfWirelessHasDevices = () => {
    return selectedWireless.some((wireless) => wireless.devices_ids.length > 0);
  };

  const getListOfIdsAssociatedWithWireless = () => {
    const devicesIdsAssociatedToWireless = [] as string[];

    selectedWireless.map((wireless) => {
      devicesIdsAssociatedToWireless.push(...wireless.devices_ids);
      return wireless;
    });

    return _.uniq(devicesIdsAssociatedToWireless);
  };

  const getNumberOfSelectedWirelessWithCaptiveEnabled = () => {
    return selectedWireless.filter(
      (wireless) => wireless.is_captive_portal_enabled
    ).length;
  };

  const getUnselectedWireless = () => {
    return _.differenceBy(listWireless, selectedWireless, 'id');
  };

  const getNumberOfUnselectedWirelessWithCaptiveEnabled = () => {
    return getUnselectedWireless().filter(
      (wireless) => wireless.is_captive_portal_enabled
    ).length;
  };

  const generatePluralOrSingular = () => {
    const hasMoreThanOneDevice =
      getListOfIdsAssociatedWithWireless().length > 1;

    if (hasMoreThanOneDevice) {
      return t('CONFIGURANDO EQUIPAMENTOS');
    }

    return t('CONFIGURANDO EQUIPAMENTO');
  };

  const numberOfSelectedWirelessWithCaptiveEnabled =
    getNumberOfSelectedWirelessWithCaptiveEnabled();

  const numberOfUnselectedWirelessWithCaptiveEnabled =
    getNumberOfUnselectedWirelessWithCaptiveEnabled();

  const isMoreThanOneWirelessWithCaptiveButNotAll =
    numberOfSelectedWirelessWithCaptiveEnabled >= 1 &&
    selectedWireless.length < listWireless.length &&
    selectedWireless.length > 1 &&
    numberOfUnselectedWirelessWithCaptiveEnabled > 0;

  const isAllWirelessWithCaptive =
    numberOfSelectedWirelessWithCaptiveEnabled > 1 &&
    numberOfUnselectedWirelessWithCaptiveEnabled === 0 &&
    selectedWireless.length === numberOfSelectedWirelessWithCaptiveEnabled;

  const isOneWirelessWithCaptiveEnabled =
    numberOfSelectedWirelessWithCaptiveEnabled === 1 &&
    numberOfUnselectedWirelessWithCaptiveEnabled >= 1 &&
    selectedWireless.length === 1;

  const isLastWirelessWithCaptiveEnabledAndOtherWireless =
    numberOfSelectedWirelessWithCaptiveEnabled >= 1 &&
    numberOfUnselectedWirelessWithCaptiveEnabled === 0 &&
    !isAllWirelessWithCaptive &&
    selectedWireless.length > 1;

  const isLastWirelessWithCaptiveEnabled =
    numberOfSelectedWirelessWithCaptiveEnabled === 1 &&
    numberOfUnselectedWirelessWithCaptiveEnabled === 0 &&
    selectedWireless.length === 1;

  const isMoreThanOneWirelessWithoutCaptive =
    numberOfSelectedWirelessWithCaptiveEnabled === 0 &&
    selectedWireless.length > 1;

  const isUniqueWirelessWithoutCaptive =
    selectedWireless.length === 1 &&
    numberOfSelectedWirelessWithCaptiveEnabled === 0;

  const deleteWirelessList = (data: IWirelessRow[]) => {
    return api.wireless.deleteList(data.map((item: IWirelessRow) => item.id));
  };

  const isToDisableCaptivePortal =
    isLastWirelessWithCaptiveEnabled ||
    isAllWirelessWithCaptive ||
    isLastWirelessWithCaptiveEnabledAndOtherWireless;

  const translatedCaptiveMethodName = useGetTranslatedCaptiveMethodType(
    defaultCaptivePortal?.mode as IMethodTypeName
  );

  const checkAndDisplayToastOfDisabledCaptivePortal = () => {
    if (isToDisableCaptivePortal) {
      if (defaultCaptivePortal) {
        addToast(
          'success',
          t('A desativação do Captive - {{captiveMethod}} foi iniciada', {
            captiveMethod: translatedCaptiveMethodName
          })
        );
      }
    }
  };

  const { mutate: deleteWirelessListMutate } = useMutation(deleteWirelessList, {
    onSuccess: () => {
      addToast(
        'success',
        selectedWireless.length === 1
          ? t('Wireless excluída com sucesso')
          : `${selectedWireless.length} ${t('Wireless excluídas com sucesso')}`
      );

      if (checkIfWirelessHasDevices()) {
        triggerNotificationAnimationWithText(generatePluralOrSingular());
      }

      checkAndDisplayToastOfDisabledCaptivePortal();

      setIsDeleting(false);

      onSuccessDeleteWireless();
    }
  });

  const onConfirmDeleteWirelessAction = () => {
    setIsDeleting(true);
    setShowModalDelete(false);
    deleteWirelessListMutate(selectedWireless);
  };

  const onCancelDeleteWirelessAction = () => {
    setShowModalDelete(false);
    setIsDeleting(false);
  };

  const generateModalText = (textElement: React.ReactNode) => {
    return (
      <>
        <div className="mb-2 text-xl-base">{textElement}</div>
        {checkIfWirelessHasDevices() && (
          <Alert type="warning" className="mt-4">
            {t(
              'Todos os equipamentos associados a esta wireless deixarão de transmiti-la'
            )}
            .
          </Alert>
        )}
      </>
    );
  };

  const moreThanOneWirelessWithCaptiveEnabledModal = () => {
    return generateModalText(
      <>
        <span className="text-bold">
          {t(
            'Uma ou mais wireless selecionadas, estão sendo utilizadas no Captive Portal.'
          )}
        </span>{' '}
        {t(
          'Caso você as exclua, elas serão desvinculadas do Captive Portal e os clientes conectados a essas redes perderão o acesso. Excluir mesmo assim?'
        )}
      </>
    );
  };

  const allWirelessWithCaptiveEnabledModal = () => {
    return generateModalText(
      <>
        <span className="text-bold">
          {t(
            'As wireless selecionadas, estão sendo utilizadas no Captive Portal.'
          )}
        </span>{' '}
        {t(
          'Caso você as exclua, o Captive Portal será desativado automaticamente e os clientes conectados perderão o acesso. Excluir mesmo assim?'
        )}
      </>
    );
  };

  const oneWirelessWithCaptiveEnabledModal = () => {
    return generateModalText(
      <span>
        {t('A Wireless')}{' '}
        <span className="text-bold">{selectedWireless[0].name},</span>{' '}
        {t(
          'está sendo utilizada no Captive Portal. Caso você a exclua, ela será desvinculada do Captive Portal e os clientes conectados a essa rede perderão o acesso. Excluir mesmo assim?'
        )}
      </span>
    );
  };

  const lastWirelessWithCaptiveEnabledAndOtherWirelessModal = () => {
    return generateModalText(
      <>
        <span className="text-bold">
          {t(
            'Uma ou mais wireless selecionadas, estão sendo utilizadas no Captive Portal.'
          )}
        </span>{' '}
        {t(
          'Caso você as exclua, o Captive Portal será desativado automaticamente e os clientes conectados perderão o acesso. Excluir mesmo assim?'
        )}
      </>
    );
  };

  const lastWirelessWithCaptiveEnabledModal = () => {
    return generateModalText(
      <span>
        {t('A Wireless')}{' '}
        <span className="text-bold">{selectedWireless[0].name},</span>{' '}
        {t(
          'é a única rede sendo utilizada no Captive Portal. Caso você a exclua, o Captive Portal será desativado automaticamente e os clientes conectados perderão o acesso. Excluir mesmo assim?'
        )}
      </span>
    );
  };

  const moreThanOneWirelessWithoutCaptiveModal = () => {
    return generateModalText(
      <span>{t('Deseja realmente excluir as wireless selecionadas?')}</span>
    );
  };

  const oneWirelessWithoutCaptiveModal = () => {
    return generateModalText(
      <span>
        {t('Deseja realmente excluir a wireless')}{' '}
        <span className="text-bold">{selectedWireless[0].name}</span>?
      </span>
    );
  };

  return (
    <Modal
      width="553px"
      show={showModalDelete}
      actions={[
        { label: t('Cancelar'), action: onCancelDeleteWirelessAction },
        {
          label: t('Excluir'),
          action: onConfirmDeleteWirelessAction,
          type: 'error'
        }
      ]}>
      {isMoreThanOneWirelessWithCaptiveButNotAll &&
        moreThanOneWirelessWithCaptiveEnabledModal()}

      {isAllWirelessWithCaptive && allWirelessWithCaptiveEnabledModal()}

      {isOneWirelessWithCaptiveEnabled && oneWirelessWithCaptiveEnabledModal()}

      {isLastWirelessWithCaptiveEnabled &&
        lastWirelessWithCaptiveEnabledModal()}

      {isLastWirelessWithCaptiveEnabledAndOtherWireless &&
        lastWirelessWithCaptiveEnabledAndOtherWirelessModal()}

      {isMoreThanOneWirelessWithoutCaptive &&
        moreThanOneWirelessWithoutCaptiveModal()}

      {isUniqueWirelessWithoutCaptive && oneWirelessWithoutCaptiveModal()}
    </Modal>
  );
};

export default DeleteWirelessModal;
