import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/useToast';
import Modal from 'ui-components/Modal';
import { ICustomer } from '../CustomersTab/types';

interface IProps {
  show: boolean;
  selectedCustomers: ICustomer[];
  listName: string;
  onClose: () => void;
  setCustomersList: React.Dispatch<React.SetStateAction<ICustomer[]>>;
  onDeleted: () => void;
}

const DeleteCustomersModal = ({
  show,
  selectedCustomers,
  listName,
  onClose,
  setCustomersList,
  onDeleted
}: IProps) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const removeCustomers = (customersToRemove: ICustomer[]) => {
    const toastMessage =
      customersToRemove.length === 1
        ? t('Cliente excluído com sucesso')
        : t('Clientes excluídos com sucesso');
    addToast('success', toastMessage);

    setCustomersList((customersList) =>
      customersList.filter(
        (customer) => !customersToRemove.some((c) => c.mac === customer.mac)
      )
    );

    onDeleted();
    onClose();
  };

  const renderModalMessage = () => {
    if (selectedCustomers.length === 1) {
      return (
        <span>
          {t('O Cliente será removido da lista')}{' '}
          <span className="text-bold">{listName}</span>. {t('Deseja continuar')}
          ?
        </span>
      );
    }

    return (
      <span>
        <span className="text-bold">{selectedCustomers.length} </span>
        {t('Clientes serão removido da lista')}{' '}
        <span className="text-bold">{listName}</span>. {t('Deseja continuar')}?
      </span>
    );
  };

  return (
    <Modal
      show={show}
      actions={[
        {
          label: t('NÃO'),
          action: onClose
        },
        {
          label: t('SIM'),
          action: () => removeCustomers(selectedCustomers)
        }
      ]}>
      {renderModalMessage()}
    </Modal>
  );
};

export default DeleteCustomersModal;
