import React from 'react';
import Modal from 'ui-components/Modal';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmModal.module.css';
import { IConfirmModal } from './types';

const ConfirmModal = ({
  children,
  showModal,
  isLoadingAction,
  width,
  height,
  typeButtonToConfirm,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel
}: IConfirmModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'confirmModal'
  });

  const modalActions = [
    {
      label: cancelLabel || t('Não'),
      action: onCancel,
      disabled: isLoadingAction
    },
    {
      label: confirmLabel || t('Sim'),
      action: onConfirm,
      type: typeButtonToConfirm,
      isLoading: isLoadingAction
    }
  ];
  return (
    <Modal
      actions={modalActions}
      show={showModal}
      height={height || '190px'}
      width={width || '465px'}
      className={styles.container}>
      <div>{children}</div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  width: '465px',
  height: '190px',
  typeButtonToConfirm: undefined,
  confirmLabel: undefined,
  cancelLabel: undefined
};

export { ConfirmModal };
