import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';

interface IProps {
  show: boolean;
  onCancel: () => void;
  onRestore: () => void;
}

const FactoryStandardSection = ({ show, onCancel, onRestore }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.factory-standard'
  });

  return (
    <Modal
      show={show}
      width="550px"
      actions={[
        {
          label: t('CANCELAR'),
          action: () => {
            onCancel();
          }
        },
        {
          label: t('RESTAURAR'),
          action: () => {
            onRestore();
          }
        }
      ]}>
      {t(
        'Deseja realmente restaurar as configurações de fábrica das opções avançadas de Wireless?'
      )}
    </Modal>
  );
};

export { FactoryStandardSection };
