import moment from 'moment-timezone';

class DateConverter {
  private momentDate: moment.Moment;

  private timeZone?: string;

  constructor(date?: string | Date | null, timeZone?: string) {
    if (!timeZone) {
      this.momentDate = moment(date).tz('America/Sao_Paulo');
    } else {
      this.momentDate = moment(date).tz(timeZone);
    }

    this.timeZone = timeZone;
  }

  public getDateWithFormat(format?: string) {
    return this.momentDate.format(format || 'DD/MM/YYYY HH:mm:ss');
  }

  public getDateWithDefaultFormat() {
    return this.momentDate.format();
  }

  public setNewMomentDate(date?: string | Date, timeZone?: string) {
    if (!timeZone) {
      this.momentDate = moment(date).tz('America/Sao_Paulo');
    } else {
      this.momentDate = moment(date).tz(timeZone);
    }
  }

  get getMomentDateWithCurrentHour() {
    if (!this.timeZone) {
      return this.momentDate
        .startOf('day')
        .add(moment().tz('America/Sao_Paulo').format('HH:mm'), 'hours');
    }
    return this.momentDate
      .startOf('day')
      .add(moment().tz(this.timeZone).format('HH:mm'), 'hours');
  }

  get getMomentDate() {
    return this.momentDate;
  }

  set setMomentDate(momentDate: moment.Moment) {
    this.momentDate = momentDate;
  }

  get getTimeZone() {
    return this.timeZone;
  }

  set setTimeZone(timeZone: string) {
    this.timeZone = timeZone;
  }
}

export { DateConverter };
