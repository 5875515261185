import classNames from 'classnames';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import styles from './ButtonComeBack.module.css';

interface IButtonComeBack
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  onClick: () => void;
  className?: string;
}

const ButtonComeBack = ({
  onClick,
  className,
  children,
  ...props
}: IButtonComeBack) => {
  return (
    <button
      type="button"
      id="btn-back-to-sites"
      className={classNames([
        styles.backToSitesButton,
        'mr-3',
        children && 'd-flex',
        className
      ])}
      onClick={onClick}
      {...props}>
      <div className={styles.iconBack}>
        <MdKeyboardBackspace size={16} />
      </div>
      <span>{children}</span>
    </button>
  );
};

ButtonComeBack.defaultProps = {
  children: undefined,
  className: ''
};

export { ButtonComeBack };
