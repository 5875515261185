import { Option as MuiOption } from '@mui/base';
import type { OptionProps } from '@mui/base';
import classNames from 'classnames';
import React from 'react';
import './GlobalOption.css';
import styles from './Option.module.css';

const Option = React.forwardRef(function Option<TValue>(
  props: OptionProps<TValue>,
  ref: React.ForwardedRef<HTMLLIElement> | null
) {
  const { children, slotProps, value, ...rest } = props;

  return (
    <MuiOption
      ref={ref}
      slotProps={{
        ...slotProps,
        root: {
          className: classNames([styles.selectOption, 'common-body-1']),
          ...slotProps?.root
        }
      }}
      value={value}
      {...rest}>
      {children}
    </MuiOption>
  );
});

export default Option;
