import React from 'react';
import getClass from '../classNames';
import type { IGrid } from './types';

const Grid = ({ fluid, className, children, style }: IGrid) => {
  const containerClass = getClass(fluid ? 'container-fluid' : 'container');
  const finalClassName = [className, containerClass].filter(Boolean).join(' ');

  return (
    <div className={finalClassName} style={style}>
      {children}
    </div>
  );
};

Grid.defaultProps = {
  fluid: false,
  className: '',
  style: {}
};

export default Grid;
