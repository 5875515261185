import _ from 'lodash';
import { IResumedWireless } from 'src/pages/CaptivePortal/AssociatedWireless/types';

export const getListOfIdsAssociatedWithWireless = (
  wirelessToGetIds: IResumedWireless[]
) => {
  const devicesIdsAssociatedToWireless = [] as string[];

  wirelessToGetIds.map((wireless) => {
    devicesIdsAssociatedToWireless.push(...wireless.devices_ids);
    return wireless;
  });

  return _.uniq(devicesIdsAssociatedToWireless);
};
