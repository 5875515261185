import _ from 'lodash';
import { IOption } from 'src/components/InputTime/types';
import i18n from 'src/i18n';
import { DateCalculator } from '../Dates/DateCalculator';
import { DateConverter } from '../Dates/DateConverter';

const t = (key: string) => i18n.t(`captivePortal.${key}`);

const getExpiresAtDateInDays = (
  hasDaysOrHoursSelected: boolean,
  endDateSelected?: Date,
  timeZone?: string
) => {
  const endDateConverter = new DateConverter(
    endDateSelected?.toISOString(),
    timeZone
  );

  if (!hasDaysOrHoursSelected) {
    return endDateConverter.getMomentDateWithCurrentHour.format();
  }

  return endDateConverter.getDateWithDefaultFormat();
};

const getExpiresAtDateInTime = (
  endTimeSelected?: IOption,
  timeZone?: string,
  startTimeSelected?: string
) => {
  const getPeriodInTime = endTimeSelected?.value || undefined;

  const getHours = new Date(getPeriodInTime).toTimeString().split(' ')[0];

  const expiresAtMoment = new DateConverter(
    startTimeSelected || undefined,
    timeZone
  ).getMomentDate;

  return expiresAtMoment.add(getHours).format();
};

export const getExpiresAtDaysOrTime = (
  periodType: string,
  periodInDays: [Date | null, Date | null],
  periodInTime?: IOption,
  hasDaysOrHoursInExpiresAt?: boolean,
  timezone?: string,
  startedAt?: string
) => {
  const checkIfIsPeriodTypeDateAndHasValue =
    periodType === 'date' && periodInDays[1];

  const checkIfIsPeriodTypeTimeAndHasValue =
    periodType === 'time' && periodInTime;

  if (checkIfIsPeriodTypeDateAndHasValue) {
    return getExpiresAtDateInDays(
      hasDaysOrHoursInExpiresAt || false,
      periodInDays[1] || undefined,
      timezone
    );
  }

  if (checkIfIsPeriodTypeTimeAndHasValue) {
    return getExpiresAtDateInTime(periodInTime, timezone, startedAt);
  }
  return '';
};

export const convertMomentToTimezoneWithCaptiveFormat = (
  date?: string,
  siteTimezone?: string,
  format?: string
) => {
  const dateConverter = new DateConverter(date, siteTimezone);

  return dateConverter.getDateWithFormat(
    format || `DD/MM/YYYY [${t('às')}] HH:mm`
  );
};

export const getDateOrNullFormat = (
  time?: string | null,
  siteTimezone?: string
) => {
  const timeIsInvalid = _.isNil(time);

  if (timeIsInvalid) {
    return '--';
  }
  return convertMomentToTimezoneWithCaptiveFormat(time, siteTimezone);
};

export const generateExpirationTime = (
  startedAt: string | null,
  expiresAt: string | null,
  siteTimezone?: string
) => {
  const expirationIsInvalid = _.isNil(startedAt) || _.isNil(expiresAt);

  if (expirationIsInvalid) {
    return '--';
  }

  return new DateCalculator(
    startedAt,
    expiresAt || undefined,
    siteTimezone
  ).getDiffBetweenStartAndEndDate();
};

export const formatExpirationTimeWithEndDate = (
  startDate?: Date,
  endDate?: Date,
  message?: string
) => {
  if (!startDate || !endDate) {
    return message || '--';
  }

  return new DateCalculator(
    startDate,
    endDate
  ).getDurationWithEndDateOnFormat();
};
