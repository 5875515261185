import classNames from 'classnames';
import React from 'react';
import styles from './Menu.module.css';

function MenuItem({ disabled = false, children, ...props }) {
  return (
    <div
      className={classNames([
        styles.menuItem,
        disabled ? styles.disabled : null
      ])}
      {...props}>
      {children}
    </div>
  );
}

export default MenuItem;
