import { RoleMember } from 'src/components/ModalCreateMembers/types';

// eslint-disable-next-line no-shadow
export enum ActiveTabSitesEnum {
  MY_SITES,
  SHARED_WITH_ME
}

export interface ISiteInfos {
  name: string;
  timezone: string;
}

export interface ISiteData extends ISiteInfos {
  id: string;
}

export type ModalsTypesOfSites =
  | 'members'
  | 'delete'
  | 'create'
  | 'edit'
  | 'exitSharedSit'
  | null;

export interface ISiteItemPage {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  timezone: string;
  user_count: number;
  place_count: number;
  has_acl: boolean;

  member: {
    id?: string;
    is_new_site_member?: boolean;
    role: RoleMember;
  };
}

export interface IDeleteSiteData {
  checkContinueDelete: boolean;
}

export interface IResponsePostSiteData {
  site: ISiteData;
}
