import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';

interface IBlockActiveCaptiveModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BlockActiveCaptiveModal = ({
  showModal,
  setShowModal
}: IBlockActiveCaptiveModal) => {
  const MIN_FIRMWARE_CAPTIVE_V3 =
    process.env.REACT_APP_MIN_SUPPORTED_CAPTIVE_V3_FIRMWARE_VERSION;
  const MIN_FIRMWARE_CAPTIVE_V4 =
    process.env.REACT_APP_MIN_SUPPORTED_CAPTIVE_V4_FIRMWARE_VERSION;

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.blockActiveCaptiveModal'
  });

  const onCloseModal = () => {
    setShowModal(false);
  };

  const actionsModal = [
    {
      label: t('Ciente'),
      action: onCloseModal
    }
  ];
  return (
    <Modal
      actions={actionsModal}
      show={showModal}
      onClose={onCloseModal}
      width="450px"
      height="auto"
      key="block-active-captive">
      <p>
        {t(
          'Para prosseguir com a ação é necessário que todos os equipamentos adicionados neste ambiente estejam com a versão de firmware atualizada para'
        )}
        :
      </p>
      <p className="mt-3">
        {t('Linha AC versão')} {MIN_FIRMWARE_CAPTIVE_V3} {t('ou superior')};
      </p>
      <p>
        {t('Linha AX versão')} {MIN_FIRMWARE_CAPTIVE_V4} {t('ou superior')}.
      </p>
    </Modal>
  );
};

export default BlockActiveCaptiveModal;
