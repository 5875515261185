import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { tabId } from 'src';
import { tokenEvents } from 'src/contexts/AuthContext/AuthEventEmitter';
import api from '.';
import { ILoginResponse } from './urls/auth/types';

export const baseURL = `${process.env.REACT_APP_API_HOST}/api/v1`;
export const baseURLDevice = `${process.env.REACT_APP_API_DEVICE_HOST}/api/v1`;
export const baseURLStatistics = `${process.env.REACT_APP_API_STATISTICS_HOST}/api/v1`;
export const baseURLHefesto = `${process.env.REACT_APP_API_HEFESTO_HOST}/api/v1`;

const axiosInstance = axios.create({
  baseURL
});

let isExiting = false;

const logout = (type: string) => {
  if (isExiting) return;
  const location = window.location.href;
  const refreshToken = JSON.parse(
    localStorage.getItem('@manager/refresh_token') || '{}'
  );
  if (!refreshToken?.id_token || location.includes('logout')) {
    // evita que o logout seja feito mais de uma vez
    return;
  }

  // Url de logout do conta unica
  const urlAuth = `${process.env.REACT_APP_CONTA_INTELBRAS_URL}/auth/logout?id_token_hint=${refreshToken?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_CONTA_INTELBRAS_REDIRECT_URI_LOGOUT}/${type}&state=exited`;

  // evita que o logout seja feito mais de uma vez por tabs diferentes
  if (type !== 'logout' && !type.includes('unauthorized_401')) {
    if (type.split('@')[1] !== tabId) return;
  }

  // Redireciona a tela para o conta unica, para que ele faça o logout e retorne para o redirect_uri_logout
  window.location.href = new URL(urlAuth).href;
  isExiting = true;
};

let generatingNewAccessToken = false;

const getNewRefreshToken = async () => {
  try {
    const refreshTokenCurrent = JSON.parse(
      localStorage.getItem('@manager/refresh_token') || '{}'
    );
    if (refreshTokenCurrent) {
      const response = await api.auth.refresh({
        refresh_token: refreshTokenCurrent?.refresh_token
      });
      const { access_token, refresh_token, access_token_info } =
        response.data as ILoginResponse;
      localStorage.setItem(
        '@manager/access_token',
        JSON.stringify(access_token)
      );
      localStorage.setItem(
        '@manager/refresh_token',
        JSON.stringify(refresh_token)
      );
      localStorage.setItem(
        '@manager/access_token_info',
        JSON.stringify(access_token_info)
      );
      tokenEvents.emit(
        'tokensUpdated',
        access_token,
        refresh_token,
        access_token_info
      );
      generatingNewAccessToken = false;
      return access_token;
    }
  } catch (error) {
    window.console.error(error);
  }
  return undefined;
};

const shouldGenNewTokenOrKickUser = async (
  request: AxiosRequestConfig<any>,
  controller: AbortController
) => {
  const accessToken = JSON.parse(
    localStorage.getItem('@manager/access_token') || '{}'
  );
  if (
    accessToken &&
    !request?.url?.includes('refresh') &&
    !request?.url?.includes('auths')
  ) {
    const createdAt = new Date(accessToken.created_at);
    const expiresAt = new Date(
      createdAt.getTime() + accessToken.expires_in * 1000
    );
    const now = new Date();
    const FIFTY_MINUTES_IN_MILLISECONDS = 3000 * 1000;
    const hasPassed50Minutes =
      now.getTime() - createdAt.getTime() >= FIFTY_MINUTES_IN_MILLISECONDS;
    const isBefore60Minutes = now.getTime() < expiresAt.getTime();
    const hasPassed60Minutes = now.getTime() >= expiresAt.getTime();

    if (hasPassed50Minutes && isBefore60Minutes && !generatingNewAccessToken) {
      generatingNewAccessToken = true;
      const newAccessToken = await getNewRefreshToken();
      return newAccessToken;
    }
    if (hasPassed60Minutes) {
      // se tiver passado 60 minutos, o token está expirado e o usuário deve ser deslogado
      // cancelando o request atual e gerando uma mensagem de erro com 'canceled'
      controller.abort();
    }
  }
  return undefined;
};

// Escuta os requests e verifica se o token está expirado ou invalido
axiosInstance.interceptors.request.use(async (request) => {
  const controller = new AbortController();

  // Um novo accessToken só é gerado se necessário, caso contrário retorna undefined
  const newAccessToken = await shouldGenNewTokenOrKickUser(request, controller);

  if (request.headers && newAccessToken) {
    request.headers.Authorization = `Bearer ${newAccessToken.access_token}`;
  }
  return { ...request, signal: controller.signal };
});

const doLogout = (error: AxiosError) => {
  logout(`unauthorized_401@${tabId}`);
  return Promise.reject(error);
};

const isInErrorPage = (location: string) => {
  return location.includes('error');
};

const errorRoute = '/error/service_unavailable';

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.message === 'canceled') {
      return doLogout(error);
    }

    if (error.response === undefined) {
      window.location.href = errorRoute;
      return Promise.reject(error);
    }

    const location = window.location.href;
    const isServerError = error.response?.status >= 500;
    const isGETMethod = error.response.config.method === 'get';
    const isPUTMethod = error.response.config.method === 'put';

    const isErrorOnServer =
      isServerError && !isInErrorPage(location) && isGETMethod;

    const userNotLoggedIn = error.response.status === 401 && !isExiting;

    const isChoosePlaceRequest =
      error.response.config.url === '/access/auths/' && isPUTMethod;

    if (isErrorOnServer) {
      window.location.href = errorRoute;
    } else if (userNotLoggedIn) {
      if (isChoosePlaceRequest) {
        return doLogout(error);
      }

      if (error.response.config.url === '/access/auths/') {
        return Promise.reject(error);
      }

      // eslint-disable-next-line no-console
      console.log(
        `Usuário deslogado pois ocorreu um erro 401 (Unauthorized) na requisição de dados para a rota: ${error.response.config.url}`
      );

      logout(`unauthorized_401@${tabId}`);
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
