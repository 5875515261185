import { t } from 'i18next';
import React from 'react';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface ModalEditProps {
  showModalEdit: boolean;
  setShowModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  isEditing: boolean;
}

const ModalConfirmEditWireless = ({
  setShowModalEdit,
  showModalEdit,
  onSubmit,
  isEditing
}: ModalEditProps) => {
  return (
    <ConfirmModal
      width="500px"
      showModal={showModalEdit}
      isLoadingAction={isEditing}
      onCancel={() => setShowModalEdit(false)}
      onConfirm={onSubmit}>
      {t(
        'Todos os equipamentos associados a essa Rede Wireless receberão as novas configurações. Deseja continuar?'
      )}
    </ConfirmModal>
  );
};

export default ModalConfirmEditWireless;
