import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Input.module.css';

const InputWithoutRef = (
  {
    type = 'text',
    id = '',
    placeholder = '',
    disabled = false,
    readOnly = false,
    iconInsideLeft = false,
    iconInsideRight = false,
    className = '',
    ...props
  },
  ref
) => {
  return (
    <input
      className={classNames([
        styles.input,
        iconInsideLeft && styles.iconInsideLeft,
        iconInsideRight && styles.iconInsideRight,
        className
      ])}
      type={type}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      ref={ref}
      {...props}></input>
  );
};

const TextArea = ({ id, placeholder, disabled, readOnly, rows, children }) => {
  return (
    <>
      <textarea
        className={classNames([styles.input])}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        rows={rows}>
        {children}
      </textarea>
    </>
  );
};

const InputFile = ({ id }) => {
  return (
    <>
      <div className={classNames([styles.input, styles.inputfile])}>
        <span>backup-2021-11-19.bin</span>
        <Input id={id} type="file" />
      </div>
    </>
  );
};

const Input = forwardRef(InputWithoutRef);
export { Input, TextArea, InputFile };
