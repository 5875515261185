import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdError } from 'react-icons/md';
import Modal from 'ui-components/Modal';

interface IProps {
  show: boolean;
  onClose: () => void;
  onSave: () => void;
}

const DefaultPortWarningModal = ({ show, onClose, onSave }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const actions = [
    {
      label: t('NÃO'),
      action: onClose
    },
    {
      label: t('SIM'),
      action: onSave
    }
  ];

  return (
    <Modal show={show} onClose={onClose} width="735px" actions={actions}>
      <div className="d-flex flex-column align-center">
        <MdError
          size={96}
          color="var(--color-status-alert-base)"
          className="mb-6"
        />

        <div className="d-flex flex-column align-center">
          <span className="title-xl-sm">
            {t('A porta inserida não é a padrão.')}
          </span>
          <span className="text-center">
            {t(
              'Verifique sua escolha antes de continuar com a configuração do SSH. Caso a porta escolhida estiver em uso, a configuração será interrompida impedindo novas aplicações, levando a ter que excluir o equipamento do sistema. Deseja continuar?'
            )}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default DefaultPortWarningModal;
