import { isNull } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { eventGA } from 'src/utils/analytics';
import Modal from 'ui-components/Modal';
import { IDeviceRow } from '../types';

interface IProps {
  selectedDevices: IDeviceRow[];
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  refetchPagination: () => void;
  setDeviceIsDeleting?: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const DeleteDeviceModal = ({
  selectedDevices,
  show,
  onClose,
  onCancel,
  refetchPagination,
  setDeviceIsDeleting
}: IProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'devices' });
  const { refetchConfigurationsStatus } = useNotification();
  const { addToast } = useToast();

  const deleteDeviceMutation = useMutation(
    (data: IDeviceRow[]) => {
      return api.devices.deleteList(data.map((item: IDeviceRow) => item.id));
    },
    {
      onSuccess: () => {
        addToast(
          'success',
          selectedDevices.length === 1
            ? t('Equipamento excluído com sucesso')
            : t('Equipamentos excluídos com sucesso')
        );
        eventGA('Equipamentos', 'Click', 'Excluir equipamento');
        refetchPagination();
        refetchConfigurationsStatus();
        onClose();
      }
    }
  );

  const deleteDevices = () => {
    if (!isNull(setDeviceIsDeleting) && setDeviceIsDeleting) {
      setDeviceIsDeleting(true);
    }
    deleteDeviceMutation.mutate(selectedDevices);
  };

  const modalDeleteActions = [
    {
      label: 'Cancelar',
      action: () => onCancel(),
      disabled: deleteDeviceMutation.isLoading
    },
    {
      label: 'Excluir',
      action: deleteDevices,
      isLoading: deleteDeviceMutation.isLoading
    }
  ];

  const messagesToDisplayOnModalSingular = {
    AP: t(
      'O equipamento será removido da sua conta e voltará ao padrão de fábrica. Deseja realmente excluir?'
    ),
    CFTV: t(
      'O equipamento será removido da sua conta. Deseja realmente excluir?'
    )
  };

  const messagesToDisplayOnModalPlural = {
    AP: t(
      'Os equipamentos serão removidos da sua conta e voltarão ao padrão de fábrica. Deseja realmente excluir?'
    ),
    CFTV: t(
      'Os equipamentos serão removidos da sua conta. Deseja realmente excluir?'
    )
  };

  return (
    <Modal width="500px" show={show} actions={modalDeleteActions}>
      {selectedDevices.length > 1
        ? messagesToDisplayOnModalPlural[selectedDevices[0]?.type_equipment]
        : messagesToDisplayOnModalSingular[selectedDevices[0]?.type_equipment]}
    </Modal>
  );
};

DeleteDeviceModal.defaultProps = {
  setDeviceIsDeleting: null
};

export default DeleteDeviceModal;
