/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Grid, Row } from 'inmaster-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Card from 'ui-components/Card';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import api from 'src/services/api';
import { IUpdateCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import { useToast } from 'src/hooks/useToast';
import { useAuth } from 'src/hooks/useAuth';
import { removePrefixFromBase64 } from 'src/utils/canvasUtils';
import FormCustomizeCaptive from 'src/components/FormCustomizeCaptive';
import { isUrl } from 'src/utils/regexUtils';
import styles from './PersonalizeCaptivePortal.module.css';
import { IPersonalizeForm, firstCaptivePortal } from './types';
import { RestoreFactoryDefaultModal } from './RestoreFactoryDefaultModal';
import { ICaptivePortal } from '../types';

interface IPersonalizeCaptivePortal {
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  refetchCaptivePortal: () => void;
  setIsLoadingFormCaptive: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const PersonalizeCaptivePortal = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  isLoadingFormCaptive,
  refetchCaptivePortal,
  setIsLoadingFormCaptive,
  setActiveTab
}: IPersonalizeCaptivePortal) => {
  const { addToast } = useToast();

  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.personalize'
  });

  const { accessToken } = useAuth();

  const [typeChange, setTypeChange] = useState<'save' | 'restore'>('restore');

  const [restoreFactoryDefaultModal, setRestoreFactoryDefaultModal] =
    useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm<IPersonalizeForm>({
    defaultValues: {
      captivePortal: {
        pageTitle: '',
        pageText: '',
        urlRedirectionAfterLogin: '',
        header_image_url: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const updateCaptivePortal = (id: string, data: ICaptivePortal) => {
    const image = data.header_image_url || '';
    const imageHasChanged = !isUrl(image);
    const imageToBackendWithoutPrefix = imageHasChanged
      ? removePrefixFromBase64(image)
      : null;
    const formatedData = {
      ...data,
      header_image_url: null,
      header_image: imageToBackendWithoutPrefix
    };
    const isToRestoreImage = typeChange === 'restore';

    return api.captivePortal.captive.put(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      { captive_portal: formatedData } as IUpdateCaptivePortal,
      id,
      imageHasChanged,
      isToRestoreImage
    );
  };

  const updateCaptivePortalMutation = useMutation(
    'updateCaptivePortal',
    (data: Omit<ICaptivePortal, 'is_blocked_to_activate'>) => {
      return updateCaptivePortal(defaultCaptivePortal?.id || '', data);
    },
    {
      onSuccess: () => {
        setIsLoadingFormCaptive(false);
        if (typeChange === 'save') {
          addToast('success', `${t('Configurações salvas com sucesso')}`);
        } else {
          addToast('success', `${t('Configurações restauradas com sucesso')}`);
          clearErrors();
        }
        refetchCaptivePortal();
      },
      onError: () => {
        addToast(
          'error',
          'Desculpe não foi possível salvar as configurações, tente novamente mais tarde'
        );
        setIsLoadingFormCaptive(false);
      }
    }
  );

  const onConfirmRestore = () => {
    setIsLoadingFormCaptive(true);
    setRestoreFactoryDefaultModal(false);
    updateCaptivePortalMutation.mutate({
      ...firstCaptivePortal,
      mode: defaultCaptivePortal ? defaultCaptivePortal.mode : undefined
    } as ICaptivePortal);
  };

  const onSubmit = handleSubmit((data: IPersonalizeForm) => {
    setTypeChange('save');
    setIsLoadingFormCaptive(true);
    updateCaptivePortalMutation.mutate({
      name: data.captivePortal.pageTitle,
      preview_text: data.captivePortal.pageText,
      redirect_url: data.captivePortal.urlRedirectionAfterLogin,
      mode: defaultCaptivePortal ? defaultCaptivePortal.mode : undefined,
      header_image_url: data.captivePortal.header_image_url || null
    } as ICaptivePortal);
  });

  const renderSkeleton = () => {
    return (
      <Row className="mt-3">
        <Col xs={12}>
          <div className="fit-width">
            <div className={classNames('mb-3')}>
              <Skeleton width={145} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
              <div className="mt-1">
                <Skeleton width="100%" height={40} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={104} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={37} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <div className={classNames('mb-3')}>
              <Skeleton width={200} height={20} />
              <div className="mt-1">
                <Skeleton width="100%" height={50} />
              </div>
            </div>
            <Skeleton width={250} height={24} />
            <div
              className={classNames(
                'd-flex',
                'justify-end',
                'fit-width',
                'mt-8'
              )}>
              <div className={classNames('ml-3')}>
                <Skeleton width={130} height={45} />
              </div>
              <div className={classNames('ml-3')}>
                <Skeleton width={130} height={45} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Grid fluid>
      <RestoreFactoryDefaultModal
        showModalRestore={restoreFactoryDefaultModal}
        isLoadingRestore={isLoadingFormCaptive}
        onCancelRestore={() => setRestoreFactoryDefaultModal(false)}
        onConfirmRestore={onConfirmRestore}
      />
      <Row>
        <Col xs={12} className="mb-7 d-flex flex-column">
          <div>
            <h2
              className={classNames([
                'title-xl-base text-uppercase',
                styles.titlePage
              ])}
              style={{ color: 'var(--color-neutral-dark-3)' }}>
              {t('Personalizar')}
            </h2>
          </div>
          <div className="mt-3">
            <span className={classNames(['text-base-lg', styles.subtitlePage])}>
              {t(
                'Personalize a página de acesso que irá aparecer para os usuários, sempre que eles se conectarem à sua rede. Caso não pretenda personalizar, a página irá aparecer com nossa configuração padrão'
              )}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Card className={classNames('px-10', 'py-7', 'fit-width')}>
          {isFetchedCaptivePortal ? (
            <FormCustomizeCaptive
              defaultCaptivePortal={defaultCaptivePortal}
              isFetchedCaptivePortal={isFetchedCaptivePortal}
              isLoadingFormCaptive={isLoadingFormCaptive}
              setActiveTab={setActiveTab}
              watch={watch}
              setValue={setValue}
              control={control}
              errors={errors}
              onSubmit={onSubmit}
              setRestoreFactoryDefaultModal={setRestoreFactoryDefaultModal}
              typeChange={typeChange}
              setTypeChange={setTypeChange}
            />
          ) : (
            renderSkeleton()
          )}
        </Card>
      </Row>
    </Grid>
  );
};

export { PersonalizeCaptivePortal };
