import React from 'react';
import { PasswordVisibilityIcon } from 'src/components/PasswordVisibilityIcon';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';

interface IProps {
  className?: string;
  label: string;
  placeholder: string;
  id: string;
  type?: string;
  hasShowPassword?: boolean;
}

const InputWithLabelForCaptiveForm = ({
  label,
  placeholder,
  id,
  type,
  className,
  hasShowPassword
}: IProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const iconRight = hasShowPassword ? (
    <PasswordVisibilityIcon
      setShowPassword={setShowPassword}
      showPassword={showPassword}
    />
  ) : undefined;

  return (
    <div className={className}>
      <Label>{label}</Label>

      <InputIconInside iconRight={iconRight}>
        <Input
          placeholder={placeholder}
          id={id}
          type={showPassword ? 'text' : type}
          iconInsideRight={hasShowPassword}
          autoComplete="off"
        />
      </InputIconInside>
    </div>
  );
};

InputWithLabelForCaptiveForm.defaultProps = {
  className: '',
  type: 'text',
  hasShowPassword: false
};

export { InputWithLabelForCaptiveForm };
