import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from './ItemAndDescription.module.css';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string | JSX.Element;
  small?: boolean;
  isLoading?: boolean;
}

const ItemAndDescription = ({
  title,
  description,
  small,
  className,
  isLoading
}: IProps) => {
  return (
    <div className={`${className} d-flex flex-column`}>
      <span
        id={`title-${title.replaceAll(' ', '-').toLocaleLowerCase()}`}
        className={small ? 'title-sm-sm mb-1' : 'title-sm-base mb-2'}>
        {title}
      </span>
      {!isLoading ? (
        <span
          id={`description-${title.replaceAll(' ', '-').toLocaleLowerCase()}`}
          className={classNames([
            styles.description,
            small ? 'text-sm-xs' : 'text-xl-lg'
          ])}>
          {description}
        </span>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

ItemAndDescription.defaultProps = {
  small: false,
  isLoading: false
};

export default ItemAndDescription;
