import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import { generateColumns } from 'src/utils/tableUtils';
import Table from 'ui-components/Table';
import Tag from 'ui-components/Tags';
import { ICaptivePortal } from '../../types';
import {
  IFrequencyFormat,
  IResumedWireless,
  IResumedWirelessRow,
  ISecurityFormat
} from '../types';

interface ICaptiveTableAssociatedWirelessProps {
  isFetchedWirelessAndCaptive: boolean;
  wirelessList: IResumedWireless[];
  setSelectedWireless: React.Dispatch<React.SetStateAction<IResumedWireless[]>>;
  selectedWireless: IResumedWireless[];
  defaultCaptivePortal: ICaptivePortal | null;
  setAssociatedWireless: React.Dispatch<
    React.SetStateAction<IResumedWireless[]>
  >;
  setWirelessList: React.Dispatch<React.SetStateAction<IResumedWireless[]>>;
  setIsFetchedWirelessList: (isFetched: boolean) => void;
  isWizardFlow?: boolean;
}

const CaptiveTableAssociatedWireless = ({
  isFetchedWirelessAndCaptive,
  wirelessList,
  setSelectedWireless,
  selectedWireless,
  defaultCaptivePortal,
  setAssociatedWireless,
  setWirelessList,
  setIsFetchedWirelessList,
  isWizardFlow
}: ICaptiveTableAssociatedWirelessProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.associatedWireless'
  });

  const frequencyFormat: IFrequencyFormat = {
    2.4: '2.4 GHz',
    5: '5 GHz',
    dualband: '2.4GHz | 5GHz'
  };

  const renderLabelFrequency = (frequency: string) => {
    if (frequency === 'dualband' || frequency === '2.4GHz | 5GHz') {
      return <span>2.4GHz | 5GHz</span>;
    }
    return <span>{frequency}GHz</span>;
  };

  const securityFormat: ISecurityFormat = {
    Open: t('Aberta'),
    'WPA-PSK': 'WPA-PSK',
    'WPA2-PSK': 'WPA2-PSK'
  };

  const isLastWirelessSelected = (id: string) => {
    if (selectedWireless.length !== 1) return false;
    return selectedWireless.some((wireless) => wireless.id === id);
  };

  const createRowToDisplay = (
    wirelessListToDisplay: IResumedWireless[]
  ): IResumedWirelessRow[] => {
    return wirelessListToDisplay.map((wireless) => {
      return {
        ...wireless,
        isLastWirelessSelected: isLastWirelessSelected(wireless.id),
        frequency: renderLabelFrequency(wireless.frequency),
        security: wireless.is_captive_portal_enabled
          ? t('Captive Portal')
          : securityFormat[wireless.security as keyof ISecurityFormat],
        isVisible: (
          <div>
            {wireless.enabled ? (
              <div>
                {wireless.hidden ? (
                  <Tag color="neutral-dark-4" outlined wide>
                    {t('Oculta')}
                  </Tag>
                ) : (
                  <Tag color="blue" outlined wide>
                    {t('Visível')}
                  </Tag>
                )}
              </div>
            ) : (
              <Tag color="lightblue" outlined wide>
                {t('Desabilitada')}
              </Tag>
            )}
          </div>
        )
      };
    });
  };

  const renderSkeletons = (numberOfSkeletons = 8) => {
    const skeletons = [
      <Skeleton
        key="-1-skeleton-table-wireless-associated"
        height={50}
        className="mb-1"
      />
    ];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 mx-2"
          key={`${i}-skeleton-table-wireless-associated`}>
          <Skeleton count={1} height={30} width={30} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
          <Skeleton count={1} height={30} width={120} />
        </div>
      );
    }
    return skeletons;
  };

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('security', t('Segurança'), 'left', 0),
    generateColumns('frequency', t('Frequência'), 'left', 0),
    generateColumns('isVisible', t('Visibilidade'), 'left', 0)
  ];

  const getWireless = async () => {
    return api.wireless.get();
  };

  const { isFetchedAfterMount: isFetchedWirelessList } = useQuery(
    ['wirelessToAssociateQuery', defaultCaptivePortal],
    getWireless,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }: { data: { wireless: IResumedWireless[] } }) => {
        const wirelessListFormatted = data.wireless.map((wireless) => {
          wireless.frequency =
            frequencyFormat[wireless.frequency as keyof IFrequencyFormat];

          return wireless;
        });

        setWirelessList(wirelessListFormatted);

        if (!isWizardFlow) {
          setAssociatedWireless(
            wirelessListFormatted.filter((w) => w.is_captive_portal_enabled)
          );

          setSelectedWireless(
            wirelessListFormatted.filter((w) => w.is_captive_portal_enabled)
          );
        }
      }
    }
  );

  const isLastWireless = wirelessList.length === 1;

  useEffect(() => {
    setIsFetchedWirelessList(isFetchedWirelessList);
  }, [isFetchedWirelessList, setIsFetchedWirelessList]);

  return (
    <div>
      {isFetchedWirelessAndCaptive ? (
        <Table
          columns={columns}
          rows={createRowToDisplay(wirelessList)}
          onSelectRows={(selectedRows: IResumedWireless[]) => {
            setSelectedWireless(selectedRows);
          }}
          disableSelection={isLastWireless}
          firstItemIsAwaysSelected
          selectedRows={selectedWireless}
          elementToDisableSelection="isLastWirelessSelected"
          textTooltipOfCheckbox={t(
            'Essa rede não pode ser desvinculada, pois é necessário ter ao menos uma rede vinculada ao Captive.'
          )}
        />
      ) : (
        renderSkeletons()
      )}
    </div>
  );
};

CaptiveTableAssociatedWireless.defaultProps = {
  isWizardFlow: false
};

export default CaptiveTableAssociatedWireless;
