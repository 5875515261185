import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'ui-components/Input';

const VoucherForm = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  return <Input placeholder={t('Voucher')} id="input-voucher" />;
};

export { VoucherForm };
