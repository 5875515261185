import classNames from 'classnames';
import React from 'react';
import { useTemplate } from 'src/hooks/useTemplate';
import girlOnBenchImg from 'src/assets/girl-on-bench.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'ui-components/Card';
import styles from './GenericError.module.css';

const GenericError = () => {
  useTemplate('avatarNavbar');

  const { t } = useTranslation('translations', {
    keyPrefix: 'genericError'
  });

  const navigate = useNavigate();
  const { type } = useParams();

  const errors = {
    not_found: {
      title: '4 0 4',
      subtitle: t('A página que você está procurando não foi encontrada.')
    },
    service_unavailable: {
      title: t('Hmm... nada por aqui'),
      subtitle: t(
        'No momento estamos passando por instabilidade. Por favor, tente novamente mais tarde.'
      )
    }
  };

  const activeError = errors[type as keyof typeof errors] || errors.not_found;

  return (
    <div
      className={classNames([
        styles.container,
        'd-flex justify-center align-center flex-column'
      ])}>
      <Card className={classNames([styles.containerCard, 'fit-width d-flex'])}>
        <div className="fit-width d-flex justify-center align-center flex-column">
          <div className={styles.contentCard}>
            <img src={girlOnBenchImg} alt={t('Garota sentada em um banco')} />
            <h1 className={classNames([styles.errorCode, 'title-xl-xl mt-3'])}>
              {activeError.title}
            </h1>
            <span className="my-3 text-xl-lg">{activeError.subtitle}</span>
            <Button
              outline
              className="mt-10"
              onClick={() => navigate('/sites')}>
              {t('VOLTAR AO INÍCIO')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export { GenericError };
