import { RoleMember } from 'src/components/ModalCreateMembers/types';
import { IPagination } from 'src/pages/Devices/types';

export interface ISite {
  name: string;
  timezone?: string;
}

export interface IWireless {
  name: string;
  password: string;
}

export interface IDataSite {
  site: ISite;
  place?: {
    name: string;
  };
}

export interface IWirelessSite {
  id: string;
  name: string;
  security: string;
  frequency: string;
  encryption: string;
  enabled: boolean;
  hidden: boolean;
  count_devices?: number;
  is_captive_portal_enabled: boolean;
  devices_ids: string[];
}

export interface ISiteDataWireless {
  data: {
    wireless: IWirelessSite[];
  };
}

export interface ISiteItemResponse {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  timezone: string;
  user_count: number;
  place_count: number;
  has_acl: boolean;
}

export interface ISharedSiteItemResponse extends ISiteItemResponse {
  member: {
    id: string;
    is_new_site_member: boolean;
    role: RoleMember;
  };
}

export interface ISiteMember {
  id: string;
  is_new_site_member: boolean;
  role: RoleMember;
}

export interface ISiteResponseUnique {
  site: ISiteItemResponse;
  member: ISiteMember;
}

export interface ISiteResponse {
  sites: ISiteItemResponse[];
}

export interface ISharedSiteResponse {
  sites: ISharedSiteItemResponse[];
}

export interface IDataDeleteSite {
  email: string;
}

// eslint-disable-next-line no-shadow
export enum DevicesType {
  CFTV = 'CFTV',
  AP = 'AP'
}

export interface IPlace {
  name: string;
  id: string;
  device_count?: number;
  wireless_count?: number;
  devices_type: DevicesType;
  captive_portal_mode?: string;
  created_at: string;
  updated_at: string;
  configuration?: {
    error_adoption: boolean;
    error_configuration: boolean;
  };
}

export interface IDataPlace {
  place: {
    name: string;
  };
}

export interface IDataDeletePlace {
  email: string;
}

export interface IPlaceResponseUnique {
  place: IPlace;
}

export interface IPlacesResponse {
  place_list: IPlace[];
}

export interface IMembersRequest {
  member: {
    email: string;
    role: string;
  };
}

export interface IMembersUpdateRequest {
  member: {
    role: string;
  };
}

export interface IUserMemberData {
  id: string;
  name: string;
  email: string;
  account_status: 'registered' | 'not-registered';
}

export interface IMemberData {
  id: string;
  role: RoleMember;
  created_at: string;
  updated_at: string;
  user: IUserMemberData;
  site: string;
}

export interface IMemberWithTranslatedRoleData extends IMemberData {
  translatedRole: string;
}

export interface IMembersResponse {
  user_role: string;
  members: IMemberData[];
}

export interface IPostMembersResponse {
  user: IUserMemberData;
  site_counters: {
    name: string;
    timezone: string;
    id: string;
    created_at: string;
    updated_at: string;
    place_count: number;
    user_count: number;
    member: null;
  };
}

export interface INewCountResponse {
  new_sites_count: number;
}
export interface IPutMembersResponse {
  member: IMemberData;
}

export interface IPatchMemberData {
  is_new_site_member: boolean;
}

export interface IConnectionLog {
  id: string;
  node_ip: string;
  node_mac: string;
  node_name: string;
  place: {
    id: string;
    name: string;
  };
  device_mac: string;
  opened_at: string;
  closed_at: string;
}

export interface IConnectionLogPagination {
  connection_list: IConnectionLog[];
  pagination: IPagination;
}
