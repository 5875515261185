import classNames from 'classnames';
import React from 'react';
import styles from './DuoToneIconsInline.module.css';
import { IDuoToneIconsInlineProps } from './types';

const DuoToneIconsInline = ({
  backgroundColor,
  colorIcon,
  icon,
  value,
  title,
  className,
  onClick,
  ...props
}: IDuoToneIconsInlineProps) => {
  return (
    <div
      onClick={() => (onClick ? onClick() : null)}
      className={classNames(['d-flex align-center justify-center', className])}
      {...props}
      aria-hidden="true">
      <div
        className={classNames([styles.containerIcon, 'mr-1'])}
        style={{ backgroundColor: backgroundColor || '' }}>
        <div
          style={{ color: colorIcon }}
          className="d-flex align-center justify-center">
          {icon}
        </div>
      </div>

      <span className="title-sm-sm mr-1" id={`duotone-${title}@${value}`}>
        {value}
      </span>
      <span className="text-xl-xs">{title}</span>
    </div>
  );
};

DuoToneIconsInline.defaultProps = {
  className: '',
  backgroundColor: '',
  onClick: null,
  value: ''
};

export default DuoToneIconsInline;
