import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Grid, Row, Tooltip } from 'inmaster-ui';
import { useTemplate } from 'src/hooks/useTemplate';
import Card from 'ui-components/Card';
import { Button, ButtonTextOutside } from 'ui-components/Button';
import { MdAdd } from 'react-icons/md';
import api from 'src/services/api';
import { ISiteResponse } from 'src/services/api/urls/sites/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tabs from 'ui-components/Tab';
import { EmptyMessage } from 'src/components/EmptyMessage';
import Badge from 'src/components/Badge';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import Loading from 'ui-components/Loading';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import {
  ActiveTabSitesEnum,
  ISiteData,
  ISiteItemPage,
  ModalsTypesOfSites
} from './types';
import styles from './Sites.module.css';
import { SiteCard } from './SiteCard';
import SiteOptionsModalsAndDrawers from './SiteOptionsModalsAndDrawers';

const Sites = () => {
  const { accessToken, setSiteTimezone } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const [sites, setSites] = useState<ISiteItemPage[]>([] as ISiteItemPage[]);

  const [sharedSites, setSharedSites] = useState<ISiteItemPage[]>(
    [] as ISiteItemPage[]
  );

  const [selectedSite, setSelectedSite] = useState<ISiteItemPage | null>(null);

  const [canCreateLocal, setCanCreateLocal] = useState(false);

  const [newSharedSitesCount, setNewSharedSiteCount] = useState(0);

  const [activeTabIndex, setActiveTabIndex] = useLocalStorage(
    '@manager/activeTabSite',
    ActiveTabSitesEnum.MY_SITES
  );

  const [isAddingSite, setIsAddingSite] = useState(false);

  const [siteIdBeingEditedOrDeleted, setSiteIdBeingEditedOrDeleted] = useState<
    string | null
  >(null);

  const [activeModalType, setActiveModalType] =
    useState<ModalsTypesOfSites>(null);

  useEffect(() => {
    if (sites.length < 32) {
      setCanCreateLocal(true);
    } else {
      setCanCreateLocal(false);
    }
  }, [sites]);

  const {
    refetch: refetchSites,
    isFetchedAfterMount: isFetchedSitesAfterMount
  } = useQuery(
    ['sitesListQuery', accessToken?.user_id],
    () => {
      return api.sites.get('', true);
    },
    {
      onSuccess: ({ data: sitesResponse }: { data: ISiteResponse }) => {
        if (sitesResponse.sites.length === 0) {
          navigate('/first-access');
        }
        if (isAddingSite) {
          setIsAddingSite(false);
        }
        if (siteIdBeingEditedOrDeleted) {
          setSiteIdBeingEditedOrDeleted(null);
        }
        setSites(
          sitesResponse.sites.map((site) => {
            return {
              ...site,
              member: { role: RoleMember.OWNER }
            } as ISiteItemPage;
          })
        );
      }
    }
  );

  const { refetch: refetchNewSharedSitesCount } = useQuery(
    'newSharedSitesCountQuery',
    () => {
      return api.sites.shared.getNewSitesCount();
    },
    {
      onSuccess: ({ data }) => {
        setNewSharedSiteCount(data.new_sites_count);
      }
    }
  );

  const {
    refetch: refetchSharedSites,
    isFetchedAfterMount: isFetchedSharedSitesAfterMount
  } = useQuery(
    'sharedSitesListQuery',
    () => {
      return api.sites.shared.get('', true);
    },
    {
      onSuccess: ({ data: sharedSitesResponse }: { data: ISiteResponse }) => {
        setSharedSites(
          sharedSitesResponse.sites.map((sharedSite) => {
            return { ...sharedSite } as ISiteItemPage;
          })
        );
      }
    }
  );

  const onDeleteSite = (isSharedSite: boolean) => {
    if (isSharedSite) {
      setSelectedSite(null);
      setActiveModalType(null);
      refetchSharedSites();
      refetchNewSharedSitesCount();
    }
    refetchSites();
  };

  const refetchOwnerSitesAndSharedSites = () => {
    refetchSites();
    refetchSharedSites();
  };

  const onCreatingSite = (site: ISiteData) => {
    setSiteTimezone(site.timezone);
    setActiveTabIndex(ActiveTabSitesEnum.MY_SITES);
    navigate(`/sites/${site.id}/places`);
    refetchOwnerSitesAndSharedSites();
  };

  const openModalByType = (
    siteData: ISiteItemPage,
    type: ModalsTypesOfSites
  ) => {
    setSelectedSite(siteData);
    setActiveModalType(type);
  };

  const renderSites = () => {
    return (
      <Row>
        {isFetchedSitesAfterMount ? (
          <>
            {sites.map((site) => (
              <SiteCard
                key={`site-card-${site.id}`}
                site={site}
                isSharedSite={false}
                hasMoreThanOneSite={sites.length > 1}
                siteIdBeingEditedOrDeleted={siteIdBeingEditedOrDeleted}
                openModalByType={openModalByType}
              />
            ))}

            {isAddingSite && (
              <div className="fit-width">
                <Skeleton count={1} height={114} width="100%" />
              </div>
            )}
          </>
        ) : (
          <div className="fit-width">
            <Skeleton
              count={6}
              height={114}
              width="100%"
              className={styles.skeletonSites}
            />
          </div>
        )}
      </Row>
    );
  };

  const renderSharedSites = () => {
    return !sharedSites.length && isFetchedSharedSitesAfterMount ? (
      <div className="fit-width d-flex justify-center align-center">
        <EmptyMessage
          title={t('Nenhum local')}
          subtitle={t('Ainda não há locais compartilhados com você')}
          id="message-no-shared-sites"
          height="calc(50vh - 95px)"
        />
      </div>
    ) : (
      <Row>
        {isFetchedSharedSitesAfterMount ? (
          sharedSites.map((site) => (
            <SiteCard
              key={`shared-site-card-${site.id}`}
              site={site}
              isSharedSite
              hasMoreThanOneSite={sharedSites.length > 1}
              siteIdBeingEditedOrDeleted={siteIdBeingEditedOrDeleted}
              openModalByType={openModalByType}
            />
          ))
        ) : (
          <div className="fit-width">
            <Skeleton
              count={6}
              height={114}
              width="100%"
              className={styles.skeletonSites}
            />
          </div>
        )}
      </Row>
    );
  };

  const renderTabBadgeSharedWithMe = () => {
    return (
      <Badge
        color="var(--color-neutral-light-5)"
        backgroundColor="var(--color-status-critical-base)"
        title={`${newSharedSitesCount} ${
          newSharedSitesCount > 1 ? t('novos') : t('novo')
        }`}
        size="small"
        className="ml-1"
        id={`badge-new-shared-sites-count-${newSharedSitesCount}`}
      />
    );
  };

  const tabs = [
    {
      label: `${t('Meus Locais')} (${sites.length || 0})`,
      id: 'mySitesTab',
      component: renderSites(),
      onClick: () => {
        setActiveTabIndex(ActiveTabSitesEnum.MY_SITES);
      }
    },
    {
      label: `${t('Compartilhados comigo')} (${sharedSites.length || 0})`,
      id: 'sharedWithMeTab',
      component: renderSharedSites(),
      onClick: () => {
        setActiveTabIndex(ActiveTabSitesEnum.SHARED_WITH_ME);
      },
      badge: newSharedSitesCount > 0 && renderTabBadgeSharedWithMe()
    }
  ];

  useTemplate('avatarNavbar');

  if (!isFetchedSitesAfterMount && sites.length === 0) {
    return <Loading value={40} indeterminate fullscreen show />;
  }

  return (
    <div className={styles.shellSites}>
      <SiteOptionsModalsAndDrawers
        activeModalType={activeModalType}
        setActiveModalType={setActiveModalType}
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        onCreatingSite={onCreatingSite}
        setIsAddingSite={setIsAddingSite}
        setSiteIdBeingEditedOrDeleted={setSiteIdBeingEditedOrDeleted}
        setSites={setSites}
        setSharedSites={setSharedSites}
        onDeleteSite={onDeleteSite}
      />

      <Grid fluid>
        <Row className="mb-7">
          <Col xs={12}>
            <div className="d-flex justify-between align-center">
              <h1
                className={classNames(['title-sm-xl ml-2', styles.title])}
                id="site-title-page">
                {t('LOCAIS')}
              </h1>
              <div className="d-flex ">
                <Tooltip
                  content={t('Limite de criação de 32 locais atingido')}
                  style={{ width: 190 }}
                  id="tooltip-limit-creation-site"
                  place="bottom"
                  disabled={canCreateLocal}>
                  {isFetchedSitesAfterMount ? (
                    <ButtonTextOutside
                      id="text-btn-open-modal-to-create-site"
                      value={t('CRIAR LOCAL')}
                      className={
                        canCreateLocal
                          ? classNames(['text-uppercase mr-7'])
                          : classNames([
                              'text-uppercase mr-7',
                              styles.textDisabled
                            ])
                      }>
                      <Button
                        onClick={() => setActiveModalType('create')}
                        id="btn-open-modal-to-create-site"
                        disabled={!canCreateLocal}
                        rounded>
                        <MdAdd size={22} />
                      </Button>
                    </ButtonTextOutside>
                  ) : (
                    <Skeleton count={1} width={156} height={45} />
                  )}
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Card
            className={classNames([
              'fit-width fit-height',
              styles.cardOfSitesAndSharedSites
            ])}>
            <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
          </Card>
        </Row>
      </Grid>
    </div>
  );
};

export default Sites;
