import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'ui-components/Input';

const CPFRecordForm = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  return <Input placeholder={t('CPF')} id="input-cpf" />;
};

export { CPFRecordForm };
