import React from 'react';
import classNames from 'classnames';
import { Col, Row, Tooltip } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import {
  MdArticle,
  MdDelete,
  MdMoreHoriz,
  MdSearch,
  MdUpdate
} from 'react-icons/md';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import TableWithPagination from 'src/components/TableWithPagination';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from 'src/pages/Devices/types';
import { generateColumns } from 'src/utils/tableUtils';
import { useDebounce } from 'use-debounce';
import { useToast } from 'src/hooks/useToast';
import {
  IGetExpiredIndividualRecords,
  IRenewExpiredIndividualRecords
} from 'src/services/api/urls/captivePortal/types';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { generateIndividualRecordsItemsOfPagination } from 'src/utils/CaptivePortal/individualRecords';
import { DateConverter } from 'src/utils/Dates/DateConverter';
import styles from './ExpiredRecordTab.module.css';
import {
  IIndividualRecord,
  IIndividualRecordRow,
  IPaginationIndividualRecord
} from '../types';
import { DeleteIndividualRecordModal } from '../DeleteIndividualRecordModal';
import { UpdateAllExpiredRecordModal } from './UpdateAllExpiredRecordModal';
import { UpdateRecordModal } from '../UpdateRecordModal';
import { MoreInformationModal } from '../MoreInformationModal';
import { PreViewCaptiveModal } from '../../PreViewCaptiveModal';
import { ICaptivePortal } from '../../types';

const ExpiredRecordTab = ({
  defaultCaptivePortal
}: {
  defaultCaptivePortal: ICaptivePortal;
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const { addToast } = useToast();

  const { cp_id: captiveId } = useParams();

  const { accessToken, siteTimezone } = useAuth();

  const [paginationExpiredRecordObject, setPaginationExpiredRecordObject] =
    React.useState<IPaginationIndividualRecord>(
      {} as IPaginationIndividualRecord
    );

  const [
    isInitialLoadingCompleteOfExpiredRecords,
    setIsInitialLoadingCompleteOfExpiredRecords
  ] = React.useState(false);

  const [showMenu, setShowMenu] = React.useState(false);

  const [selectedRecords, setSelectedRecords] = React.useState<
    IIndividualRecordRow[]
  >([]);

  const [isChangingPage, setIsChangingPage] = React.useState(false);

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    React.useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    React.useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [
    oldPaginationRequestDataDebounce,
    setOldPaginationRequestDataDebounce
  ] = React.useState(paginationRequestDataDebounce);

  const [searchExpiredRecords, setSearchExpiredRecords] = React.useState('');

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [showUpdateAllRecordsModal, setShowUpdateAllRecordsModal] =
    React.useState(false);

  const [showUpdateRecordModal, setShowUpdateRecordModal] =
    React.useState(false);

  const [selectedRecordToShowInformation, setSelectedRecordToShowInformation] =
    React.useState<IIndividualRecord>({} as IIndividualRecord);

  const [showMoreInformationModal, setShowMoreInformationModal] =
    React.useState(false);

  const getExpiredRecords = () => {
    return api.captivePortal.individualRecord.getExpiredHistoric(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      paginationRequestDataDebounce
    );
  };

  const {
    isFetching: isFetchingExpiredIndividualRecords,
    refetch: refetchExpiredIndividualRecords
  } = useQuery(
    ['getExpiredRecords', captiveId, paginationRequestDataDebounce],
    getExpiredRecords,
    {
      onSuccess: ({ data }: { data: IGetExpiredIndividualRecords }) => {
        const responseIndividualRecordHistoric = { ...data };

        setPaginationExpiredRecordObject({
          individualRecord: generateIndividualRecordsItemsOfPagination(
            'history',
            data.history_individual_records,
            siteTimezone
          ),

          pagination: { ...responseIndividualRecordHistoric.pagination }
        });
      },
      onError: () => {
        setPaginationExpiredRecordObject({
          individualRecord: [],
          pagination: {
            total_items: 0,
            total_pages: 0,
            current_page: 0,
            total_filtered_items: 0
          }
        });
      },
      onSettled: () => {
        if (!isInitialLoadingCompleteOfExpiredRecords) {
          setIsInitialLoadingCompleteOfExpiredRecords(true);
        }
      }
    }
  );

  const deleteAllExpiredRecords = () => {
    return api.captivePortal.individualRecord.deleteAllExpiredRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || ''
    );
  };

  const deleteExpiredRecords = () => {
    const recordsIdsToDelete = selectedRecords.map((record) => record.id || '');

    return api.captivePortal.individualRecord.deleteExpiredRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      recordsIdsToDelete
    );
  };

  const deleteAllExpiredRecordsMutation = useMutation(deleteAllExpiredRecords, {
    onSuccess: () => {
      addToast(
        'success',
        t(
          'O processo de exclusão de {{qtySelectedRecords}} cadastro(s) foi iniciado',
          {
            qtySelectedRecords:
              paginationExpiredRecordObject.pagination.total_items
          }
        )
      );

      refetchExpiredIndividualRecords();
    },
    onError: () => {
      addToast('error', t('Erro ao remover cadastro(s)'));
    }
  });

  const deleteExpiredRecordsMutation = useMutation(deleteExpiredRecords, {
    onSuccess: () => {
      addToast(
        'success',
        t(
          'O processo de exclusão de {{qtySelectedRecords}} cadastro(s) foi iniciado',
          {
            qtySelectedRecords: selectedRecords.length
          }
        )
      );

      refetchExpiredIndividualRecords();
      setSelectedRecords([]);
    },
    onError: () => {
      addToast('error', t('Erro ao remover cadastro(s)'));
    }
  });

  const renewSelectedIndividualRecords = () => {
    const data: IRenewExpiredIndividualRecords = {
      history_individual_records_ids: selectedRecords.map(
        (record) => record.id || ''
      ),
      new_started_at: new DateConverter(
        undefined,
        siteTimezone
      ).getDateWithDefaultFormat()
    };

    return api.captivePortal.individualRecord.renewExpiredRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      data
    );
  };

  const renewSelectedIndividualRecordsMutation = useMutation(
    renewSelectedIndividualRecords,
    {
      onSuccess: () => {
        refetchExpiredIndividualRecords();
        addToast(
          'success',
          t(
            '{{qtySelectedIndividualRecords}} Cadastro(s) renovado(s) com sucesso',
            { qtySelectedIndividualRecords: selectedRecords.length }
          )
        );
      },
      onError: () => {
        addToast('error', t('Não foi possível renovar'));
      },
      onSettled: () => {
        setSelectedRecords([]);
        setShowUpdateRecordModal(false);
      }
    }
  );

  const onConfirmUpdateRecords = () => {
    renewSelectedIndividualRecordsMutation.mutate();
  };

  const onCloseUpdateAllRecordsModal = () => {
    setShowUpdateAllRecordsModal(false);
  };

  const onCloseUpdateRecordModal = () => {
    setShowUpdateRecordModal(false);
  };

  const onClickToOpenShowMoreInformationModal = (
    selectedRecord: IIndividualRecord
  ) => {
    setShowMoreInformationModal(true);
    setSelectedRecordToShowInformation(selectedRecord);
  };

  const onCloseMoreInformationModal = () => {
    setShowMoreInformationModal(false);
    setSelectedRecordToShowInformation({} as IIndividualRecord);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onConfirmToDeleteRecord = () => {
    if (selectedRecords.length > 0) {
      deleteExpiredRecordsMutation.mutate();
    } else {
      deleteAllExpiredRecordsMutation.mutate();
    }

    setShowDeleteModal(false);
  };

  const isFetchingAndNotInitialLoadingCompleteExpiredIndividualRecords =
    !isInitialLoadingCompleteOfExpiredRecords &&
    isFetchingExpiredIndividualRecords;

  const isNotFetchingAndInitialLoadingCompleteExpiredIndividualRecords =
    isInitialLoadingCompleteOfExpiredRecords &&
    !isFetchingExpiredIndividualRecords;

  const isEmptyRecords =
    isNotFetchingAndInitialLoadingCompleteExpiredIndividualRecords &&
    paginationExpiredRecordObject.pagination.total_items === 0;

  const isResultNotFound =
    paginationExpiredRecordObject.pagination?.total_filtered_items === 0 &&
    isNotFetchingAndInitialLoadingCompleteExpiredIndividualRecords;

  const isSearchingRecords =
    isFetchingExpiredIndividualRecords &&
    isInitialLoadingCompleteOfExpiredRecords &&
    !isChangingPage &&
    loadingTypeOfRowsPerPage.type === 'success';

  const columnsTableIndividualRecord = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('document', t('Nº Documento'), 'left', 0),
    generateColumns('authorizedStatus', t('Autorizado'), 'center', 0),
    generateColumns('time', t('Tempo'), 'left', 0),
    generateColumns('expires_at', t('Data de Expiração'), 'left', 0),
    generateColumns('actions', t('Ações'), 'left', 0)
  ];

  const generateRows = (recordsList: IIndividualRecord[]) => {
    return recordsList.map((recordItem: IIndividualRecord) => {
      const isAuthorized = recordItem.authorizedStatus;

      return {
        id: recordItem.id,
        created_at: isAuthorized ? recordItem.created_at : '--',
        expires_at: isAuthorized ? recordItem.expires_at : '--',
        time: recordItem.time,
        document: recordItem.document,
        name: recordItem.name,
        authorizedStatus: isAuthorized ? t('Sim') : t('Não'),
        actions: (
          <div className={classNames('d-flex justify-between')}>
            <Tooltip
              content={t('Mais informações')}
              id="tooltip-more-information"
              place="top">
              <ButtonIcon
                onClick={() => {
                  onClickToOpenShowMoreInformationModal(recordItem);
                }}>
                <MdArticle size={18} />
              </ButtonIcon>
            </Tooltip>
          </div>
        )
      } as IIndividualRecordRow;
    });
  };

  const [showPreViewModal, setShowPreViewModal] = React.useState(false);

  const renderMenu = () => {
    return (
      <Menu
        menuId="menu-expired-individual-record-actions"
        width="160px"
        positionX="end"
        show={showMenu}
        onClose={() => setShowMenu(false)}
        items={
          <>
            <MenuItem
              id="btn-pre-view-captive"
              onClick={() => setShowPreViewModal(true)}>
              <span className={classNames('text-sm-xs')}>
                {t('Visualizar Captive')}
              </span>
            </MenuItem>
            <MenuItem
              id="btn-clear-expired-records"
              onClick={() => {
                setShowDeleteModal(true);
              }}>
              <span className={classNames('text-sm-xs')}>
                {t('Limpar expirados')}
              </span>
            </MenuItem>
            {/* <MenuItem
              id="btn-export-table"
              onClick={() => {
                addToast(
                  'success',
                  `(${
                    paginationExpiredRecordObject.individualRecord.length
                  }) ${t('Cadastro(s) exportado(s) com sucesso')}`
                );
              }}>
              <span className={classNames('text-sm-xs')}>
                {t('Exportar tabela')}
              </span>
            </MenuItem> */}
          </>
        }>
        <div
          id="btn-menu-actions"
          className={styles.menuActions}
          onClick={() => setShowMenu(true)}
          aria-hidden="true">
          <MdMoreHoriz size={24} />
        </div>
      </Menu>
    );
  };

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 align-center py-5"
          key={`${i}-skeleton-individual-record-table`}>
          <Skeleton count={1} height={25} width={25} className="ml-2" />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={78} borderRadius={100} />
          <div className="d-flex align-center mr-2">
            <Skeleton count={1} height={35} width={35} className="mr-2" />
            <Skeleton count={1} height={35} width={35} />
          </div>
        </div>
      );
    }

    return skeletons;
  };

  const renderSkeletonsOfCompleteTable = (numberOfSkeletons: number) => {
    const skeletonsOfTable = renderSkeletonsOfTable(numberOfSkeletons);
    const skeletonsOfCompleteTable = [
      <div className="fit-width" key="header-skeleton-individual-record-table">
        <Skeleton height={50} />
      </div>,
      ...skeletonsOfTable
    ];

    return <Card>{skeletonsOfCompleteTable}</Card>;
  };

  const renderSkeletonsTab = () => {
    return (
      <div>
        <Card className="px-7 py-6 mb-5">
          <Row between="md" className="mb-1">
            <Col xs={12}>
              <Label>
                <Skeleton width={120} height={20} />
              </Label>
            </Col>
            <Col xs={12} className="mt-1">
              <Row className="d-flex align-center">
                <Col xs={6} className="mr-7">
                  <Skeleton height={50} width={550} />
                </Col>

                <Col className="d-flex align-center">
                  <div className="mr-7">
                    <Skeleton height={50} width={200} />
                  </div>
                  <Skeleton height={50} width={50} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card>{renderSkeletonsOfCompleteTable(10)}</Card>
      </div>
    );
  };

  React.useEffect(() => {
    setPaginationRequestData((prev) => ({
      ...prev,
      search_param: searchExpiredRecords,
      page_number: 1
    }));
  }, [searchExpiredRecords]);

  React.useEffect(() => {
    const debounceChanged = !_.isEqual(
      oldPaginationRequestDataDebounce,
      paginationRequestDataDebounce
    );

    if (debounceChanged) {
      refetchExpiredIndividualRecords();
      setOldPaginationRequestDataDebounce(paginationRequestDataDebounce);
    }
  }, [
    oldPaginationRequestDataDebounce,
    paginationRequestDataDebounce,
    refetchExpiredIndividualRecords
  ]);

  return (
    <div>
      {isFetchingAndNotInitialLoadingCompleteExpiredIndividualRecords ? (
        renderSkeletonsTab()
      ) : (
        <div>
          {isEmptyRecords ? (
            <EmptyMessage
              id="empty-expired-individual-record"
              title={t('Nenhum cadastro expirado')}
              subtitle={t(
                'Ainda não há cadastros individuais expirados neste ambiente. Verifique os cadastros na aba "Ativos"'
              )}
              minHeight="190px"
              height="calc(100vh - 500px)"
            />
          ) : (
            <>
              <PreViewCaptiveModal
                methodCaptive="individual_record"
                defaultCaptivePortal={defaultCaptivePortal}
                showPreViewModal={showPreViewModal}
                isFetchedCaptivePortal
                onClose={() => {
                  setShowPreViewModal(false);
                }}
              />
              <DeleteIndividualRecordModal
                onCloseDeleteModal={onCloseDeleteModal}
                countSelectedRecords={
                  selectedRecords.length > 0
                    ? selectedRecords.length
                    : paginationExpiredRecordObject.pagination.total_items
                }
                showDeleteModal={showDeleteModal}
                isLoadingDeleteMutation={
                  deleteAllExpiredRecordsMutation.isLoading
                }
                hasSomeExpiredRecords
                onDeleteRecord={onConfirmToDeleteRecord}
              />

              <UpdateAllExpiredRecordModal
                onCloseUpdateAllRecordsModal={onCloseUpdateAllRecordsModal}
                showUpdateAllRecordsModal={showUpdateAllRecordsModal}
                onSuccessUpdateAllRecords={() =>
                  refetchExpiredIndividualRecords()
                }
                countExpiredRecords={
                  paginationExpiredRecordObject.pagination.total_items
                }
              />

              <UpdateRecordModal
                qtySelectedRecords={selectedRecords.length}
                isLoadingUpdateRecord={
                  renewSelectedIndividualRecordsMutation.isLoading
                }
                onConfirmUpdateRecords={onConfirmUpdateRecords}
                onCloseUpdateRecordModal={onCloseUpdateRecordModal}
                showUpdateRecordModal={showUpdateRecordModal}
              />

              <MoreInformationModal
                selectedIndividualRecord={selectedRecordToShowInformation}
                onCloseMoreInformationModal={() =>
                  onCloseMoreInformationModal()
                }
                showMoreInformationModal={showMoreInformationModal}
              />

              {/* <Row className="mb-5">
                <Col xs={9}>
                  <Alert type="warning">
                    <span>
                      {t(
                        'Atenção, após expirados os cadastros possuem duração máxima de 30 dias, caso não sejam renovados o sistema os excluirá automaticamente'
                      )}
                      .
                    </span>
                  </Alert>
                </Col>
              </Row> */}

              <Card className="px-7 py-6 mb-5">
                {selectedRecords.length === 0 ? (
                  <Row between="md" className="mb-1">
                    <Col xs={12}>
                      <Label>{t('Pesquisar na lista')}:</Label>
                    </Col>
                    <Col xs={12} className="mt-1">
                      <Row className="align-center">
                        <Col xs={6}>
                          <InputIconInside
                            iconLeft={
                              isSearchingRecords ? (
                                <Loading value={40} indeterminate />
                              ) : (
                                <MdSearch />
                              )
                            }>
                            <Input
                              id="search-input"
                              iconInsideLeft
                              placeholder={t(
                                'Pesquise pelo nº documento, nome, telefone ou e-mail'
                              )}
                              onChange={(
                                eventInput: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setSearchExpiredRecords(
                                  eventInput.target.value
                                );
                              }}
                            />
                          </InputIconInside>
                        </Col>

                        <Col className="pl-7 d-flex align-center">
                          <ButtonTextOutside
                            id="text-btn-open-modal-to-update-individual-record"
                            value={t('Renovar expirados')}
                            className="text-uppercase mr-9">
                            <Button
                              id="btn-open-modal-to-update-individual-record"
                              onClick={() => setShowUpdateAllRecordsModal(true)}
                              ghost
                              rounded>
                              <MdUpdate size={18} />
                            </Button>
                          </ButtonTextOutside>

                          {renderMenu()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mb-1">
                    <Col xs={12} className="d-flex">
                      <ButtonTextOutside
                        value={`${t('Excluir')} (${selectedRecords.length})`}
                        className="text-uppercase mt-6 mb-6 ml-7">
                        <Button
                          onClick={() => setShowDeleteModal(true)}
                          id="button-delete-record"
                          rounded
                          color="var(--color-status-critical-base)">
                          <MdDelete size={22} />
                        </Button>
                      </ButtonTextOutside>
                      <ButtonTextOutside
                        value={`${t('RENOVAR')} (${selectedRecords.length})`}
                        className="text-uppercase mt-6 mb-6 ml-7">
                        <Button
                          onClick={() => setShowUpdateRecordModal(true)}
                          id="button-update-record"
                          rounded
                          ghost>
                          <MdUpdate size={18} />
                        </Button>
                      </ButtonTextOutside>
                      {/* <ButtonTextOutside
                        value={`${t('Exportar')} (${selectedRecords.length})`}
                        className="text-uppercase mt-6 mb-6 ml-7">
                        <Button
                          onClick={() => {
                            addToast(
                              'success',
                              `(${selectedRecords.length}) ${t(
                                'Cadastro(s) exportado(s) com sucesso'
                              )}`
                            );
                            setSelectedRecords([]);
                          }}
                          id="button-update-record"
                          rounded
                          ghost>
                          <MdFileDownload size={18} />
                        </Button>
                      </ButtonTextOutside> */}
                    </Col>
                  </Row>
                )}
              </Card>

              {isSearchingRecords ? (
                renderSkeletonsOfCompleteTable(10)
              ) : (
                <div>
                  {isResultNotFound ? (
                    <ResultsNotFoundMessage
                      id="result-not-found-individual-record"
                      minHeight="190px"
                      height="calc(100vh - 680px)"
                    />
                  ) : (
                    <Card>
                      <TableWithPagination<IIndividualRecord>
                        keyToCompare="id"
                        useKeyToCompareAsId
                        isChangingPage={isChangingPage}
                        setPaginationObject={setPaginationExpiredRecordObject}
                        loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                        columns={columnsTableIndividualRecord}
                        rows={generateRows(
                          paginationExpiredRecordObject.individualRecord
                        )}
                        isFetchingData={isFetchingExpiredIndividualRecords}
                        paginationData={
                          paginationExpiredRecordObject.pagination
                        }
                        firstFetchWasMade={
                          isInitialLoadingCompleteOfExpiredRecords
                        }
                        paginationRequestData={paginationRequestDataDebounce}
                        setIsChangingPage={setIsChangingPage}
                        setLoadingTypeOfRowsPerPage={
                          setLoadingTypeOfRowsPerPage
                        }
                        setPaginationRequestData={setPaginationRequestData}
                        minRowsToShowPagination={10}
                        renderSkeletonsOfTable={renderSkeletonsOfTable}
                        keyOfItemsOfPaginationTable="individualRecord"
                        selectedRows={selectedRecords}
                        onSelectRows={(
                          selectedRows: IIndividualRecordRow[]
                        ) => {
                          setSelectedRecords(selectedRows);
                        }}
                      />
                    </Card>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { ExpiredRecordTab };
