import React, { useEffect, useState } from 'react';
import downloadGoogleImg from 'src/assets/download-app-google.svg';
import downloadAppleImg from 'src/assets/download-app-apple.svg';
import classNames from 'classnames';
import { ButtonIcon } from 'ui-components/Button';
import { MdClose } from 'react-icons/md';
import { useAuth } from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import styles from './MobileWarning.module.css';

export const MobileWarning = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'mobileWarning'
  });

  const { accessToken } = useAuth();
  const [isMobile, setIsMobile] = useState(false);

  const isTouchScreenDevice = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleClose = () => {
    localStorage.setItem('@manager/warn_close_time', Date.now().toString());
    setIsMobile(false);
  };

  useEffect(() => {
    const closeTime = localStorage.getItem('@manager/warn_close_time');
    const currentTime = Date.now();
    const TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000;

    if (closeTime && currentTime - Number(closeTime) < TWELVE_HOURS_IN_MS) {
      setIsMobile(false);
    } else {
      setIsMobile(isTouchScreenDevice());
    }
  }, []);

  if (!accessToken || !isMobile) {
    return null;
  }

  const storeLinks = [
    {
      link: 'https://play.google.com/store/apps/details?id=br.com.intelbras.inmaster.prod',
      image: downloadGoogleImg,
      alt: t('Download na Google Play Store')
    },
    {
      link: 'https://apps.apple.com/br/app/inmaster-intelbras/id6449433565',
      image: downloadAppleImg,
      alt: t('Download na Apple Store')
    }
  ];

  return (
    <div className={classNames(['fit-width text-center', styles.container])}>
      <div className="d-flex flex-column align-start">
        <div className="fit-width d-flex justify-end">
          <ButtonIcon onClick={handleClose} className={styles.closeButton}>
            <MdClose />
          </ButtonIcon>
        </div>
        <span className="text-xl-base">
          {t('Experimente o nosso Aplicativo')}
        </span>
      </div>

      <div className={classNames(['d-flex align-start', styles.links])}>
        {storeLinks.map(({ link, image, alt }) => (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={alt} className={styles.imageLink} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default MobileWarning;
