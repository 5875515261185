import React from 'react';
import { useTemplate } from 'src/hooks/useTemplate';
import { FormAddDevices } from 'src/components/FormAddDevices';

const AddFirstDevice = () => {
  useTemplate('fullNavbarWithPlacesSelector');

  return <FormAddDevices redirectOnCancel="/first-device" />;
};

export { AddFirstDevice };
