import React, { ReactNode } from 'react';
import {
  DatePicker as DatePickerConectese
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@conecte-se/ui-components-reactjs';
import '@conecte-se/ui-components-reactjs/dist/index.css'; // todo: move to index file after mvp
import '@conecte-se/ui-components-reactjs/src/styles/theme/external_lib_themes.scss';
import { ptBR } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import './styles.module.css';

registerLocale('ptBR', ptBR);

interface IProps {
  onChange(date: Date | [Date, Date]): void;
  selected?: Date;
  placeholder?: string;
  customInput?:
    | React.ForwardRefExoticComponent<
        {
          onClick: () => void;
        } & React.RefAttributes<HTMLDivElement>
      >
    | ReactNode;
  onClear?(): void;
  isClearable?: boolean;
  readOnly?: boolean;
  visible?: boolean;
  disabled?: boolean;
  onInputClick?(): void;
  className?: string;
  dateFormat?: string;
  onCalendarClose?(): void;
  onCalendarOpen?(): void;
  monthsShown?: number;
  excludeDates?: Date[];
  excludeDateIntervals?: {
    start: Date;
    end: Date;
  }[];
  locale?: string;
  closeOnScroll?: boolean;
  minDate?: Date;
  maxDate?: Date;
  shouldCloseOnSelect?: boolean;
  filterDate?(date: Date): boolean;
  includeDates?: Date[];
  includeDateIntervals?: {
    start: Date;
    end: Date;
  }[];
  disabledKeyboardNavigation?: boolean;
  value?: 'Valid' | 'Invalid' | 'Alert';
  selectsRange?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
}

const DatePicker: React.FC<IProps> = ({
  onChange,
  selected,
  placeholder,
  customInput,
  onClear,
  isClearable,
  readOnly,
  visible,
  disabled,
  onInputClick,
  className,
  dateFormat,
  onCalendarClose,
  onCalendarOpen,
  monthsShown,
  excludeDates,
  excludeDateIntervals,
  locale,
  closeOnScroll,
  minDate,
  maxDate,
  shouldCloseOnSelect,
  filterDate,
  includeDates,
  includeDateIntervals,
  disabledKeyboardNavigation,
  selectsRange,
  value,
  endDate,
  startDate,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption
}) => {
  return (
    <DatePickerConectese
      className={className}
      selected={selected}
      onChange={onChange}
      placeholder={placeholder}
      customInput={customInput}
      onClear={onClear}
      isClearable={isClearable}
      readOnly={readOnly}
      visible={visible}
      disabled={disabled}
      onInputClick={onInputClick}
      dateFormat={dateFormat}
      onCalendarClose={onCalendarClose}
      onCalendarOpen={onCalendarOpen}
      monthsShown={monthsShown}
      excludeDates={excludeDates}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      excludeDateIntervals={excludeDateIntervals}
      locale={locale}
      closeOnScroll={closeOnScroll}
      minDate={minDate}
      maxDate={maxDate}
      shouldCloseOnSelect={shouldCloseOnSelect}
      filterDate={filterDate}
      includeDates={includeDates}
      includeDateIntervals={includeDates}
      disabledKeyboardNavigation={disabledKeyboardNavigation}
      value={value}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

DatePicker.defaultProps = {
  selected: undefined,
  placeholder: '',
  customInput: undefined,
  onClear: undefined,
  isClearable: false,
  readOnly: false,
  visible: false,
  disabled: false,
  onInputClick: undefined,
  className: '',
  dateFormat: '',
  onCalendarClose: undefined,
  onCalendarOpen: undefined,
  monthsShown: 1,
  excludeDates: [],
  excludeDateIntervals: [],
  locale: 'en-US',
  closeOnScroll: false,
  shouldCloseOnSelect: true,
  filterDate: undefined,
  includeDates: undefined,
  includeDateIntervals: undefined,
  disabledKeyboardNavigation: false,
  value: 'Valid',
  minDate: undefined,
  maxDate: undefined,
  selectsRange: false,
  startDate: undefined,
  endDate: undefined,
  showTimeSelect: false,
  showTimeSelectOnly: false,
  timeIntervals: undefined,
  timeCaption: undefined
};

export default DatePicker;
