import React from 'react';
import classNames from 'classnames';
import styles from './RadioButton.module.css';

function RadioButton({
  id = '',
  checked = false,
  disabled = false,
  value = '',
  onChange
}) {
  return (
    <label
      className={classNames([styles.container, disabled && styles.disabled])}>
      <input
        type="radio"
        onChange={onChange}
        checked={checked}
        value={value}
        name={id}
        data-is-checked={checked}
      />
      <span id={id} className={classNames([styles.checkmark])} />
    </label>
  );
}

export default RadioButton;
