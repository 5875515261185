import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'ui-components/Button';
import Modal from 'ui-components/Modal';
import styles from './InfoModalWithIframe.module.css';

interface IProps {
  show: boolean;
  onClose: () => void;
  title: string;
  href: string;
}

const InfoModalWithIframe = ({ show, onClose, href, title }: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation('translations', {
    keyPrefix: 'information'
  });

  const renderSkeleton = () => {
    return (
      <div className="fit-width">
        <Skeleton height={25} width={255} />
        <Skeleton className="mt-1" height={25} width={300} />
        <Skeleton
          className="fit-width"
          height={75}
          style={{ marginTop: 'var(--spacing-stack-xl)' }}
        />
        <Skeleton className="fit-width mt-1" height={75} />
        <Skeleton className="fit-width mt-1" height={75} />
        <Skeleton className="fit-width mt-1" height={75} />
      </div>
    );
  };

  return (
    <Modal
      title={title}
      show={show}
      onClose={() => {
        setIsLoaded(false);
        onClose();
      }}
      closable
      width="795px">
      <div className={styles.modalBody}>
        {!isLoaded && renderSkeleton()}
        <iframe
          src={href}
          frameBorder="0"
          title={title}
          className={classNames([
            'fit-width',
            styles.iframeShell,
            !isLoaded && styles.hidden
          ])}
          onLoad={() => setIsLoaded(true)}
        />
      </div>

      <div className="d-flex justify-end mt-3">
        <Button
          onClick={() => {
            onClose();
            setIsLoaded(false);
          }}>
          {t('FECHAR')}
        </Button>
      </div>
    </Modal>
  );
};

export default InfoModalWithIframe;
