import React from 'react';
import { useTemplate } from 'src/hooks/useTemplate';
import { FormAddDevices } from 'src/components/FormAddDevices';

const AddDevices = () => {
  useTemplate('menuAndFullNavbar');

  return <FormAddDevices redirectOnCancel="/devices" />;
};

export { AddDevices };
