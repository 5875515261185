import type { SelectRootSlotProps } from '@mui/base';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  MdArrowDropDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight
} from 'react-icons/md';
import { Button } from 'src/components/Button';
import { Select } from 'src/components/Select';
import type { ITablePagination } from './types';
import styles from './TablePagination.module.css';

const PaginationButton = React.forwardRef(function PaginationButton<
  TValue extends NonNullable<unknown>,
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ownerState, ...other } = props;
  return (
    <Button type="button" id="pagination-button" {...other} ref={ref}>
      <div className={classNames(styles.contentPaginationButton)}>
        {other.children}
      </div>
      <MdArrowDropDown size={20} />
    </Button>
  );
});

const TablePagination = ({
  countItems,
  pageIndex = 1,
  rowsPerPage = 10,
  rowsPerPageOptions = [10, 25, 50, 100, { label: 'Todas', value: -1 }],
  countSelectedItems,
  labelSelectedItems = 'Selecionado(s)',
  labelRowsPerPage = 'Linhas por página',
  labelRangePage = 'de',
  onPageChange,
  onRowsPerPageChange,
  ...rest
}: ITablePagination) => {
  const ALL_PAGES = -1;

  const [firstRow, setFirstRow] = useState(1);

  const [lastRow, setLastRow] = useState(0);

  const [lastPage, setLastPage] = useState(Math.ceil(countItems / rowsPerPage));

  const [currentPage, setCurrentPage] = useState<number | null>(pageIndex);

  const [rowsPerPageSelected, setRowsPerPageSelected] = useState(rowsPerPage);

  // Função para mapear as opções de quantidade de itens por página da tabela
  const mapRowsPerPageOptions = (): {
    value: unknown;
    label: string;
  }[] => {
    return rowsPerPageOptions.map((item) => {
      const value = typeof item === 'number' ? item : item.value;

      const label = typeof item === 'number' ? item.toString() : item.label;

      return { value: value as number, label };
    });
  };

  // Função para calcular o intervalo da quantidade de itens da tabela
  const calculateRangePage = () => {
    // Calcula a última página
    const calculateLastPage =
      rowsPerPageSelected === ALL_PAGES || countItems === 0
        ? 1
        : Math.ceil(countItems / rowsPerPageSelected);

    setLastPage(calculateLastPage);

    // Calcula o intervalo da quantidade de itens por página da tabela
    // Se não tiver nenhum item na tabela irá zerar os valores
    if (countItems === 0) {
      setFirstRow(0);
      setLastRow(0);
      return;
    }

    // Se houver apenas uma página, o intervalo será de 1 até a quantidade de itens
    if (rowsPerPageSelected === ALL_PAGES) {
      setFirstRow(1);
      setLastRow(countItems);
    } else {
      const actualPage = currentPage || 1;
      setFirstRow((actualPage - 1) * rowsPerPageSelected + 1);
      setLastRow(Math.min(actualPage * rowsPerPageSelected, countItems));
    }
  };

  // Função para voltar uma página
  const handleBack = () => {
    const actualPage = currentPage || 1;

    if (actualPage > 1) {
      setCurrentPage(actualPage - 1);

      /*
       * Verificação para disparar o evento de mudança de página
       */
      onPageChange(actualPage - 1);
    }
  };

  // Função para a próxima página
  const handleNext = () => {
    const actualPage = currentPage || 1;

    if (actualPage < lastPage) {
      setCurrentPage(actualPage + 1);

      /*
       * Verificação para disparar o evento de mudança de página
       */
      onPageChange(actualPage + 1);
    }
  };

  // Função para quando mudar a quantidade de itens por página
  const onRowsPerPageSelectChange = (value: number | null | number[]) => {
    setRowsPerPageSelected(value as number);
    setCurrentPage(null);

    // Dispara evento, ao mudar de página
    onRowsPerPageChange(value as number);
  };

  /*
   * Evento de quando mudar a página atual, atualiza os intervalos das páginas
   * e dispara o evento de mudança de página
   */
  useEffect(() => {
    /*
     * Checa se a página atual é nula, que significa que houve uma mudança na quantidade de itens totais
     * ou na quantidade de itens por página
     */

    if (currentPage === null) {
      setCurrentPage(1);
      return;
    }

    calculateRangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  /*
   * Evento para disparar quando mudar a página repassada por parâmetro
   */
  useEffect(() => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);

      /*
       * Verificação para disparar o evento de mudança de página
       */
      onPageChange(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  /*
   * Evento para disparar quando mudar a quantidade de itens totais
   */
  useEffect(() => {
    setCurrentPage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countItems]);

  return (
    <div
      className={classNames([
        'd-flex  align-center common-body-2',
        countSelectedItems !== null ? 'justify-between' : 'justify-end',
        styles.bodyPagination,
        rest.className
      ])}
      {...rest}>
      {countSelectedItems !== null && (
        <div className="d-flex align-center">
          <span
            className={classNames(
              'title-heading-5 d-flex justify-center align-center',
              styles.countSelected
            )}>
            {countSelectedItems}
          </span>
          <span>{labelSelectedItems}</span>
        </div>
      )}
      <div className="d-flex justify-around align-center">
        <span>{labelRowsPerPage}</span>
        <Select
          onChange={(_event, value) => {
            onRowsPerPageSelectChange(value as number);
          }}
          slots={{
            root: PaginationButton
          }}
          slotProps={{
            root: {
              className: classNames(
                'd-flex justify-between align-center common-body-2',
                styles.paginationSelectButton
              )
            }
          }}
          defaultValue={rowsPerPage}
          value={rowsPerPageSelected}
          options={mapRowsPerPageOptions()}
          className={classNames(styles.selectOptions, 'common-body-2')}
        />
        <span>
          {firstRow} - {lastRow} {labelRangePage} {countItems}
        </span>
        <Button
          id="button-back"
          category="tertiary"
          disabled={currentPage === 1}
          className={classNames(styles.buttonPagination)}
          onClick={handleBack}>
          <MdKeyboardArrowLeft size={16} />
        </Button>
        <Button
          id="button-next"
          category="tertiary"
          className={classNames(styles.buttonPagination)}
          disabled={currentPage === lastPage}
          onClick={handleNext}>
          <MdKeyboardArrowRight size={16} />
        </Button>
      </div>
    </div>
  );
};

TablePagination.defaultProps = {
  pageIndex: 1,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100, { label: 'Todas', value: -1 }],
  countSelectedItems: null,
  labelSelectedItems: 'Selecionado(s)',
  labelRowsPerPage: 'Linhas por página',
  labelRangePage: 'de'
};

export default TablePagination;
