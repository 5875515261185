import { ICaptivePortal } from '../types';

export interface IPersonalizeForm {
  captivePortal: {
    pageTitle: string;
    pageText: string;
    urlRedirectionAfterLogin: string;
    header_image_url: string;
  };
}

export const firstCaptivePortal: ICaptivePortal = {
  name: 'Intelbras',
  redirect_url: 'http://www.intelbras.com.br/',
  preview_text: 'Bem-Vindo a página de login customizável da Intelbras'
};
