import React, { useEffect, useRef, useState } from 'react';
import styles from './Card.module.css';
import classNames from 'classnames';

const Card = ({
  width = '',
  height = '',
  background = '',
  shadow = 'md',
  focusable = false,
  rounded = 'sm',
  hoverable = false,
  borderColor = '',
  clickable = false,
  style = {},
  maxHeight = '',
  className = '',
  ...props
}) => {
  const cardElement = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const focusStyled = {
    border: '1px solid var(--card-outline)',
    boxShadow:
      shadow === 'lg'
        ? 'var(--shadow-level-2) rgba(0, 0, 0, 0.15)'
        : 'var(--shadow-level-4) rgba(0, 0, 0, 0.15)'
  };

  const styledProps = {
    width: width,
    height: height,
    maxHeight: maxHeight,
    background: background,
    border: borderColor.length ? `1px solid ${borderColor}` : 'none'
  };

  const generateStyles = () => {
    let styles = { ...style };
    if (isFocused) {
      styles = Object.assign(focusStyled, styles);
      styles = Object.assign(styles, styledProps);
      return styles;
    }
    return Object.assign(styles, styledProps);
  };

  const handleClick = (e) => {
    if (!focusable) return;
    try {
      if (cardElement.current && cardElement.current.contains(e.target)) {
        // inside of card click
        return;
      }
    } catch (error) {
      console.log('error on contains: ', error);
    }
    // outside of card click
    setIsFocused(false);
  };

  function focusCard() {
    if (!focusable) return;
    setIsFocused(!isFocused);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
  });

  return (
    <div
      {...props}
      className={classNames([
        [
          styles.cardContainer,
          hoverable ? styles[`shadow${shadow.toUpperCase()}`] : '',
          hoverable ? styles.hoverable : '',
          styles[`rounded${rounded.toUpperCase()}`],
          clickable ? styles.clickable : '',
          className
        ]
      ])}
      ref={cardElement}
      onMouseDownCapture={() => focusCard()}
      style={generateStyles()}>
      {props.children}
    </div>
  );
};

export default Card;
