import React from 'react';
import classNames from 'classnames';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Divider } from 'src/components/Divider';
import styles from './TextVisualizer.module.css';
import { ITextVisualizerProps } from './types';

const TextVisualizer = ({
  title,
  content,
  section,
  onBack
}: ITextVisualizerProps) => {
  return (
    <div>
      <div className="d-flex align-center">
        <button
          type="button"
          id="btn-back-to-all-helper-texts"
          aria-label="Voltar"
          className={classNames([styles.backToHelperDrawerBtn, 'mr-3'])}
          onClick={() => onBack()}>
          <MdKeyboardBackspace size={16} />
        </button>
        <span className={classNames([styles.sectionTitle, 'text-bold'])}>
          {section}
        </span>
      </div>
      <h3 className="title-sm-base ml-3 text-bold text-center mt-4">{title}</h3>
      <Divider color="var(--color-neutral-light-3)" />
      {content}
    </div>
  );
};

export default TextVisualizer;
