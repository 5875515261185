import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/useToast';
import Modal from 'ui-components/Modal';
import { IWireless } from '../WirelessTab/types';

interface IProps {
  show: boolean;
  selectedWireless: IWireless[];
  listName: string;
  onClose: () => void;
  setWirelessList: React.Dispatch<React.SetStateAction<IWireless[]>>;
  onDeleted: () => void;
}

const DeleteWirelessModal = ({
  show,
  selectedWireless,
  listName,
  onClose,
  setWirelessList,
  onDeleted
}: IProps) => {
  const { addToast } = useToast();
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists.add'
  });

  const removeWireless = (wirelessToRemove: IWireless[]) => {
    const toastMessage =
      wirelessToRemove.length === 1
        ? t('Wireless excluído com sucesso')
        : t('Wireless excluídos com sucesso');
    addToast('success', toastMessage);

    setWirelessList((wirelessList) =>
      wirelessList.filter(
        (customer) => !wirelessToRemove.some((c) => c.name === customer.name)
      )
    );

    onDeleted();
    onClose();
  };

  const renderModalMessage = () => {
    if (selectedWireless.length === 1) {
      return (
        <span>
          {t('O Wireless será removido da lista')}{' '}
          <span className="text-bold">{listName}</span>. {t('Deseja continuar')}
          ?
        </span>
      );
    }

    return (
      <span>
        <span className="text-bold">{selectedWireless.length} </span>
        {t('Wireless serão removido da lista')}{' '}
        <span className="text-bold">{listName}</span>. {t('Deseja continuar')}?
      </span>
    );
  };

  return (
    <Modal
      show={show}
      actions={[
        {
          label: t('NÃO'),
          action: onClose
        },
        {
          label: t('SIM'),
          action: () => removeWireless(selectedWireless)
        }
      ]}>
      {renderModalMessage()}
    </Modal>
  );
};

export default DeleteWirelessModal;
