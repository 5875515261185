import imageCompression from 'browser-image-compression';

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

interface PixelCrop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: PixelCrop,
  imageName: string,
  imageType: string,
  maxFileSizeInBytes: number
): Promise<File | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  if (imageType === 'image/png') {
    const MAX_FILE_SIZE_IN_MB = maxFileSizeInBytes / 1000 / 1000;
    const options = {
      maxSizeMB: MAX_FILE_SIZE_IN_MB,
      useWebWorker: true
    };

    return new Promise((resolve) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          resolve(null);
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const file = new File([blob!], imageName, { type: imageType });

        if (blob && blob.size <= maxFileSizeInBytes) {
          resolve(file);
        } else {
          const compressedFile = await imageCompression(file, options);
          resolve(compressedFile);
        }
      }, imageType);
    });
  }

  const quality = 1.0; // Iniciando com 100% de qualidade
  return new Promise((resolve) => {
    const attemptCompression = (newQuality: number) => {
      canvas.toBlob(
        (blob) => {
          if (blob && blob.size <= maxFileSizeInBytes) {
            const file = new File([blob], imageName, { type: imageType });
            resolve(file);
          } else if (newQuality > 0.1) {
            attemptCompression(newQuality - 0.1); // Reduz 10% de qualidade e tenta novamente
          } else {
            resolve(null);
          }
        },
        imageType,
        newQuality
      );
    };

    attemptCompression(quality);
  });
}

export const convertImageToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    const fileType = file.type || 'application/octet-stream';

    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result?.toString() || '';
      const base64WithType = base64.replace(
        'application/octet-stream',
        fileType
      );
      resolve(base64WithType);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getMimeTypeFromBase64 = (base64String: string) => {
  if (typeof base64String !== 'string') return '';

  const match = base64String.match(
    /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,.*/
  );
  return match ? match[1] : '';
};

export const removePrefixFromBase64 = (base64String: string) => {
  if (typeof base64String !== 'string') return '';

  if (base64String.includes('data:')) {
    return base64String.split('base64,')[1];
  }

  return base64String;
};

export const getSizeFromBase64 = (base64String: string) => {
  const base64WithoutPrefix = removePrefixFromBase64(base64String);
  const base64Length = base64WithoutPrefix.replace(/=/g, '').length;
  const sizeInBytes = (base64Length * 3) / 4;

  return sizeInBytes;
};
