import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';

interface IConfirmChangePasswordModal {
  showModal: boolean;
  isLoadingAction: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmChangePasswordModal = ({
  showModal,
  isLoadingAction,
  onCancel,
  onConfirm
}: IConfirmChangePasswordModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  return (
    <ConfirmModal
      isLoadingAction={isLoadingAction}
      showModal={showModal}
      onCancel={onCancel}
      onConfirm={onConfirm}>
      {t('Já existe uma senha em uso. Deseja substituir a atual?')}
    </ConfirmModal>
  );
};

export default ConfirmChangePasswordModal;
