import React, { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { InputRange } from '@conecte-se/ui-components-reactjs';
import '@conecte-se/ui-components-reactjs/dist/index.css'; // todo: move to index file after mvp
import '@conecte-se/ui-components-reactjs/src/styles/theme/external_lib_themes.scss'; // todo: move to index file after mvp
import { MdAdd, MdRemove } from 'react-icons/md';
import classNames from 'classnames';
import styles from './Range.module.css';

interface IProps {
  min: number;
  max: number;
  onChange: (value: number) => void;
  value: number;
  hasTooltip?: boolean;
  disabled?: boolean;
  id?: string;
  step?: number;
  isPercentage?: boolean;
}

const Range = ({
  min,
  max,
  onChange,
  value,
  hasTooltip,
  disabled,
  id,
  step,
  isPercentage
}: IProps) => {
  useEffect(() => {
    if (isPercentage) {
      const porcentage = ((value - min) * 100) / (max - min);

      const tooltip = document.getElementsByClassName('rc-tooltip-inner');

      if (tooltip[0]) {
        tooltip[0].innerHTML = `${Math.trunc(porcentage)}%`;
      }
    }
  }, [isPercentage, max, min, value]);

  return (
    <div
      className={classNames([
        'fit-width',
        isPercentage && styles.percentageContainer
      ])}>
      {isPercentage && (
        <MdRemove size={24} color="var(--color-neutral-dark-5)" />
      )}
      <InputRange
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        hasTooltip={hasTooltip}
        disabled={disabled}
        id={id}
        step={step}
      />
      {isPercentage && <MdAdd size={24} color="var(--color-neutral-dark-5)" />}
    </div>
  );
};

Range.defaultProps = {
  hasTooltip: false,
  disabled: false,
  id: '',
  step: 1,
  isPercentage: false
};

export default Range;
