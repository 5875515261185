import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import { Navigate } from 'react-router-dom';
import { IFinishWizardModal } from './types';

const FinishWizardModal = ({
  showFinishWizardModal,
  isLoadingWizardMutation,
  captiveNameType,
  onClose,
  onConfirm,
  customizeCaptiveClientPageFormData,
  captiveId,
  methodType
}: IFinishWizardModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const actions = [
    {
      label: t('CANCELAR'),
      action: () => onClose(),
      disabled: isLoadingWizardMutation
    },
    {
      label: t('CONTINUAR'),
      action: () => onConfirm(),
      isLoading: isLoadingWizardMutation
    }
  ];

  const [modalText, setModalText] = React.useState<string>(
    t(
      'O Captive Portal {{nameType}} será aplicado nos equipamentos deste ambiente. Enquanto este processo não for concluído, não será possível alterar as configurações deste método',
      {
        nameType: captiveNameType
      }
    )
  );

  useEffect(() => {
    if (methodType !== 'individual_record') return;

    setModalText(
      t(
        'O Captive Portal Cadastro Individual será aplicado nos equipamentos deste ambiente e os dados de cadastros antigos serão sobrepostos. Enquanto este processo não for concluído, não será possível alterar as configurações deste método'
      )
    );
  }, [methodType]);

  if (!customizeCaptiveClientPageFormData && !methodType && !captiveId) {
    return <Navigate to="/error/not_found" />;
  }

  return (
    <Modal actions={actions} show={showFinishWizardModal} width="450px">
      <p className="text-xl-base">{modalText}.</p>
    </Modal>
  );
};

export default FinishWizardModal;
