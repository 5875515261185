import classNames from 'classnames';
import React from 'react';
import styles from './Divider.module.css';

interface IDividerProps extends React.HTMLAttributes<HTMLDivElement> {
  orientation?: 'horizontal' | 'vertical';
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}

const Divider = ({
  orientation = 'horizontal',
  color,
  style,
  className,
  ...props
}: IDividerProps) => {
  return (
    <div
      {...props}
      style={{ background: color, ...style }}
      className={classNames([
        orientation === 'vertical'
          ? styles.verticalDivider
          : styles.horizontalDivider,
        className
      ])}
    />
  );
};

Divider.defaultProps = {
  orientation: 'horizontal',
  color: null,
  style: {}
};

export { Divider };
