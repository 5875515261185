import React, { useState } from 'react';
import { MdInfo } from 'react-icons/md';
import classNames from 'classnames';
import styles from './TitleAndDescription.module.css';
import { InfoModal } from './InfoModal';

interface ITitleAndDescriptionProps {
  title: string;
  description?: string | React.ReactNode;
  infoModal?: {
    title: string;
    description: string;
  };
}

const TitleAndDescription = ({
  title,
  description,
  infoModal
}: ITitleAndDescriptionProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.shellTitle}>
      <div
        className={classNames(['d-flex align-center', !description && 'mb-5'])}>
        <h2 className={classNames(['title-xl-base', styles.titles])}>
          {title}
        </h2>

        {!infoModal || (
          <>
            <span
              className={classNames([
                'd-flex align-center ml-4 mr-4',
                styles.iconInfo
              ])}
              aria-hidden="true"
              id="open-info-modal"
              onClick={() => setShowModal(true)}>
              <MdInfo size={24} color="var(--color-neutral-dark-4)" />
            </span>

            <InfoModal
              description={infoModal.description}
              setShowModal={setShowModal}
              showModal={showModal}
              title={infoModal.title}
            />
          </>
        )}
      </div>
      {!description || (
        <div className="mt-1 mb-5 mt-5 d-flex flex-column ">{description}</div>
      )}
    </div>
  );
};

TitleAndDescription.defaultProps = {
  infoModal: undefined,
  description: ''
};

export default TitleAndDescription;
