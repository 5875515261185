import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import stylesAdvancedWireless from '../AdvancedWireless.module.css';

const SmartHandoverSection = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.smart-handover'
  });
  const [showSmartHandoverModal, setShowSmartHandoverModal] = useState(false);

  const smartHandoverInfoModal = () => {
    return (
      <Modal
        title={t('Smart Handover')}
        show={showSmartHandoverModal}
        width="720px"
        closable
        onClose={() => setShowSmartHandoverModal(false)}>
        <div
          className={`text-sm-base pb-8 px-3 ${stylesAdvancedWireless.modalText}`}>
          <ul>
            <li>
              {t(
                'Funcionalidade recomendada para redes de pequeno porte, com quantidade máxima de 6 APs e 100 dispositivos (para redes maiores, utilize a função Fast Roaming).'
              )}
            </li>
            <li>
              {t('O handover será habilitado apenas para:')}
              <span className={stylesAdvancedWireless.itemList}>
                {t('Dispositivos dual band;')}
              </span>
              <span className={stylesAdvancedWireless.itemList}>
                {t(
                  'Grupo WLAN no qual o Access Point principal está vinculado.'
                )}
              </span>
            </li>
            <li>
              {t(
                'Ao habilitar o Smart Handover, seu AP ficará indisponível por alguns minutos para configuração da estrutura de rede (este tempo pode variar de 1 a 5 minutos, de acordo com a configuração da sua rede).'
              )}
            </li>
            <li>
              {t(
                "A função 'Não mostrar SSID' será desabilitada para todas as redes Wi-Fi."
              )}
            </li>
            <li>
              {t(
                "Certifique-se de que apenas um dos APs esteja configurado como 'Principal'."
              )}
            </li>
            <li>
              {t(
                "A função 'Mínimo sinal do cliente' passará a ser controlada pela função Smart Handover, que definirá o nível ideal para realizar a transição dos clientes entre APs."
              )}
            </li>
          </ul>
        </div>
      </Modal>
    );
  };

  return smartHandoverInfoModal();
};

export { SmartHandoverSection };
