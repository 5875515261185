import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithLabelForCaptiveForm } from '../InputWithLabelForCaptiveForm';

const SimplePasswordForm = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  return (
    <InputWithLabelForCaptiveForm
      id="input-password"
      label={t('Senha')}
      placeholder={t('Senha')}
      type="password"
      hasShowPassword
    />
  );
};

export { SimplePasswordForm };
