import React from 'react';
import classNames from 'classnames';
import styles from './Toggle.module.css';

function Toggle({
  id = '',
  checked = false,
  disabled = false,
  color = '',
  onChange = () => {}
}) {
  return (
    <label className={classNames([styles.switch, disabled && styles.disabled])}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span
        id={id}
        className={classNames([
          styles.slider,
          styles.round,
          color && styles.color
        ])}
      />
    </label>
  );
}

export default Toggle;
