import ReactGA from 'react-ga4';

export const initGA = () => {
  const GACODE = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;
  if ((GACODE !== undefined && GACODE.length === 0) || !GACODE) return;

  ReactGA.initialize(GACODE);
};

export const PageView = (title: string) => {
  if (process.env.REACT_APP_TYPE_ENV !== 'production') return;
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title
  });
};

export const eventGA = (category: string, action: string, label: string) => {
  if (process.env.REACT_APP_TYPE_ENV !== 'production') return;
  ReactGA.event({
    category,
    action,
    label
  });
};
