import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { Alert } from 'ui-components/Alert';
import { IDeleteRecordModal } from './types';
import styles from './DeleteIndividualRecordModal.module.css';

const DeleteIndividualRecordModal = ({
  showDeleteModal,
  countSelectedRecords,
  hasUsedRecords,
  hasSomeExpiredRecords,
  isLoadingDeleteMutation,
  onCloseDeleteModal,
  onDeleteRecord,
  isDeletingAllAvailableRecords
}: IDeleteRecordModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const getMessage = () => {
    const messages = {
      oneRecordWithUsedRecords: t(
        'Cadastro será excluído e os clientes conectados perderão acesso à rede. Deseja continuar?'
      ),
      oneRecord: t('Cadastro será excluído. Deseja continuar?'),
      multipleRecordsWithUsedRecords: t(
        'Cadastros serão excluídos e os clientes conectados perderão acesso à rede. Deseja continuar?'
      ),
      multipleRecords: t('Cadastros serão excluídos. Deseja continuar?'),
      allAvailableRecords: t(
        'Todos os cadastros disponíveis serão excluídos. Deseja continuar?'
      )
    };

    if (isDeletingAllAvailableRecords) {
      return messages.allAvailableRecords;
    }

    if (countSelectedRecords === 1) {
      return hasUsedRecords
        ? messages.oneRecordWithUsedRecords
        : messages.oneRecord;
    }

    return hasUsedRecords
      ? messages.multipleRecordsWithUsedRecords
      : messages.multipleRecords;
  };

  return (
    <ConfirmModal
      showModal={showDeleteModal}
      isLoadingAction={isLoadingDeleteMutation}
      typeButtonToConfirm="error"
      onCancel={onCloseDeleteModal}
      width={hasSomeExpiredRecords ? '496px' : undefined}
      height="auto"
      onConfirm={onDeleteRecord}>
      {!isDeletingAllAvailableRecords && (
        <span className={classNames(['text-bold'])}>
          {countSelectedRecords}{' '}
        </span>
      )}

      <span>{getMessage()}</span>

      {hasSomeExpiredRecords && (
        <div className={classNames(['mt-3', styles.alertIcon])}>
          <Alert type="warning">
            <span>
              {t(
                'Caso exista um ou mais cadastros em processamento, a exclusão não será imediata. Se necessário, considere reiniciar o equipamento'
              )}
              .
            </span>
          </Alert>
        </div>
      )}
    </ConfirmModal>
  );
};

DeleteIndividualRecordModal.defaultProps = {
  isDeletingAllAvailableRecords: false,
  hasUsedRecords: false,
  hasSomeRecordsWithWaitingWorkerStatus: false
};

export { DeleteIndividualRecordModal };
