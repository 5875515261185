import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import { IErrorWizardRequestModal } from './types';

const ErrorWizardRequestModal = ({
  showErrorRequestModal,
  isLoadingWizardMutation,
  onExit,
  onWait
}: IErrorWizardRequestModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.wizardCaptive'
  });

  const actions = [
    {
      label: t('Sair'),
      action: () => {
        onExit();
      },
      disabled: isLoadingWizardMutation
    },
    {
      label: t('Aguardar'),
      type: 'secondary',
      action: () => {
        onWait();
      },
      isLoading: isLoadingWizardMutation
    }
  ];
  return (
    <Modal
      title={t('Falha ao enviar requisição')}
      width="660px"
      show={showErrorRequestModal}
      actions={actions}>
      <span>
        {t(
          'Não foi possível iniciar a ativação do Captive Portal devido a uma instabilidade no nosso sistema. Deseja aguardar nessa página e tentar novamente, ou sair e configurar o Captive em outro momento?  Ao sair você perderá todas as informações salvas'
        )}
        .
      </span>
    </Modal>
  );
};

export { ErrorWizardRequestModal };
