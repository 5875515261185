import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Navbar } from 'src/components/Navbar';
import { INavbarActions } from 'src/components/Navbar/types';
import styles from './NavbarWithPlacesSelector.module.css';
import AddDeviceDrawer from '../MenuAndNavbar/AddDeviceDrawer';

interface ITemplateProps {
  children: ReactNode;
  navbarActions?: INavbarActions;
}
const NavbarWithPlacesSelectorTemplate = ({
  children,
  navbarActions
}: ITemplateProps) => {
  return (
    <>
      <AddDeviceDrawer />
      <Navbar hasLogo={false} actions={navbarActions} fixed canChangePlace />
      <div className={classNames([styles.templateWithoutMenuContent])}>
        {children}
      </div>
    </>
  );
};

NavbarWithPlacesSelectorTemplate.defaultProps = {
  navbarActions: {
    config: false,
    notification: false,
    avatar: false
  }
};

export { NavbarWithPlacesSelectorTemplate };
