import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useAuth } from 'src/hooks/useAuth';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IPasswordHistoricRow } from '../types';

interface IUpdateActivePasswordModal {
  showModal: boolean;
  selectedPassword: IPasswordHistoricRow;
  captiveId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const UpdateActivePasswordModal = ({
  showModal,
  selectedPassword,
  captiveId,
  onSuccess,
  onCancel
}: IUpdateActivePasswordModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  const { accessToken } = useAuth();

  const { addToast } = useToast();

  const updatePassword = (data: {
    siteId: string;
    placeId: string;
    captiveId: string;
    simplePasswordIdToRenew: string;
  }) => {
    return api.captivePortal.simplePassword.updateActivePassword(
      data.siteId,
      data.placeId,
      data.captiveId,
      data.simplePasswordIdToRenew
    );
  };

  const updatePasswordMutation = useMutation(updatePassword, {
    onSuccess: () => {
      addToast('success', `(1) ${t('Cadastro(s) renovado(s) com sucesso')}`);
      onSuccess();
    },
    onError: () => {
      onCancel();
    }
  });

  const onConfirm = () => {
    updatePasswordMutation.mutate({
      siteId: accessToken?.site_id || '',
      placeId: accessToken?.place_id || '',
      captiveId,
      simplePasswordIdToRenew: selectedPassword.id
    });
  };

  return (
    <ConfirmModal
      width="500px"
      height="auto"
      isLoadingAction={updatePasswordMutation.isLoading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      showModal={showModal}>
      {t(
        'Ao renovar, a senha irá ser restaurada de acordo com a configuração de dias ou horas que foi estabelecida anteriormente. Deseja continuar?'
      )}
    </ConfirmModal>
  );
};

export { UpdateActivePasswordModal };
