import React, { useCallback, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'ui-components/Pagination';
import Table from 'ui-components/Table';
import classNames from 'classnames';
import { ITableWithPaginationProps } from './types';
import styles from './TableWithPagination.module.css';

const TableWithPagination = <U,>({
  paginationData,
  rows,
  columns,
  keyToCompare,
  useKeyToCompareAsId,
  selectedRows,
  disableSelection,
  isToShowSkeletons,
  rowsPerPageOptions,
  minRowsToShowPagination,
  isFetchingData,
  firstFetchWasMade,
  paginationRequestData,
  isChangingPage,
  loadingTypeOfRowsPerPage,
  keyOfItemsOfPaginationTable,
  noSelection,
  hasStatus,
  onSelectRows,
  onPageChange,
  onRowsPerPageChange,
  renderSkeletonsOfTable,
  setPaginationRequestData,
  setIsChangingPage,
  setLoadingTypeOfRowsPerPage,
  setPaginationObject,
  className,
  ...props
}: ITableWithPaginationProps) => {
  const getRowsPerPageOptions = () => {
    if (rowsPerPageOptions?.length) return rowsPerPageOptions;
    return [10, 25, 50, 75, 100];
  };

  const canPaginationDisplay = () => {
    if (minRowsToShowPagination === undefined) return true;
    return paginationData?.total_filtered_items > minRowsToShowPagination;
  };

  const setLoadingVariables = useCallback(() => {
    if (isChangingPage) {
      setIsChangingPage(false);
    }
    if (loadingTypeOfRowsPerPage) {
      setLoadingTypeOfRowsPerPage({
        type: 'success',
        isFirstPage: true
      });
    }
  }, [
    isChangingPage,
    loadingTypeOfRowsPerPage,
    setIsChangingPage,
    setLoadingTypeOfRowsPerPage
  ]);

  const handleChangePage = (newPage: number) => {
    setIsChangingPage(true);
    setPaginationRequestData((prev) => ({
      ...prev,
      page_number: newPage
    }));
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const changeLoadingTypeOfRowsPerPage = (
    loadingType: 'success' | 'increasing' | 'cutting'
  ) => {
    setLoadingTypeOfRowsPerPage({
      type: loadingType,
      isFirstPage: paginationRequestData.page_number === 1
    });
  };

  const changeRowsPerPage = (newRows: number) => {
    setPaginationRequestData((prev) => ({
      ...prev,
      page_number: 1,
      page_size: newRows
    }));
    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRows);
    }
  };

  const handleChangeRowsPerPage = (newRows: number) => {
    if (
      newRows < paginationRequestData.page_size &&
      paginationData.current_page === 1
    ) {
      setPaginationObject((prev: { [x: string]: U[] }) => {
        const arrayOfPaginationItems = prev[keyOfItemsOfPaginationTable] as U[];
        arrayOfPaginationItems.length = newRows;
        prev[keyOfItemsOfPaginationTable] = arrayOfPaginationItems;
        return prev;
      });
      changeLoadingTypeOfRowsPerPage('cutting');
      changeRowsPerPage(newRows);
      return;
    }
    changeLoadingTypeOfRowsPerPage('increasing');
    changeRowsPerPage(newRows);
  };

  useEffect(() => {
    setLoadingVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationData]);

  const isToShowSkeletonsOnRowsPerPageChange =
    loadingTypeOfRowsPerPage.isFirstPage &&
    loadingTypeOfRowsPerPage.type === 'increasing';

  const isToShowSkeletonOnLoading =
    !firstFetchWasMade ||
    isChangingPage ||
    isFetchingData ||
    !!isToShowSkeletons ||
    !loadingTypeOfRowsPerPage.isFirstPage;

  return (
    <div
      className={classNames([styles.tableWithPagination, className])}
      {...props}>
      {isToShowSkeletonOnLoading && renderSkeletonsOfTable ? (
        <div className="d-flex flex-column">
          <Skeleton count={1} height={50} borderRadius={10} className="mb-3" />
          {renderSkeletonsOfTable(paginationRequestData.page_size as number)}
        </div>
      ) : (
        <Table
          hasStatus={hasStatus}
          noSelection={noSelection}
          columns={columns}
          rows={rows}
          onSelectRows={onSelectRows}
          keyToCompare={keyToCompare}
          useKeyToCompareAsId={useKeyToCompareAsId}
          selectedRows={selectedRows}
          disableSelection={disableSelection}
        />
      )}
      {isToShowSkeletonsOnRowsPerPageChange
        ? renderSkeletonsOfTable &&
          renderSkeletonsOfTable(
            (paginationRequestData.page_size as number) ||
              0 - paginationData.total_filtered_items ||
              0
          )
        : null}
      <div className="d-flex justify-end mt-3">
        {canPaginationDisplay() ? (
          <Pagination
            count={paginationData?.total_filtered_items}
            pageIndex={paginationRequestData.page_number}
            rowsPerPageOptions={getRowsPerPageOptions()}
            onPageChange={handleChangePage}
            rowsPerPage={paginationRequestData.page_size}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TableWithPagination;
