import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdSettings, MdSync } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { Divider } from 'src/components/Divider';
import { Alert } from 'ui-components/Alert';
import { Button } from 'ui-components/Button';
import Card from 'ui-components/Card';
import styles from './CardOfCaptivePortalType.module.css';

interface ICardOfCaptivePortalType {
  id?: string;
  nameType?: string;
  descriptionType?: string;
  statusMethod?: boolean;
  onClick: () => void;
  isFetchedCaptivePortal: boolean;
  isLoadingFormCaptive: boolean;
  showWarningFirmware?: boolean;
  noWirelessBlock?: boolean;
  hasActiveMethod?: boolean;
  disableCaptivePortalIsLoading?: boolean;
}

const CardOfCaptivePortalType = ({
  nameType,
  id,
  statusMethod,
  descriptionType,
  onClick,
  isFetchedCaptivePortal,
  isLoadingFormCaptive,
  showWarningFirmware,
  noWirelessBlock,
  hasActiveMethod,
  disableCaptivePortalIsLoading
}: ICardOfCaptivePortalType) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.setUpMethod.cardOfCaptivePortalType'
  });

  const getButtonContent = () => {
    if (statusMethod) {
      return (
        <>
          <MdSettings size={24} />
          <span className="ml-2">{t('Gerenciar')}</span>
        </>
      );
    }

    if (hasActiveMethod) {
      return (
        <>
          <MdSync size={24} />
          <span className="ml-2">{t('Trocar')}</span>
        </>
      );
    }

    return (
      <>
        <MdSettings size={24} />
        <span className="ml-2">{t('Definir')}</span>
      </>
    );
  };

  return (
    <Card
      borderColor={
        statusMethod
          ? 'var(--color-brand-primary-dark)'
          : 'var(--color-brand-secondary-light)'
      }
      className={classNames([
        'd-flex flex-column align-start fit-width fit-height pa-5 justify-between',
        styles.cardCointainer
      ])}
      key={id}>
      <div className="fit-width">
        <div className="fit-width d-flex justify-between align-center mb-5">
          <span
            className={classNames([
              'title-sm-base',
              statusMethod && styles.title
            ])}>
            {nameType}
          </span>
          {statusMethod && (
            <span
              className={classNames(styles.activeLabel, 'd-flex align-center')}>
              <div className={classNames(styles.ball, 'mr-2')} /> {t('Ativo')}
            </span>
          )}
        </div>
        <Divider />
        <div className="d-flex flex-column align-start justify-between mt-3 mb-4">
          <span className={showWarningFirmware ? 'mb-4' : ''}>
            {descriptionType}
          </span>
        </div>
      </div>
      <div>
        {isLoadingFormCaptive || !isFetchedCaptivePortal ? (
          <Skeleton height={50} width={100} />
        ) : (
          <div>
            {showWarningFirmware && (
              <div className="mb-2">
                <Alert type="warning" width="420px" classN>
                  {t(
                    'Há equipamento(s) neste ambiente com firmware desatualizado'
                  )}
                </Alert>
              </div>
            )}
            <Button
              ghost
              disabled={
                isLoadingFormCaptive ||
                noWirelessBlock ||
                disableCaptivePortalIsLoading
              }
              isLoading={!isFetchedCaptivePortal}
              className={classNames([
                statusMethod && styles.activeLabel,
                statusMethod && styles.buttonHoverColor
              ])}
              onClick={() => onClick()}>
              <span className={classNames(['d-flex align-center fit-width'])}>
                {getButtonContent()}
              </span>
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

CardOfCaptivePortalType.defaultProps = {
  nameType: '',
  descriptionType: '',
  statusMethod: false,
  id: '',
  showWarningFirmware: false,
  noWirelessBlock: false,
  hasActiveMethod: false,
  disableCaptivePortalIsLoading: false
};

export { CardOfCaptivePortalType };
