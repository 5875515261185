import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui-components/Alert';
import Toggle from 'ui-components/Toggle';
import { MdInfo } from 'react-icons/md';
import Modal from 'ui-components/Modal';
import { IAdvancedWirelessForm } from '../types';
import stylesAdvancedWireless from '../AdvancedWireless.module.css';

interface IMultiUserMIMOSectionProps {
  controlForm: Control<IAdvancedWirelessForm, unknown>;
}
const MultiUserMIMOSection = (props: IMultiUserMIMOSectionProps) => {
  const { controlForm: control } = props;
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.advanced-wireless.multiUserMIMO'
  });

  const [showMumimoModal, setShowMumimoModal] = useState(false);

  const mumimoModal = () => {
    return (
      <Modal
        title={t('Multi-User MIMO (MU-MIMO)')}
        show={showMumimoModal}
        width="720px"
        closable
        onClose={() => setShowMumimoModal(false)}>
        <p className="text-sm-base">
          {t(
            'O MU-MIMO garante maior performance aos clientes conectados na rede, realizando múltiplas transmissões de dados para clientes simultaneamente.'
          )}
        </p>
        <div className="my-2">
          <Alert type="warning">
            {t(
              'O MU-MIMO requer que o dispositivo do cliente tenha suporte a esta tecnologia para atuar ativamente'
            )}
          </Alert>
        </div>
      </Modal>
    );
  };

  return (
    <div className="d-flex mb-4">
      {mumimoModal()}
      <Controller
        control={control}
        name="mu_mimo"
        render={({ field: { onChange, value } }) => (
          <Toggle
            color="var(--color-status-ok-base)"
            checked={value}
            id="mu-mimo"
            onChange={onChange}
          />
        )}
      />
      <span className="d-flex flex-column">
        <div className="d-flex justify-center align-center">
          {t('Multi-User MIMO (MU-MIMO)')}
          <span
            className={`${stylesAdvancedWireless.iconContainer} d-flex justify-center align-center`}>
            <MdInfo
              size={20}
              className={`${stylesAdvancedWireless.icon} ml-1`}
              onClick={() => {
                setShowMumimoModal(true);
              }}
            />
          </span>
        </div>
        <span className={stylesAdvancedWireless.labelDetails}>
          {t('Disponível para os modelos Wave 2')}
        </span>
      </span>
    </div>
  );
};

export { MultiUserMIMOSection };
