import classNames from 'classnames';
import React from 'react';
import styles from './Indicator.module.css';

function Indicator({
  content,
  color = '',
  textColor = '',
  hasBorder = true,
  show = false,
  children,
  className,
  ...props
}) {
  return (
    <div className={styles.indicatorContainer}>
      {show ? (
        <div
          className={classNames([
            styles.indicatorContent,
            hasBorder ? styles.border : '',
            className
          ])}
          style={{
            '--color-indicator': textColor
              ? textColor
              : 'var(--color-neutral-light-5)',
            '--background-indicator': color
              ? color
              : 'var(--color-status-critical-base)'
          }}
          {...props}>
          {content}
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default Indicator;
