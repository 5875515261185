import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'inmaster-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import InputWithValidation from 'src/components/InputWithValidation';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IErrorResponse } from 'src/services/api/types';
import { IDataDeviceDetail } from 'src/services/api/urls/devices/types';
import { Button, ButtonIcon } from 'ui-components/Button';
import Tag from 'ui-components/Tags';
import DeleteDeviceModal from '../DeleteDeviceModal';
import ConfirmUpdateModal from '../DeviceDetails/ConfirmUpdateModal';
import ItemAndDescription from '../DeviceDetails/ItemAndDescription';
import {
  IDevicesInputsForm,
  ISelectedFirmwareVersion
} from '../DeviceDetails/types';
import UpdateFirmwareModal from '../DeviceDetails/UpdateFirmwareModal';
import RestartDeviceModal from '../RestartDeviceModal';
import { IDeviceRow } from '../types';
import styles from './HeaderDeviceDetails.module.css';
import { IHeaderDeviceDetails } from './types';

const HeaderDeviceDetails = ({
  selectedDevice,
  setUpdatedDevice,
  isFetchedAfterMountDeviceInfo,
  refetchDeviceInfo,
  hasUpdateFirmware,
  hasRebootDevice,
  hasConnectedClient,
  hasRX,
  hasWireless
}: IHeaderDeviceDetails) => {
  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);

  const [showRestartModal, setShowRestartModal] = useState(false);

  const [isRebooting, setIsRebooting] = useState(false);

  const [isEditingDeviceName, setIsEditingDeviceName] = useState(false);

  const [showUpdateFirmwareModal, setShowUpdateFirmwareModal] = useState(false);

  const [selectedFirmwareVersion, setSelectedFirmwareVersion] =
    useState<ISelectedFirmwareVersion | null>(null);

  const statusColors = {
    online: 'green',
    offline: 'red'
  };

  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.details'
  });

  const params = useParams();

  const navigate = useNavigate();

  const { addToast } = useToast();

  const { triggerNotificationAnimationWithText } = useNotification();

  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IDevicesInputsForm>({
    defaultValues: {
      device: { alias: '' }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const isNameOfDeviceChanged = useFormCompare({
    initialValues: { alias: selectedDevice?.device?.alias },
    currentValues: { alias: watch('device.alias') }
  });

  const updateSelectedDeviceMutation = useMutation(
    (data: IDataDeviceDetail) => {
      return api.devices.put(data, params.id as string);
    },
    {
      onSuccess: (data, { device }) => {
        setUpdatedDevice((prev) => {
          return {
            ...prev,
            device: {
              ...prev.device,
              alias: device.alias
            }
          };
        });
        setIsEditingDeviceName(false);
        refetchDeviceInfo();
        addToast('success', t('Nome do equipamento editado com sucesso'));
        triggerNotificationAnimationWithText(t('CONFIGURANDO EQUIPAMENTO'));
      },
      onError: (error: IErrorResponse) => {
        setIsEditingDeviceName(false);
        if (error.response.status === 304) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status === 400 &&
          error.response.data.message[0].attr.includes('frequency')
        ) {
          addToast('error', error.response.data.message[0].msg);
        } else {
          addToast(
            'error',
            t('Ocorreu um problema interno durante a atualização do nome')
          );
        }
      }
    }
  );

  const onSubmit = handleSubmit((data: IDevicesInputsForm) => {
    updateSelectedDeviceMutation.mutate(data as IDataDeviceDetail);
  });

  const handleOnDelete = () => {
    setShowDeleteDeviceModal(false);
    navigate('/devices');
  };

  useEffect(() => {
    setValue('device.alias', selectedDevice?.device?.alias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingDeviceName]);

  return (
    <>
      {hasUpdateFirmware && (
        <>
          <UpdateFirmwareModal
            onClose={() => setShowUpdateFirmwareModal(false)}
            show={showUpdateFirmwareModal}
            setSelectedFirmwareVersion={setSelectedFirmwareVersion}
            device={selectedDevice?.device}
          />

          <ConfirmUpdateModal
            show={selectedFirmwareVersion !== null}
            onClose={() => setSelectedFirmwareVersion(null)}
            equipamentName={selectedDevice?.device?.alias}
            selectedFirmwareVersion={selectedFirmwareVersion}
            onSucess={() => {
              setSelectedFirmwareVersion(null);
              refetchDeviceInfo();
            }}
          />
        </>
      )}
      <DeleteDeviceModal
        show={showDeleteDeviceModal}
        refetchPagination={refetchDeviceInfo}
        onClose={handleOnDelete}
        onCancel={() => setShowDeleteDeviceModal(false)}
        selectedDevices={[
          {
            alias: selectedDevice.device?.alias,
            firmware_version: selectedDevice.device?.firmware_version,
            id: selectedDevice.device?.id,
            mac: selectedDevice.device?.mac,
            type_equipment: selectedDevice.device?.type_equipment
          } as IDeviceRow
        ]}
        setDeviceIsDeleting={null}
      />

      {hasRebootDevice && (
        <RestartDeviceModal
          show={showRestartModal}
          onClose={() => setShowRestartModal(false)}
          onCancel={() => setShowRestartModal(false)}
          selectedDevices={[
            {
              alias: selectedDevice.device?.alias,
              firmware_version: selectedDevice.device?.firmware_version,
              id: selectedDevice.device?.id,
              mac: selectedDevice.device?.mac,
              type_equipment: selectedDevice.device?.type_equipment
            } as IDeviceRow
          ]}
          isRebooting={isRebooting}
          setIsRebooting={setIsRebooting}
        />
      )}

      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} className="d-flex align-end">
              {isFetchedAfterMountDeviceInfo ? (
                <div>
                  <img
                    width={155}
                    id="image-device"
                    src={selectedDevice?.device?.image}
                    alt="Foto do equipamento"
                  />
                </div>
              ) : (
                <Skeleton width={155} height={155} />
              )}
              <div className="d-flex flex-column ml-9 fit-width">
                {isEditingDeviceName ? null : (
                  <div className="d-flex justify-end">
                    {isFetchedAfterMountDeviceInfo ? (
                      <>
                        <Button
                          ghost
                          id="btn-back"
                          onClick={() => {
                            navigate('/devices');
                          }}>
                          {t('VOLTAR')}
                        </Button>
                        <Button
                          className="ml-2"
                          outline
                          id="btn-delete-device"
                          onClick={() => setShowDeleteDeviceModal(true)}>
                          {t('EXCLUIR')}
                        </Button>
                        {hasUpdateFirmware && (
                          <Button
                            className="ml-2"
                            secondary
                            id="btn-update-firmware-device"
                            disabled={
                              // selectedDevice?.device?.status === 'offline' ||
                              !isFetchedAfterMountDeviceInfo || isRebooting
                            }
                            onClick={() => setShowUpdateFirmwareModal(true)}>
                            {t('ATUALIZAR FIRMWARE')}
                          </Button>
                        )}
                        {hasRebootDevice && (
                          <Button
                            className="ml-2"
                            id="btn-reboot-device"
                            disabled={
                              // selectedDevice?.device?.status === 'offline' ||
                              !isFetchedAfterMountDeviceInfo || isRebooting
                            }
                            onClick={() => setShowRestartModal(true)}>
                            {isRebooting
                              ? t('REINICIANDO...')
                              : t('Reiniciar Equipamento')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Skeleton width={98} height={45} className="mr-2" />
                        <Skeleton width={101} height={45} className="mr-2" />
                        {hasUpdateFirmware && (
                          <Skeleton width={200} height={45} className="mr-2" />
                        )}
                        {hasRebootDevice && (
                          <Skeleton width={215} height={45} />
                        )}
                      </>
                    )}
                  </div>
                )}
                <div
                  className={classNames([
                    'mb-6 d-flex',
                    isEditingDeviceName ? 'align-start' : 'align-center'
                  ])}>
                  {isEditingDeviceName ? (
                    <form
                      onSubmit={onSubmit}
                      className={classNames([
                        'mb-6 d-flex fit-width',
                        isEditingDeviceName
                          ? 'align-start mt-6'
                          : 'align-center'
                      ])}>
                      <InputWithValidation
                        disabled={updateSelectedDeviceMutation.isLoading}
                        inputProps={{
                          itemType: 'text',
                          id: 'input-name-device'
                        }}
                        control={control}
                        controllerProps={{
                          name: 'device.alias',
                          rules: {
                            required: {
                              value: true,
                              message: t('Campo obrigatório')
                            },
                            maxLength: {
                              value: 32,
                              message: t(
                                'O nome do equipamento precisa ter entre 1 e 32 caracteres'
                              )
                            },
                            pattern: {
                              value: /^([a-zA-Z0-9 -]+)$/,
                              message: t(
                                'O nome do equipamento não pode conter caracteres especiais'
                              )
                            },
                            validate: {
                              notWhitespaceOnly: (value) =>
                                value.trim().length > 0 ||
                                (t('Campo obrigatório') as string),
                              notStartsOrEndsWithWhitespace: (value) =>
                                (!value.startsWith(' ') &&
                                  !value.endsWith(' ')) ||
                                (t(
                                  'O nome do equipamento não pode começar nem terminar com um espaço'
                                ) as string)
                            }
                          }
                        }}
                        errors={errors?.device?.alias}
                        placeholder={selectedDevice.device?.alias}
                      />
                      <div className="mt-2 d-flex ml-3">
                        <Button
                          ghost
                          disabled={updateSelectedDeviceMutation.isLoading}
                          onClick={() => {
                            setIsEditingDeviceName(false);
                          }}
                          id="bnt-cancel-edit-name">
                          {t('CANCELAR')}
                        </Button>
                        <Button
                          disabled={isNameOfDeviceChanged}
                          isLoading={updateSelectedDeviceMutation.isLoading}
                          className="ml-3"
                          type="submit"
                          id="btn-save-new-name">
                          {t('SALVAR')}
                        </Button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <h1
                        id="name-device"
                        className={classNames([
                          styles.deviceName,
                          'title-xl-base'
                        ])}>
                        {selectedDevice.device?.alias}
                      </h1>
                      {isFetchedAfterMountDeviceInfo ? (
                        <ButtonIcon
                          className="ml-3"
                          disabled={
                            // selectedDevice?.device?.status === 'offline' ||
                            !isFetchedAfterMountDeviceInfo
                          }
                          id="btn-edit-device-name"
                          onClick={() => {
                            clearErrors();
                            setIsEditingDeviceName(true);
                          }}>
                          <MdEdit size={24} />
                        </ButtonIcon>
                      ) : (
                        <Skeleton width={29} height={29} className="ml-3" />
                      )}
                    </>
                  )}
                </div>
                <div className={styles.deviceInfos}>
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={t('Versão de firmware')}
                    description={selectedDevice.device?.firmware_version}
                  />
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={t('Modelo')}
                    description={selectedDevice.device?.model}
                  />
                  {hasConnectedClient && (
                    <ItemAndDescription
                      isLoading={!isFetchedAfterMountDeviceInfo}
                      title={t('Clientes Conectados')}
                      description={String(
                        selectedDevice.device?.connected_clients
                      )}
                    />
                  )}
                  {hasRX && (
                    <ItemAndDescription
                      isLoading={!isFetchedAfterMountDeviceInfo}
                      title={t('Quantidade RX')}
                      description={String(
                        selectedDevice.device?.connected_clients
                      )}
                    />
                  )}
                  <ItemAndDescription
                    isLoading={!isFetchedAfterMountDeviceInfo}
                    title={t('Status')}
                    description={
                      <div className={styles.tags}>
                        <Tag
                          color={statusColors[selectedDevice.device?.status]}
                          wide>
                          {selectedDevice.device?.status}
                        </Tag>
                        {hasWireless &&
                        selectedDevice.wireless_list?.length === 0 ? (
                          <Tag color="wine" wide>
                            {t('sem wireless')}
                          </Tag>
                        ) : null}
                      </div>
                    }
                  />
                </div>
                {/* {selectedDevice?.device?.status === 'offline' ? (
                    <div className="mt-4">
                      <Alert type="warning">
                        {t(
                          'Equipamentos offline não podem ter suas configurações alteradas'
                        )}
                      </Alert>
                    </div>
                  ) : null} */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

HeaderDeviceDetails.defaultProps = {
  hasUpdateFirmware: false,
  hasRebootDevice: false,
  hasConnectedClient: false,
  hasRX: false,
  hasWireless: false
};

export { HeaderDeviceDetails };
