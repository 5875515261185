import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithLabelForCaptiveForm } from '../InputWithLabelForCaptiveForm';

const IndividualRegisterForm = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.formForPreviewCaptive'
  });

  return (
    <>
      <InputWithLabelForCaptiveForm
        id="input-doc"
        label={t('Número do documento')}
        placeholder={t('Nº de documento')}
      />
      <InputWithLabelForCaptiveForm
        className="mt-3"
        id="input-name"
        label={t('Nome')}
        placeholder={t('Nome')}
      />
      <InputWithLabelForCaptiveForm
        className="mt-3"
        id="input-email"
        label={t('E-mail')}
        placeholder={t('E-mail')}
        type="email"
      />
      <InputWithLabelForCaptiveForm
        className="mt-3"
        id="input-phone"
        label={t('Telefone')}
        placeholder={t('Telefone')}
        type="tel"
      />
    </>
  );
};

export { IndividualRegisterForm };
