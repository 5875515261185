import { IAccessToken } from '../urls/auth/types';

export function getAuthHeaders() {
  const accessTokenString = window.localStorage.getItem(
    '@manager/access_token'
  );
  const accessToken: IAccessToken =
    accessTokenString !== null ? JSON.parse(accessTokenString) : undefined;

  const headers = {
    Authorization: `Bearer ${accessToken.access_token}`
  };
  return headers;
}
