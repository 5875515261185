import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { IUpdateRecordModalProps } from './types';

const UpdateRecordModal = ({
  showUpdateRecordModal,
  qtySelectedRecords,
  isLoadingUpdateRecord,
  onCloseUpdateRecordModal,
  onConfirmUpdateRecords
}: IUpdateRecordModalProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  return (
    <ConfirmModal
      showModal={showUpdateRecordModal}
      height="auto"
      isLoadingAction={isLoadingUpdateRecord}
      onCancel={onCloseUpdateRecordModal}
      onConfirm={onConfirmUpdateRecords}>
      <span className={classNames(['text-bold'])}>{qtySelectedRecords}</span>{' '}
      {qtySelectedRecords === 1 ? (
        <span>
          {t(
            'Cadastro será renovado. Ao renovar, o cadastro irá ser restaurado de acordo com a configuração de dias ou horas que foi estabelecida anteriormente'
          )}
        </span>
      ) : (
        <span>
          {t(
            'Cadastros serão renovados. Ao renovar, os cadastros irão ser restaurados de acordo com as configurações de dias ou horas que foram estabelecidas anteriormente'
          )}
        </span>
      )}
      {'. '}
      <span>{t('Deseja continuar?')}</span>
    </ConfirmModal>
  );
};

export { UpdateRecordModal };
