import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'ui-components/Modal';
import { IDeviceAdoptionData } from '../types';

interface IModalIgnoreNodeWithErrorProps {
  ignoreNodeWithError: (deviceConfiguration: IDeviceAdoptionData) => void;
  show: boolean;
  handleRetryDeviceWithSomeError: (
    deviceConfiguration: IDeviceAdoptionData
  ) => void;
  currentDeviceWithError: IDeviceAdoptionData | null;
}

const ModalIgnoreNodeWithError = ({
  ignoreNodeWithError,
  show,
  handleRetryDeviceWithSomeError,
  currentDeviceWithError
}: IModalIgnoreNodeWithErrorProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'devices.drawer'
  });

  const actions = [
    {
      label: t('TENTAR NOVAMENTE'),
      action: () => {
        if (currentDeviceWithError) {
          handleRetryDeviceWithSomeError(currentDeviceWithError);
        }
      }
    },
    {
      label: t('IGNORAR'),
      action: () => {
        if (currentDeviceWithError) {
          ignoreNodeWithError(currentDeviceWithError);
        }
      },
      type: 'error'
    }
  ];

  return (
    <Modal actions={actions} show={show} width="450px">
      <p className="text-xl-base">
        {t(
          'Caso não realize a desautorização, os usuários autorizados ainda poderão ter acesso à rede, mesmo com o tempo do Captive Portal expirado.'
        )}
      </p>
    </Modal>
  );
};

export default ModalIgnoreNodeWithError;
