import { axiosInstance } from '../../axiosInstance';
import { getAuthHeaders } from '../../utils';
import { IRequestMethods } from '../../requestMethods';
import { IClientsData } from './types';

export class ClientsAPI implements IRequestMethods {
  url = '/hefesto/clients';

  get(params = {}) {
    return axiosInstance.get<IClientsData>(this.url, {
      headers: getAuthHeaders(),
      params
    });
  }
}
