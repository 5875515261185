import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/useToast';
import { ConfirmModal } from 'src/components/ConfirmModal';
import api from 'src/services/api';
import { useMutation } from 'react-query';
import { IPasswordHistoricRow } from '../types';

interface IDeletePasswordModal {
  show: boolean;
  onClose: () => void;
  selectedPasswords: IPasswordHistoricRow[];
  siteId: string;
  placeId: string;
  captivePortalId: string;
  refetchPasswordHistoric: () => void;
}

const DeletePasswordModal = ({
  show,
  selectedPasswords,
  siteId,
  placeId,
  captivePortalId,
  refetchPasswordHistoric,
  onClose
}: IDeletePasswordModal) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.simplePassword'
  });

  const { addToast } = useToast();

  const deletePasswordMutation = useMutation(
    (data: string[]) => {
      return api.captivePortal.simplePassword.deleteListOfSimplePasswords(
        siteId,
        placeId,
        captivePortalId,
        data
      );
    },
    {
      onSuccess: () => {
        addToast('success', t('Cadastro(s) excluído(s) com sucesso'));
        onClose();
        refetchPasswordHistoric();
      },
      onError: () => {
        addToast('error', t('Erro ao excluir cadastro(s)'));
      }
    }
  );

  const deletePassword = () => {
    const passwordsIds = selectedPasswords.map((password) => password.id);
    deletePasswordMutation.mutate(passwordsIds);
  };

  return (
    <ConfirmModal
      showModal={show}
      isLoadingAction={deletePasswordMutation.isLoading}
      onCancel={onClose}
      onConfirm={deletePassword}>
      <span className={classNames(['text-bold'])}>
        {selectedPasswords.length}
      </span>{' '}
      {selectedPasswords.length === 1 ? (
        <span>{t('Cadastro será excluído.')}</span>
      ) : (
        <span>{t('Cadastros serão excluídos.')}</span>
      )}{' '}
      <span>{t('Deseja continuar?')}</span>
    </ConfirmModal>
  );
};

export { DeletePasswordModal };
