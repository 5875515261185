import React from 'react';
import classNames from 'classnames';
import { MdCheck, MdHorizontalRule } from 'react-icons/md';
import styles from './Checkbox.module.css';
import type { ICheckbox } from './types';

export const Checkbox = ({
  id,
  checked,
  indeterminate,
  disabled,
  color,
  backgroundActive,
  background,
  ...rest
}: ICheckbox) => {
  const chooseCheckmarkCssByChecked = () => {
    if ((backgroundActive && checked) || indeterminate) {
      return {
        background: backgroundActive,
        borderColor: backgroundActive,
        color
      };
    }
    if (background && !checked) {
      return {
        background,
        color
      };
    }
    if (!background && backgroundActive && !checked) {
      return {
        borderColor: backgroundActive
      };
    }
    return {};
  };

  return (
    <label
      htmlFor={id}
      id={`label-${id}`}
      className={classNames([styles.container, disabled && styles.disabled])}>
      <input
        className={(indeterminate && styles.indeterminate) || ''}
        disabled={disabled}
        type="checkbox"
        checked={indeterminate || checked}
        id={id}
        {...rest}
      />
      <span
        id={`span-${id}`}
        className={classNames([styles.checkmark])}
        style={chooseCheckmarkCssByChecked()}>
        {checked && !indeterminate && <MdCheck style={{ color }} />}
        {indeterminate && <MdHorizontalRule style={{ color }} />}
      </span>
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  indeterminate: false,
  background: '',
  backgroundActive: '',
  color: ''
};
