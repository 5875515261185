import classNames from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { Col, Row } from 'inmaster-ui';
import {
  Control,
  Controller,
  FieldErrors,
  MultipleFieldErrors,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';
import Range from 'src/components/Range';
import { ICreateOrUpdateWireless } from 'src/services/api/urls/wireless/types';
import { Input } from 'ui-components/Input';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Toggle from 'ui-components/Toggle';
import styles from './AdvancedOption.module.css';
import { IsolationsOptions } from './IsolationOptions';

interface AdvancedOptionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<ICreateOrUpdateWireless, any>;
  isDisabledInputBecauseIsDisabledWireless: boolean;
  watch: UseFormWatch<ICreateOrUpdateWireless>;
  bandwidthDownloadObserver: string | null;
  bandwidthUploadObserver: string | null;
  validateNumberField: (message: string) => {
    startsWithZero: (value: string | number | null) => string | true;
    justIntegerNumbers: (value: string | number | null) => string | true;
  };
  trigger: UseFormTrigger<ICreateOrUpdateWireless>;
  getInputErrorMessage: (
    errorTypes: MultipleFieldErrors | undefined
  ) => JSX.Element | null;
  errors: FieldErrors<ICreateOrUpdateWireless>;
  isClientSensivityStandart: boolean;
  setIsClientSensivityStandart: (value: boolean) => void;
  setValue: UseFormSetValue<ICreateOrUpdateWireless>;
  resetField: UseFormResetField<ICreateOrUpdateWireless>;
}

const AdvancedOption = ({
  control,
  isDisabledInputBecauseIsDisabledWireless,
  watch,
  bandwidthDownloadObserver,
  bandwidthUploadObserver,
  validateNumberField,
  trigger,
  getInputErrorMessage,
  errors,
  isClientSensivityStandart,
  setIsClientSensivityStandart,
  setValue,
  resetField
}: AdvancedOptionProps) => {
  const isNullOrEmptyBandwidth = (
    download: null | string,
    upload: null | string
  ) => {
    const downloadIsNullOrEmpty = download === '' || download === null;
    const uploadIsNullOrEmpty = upload === '' || upload === null;

    return downloadIsNullOrEmpty && uploadIsNullOrEmpty;
  };

  const onVlanToggleChanges = (
    value: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...event: any[]) => void
  ) => {
    if (value) {
      resetField('wireless.vlan');
      setValue('wireless.vlan', null);
    }
    onChange(!value);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Label className="d-flex text-bold text-xl-lg mb-2">
            {t('Sensibilidade clientes')}
          </Label>
        </Col>
        <Col xs={12} className={`d-flex ${styles.columnsGap}`}>
          <Col xs={6}>
            <div>
              <p className="text-sm-base">
                {t('Limite de sensibilidade para desconectar cliente (dBm)')}
              </p>
            </div>
          </Col>
          <Col xs={5}>
            <div className="d-flex flex-column">
              <div className="d-flex ">
                <Toggle
                  color="var(--color-status-ok-base)"
                  checked={isClientSensivityStandart}
                  id="client-sensitivity-standart"
                  onChange={() =>
                    setIsClientSensivityStandart(!isClientSensivityStandart)
                  }
                  disabled={isDisabledInputBecauseIsDisabledWireless}
                />
                <span>{t('Sensibilidade padrão (-90 dBm)')}</span>
              </div>
              <div className="mt-4" style={{ maxWidth: 400 }}>
                <Controller
                  control={control}
                  name="wireless.sensibility"
                  render={({ field: { onChange, value } }) => (
                    <Range
                      id="client-sensitivity-range"
                      min={-100}
                      max={-20}
                      value={value}
                      disabled={isClientSensivityStandart}
                      onChange={onChange}
                      hasTooltip
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <hr className={classNames(['mt-7 mb-7', styles.dividerForm])} />
      <Row>
        <Col xs={12}>
          <Label className="d-flex text-bold text-xl-lg mb-2">
            {t('Ocultar Wireless')}
          </Label>
        </Col>
        <Col xs={12} className={`d-flex ${styles.columnsGap}`}>
          <Col xs={6}>
            <div>
              <p className="text-sm-base">
                {t(
                  'Oculte a visibilidade da Wireless aumentando sua privacidade e segurança.'
                )}
              </p>
            </div>
          </Col>
          <Col xs={5}>
            <div className="d-flex">
              <Controller
                control={control}
                name="wireless.hidden"
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    color="var(--color-status-ok-base)"
                    checked={value}
                    id="hidden-wireless"
                    onChange={onChange}
                    disabled={isDisabledInputBecauseIsDisabledWireless}
                  />
                )}
              />
              <span>
                {watch('wireless.hidden') ? t('Oculta') : t('Visível')}
              </span>
            </div>
          </Col>
        </Col>
      </Row>
      <hr className={classNames(['mt-7 mb-7', styles.dividerForm])} />
      <IsolationsOptions
        control={control}
        isDisabledInputBecauseIsDisabledWireless={
          isDisabledInputBecauseIsDisabledWireless
        }
      />
      <hr className={classNames(['mt-7 mb-7', styles.dividerForm])} />
      <Row>
        <Col xs={12}>
          <Label className="d-flex text-bold text-xl-lg mb-2">VLAN</Label>
        </Col>
        <Col xs={12} className={`d-flex ${styles.columnsGap}`}>
          <Col xs={6}>
            <div>
              <p className="text-sm-base">
                {t(
                  'Direcione o tráfego da sua Wireless para uma VLAN específica.'
                )}
              </p>
            </div>
          </Col>
          <Col xs={5}>
            <div className="d-flex mb-4">
              <Controller
                control={control}
                name="wireless.enableVlan"
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    color="var(--color-status-ok-base)"
                    checked={value}
                    id="enable-vlan"
                    onChange={() => onVlanToggleChanges(value, onChange)}
                    disabled={isDisabledInputBecauseIsDisabledWireless}
                  />
                )}
              />
              <span>
                {watch('wireless.enableVlan')
                  ? t('Habilitado')
                  : t('Desabilitado')}
              </span>
            </div>
            {watch('wireless.enableVlan') ? (
              <InputWrapper invalid={Boolean(errors?.wireless?.vlan)}>
                <Label>{t('VLAN')}:</Label>
                <Controller
                  control={control}
                  name="wireless.vlan"
                  rules={{
                    pattern: {
                      value: /^[+-]?\d+(\.\d+)?(,\d+)?$/,
                      message: t('O campo só aceita valores numéricos')
                    },
                    min: {
                      value: watch('wireless.vlan') === null ? 0 : 1,
                      message: t(
                        'O valor do campo deve ser um número inteiro entre 1 e 4094'
                      )
                    },
                    max: {
                      value: 4094,
                      message: t(
                        'O valor do campo deve ser um número inteiro entre 1 e 4094'
                      )
                    },
                    validate: validateNumberField(
                      t(
                        'O valor do campo deve ser um número inteiro entre 1 e 4094'
                      ) as string
                    ),
                    required: {
                      value: watch('wireless.enableVlan'),
                      message: t('Campo obrigatório')
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value || ''}
                      disabled={isDisabledInputBecauseIsDisabledWireless}
                      id="vlan-input"
                      placeholder={t('Insira um número entre 1 e 4094')}
                    />
                  )}
                />
                {getInputErrorMessage(errors?.wireless?.vlan?.types)}
              </InputWrapper>
            ) : (
              ''
            )}
          </Col>
        </Col>
      </Row>
      <hr className={classNames(['mt-7 mb-7', styles.dividerForm])} />
      <Row>
        <Col xs={12}>
          <Label className="d-flex text-bold text-xl-lg mb-2">
            {t('Limite de banda')}
          </Label>
        </Col>
        <Col xs={12} className={`d-flex ${styles.columnsGap}`}>
          <Col xs={6}>
            <div>
              <p className="text-sm-base">
                {t(
                  'Insira um limite de download e/ou upload para os cliente desta Wireless. Caso esteja desabilitado, a banda de rede será ilimitada.'
                )}
              </p>
            </div>
          </Col>
          <Col xs={5}>
            <div className="d-flex mb-4">
              <Controller
                control={control}
                name="wireless.band_limit"
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    color="var(--color-status-ok-base)"
                    checked={value}
                    id="band-limit"
                    onChange={onChange}
                    disabled={isDisabledInputBecauseIsDisabledWireless}
                  />
                )}
              />
              <span>
                {watch('wireless.band_limit')
                  ? t('Habilitado')
                  : t('Desabilitado')}
              </span>
            </div>
            {watch('wireless.band_limit') ? (
              <div>
                <InputWrapper
                  invalid={Boolean(errors?.wireless?.bandwidth_download)}>
                  <Label className="text-xl-xs">{t('Download')}:</Label>
                  <Controller
                    control={control}
                    name="wireless.bandwidth_download"
                    rules={{
                      required: {
                        value: isNullOrEmptyBandwidth(
                          bandwidthDownloadObserver,
                          bandwidthUploadObserver
                        ),
                        message: t(
                          'É obrigatório preencher pelo menos um dos campos, ou desabilitar a opção de limite de banda'
                        )
                      },
                      pattern: {
                        value: /^[+-]?\d+(\.\d+)?(,\d+)?$/,
                        message: t('O campo só aceita valores numéricos')
                      },
                      min: {
                        value:
                          watch('wireless.bandwidth_download') === null ? 0 : 1,
                        message: t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        )
                      },
                      max: {
                        value: 100,
                        message: t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        )
                      },
                      validate: validateNumberField(
                        t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        ) as string
                      )
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onChange(event);
                          setTimeout(() => {
                            trigger('wireless.bandwidth_upload');
                          }, 25);
                        }}
                        disabled={isDisabledInputBecauseIsDisabledWireless}
                        onBlur={onBlur}
                        value={value === null ? '' : value}
                        id="bandwidth-download-limit"
                        placeholder={t('Limite de download (Mbps)')}
                      />
                    )}
                  />
                  {getInputErrorMessage(
                    errors?.wireless?.bandwidth_download?.types
                  )}
                </InputWrapper>
                <InputWrapper
                  invalid={Boolean(errors?.wireless?.bandwidth_upload)}
                  className="mt-4">
                  <Label className="text-xl-xs">{t('Upload')}:</Label>
                  <Controller
                    control={control}
                    name="wireless.bandwidth_upload"
                    rules={{
                      required: {
                        value: isNullOrEmptyBandwidth(
                          bandwidthDownloadObserver,
                          bandwidthUploadObserver
                        ),
                        message: t(
                          'É obrigatório preencher pelo menos um dos campos, ou desabilitar a opção de limite de banda'
                        )
                      },
                      pattern: {
                        value: /^[+-]?\d+(\.\d+)?(,\d+)?$/,
                        message: t('O campo só aceita valores numéricos')
                      },
                      min: {
                        value:
                          watch('wireless.bandwidth_upload') === null ? 0 : 1,
                        message: t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        )
                      },
                      max: {
                        value: 100,
                        message: t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        )
                      },
                      validate: validateNumberField(
                        t(
                          'O valor do campo deve ser um número inteiro entre 1 e 100'
                        ) as string
                      )
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          onChange(event);
                          setTimeout(() => {
                            trigger('wireless.bandwidth_download');
                          }, 25);
                        }}
                        disabled={isDisabledInputBecauseIsDisabledWireless}
                        onBlur={onBlur}
                        value={value === null ? '' : value}
                        id="bandwidth-upload-input"
                        placeholder={t('Limite de upload (Mbps)')}
                      />
                    )}
                  />
                  {getInputErrorMessage(
                    errors?.wireless?.bandwidth_upload?.types
                  )}
                </InputWrapper>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedOption;
