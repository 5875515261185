import React from 'react';
import { Col, Row } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import { MdSearch } from 'react-icons/md';
import { IDevicePaginationTable, IDeviceRow } from 'src/pages/Devices/types';
import { IDevice } from 'src/services/api/urls/devices/types';
import { Alert } from 'ui-components/Alert';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import Table from 'ui-components/Table';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import { generateColumns } from 'src/utils/tableUtils';
import { IWirelessDevices } from '../types';

interface DeviceOptionProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  isFetchingSearch: boolean;
  devicesPagination: IDevicePaginationTable;
  setSelectedDevices: React.Dispatch<React.SetStateAction<IWirelessDevices[]>>;
  selectedDevices: IWirelessDevices[];
  isDisabledInputBecauseIsDisabledWireless: boolean;
  frequencyValue: string;
}

const DeviceOption = ({
  search,
  setSearch,
  isFetchingSearch,
  devicesPagination,
  setSelectedDevices,
  selectedDevices,
  isDisabledInputBecauseIsDisabledWireless,
  frequencyValue
}: DeviceOptionProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'site.wireless' });

  const columns = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('alias', t('Nome'), 'left', 0),
    generateColumns('mac', 'MAC', 'left', 0)
  ];

  const isResultNotFound =
    devicesPagination?.pagination?.total_filtered_items === 0 &&
    !isFetchingSearch;

  const formattedDevicesList = (devicesList: IDevice[]) => {
    return devicesList.map((device) => {
      return {
        ...device,
        disableRow:
          (device.frequency === '5' && frequencyValue === '2.4') ||
          (device.frequency === '2.4' && frequencyValue === '5')
      };
    });
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Label className="d-flex text-bold text-xl-lg mb-2">
            {t(
              'Selecione um ou mais equipamentos que irão transmitir esta Wireless'
            )}
          </Label>
          <Alert type="info">
            {t(
              'Verifique a frequência da Wireless e a capacidade de banda do seu equipamento para evitar incompatibilidades'
            )}
          </Alert>
        </Col>
        <Col xs={12} className="mt-5">
          <Row>
            <Label>{t('Pesquisar equipamento')}:</Label>
          </Row>
          <InputIconInside
            iconLeft={
              isFetchingSearch ? (
                <Loading value={40} indeterminate />
              ) : (
                <MdSearch />
              )
            }>
            <Input
              id="search-input"
              value={search}
              iconInsideLeft
              placeholder={t('Digite no mínimo 3 caracteres')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
            />
          </InputIconInside>
        </Col>
        <Col xs={12} className="mt-4">
          {isResultNotFound ? (
            <ResultsNotFoundMessage
              id="devices-wireless"
              minHeight="190px"
              height="calc(100vh - 500px)"
            />
          ) : (
            <Table
              columns={columns}
              rows={formattedDevicesList(devicesPagination.devices)}
              onSelectRows={(selectedRows: IDeviceRow[]) => {
                setSelectedDevices(
                  selectedRows.map((row) => {
                    return {
                      alias: row.alias,
                      id: row.id,
                      mac: row.mac,
                      frequency: row.frequency
                    } as IWirelessDevices;
                  })
                );
              }}
              selectedRows={selectedDevices}
              disableSelection={isDisabledInputBecauseIsDisabledWireless}
              elementToDisableRow="disableRow"
              textTooltipOfCheckbox={t(
                'Este equipamento não suporta essa wireless'
              )}
            />
          )}
          {/* <div className="d-flex justify-end mt-3">
              {devicesPagination.pagination?.total_filtered_items >= 10 ? (
                <Pagination
                  count={devicesPagination.pagination?.total_filtered_items}
                  pageIndex={page}
                  rowsPerPageOptions={[10, 25, 50, 75, 100]}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
            </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default DeviceOption;
