import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdGroup,
  MdRouter,
  MdWifi,
  MdBroadcastOnPersonal
} from 'react-icons/md';
import { useQuery } from 'react-query';
import { Navbar } from 'src/components/Navbar';
import { INavbarActions } from 'src/components/Navbar/types';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import api from 'src/services/api';
import { IAccessToken } from 'src/services/api/urls/auth/types';
import {
  IPlace,
  IPlaceResponseUnique
} from 'src/services/api/urls/sites/types';
import Sidebar from 'ui-components/Sidebar';
import { IErrorResponse } from 'src/services/api/types';
import { useNavigate } from 'react-router-dom';
import AddDeviceDrawer from './AddDeviceDrawer';

import styles from './MenuAndNavbarTemplate.module.css';

interface ITemplateProps {
  children: React.ReactNode;
  navbarActions?: INavbarActions;
}
const MenuAndNavbarTemplate = ({ children, navbarActions }: ITemplateProps) => {
  const { t } = useTranslation('translations', { keyPrefix: 'menu' });

  const navigate = useNavigate();

  const [accessToken] = useLocalStorage<IAccessToken | null>(
    '@manager/access_token'
  );

  const [isMenuMinimized, setIsMenuMinimized] = useLocalStorage<boolean>(
    '@manager/is_menu_minimized'
  );

  const [currentPlace, setCurrentPlace] = React.useState<IPlace>();

  const onSidebarClose = () => {
    setIsMenuMinimized(true);
  };

  const onSidebarOpen = () => {
    setIsMenuMinimized(false);
  };

  const defaultAPActions = [
    {
      label: t('Equipamentos'),
      icon: <MdRouter />,
      items: [
        {
          label: t('Lista de equipamentos'),
          url: '/devices'
        }
      ]
    },
    {
      label: t('Configuração de Wireless'),
      icon: <MdWifi />,
      items: [
        {
          label: t('Wireless'),
          url: '/wireless/'
        },
        {
          label: t('Opções Avançadas'),
          url: '/advanced-options'
        }
      ]
    },
    {
      label: t('Captive Portal'),
      icon: <MdBroadcastOnPersonal />,
      items: [
        {
          label: t('Intelbras'),
          url: '/captive-portal'
        }
      ]
    },
    {
      label: t('Clientes'),
      icon: <MdGroup />,
      items: [
        {
          label: t('Clientes Conectados'),
          url: '/customers'
        }
      ]
    }
  ];

  const sidebarActions = {
    CFTV: [
      {
        label: t('Equipamentos'),
        icon: <MdRouter />,
        items: [
          {
            label: t('Lista de equipamentos'),
            url: '/devices'
          }
        ]
      }
    ],
    AP: [...defaultAPActions]
  };

  const getDetailsOfCurrentPlace = () => {
    return api.sites.places.details.get(accessToken?.place_id as string);
  };

  const { isFetchedAfterMount } = useQuery(
    'getInfoCurrentPlace',
    getDetailsOfCurrentPlace,
    {
      onSuccess: ({ data }: { data: IPlaceResponseUnique }) => {
        setCurrentPlace(data.place);
      },
      onError: (error: IErrorResponse) => {
        if (error.response.status === 404) {
          navigate('/error/not_found');
        }
      }
    }
  );

  const checkDevicesType = () => {
    if (isFetchedAfterMount && currentPlace && currentPlace.devices_type) {
      return sidebarActions[currentPlace?.devices_type];
    }

    return sidebarActions.AP;
  };

  return (
    <>
      <AddDeviceDrawer />

      <Sidebar
        sidebarShouldStartMinimized={isMenuMinimized}
        onClose={onSidebarClose}
        onOpen={onSidebarOpen}
        actions={checkDevicesType()}
        isLoading={!isFetchedAfterMount}
        fixed
      />

      <Navbar
        actions={navbarActions}
        fixed
        hasMenu
        isMenuMinimized={isMenuMinimized}
        hasLogo={false}
        canChangePlace
      />
      <div
        className={classNames([
          styles.templateMenuContent,
          isMenuMinimized ? styles.templateMenuContentFull : null
        ])}>
        {children}
      </div>
    </>
  );
};

MenuAndNavbarTemplate.defaultProps = {
  navbarActions: {
    config: false,
    notification: false,
    avatar: false
  }
};

export { MenuAndNavbarTemplate };
