export const generateColumns = (
  field: string,
  headerName: string,
  align: 'left' | 'right' | 'center',
  width: number,
  invisible?: boolean
) => {
  return {
    field,
    headerName,
    align, // center || left || right
    width,
    invisible
  };
};
