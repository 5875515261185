import React from 'react';
import classNames from 'classnames';
import styles from './InputHelper.module.css';

const InputHelper = ({ value, icon = <></>, ...props }) => {
  return (
    <div className={classNames([styles.inputhelper])} {...props}>
      <span className={classNames([styles.inputhelpericon])}>{icon}</span>
      <span>{value}</span>
    </div>
  );
};

export default InputHelper;
