import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { generateColumns } from 'src/utils/tableUtils';
import Modal from 'ui-components/Modal';
import Table from 'ui-components/Table';
import styles from './CustomerAccessModal.module.css';
import { IAccessList } from './types';

interface IProps {
  show: boolean;
  onClose: () => void;
}

const CustomerAccessModal = ({ show, onClose }: IProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'site.accessLists'
  });

  const isFetchedAfterMount = true;

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex my-4 justify-between align-center py-4"
          key={`${i}-skeleton-wireless-table`}>
          <Skeleton count={1} height={14} width={100} className="mr-11" />
          <Skeleton
            count={1}
            height={14}
            width={100}
            className={styles.itemSkeleton}
          />
        </div>
      );
    }

    return skeletons;
  };

  const accessList: IAccessList[] = [
    {
      id: 1,
      name: 'Lista de acesso 1',
      type: 'block'
    },
    {
      id: 2,
      name: 'Lista de acesso 2',
      type: 'permission'
    },
    {
      id: 3,
      name: 'Lista de acesso 3',
      type: 'permission'
    },
    {
      id: 4,
      name: 'Lista de acesso 4',
      type: 'permission'
    },
    {
      id: 5,
      name: 'Lista de acesso 5',
      type: 'permission'
    },
    {
      id: 6,
      name: 'Lista de acesso 6',
      type: 'permission'
    }
  ];

  const columnsTableAccess = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('listName', t('Nome'), 'left', 0),
    generateColumns('listType', t('Tipo'), 'left', 0)
  ];

  const generateRows = (accessListToFormat: IAccessList[]) => {
    return accessListToFormat.map((list: IAccessList) => {
      return {
        ...list,
        listName: (
          <span
            id={` 
          list-name-${list.id}-${list.name}
        `}
            className="text-bold">
            {list.name}
          </span>
        ),
        listType: (
          <span
            id={` 
          list-name-${list.id}-${list.type}
        `}>
            {list.type === 'block' ? t('Bloqueio') : t('Permissão')}
          </span>
        )
      };
    });
  };

  return (
    <Modal
      closable={false}
      show={show}
      title={t('Listas de acesso do cliente')}
      width="700px"
      actions={[
        {
          label: t('FECHAR'),
          action: onClose,
          primary: true
        }
      ]}>
      <span className={styles.subtitle}>
        {t('Atualmente o cliente pertence as seguintes Listas de acesso')}:
      </span>

      <div className={styles.modalContent}>
        <div className="mt-4">
          {isFetchedAfterMount ? (
            <Table
              noSelection
              columns={columnsTableAccess}
              rows={generateRows(accessList)}
            />
          ) : (
            <div>
              <Skeleton
                count={1}
                height={45}
                borderRadius={10}
                className="mb-2"
              />
              {renderSkeletonsOfTable(3)}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomerAccessModal;
