import React, { SetStateAction } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { IconType } from 'react-icons/lib';
import styles from './PasswordVisibilityIcon.module.css';

interface IPasswordVisibilityIcon extends IconType {
  id?: string;
  size?: number | string | undefined;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  showPassword: boolean;
  disabled?: boolean;
}

const PasswordVisibilityIcon = ({
  id,
  size,
  setShowPassword,
  showPassword,
  disabled = false,
  ...rest
}: IPasswordVisibilityIcon) => {
  if (showPassword) {
    return (
      <MdVisibilityOff
        {...rest}
        id={id}
        className={disabled ? styles.disabled : styles.active}
        size={size}
        onClick={() => (disabled ? null : setShowPassword(!showPassword))}
      />
    );
  }
  return (
    <MdVisibility
      {...rest}
      id={id}
      size={size}
      className={disabled ? styles.disabled : styles.active}
      onClick={() => (disabled ? null : setShowPassword(!showPassword))}
    />
  );
};

PasswordVisibilityIcon.defaultProps = {
  disabled: false,
  id: '',
  size: undefined
};

export { PasswordVisibilityIcon };
