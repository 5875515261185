// eslint-disable-next-line no-shadow
export enum RoleMember {
  GUEST = 'guest',
  MANAGER = 'manager',
  OWNER = 'owner'
}

export interface IDataMember {
  member: {
    id?: string;
    email: string;
    role: RoleMember;
  };
}
