import { axiosInstance } from '../../axiosInstance';
import { IRequestMethods } from '../../requestMethods';
import { getAuthHeaders } from '../../utils';
import { IDataDeviceStatistics } from './types';

export class StatisticsAPI implements IRequestMethods {
  url = 'statistics/';

  get(params = [] as string[]) {
    const urlString = `${this.url}?ids=${params.join('&ids=')}`;
    return axiosInstance.get<IDataDeviceStatistics>(urlString, {
      headers: getAuthHeaders(),
      params
    });
  }
}
