import React from 'react';
import classNames from 'classnames';
import styles from './Loading.module.css';
import type { ILoading } from './types';

const Loading = ({
  value = 30,
  indeterminate = true,
  colors = {
    inside: 'var(--helper-base)',
    outside: 'var(--helper-light)'
  },
  width = 24,
  height = 24,
  radius = 7.5,
  id = '',
  className = '',
  ...props
}: ILoading) => {
  const [circumference, setCircumference] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [offsetInside, setOffsetInside] = React.useState(0);

  React.useEffect(() => {
    setCircumference(radius * 2 * Math.PI);
    setOffset(circumference - (100 / 112) * circumference);
    setOffsetInside(circumference - (value / 112) * circumference);
  }, [value, circumference, radius]);

  return (
    <div
      {...props}
      id={id}
      className={classNames(['d-flex justify-center align-center', className])}>
      <svg width={width} height={height}>
        <circle
          className={classNames([
            styles.circle,
            indeterminate ? styles.indeterminate : ''
          ])}
          fill="transparent"
          stroke="white"
          strokeWidth="5"
          r={radius}
          cx={width / 2}
          cy={height / 2}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            stroke: colors.outside || 'var(--loading-bg)'
          }}
        />
        <circle
          className={classNames([
            styles.circleInside,
            indeterminate ? styles.indeterminateInside : ''
          ])}
          fill="transparent"
          stroke="white"
          strokeWidth="5"
          r={radius}
          cx={width / 2}
          cy={height / 2}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offsetInside,
            stroke: colors.inside || 'var(--loading-inside-bg)'
          }}
        />
      </svg>
    </div>
  );
};

Loading.defaultProps = {
  value: 30,
  indeterminate: true,
  colors: {
    inside: 'var(--helper-base)',
    outside: 'var(--helper-light)'
  },
  width: 24,
  height: 24,
  radius: 7.5,
  id: '',
  className: ''
};

export default Loading;
