import React, { useRef, useState } from 'react';
import { useCombobox } from 'downshift';

import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';
import { MdArrowDropDown, MdArrowDropUp, MdSearch } from 'react-icons/md';
import { InputIconInside } from 'ui-components/InputWrapper';
import { Input } from 'ui-components/Input';
import styles from './DropdownWithSearch.module.css';

export interface IDropdownItem {
  id?: string;
  label: string;
  value: string;
}

type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T;
  relatedTarget: {
    id: string;
    getAttribute: (value: string) => string;
  };
};

interface DropdownWithSearchProps {
  options: IDropdownItem[];
  placeholder: string;
  onChange: (value: IDropdownItem) => void;
  value?: IDropdownItem;
  onBlur?: () => void;
  id?: string;
  borderless?: boolean;
  isTitle?: boolean;
  onSearchChange?: (value: string) => void;
  searchPlaceholder?: string;
  searchValue?: string;
}

const DropdownWithSearch = ({
  options,
  onChange,
  placeholder,
  onBlur,
  value,
  id,
  borderless,
  isTitle,
  onSearchChange,
  searchPlaceholder,
  searchValue,
  ...props
}: DropdownWithSearchProps) => {
  const [showItems, setShowItems] = useState(false);
  const shellSelectItemsRef = useRef<HTMLDivElement>(null);

  const handleChangeItem = (selectedItem: IDropdownItem | null | undefined) => {
    if (selectedItem) {
      onChange(selectedItem);
    }
    setShowItems(false);
  };

  const closeMenu = () => {
    setShowItems(false);
    if (onBlur) onBlur();
  };

  const {
    getMenuProps,
    highlightedIndex,
    getInputProps,
    getItemProps,
    getComboboxProps
  } = useCombobox({
    items: options,
    onSelectedItemChange: ({ selectedItem }) => handleChangeItem(selectedItem),
    onIsOpenChange: ({ isOpen }) => (!isOpen ? closeMenu() : null)
  });

  return (
    <div className={classNames([styles.shellSelect, 'ml-4'])}>
      <button
        {...props}
        className={classNames([
          styles.select,
          borderless ? styles.borderless : null,
          isTitle ? styles.title : null,
          showItems ? styles.selectActive : null
        ])}
        onClick={() => {
          if (shellSelectItemsRef.current) {
            shellSelectItemsRef.current.focus();
          }
          setShowItems(!showItems);
        }}
        id={id}
        type="button">
        <div>
          {value ? (
            <span>{value.label}</span>
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
        </div>
        <div className={styles.iconOfSelect}>
          {showItems ? (
            <MdArrowDropUp size={24} />
          ) : (
            <MdArrowDropDown size={24} />
          )}
        </div>
      </button>

      <CSSTransition
        in={showItems}
        timeout={3}
        classNames={{
          enterActive: styles.shellSelectItemsEnterActive,
          enterDone: styles.shellSelectItemsEnterDone,
          exitActive: styles.shellSelectItemsEnterExitActive,
          exitDone: styles.shellSelectItemsDrawerExitDone
        }}
        nodeRef={shellSelectItemsRef}>
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          onBlur={(e: HTMLElementEvent<HTMLDivElement>) => {
            if (
              (e.relatedTarget &&
                e.relatedTarget.id !== 'search-input-dropdown' &&
                e.relatedTarget.getAttribute('role') !== 'combobox') ||
              !e.relatedTarget
            ) {
              closeMenu();
            }
          }}
          className={styles.shellSelectItems}
          {...getComboboxProps({ ref: shellSelectItemsRef })}>
          <InputIconInside iconLeft={<MdSearch />}>
            <Input
              {...getInputProps({
                id: 'search-input-dropdown'
              })}
              value={searchValue}
              iconInsideLeft
              className="mb-3"
              placeholder={searchPlaceholder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (onSearchChange) onSearchChange(e.target.value);
              }}
            />
          </InputIconInside>
          <ul {...getMenuProps({})}>
            {options.map((item, index) => (
              <li
                className={classNames([
                  styles.selectItem,
                  highlightedIndex === index ? styles.selectItemActive : null
                ])}
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.value}-${index}`}
                {...getItemProps({ item, index })}
                id={item.id ? item.id : getItemProps({ item, index }).id}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownWithSearch.defaultProps = {
  value: {},
  id: '',
  onBlur: undefined,
  borderless: false,
  isTitle: false,
  onSearchChange: undefined,
  searchPlaceholder: '',
  searchValue: ''
};

export default DropdownWithSearch;
