import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { FieldError, MultipleFieldErrors } from 'react-hook-form';
import { MdCalendarToday, MdCancel } from 'react-icons/md';
import { Input } from 'ui-components/Input';
import InputHelper from 'ui-components/InputHelper';
import { InputIconInside, InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import styles from './styles.module.css';

interface InputOfDatePickerProps {
  valueToDisplay: string;
  onClick: () => void;
  error: FieldError | undefined;
}

const InputOfDatePicker = forwardRef(
  (
    { valueToDisplay, error, onClick }: InputOfDatePickerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const checkIfNeedToShowPlaceholder = () => {
      return valueToDisplay.includes('Dia');
    };

    const getInputErrorMessage = (
      errorTypes: MultipleFieldErrors | undefined
    ) => {
      if (!errorTypes) {
        return null;
      }

      let message;

      if (errorTypes.required) {
        message = errorTypes.required;
      } else if (errorTypes.notDates) {
        message = errorTypes.notDates;
      }

      return (
        <InputHelper
          id="error-message"
          icon={<MdCancel />}
          value={String(message)}
        />
      );
    };

    const testError = (_errors: any) => {
      const typesErrorsOfPeriod = _errors?.types as MultipleFieldErrors;
      return typesErrorsOfPeriod;
    };

    return (
      <div ref={ref} className="fit-width">
        <InputWrapper invalid={Boolean(error)}>
          <Label>Tempo</Label>
          <InputIconInside iconRight={<MdCalendarToday />}>
            <Input
              className={classNames([
                styles.inputOfDatePicker,
                checkIfNeedToShowPlaceholder() ? null : styles.placeholder
              ])}
              id="search-input"
              readOnly
              iconInsideRight
              onClick={onClick}
              value={valueToDisplay}
            />
          </InputIconInside>
          {getInputErrorMessage(testError(error))}
        </InputWrapper>
      </div>
    );
  }
);

InputOfDatePicker.displayName = 'InputOfDatePicker';

export default InputOfDatePicker;
