import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ISiteData, ISiteItemPage, ModalsTypesOfSites } from '../types';
import { MembersDrawer } from '../MembersDrawer';
import { ModalRemoveSite } from '../ModalRemoveSite';
import { ModalCreateOrEditSite } from '../ModalCreateOrEditSite';
import { ModalExitSharedSite } from '../ModalExitSharedSite';
import { queryClient } from '../../../services/queryClient';
import { useAuth } from '../../../hooks/useAuth';

interface IProps {
  activeModalType: ModalsTypesOfSites;
  onCreatingSite?: (site: ISiteData) => void;
  onDeleteSite?: (isSharedSite: boolean) => void;
  selectedSite: ISiteItemPage | null;
  setActiveModalType: React.Dispatch<React.SetStateAction<ModalsTypesOfSites>>;
  setIsAddingSite?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSite?: React.Dispatch<React.SetStateAction<ISiteItemPage | null>>;
  setSharedSites?: React.Dispatch<React.SetStateAction<ISiteItemPage[]>>;
  setSiteIdBeingEditedOrDeleted?: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setSites?: React.Dispatch<React.SetStateAction<ISiteItemPage[]>>;
}

const SiteOptionsModalsAndDrawers = ({
  setSharedSites,
  setSites,
  setActiveModalType,
  activeModalType,

  selectedSite,
  setSelectedSite,
  setSiteIdBeingEditedOrDeleted,
  onCreatingSite,
  setIsAddingSite,
  onDeleteSite
}: IProps) => {
  const { accessToken } = useAuth();

  const navigate = useNavigate();

  const refetchOwnerSitesAndSharedSites = () => {
    queryClient.refetchQueries(['sitesListQuery', accessToken?.user_id]);
    queryClient.refetchQueries(['sharedSitesListQuery']);
  };

  const handleDeleteSite = (isSharedSite: boolean) => {
    if (onDeleteSite) return onDeleteSite(isSharedSite);
    return navigate('/sites');
  };

  return (
    <>
      <MembersDrawer
        initMembersCount={selectedSite?.user_count ?? 0}
        selectedSite={selectedSite}
        showMembersDrawer={activeModalType === 'members'}
        onClose={() => setActiveModalType(null)}
        refetchOwnerSitesAndSharedSites={refetchOwnerSitesAndSharedSites}
      />

      <ModalRemoveSite
        setSites={setSites}
        setSelectedSite={setSelectedSite}
        selectedSite={selectedSite}
        showModalDeleteSite={activeModalType === 'delete'}
        setActiveModalType={setActiveModalType}
        onDeleteSite={handleDeleteSite}
        setSiteIdBeingEditedOrDeleted={setSiteIdBeingEditedOrDeleted}
      />

      <ModalCreateOrEditSite
        activeModalType={activeModalType}
        setActiveModalType={setActiveModalType}
        selectedSite={selectedSite}
        show={activeModalType === 'create' || activeModalType === 'edit'}
        onCreatingSite={onCreatingSite}
        setIsAddingSite={setIsAddingSite}
        setSiteIdBeingEditedOrDeleted={setSiteIdBeingEditedOrDeleted}
        setSites={setSites}
        setSelectedSite={setSelectedSite}
        setSharedSites={setSharedSites}
      />

      <ModalExitSharedSite
        showModalConfirmSharedSiteExit={activeModalType === 'exitSharedSit'}
        sharedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        setActiveModalType={setActiveModalType}
        onDeleteSharedSite={handleDeleteSite}
        memberId={selectedSite?.member?.id}
        setSharedSites={setSharedSites}
      />
    </>
  );
};

SiteOptionsModalsAndDrawers.defaultProps = {
  onCreatingSite: undefined,
  setSelectedSite: undefined,
  onDeleteSite: undefined,
  setSharedSites: undefined,
  setSiteIdBeingEditedOrDeleted: undefined,
  setIsAddingSite: undefined,
  setSites: undefined
};

export default SiteOptionsModalsAndDrawers;
