import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import InputWithValidation from 'src/components/InputWithValidation';
import SelectWithValidation from 'src/components/SelectWithValidation';
import { useAuth } from 'src/hooks/useAuth';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { useToast } from 'src/hooks/useToast';
import api from 'src/services/api';
import { IDataSite } from 'src/services/api/urls/sites/types';
import { timezones } from 'src/utils/timezones';
import Modal from 'ui-components/Modal';
import { IErrorResponse } from 'src/services/api/types';
import {
  IResponsePostSiteData,
  ISiteData,
  ISiteInfos,
  ISiteItemPage,
  ModalsTypesOfSites
} from '../types';
import { queryClient } from '../../../services/queryClient';

interface IModalCreateOrEditSite {
  activeModalType: ModalsTypesOfSites;
  selectedSite?: ISiteItemPage | null;
  setActiveModalType: React.Dispatch<React.SetStateAction<ModalsTypesOfSites>>;
  show: boolean;
  onCreatingSite?: (site: ISiteData) => void;
  setIsAddingSite?: Dispatch<SetStateAction<boolean>>;
  setSiteIdBeingEditedOrDeleted?: Dispatch<SetStateAction<string | null>>;
  setSites?: Dispatch<SetStateAction<ISiteItemPage[]>>;
  setSharedSites?: Dispatch<SetStateAction<ISiteItemPage[]>>;
  setSelectedSite?:
    | React.Dispatch<React.SetStateAction<ISiteItemPage | null>>
    | undefined;
}

const ModalCreateOrEditSite = (props: IModalCreateOrEditSite) => {
  const { accessToken } = useAuth();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [showStepCreatePlace, setShowStepCreatePlace] = useState(false);

  const { addToast } = useToast();

  const { resetSession } = useAuth();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const {
    activeModalType,
    setActiveModalType,
    selectedSite,
    show,
    onCreatingSite,
    setIsAddingSite,
    setSiteIdBeingEditedOrDeleted,
    setSites,
    setSharedSites,
    setSelectedSite
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IDataSite>({
    defaultValues: {
      site: {
        name: '',
        timezone: ''
      },
      place: {
        name: ''
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const onEditingSite = () => {
    queryClient.refetchQueries(['sitesListQuery', accessToken?.user_id]);
    queryClient.refetchQueries(['sharedSitesListQuery']);

    const isPlacesOptions = !setSites;
    if (isPlacesOptions) {
      queryClient.refetchQueries(['selectedSiteQuery', selectedSite?.id]);
    }
  };

  const executeOnCreatingSiteIfExists = (site: ISiteData) => {
    if (onCreatingSite) {
      onCreatingSite(site);
    }
  };

  const updateSharedSite = (data: IResponsePostSiteData) => {
    if (setSharedSites) {
      setSharedSites((prevSites) => {
        return prevSites.map((site) => {
          if (site.id === selectedSite?.id) {
            return {
              ...site,
              name: data.site.name,
              timezone: data.site.timezone
            };
          }

          return site;
        });
      });
    }
  };

  const updateSites = (data: IResponsePostSiteData) => {
    if (setSites) {
      setSites((prevSites) => {
        return prevSites.map((site) => {
          if (site.id === selectedSite?.id) {
            return {
              ...site,
              name: data.site.name,
              timezone: data.site.timezone
            };
          }

          return site;
        });
      });
    }
  };

  const resetIsAddingSite = () => {
    if (setIsAddingSite) {
      setIsAddingSite(false);
    }
  };

  const addSiteMutation = useMutation(
    (data: IDataSite) => {
      if (activeModalType === 'create') {
        return api.sites.post(data);
      }

      return api.sites.put(data, selectedSite?.id || '');
    },
    {
      onSuccess: ({ data }: { data: IResponsePostSiteData }) => {
        reset();
        if (activeModalType === 'create') {
          addToast('success', t('Local criado com sucesso'));
          executeOnCreatingSiteIfExists(data.site);
        } else {
          if (selectedSite?.member?.role === 'manager') {
            updateSharedSite(data);
          } else {
            updateSites(data);
          }

          addToast('success', t('Local editado com sucesso'));
          onEditingSite();
        }
        setActiveModalType(null);
        setShowStepCreatePlace(false);
        setDisableSubmitButton(false);
      },
      onError: (error: IErrorResponse) => {
        setDisableSubmitButton(false);
        resetIsAddingSite();
        if (error.response.status === 401) {
          resetSession();
        } else if (error.response.status === 500) {
          addToast(
            'error',
            t(
              'Estamos passando por uma instabilidade, tente novamente mais tarde'
            )
          );
        } else if (
          error.response.status === 304 ||
          error.response.status === 404
        ) {
          addToast(
            'error',
            t(
              'Desculpe, não foi possível concluir a operação devido a uma alteração recente realizada por outro usuário. Por favor, atualize a página'
            )
          );
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 600
        ) {
          for (const item of error.response.data.message) {
            const field = item.attr.pop() as 'site.name' | 'site.timezone';
            setError(`site.${field as keyof ISiteInfos}`, {
              types: {
                request: item.msg.replace(/^./, item.msg[0].toUpperCase())
              }
            });
          }

          if (showStepCreatePlace && errors.site) {
            setShowStepCreatePlace(false);
            setActiveModalType('create');
          }
        }
      }
    }
  );

  const handleSiteIdBeingEditedOrDeleted = (id: string | undefined | null) => {
    if (setSiteIdBeingEditedOrDeleted) {
      setSiteIdBeingEditedOrDeleted(id || null);
    }
  };

  const handleIsAddingSite = (value: boolean) => {
    if (setIsAddingSite) {
      setIsAddingSite(value);
    }
  };

  const onSubmit = handleSubmit((data: IDataSite) => {
    if (activeModalType === 'edit') {
      handleSiteIdBeingEditedOrDeleted(selectedSite?.id);
      setDisableSubmitButton(true);
      const editSiteData = {
        site: data.site
      };
      addSiteMutation.mutate(editSiteData);
    } else if (activeModalType === 'create' && !showStepCreatePlace) {
      setShowStepCreatePlace(true);
    } else {
      setDisableSubmitButton(true);
      handleIsAddingSite(true);
      addSiteMutation.mutate(data);
    }
  });

  const isFormEquals =
    useFormCompare({
      initialValues: {
        name: selectedSite?.name || '',
        timezone: selectedSite?.timezone || ''
      },
      currentValues: watch('site')
    }) && activeModalType === 'edit';

  const setInitialValues = () => {
    if (activeModalType === 'edit' && selectedSite) {
      setValue('site', selectedSite);
    } else {
      reset();
    }
  };

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, activeModalType]);

  const modalCreateOrEditTexts = {
    edit: {
      title: t('EDITAR LOCAL'),
      textButton: t('EDITAR LOCAL')
    },
    create: {
      title: t('CRIAR LOCAL'),
      textButton: showStepCreatePlace ? t('SALVAR') : t('CONTINUAR')
    }
  };

  const renderCreatePlace = () => {
    return (
      <InputWithValidation
        key="input-place-name"
        control={control}
        controllerProps={{
          name: 'place.name',
          rules: {
            required: {
              value: true,
              message: t('Campo obrigatório')
            },
            maxLength: {
              value: 32,
              message: t(
                'O nome do ambiente precisa ter entre 1 e 32 caracteres'
              )
            },
            validate: {
              notWhitespaceOnly: (value) =>
                value.trim().length > 0 || (t('Campo obrigatório') as string),
              notStartsOrEndsWithWhitespace: (value) =>
                (!value.startsWith(' ') && !value.endsWith(' ')) ||
                (t(
                  'O nome do ambiente não pode começar nem terminar com um espaço'
                ) as string)
            }
          }
        }}
        inputProps={{
          id: 'input-place-name'
        }}
        errors={errors?.place?.name}
        label={t('Nome do ambiente:')}
        placeholder={t('Ex.: Primeiro andar')}
      />
    );
  };

  const renderCreateSite = () => {
    return (
      <>
        <InputWithValidation
          control={control}
          key="input-site-name"
          controllerProps={{
            name: 'site.name',
            rules: {
              required: {
                value: true,
                message: t('Campo obrigatório')
              },
              maxLength: {
                value: 32,
                message: t(
                  'O nome do local precisa ter entre 1 e 32 caracteres'
                )
              },
              validate: {
                notWhitespaceOnly: (value) =>
                  value.trim().length > 0 || (t('Campo obrigatório') as string),
                notStartsOrEndsWithWhitespace: (value) =>
                  (!value.startsWith(' ') && !value.endsWith(' ')) ||
                  (t(
                    'O nome do local não pode começar nem terminar com um espaço'
                  ) as string)
              }
            }
          }}
          inputProps={{
            id: 'input-site-name'
          }}
          errors={errors?.site?.name}
          label={t('Nome do local:')}
          placeholder={t('Ex.: Hotel São Luiz')}
        />
        <SelectWithValidation
          wrapperProps={{
            className: 'mt-4'
          }}
          control={control}
          controllerProps={{
            name: 'site.timezone',
            rules: {
              required: {
                value: true,
                message: t('Campo obrigatório')
              }
            }
          }}
          id="select-site-timezone"
          errors={errors?.site?.timezone}
          label={t('Fuso horário:')}
          placeholder={t('Selecione um fuso-horário')}
          options={timezones}
        />
      </>
    );
  };

  const resetSelectedSite = () => {
    if (setSelectedSite) {
      setSelectedSite(null);
    }
  };

  const cancelCreateOrEdit = () => {
    clearErrors();
    setActiveModalType(null);
    setShowStepCreatePlace(false);
    reset();

    if (activeModalType === 'edit') {
      handleSiteIdBeingEditedOrDeleted(null);
      resetSelectedSite();
    }
  };

  const isEditOrCreateModalOnFirstStep =
    (activeModalType === 'create' || activeModalType === 'edit') &&
    !showStepCreatePlace;

  const chooseTitleOfModalByType = () => {
    if (isEditOrCreateModalOnFirstStep) {
      return modalCreateOrEditTexts[activeModalType as 'edit' | 'create']
        ?.title;
    }
    return t('CRIAR AMBIENTE');
  };

  const cancelButton = {
    label: t('CANCELAR'),
    action: () => cancelCreateOrEdit(),
    disabled: disableSubmitButton
  };

  const backButton = {
    label: t('VOLTAR'),
    action: () => {
      setShowStepCreatePlace(false);
      setActiveModalType('create');
    },
    disabled: disableSubmitButton || errors.place
  };

  const confirmButton = {
    label: t(
      modalCreateOrEditTexts[activeModalType as 'edit' | 'create']?.textButton
    ),
    action: onSubmit,
    disabled: isFormEquals || errors.site || errors.place,
    isLoading: disableSubmitButton
  };

  const chooseActionsOfModalByType = () => {
    if (isEditOrCreateModalOnFirstStep) {
      return [cancelButton, confirmButton];
    }
    return [cancelButton, backButton, confirmButton];
  };

  return (
    <Modal
      show={show}
      title={chooseTitleOfModalByType()}
      width="550px"
      actions={chooseActionsOfModalByType()}>
      <form onSubmit={onSubmit}>
        {showStepCreatePlace ? renderCreatePlace() : renderCreateSite()}
      </form>
    </Modal>
  );
};

ModalCreateOrEditSite.defaultProps = {
  selectedSite: undefined,
  onCreatingSite: undefined,
  setIsAddingSite: undefined,
  setSiteIdBeingEditedOrDeleted: undefined,
  setSites: undefined,
  setSharedSites: undefined,
  setSelectedSite: undefined
};

export { ModalCreateOrEditSite };
