import React, { useState } from 'react';
import {
  Dropdown,
  InputIconInside as InputIconInsideConectese,
  DropdownMenu,
  Chip,
  Label,
  InputWrapper,
  InputHelper
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@conecte-se/ui-components-reactjs';
import '@conecte-se/ui-components-reactjs/dist/index.css'; // todo: move to index file after mvp
import '@conecte-se/ui-components-reactjs/src/styles/theme/external_lib_themes.scss';
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdCancel,
  MdCheckCircle,
  MdClose,
  MdWarning
} from 'react-icons/md';
import classNames from 'classnames';
import styles from './Combobox.module.css';
// todo: move to index file after mvp

export interface IComboboxItem {
  label: string;
  value: any;
}

interface IProps {
  items: IComboboxItem[];
  onChange(selectedItems: IComboboxItem[]): void;
  value: IComboboxItem[];
  menuHeight?: number | string;
  label?: string;
  placeholder?: string;
  status?: 'error' | 'success' | 'warning';
  statusMessage?: string;
}

const Combobox = ({
  items,
  onChange,
  value,
  menuHeight,
  label: labelTitle,
  placeholder,
  status,
  statusMessage,
  ...props
}: IProps) => {
  const iconsByStatus = {
    error: <MdCancel />,
    success: <MdCheckCircle />,
    warning: <MdWarning />
  };

  const removeItem = (itemToRemove: IComboboxItem) => {
    onChange(value.filter((item) => item.value !== itemToRemove.value));
  };

  const generateCheckboxData = (label: string, itemValue: any) => {
    const isChecked = value.some((item) => item.value === itemValue);
    return {
      label,
      checkbox: {
        checked: isChecked,
        onChange: () => {
          if (isChecked) {
            removeItem({ label, value: itemValue });
            return;
          }
          onChange([...value, { label, value: itemValue }]);
        }
      }
    };
  };

  const [willRemoveItem, setWillRemoveItem] = useState(false);

  const comboboxItems = items.map((item) =>
    generateCheckboxData(item.label, item.value)
  );

  const [expanded, setExpanded] = useState(false);

  const closeButton = (item: IComboboxItem) => {
    return (
      <div
        className={styles.iconCloseShell}
        onMouseEnter={() => setWillRemoveItem(true)}
        onMouseLeave={() => setWillRemoveItem(false)}
        onClick={() => {
          removeItem(item);
          setWillRemoveItem(false);
        }}
        aria-hidden="true">
        <MdClose />
      </div>
    );
  };

  return (
    <div className={styles.shellCombobox}>
      <InputWrapper
        valid={status === 'success'}
        invalid={status === 'error'}
        alert={status === 'warning'}>
        <Dropdown>
          {labelTitle && <Label>{labelTitle}</Label>}
          <InputIconInsideConectese
            expandable
            iconRight={expanded ? <MdArrowDropUp /> : <MdArrowDropDown />}>
            <button
              id="combobox-input"
              type="button"
              onClick={() => (willRemoveItem ? null : setExpanded(!expanded))}
              className={classNames(styles.comboboxInput, [
                styles[status || ''],
                'd-flex align-center'
              ])}
              {...props}>
              {value.map((item) => (
                <div>
                  <Chip key={item.value} closeButtonIcon={closeButton(item)}>
                    {item.label}
                  </Chip>
                </div>
              ))}
              {value.length === 0 && (
                <span className={styles.placeholder}>{placeholder}</span>
              )}
            </button>
          </InputIconInsideConectese>
          <DropdownMenu
            visible={expanded}
            itens={comboboxItems}
            style={{ width: '100%' }}
          />
        </Dropdown>
        {status && (
          <InputHelper
            icon={iconsByStatus[status]}
            value={statusMessage || ''}
          />
        )}
      </InputWrapper>
      {expanded && (
        <div
          id="combobox-outside-click"
          onClick={() => setExpanded(false)}
          aria-hidden="true"
          className={styles.outsideClick}
        />
      )}
    </div>
  );
};

Combobox.defaultProps = {
  menuHeight: 'auto',
  label: '',
  placeholder: '',
  status: undefined,
  statusMessage: undefined
};

export default Combobox;
