import classNames from 'classnames';
import React from 'react';
import { MdFactCheck, MdHomeWork, MdPerson } from 'react-icons/md';
import Badge from 'src/components/Badge';
import Card from 'ui-components/Card';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoleMember } from 'src/components/ModalCreateMembers/types';
import { timezones } from 'src/utils/timezones';
import { useAuth } from 'src/hooks/useAuth';
import DuoToneIconsInline from 'src/components/DuoToneIconsInline';
import { SiteMenu } from 'src/components/SiteMenu';
import { ISiteMenuAndCardProps } from './types';
import styles from './SiteCard.module.css';

const SiteCard = ({
  site,
  isSharedSite,
  openModalByType,
  hasMoreThanOneSite,
  siteIdBeingEditedOrDeleted
}: ISiteMenuAndCardProps) => {
  const environment = process.env.REACT_APP_TYPE_ENV;

  const navigate = useNavigate();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const { setSiteTimezone } = useAuth();

  const [isAboveInIcon, setIsAboveInIcon] = React.useState(false);

  const handleOnSiteClick = () => {
    if (!isAboveInIcon) {
      setSiteTimezone(site.timezone);
      return navigate(`/sites/${site.id}/places`);
    }
    return null;
  };

  const renderBadgesSite = () => {
    return (
      isSharedSite && (
        <div className="d-flex align-center">
          <Badge
            color={
              site.member.role === RoleMember.MANAGER
                ? 'var(--color-neutral-dark-4)'
                : 'var(--color-product-lightest)'
            }
            title={
              site.member.role === RoleMember.MANAGER
                ? t('gerente')
                : t('convidado')
            }
            id={`site-${site.name}-role-${site.member.role}`}
          />

          {site.member.is_new_site_member && (
            <Badge
              color="var(--color-neutral-light-5)"
              backgroundColor="var(--color-status-critical-base)"
              title={t('novo')}
              className="ml-1"
              id={`badge-new-site-${site.name}`}
            />
          )}
        </div>
      )
    );
  };

  return (
    <div className="fit-width mb-3" key={site.id}>
      <Card
        clickable
        className={classNames([
          styles.cardSite,
          'd-flex align-center px-7 py-7'
        ])}
        onClick={() => handleOnSiteClick()}
        borderColor="var(--color-brand-secondary-light)">
        <div className="d-flex flex-column fit-width">
          <div className="d-flex align-center mb-2">
            <span className="title-sm-base  mr-2" id={`site-name-${site.name}`}>
              {site.name}
            </span>

            {renderBadgesSite()}
          </div>
          <span className="text-sm-base" id={`site-timezone-${site.name}`}>
            {site.timezone
              ? timezones.find((item) => item.value === site.timezone)?.label
              : null}
          </span>
        </div>

        <div className="d-flex">
          <DuoToneIconsInline
            className={styles.duoToneIcons}
            value={site.place_count}
            title={t('Ambientes')}
            colorIcon="var(--color-brand-primary-base)"
            icon={<MdHomeWork size={24} />}
          />

          <DuoToneIconsInline
            className={
              environment === 'development' && site.has_acl
                ? styles.duoToneIcons
                : undefined
            }
            onClick={() => openModalByType(site, 'members')}
            onMouseEnter={() => setIsAboveInIcon(true)}
            onMouseLeave={() => setIsAboveInIcon(false)}
            value={site.user_count}
            title={t('Membros')}
            colorIcon="var(--color-brand-primary-base)"
            icon={<MdPerson size={24} />}
          />

          {environment === 'development' && site.has_acl && (
            <DuoToneIconsInline
              value={1} // TODO: Colocar propriedade que contara a quantidade de ACLs enviadas pelo back
              title={t('ACL')}
              colorIcon="var(--color-brand-primary-base)"
              icon={<MdFactCheck size={24} />}
              onClick={() => {
                navigate(`/sites/${site.id}/access-lists`);
              }}
              onMouseEnter={() => setIsAboveInIcon(true)}
              onMouseLeave={() => setIsAboveInIcon(false)}
            />
          )}
        </div>
        <div
          onMouseEnter={() => setIsAboveInIcon(true)}
          onMouseLeave={() => setIsAboveInIcon(false)}>
          <SiteMenu
            site={site}
            openModalByType={openModalByType}
            hasMoreThanOneSite={hasMoreThanOneSite}
            isSharedSite={isSharedSite}
            siteIdBeingEditedOrDeleted={siteIdBeingEditedOrDeleted}
          />
        </div>
      </Card>
    </div>
  );
};

export { SiteCard };
