import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQuery } from 'react-query';
import { IUpdateCaptivePortal } from 'src/services/api/urls/captivePortal/types';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import { useNotification } from 'src/hooks/useNotification';
import { useToast } from 'src/hooks/useToast';
import { Button } from 'ui-components/Button';
import { IDevicePaginationTable } from 'src/pages/Devices/types';
import { Tooltip } from 'inmaster-ui';
import { useGetTranslatedCaptiveMethodType } from 'src/utils/CaptivePortal/methodsTypes';
import { queryClient } from 'src/services/queryClient';
import { ICaptivePortal } from '../types';
import { ConfirmDisableMethodCaptiveModal } from '../ConfirmDisableMethodCaptiveModal';
import { IMethodTypeName } from '../SetUpMethod/types';

interface IDisableCaptiveButton {
  defaultCaptivePortal: ICaptivePortal;
  isFetchedCaptivePortal: boolean;
  setDefaultCaptivePortal: Dispatch<SetStateAction<ICaptivePortal>>;
  setDisableCaptivePortalIsLoading: Dispatch<SetStateAction<boolean>>;
}

const DisableCaptiveButton = ({
  defaultCaptivePortal,
  isFetchedCaptivePortal,
  setDefaultCaptivePortal,
  setDisableCaptivePortalIsLoading
}: IDisableCaptiveButton) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.activeMethodCaptiveButton'
  });

  const { accessToken } = useAuth();

  const { addToast } = useToast();

  const { triggerNotificationAnimationWithText } = useNotification();

  const [isLoadingMethodCaptive, setIsLoadingMethodCaptive] = useState(false);

  const [
    countDevicesWithAssociatedWireless,
    setCountDevicesWithAssociatedWireless
  ] = useState(0);

  const [showConfirmDisableMethodModal, setShowConfirmDisableMethodModal] =
    useState(false);

  const nameChoosedMethod = useGetTranslatedCaptiveMethodType(
    defaultCaptivePortal?.mode as IMethodTypeName
  );

  useQuery(
    ['getDevicesWithWireless', accessToken?.place_id],
    api.devices.hefesto.get,
    {
      onSuccess: ({ data }: { data: IDevicePaginationTable }) => {
        const devicesWithAssociatedWirelessList = data.devices.filter(
          (device) => device.has_associated_wireless
        );

        setCountDevicesWithAssociatedWireless(
          devicesWithAssociatedWirelessList.length
        );
      }
    }
  );

  const disableCaptivePortal = (id: string) => {
    return api.captivePortal.captive.deactivate(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      id
    );
  };

  const disableMethodMutation = useMutation(
    'disableCaptivePortal',
    () => {
      return disableCaptivePortal(defaultCaptivePortal.id || '');
    },
    {
      onSuccess: ({ data }: { data: IUpdateCaptivePortal }) => {
        setDefaultCaptivePortal({ ...data.captive_portal } as ICaptivePortal);

        queryClient.refetchQueries('getCaptivePortalOfSetupMethods');

        addToast(
          'success',
          t('A desativação do Captive - {{nameOfCaptiveMethod}} foi iniciada', {
            nameOfCaptiveMethod: nameChoosedMethod
          })
        );
        if (countDevicesWithAssociatedWireless > 0) {
          triggerNotificationAnimationWithText(t('DESATIVANDO CAPTIVE PORTAL'));
        }

        setDisableCaptivePortalIsLoading(false);
        setIsLoadingMethodCaptive(false);
      },
      onError: () => {
        addToast(
          'error',
          t('Não foi possível iniciar a desativação do Captive Portal.')
        );
        setDisableCaptivePortalIsLoading(false);
        setIsLoadingMethodCaptive(false);
      }
    }
  );

  const onConfirmDisableMethod = () => {
    setIsLoadingMethodCaptive(true);
    setShowConfirmDisableMethodModal(false);
    setDisableCaptivePortalIsLoading(true);
    disableMethodMutation.mutate();
  };

  const onCancelDisableMethod = () => {
    setShowConfirmDisableMethodModal(false);
  };

  return (
    <>
      <ConfirmDisableMethodCaptiveModal
        showModalDisableMethod={showConfirmDisableMethodModal}
        onConfirmDisableMethod={onConfirmDisableMethod}
        onCancelDisableMethod={onCancelDisableMethod}
        isLoadingDisableMethod={isLoadingMethodCaptive}
      />

      <div className="d-flex align-center justify-end fit-width">
        {isFetchedCaptivePortal ? (
          <Tooltip
            place="bottom-end"
            id="tooltip-disable-method"
            isOpen={disableMethodMutation.isLoading}
            content={t('A desativação do Captive está em andamento')}>
            <Button
              outline
              secondary
              disabled={!defaultCaptivePortal.mode}
              isLoading={disableMethodMutation.isLoading}
              onClick={() => {
                setShowConfirmDisableMethodModal(true);
              }}>
              {t('DESATIVAR CAPTIVE')}
            </Button>
          </Tooltip>
        ) : (
          <Skeleton width={174.74} height={50} />
        )}
      </div>
    </>
  );
};

export { DisableCaptiveButton };
