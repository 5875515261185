import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'ui-components/Drawer';
import FormAddIndividualRecord from 'src/components/FormAddIndividualRecord';
import { IIndividualRecordFormValues } from 'src/components/FormAddIndividualRecord/types';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useFormCompare } from 'src/hooks/useFormCompare';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { IIndividualRecordFormDrawerProps } from './types';

const IndividualRecordFormDrawer = ({
  show,
  onClose,
  onSubmit,
  type,
  selectedRecordToUpdate
}: IIndividualRecordFormDrawerProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord.individualRecordFormDrawer'
  });

  const { accessToken } = useAuth();

  const { cp_id: captiveId } = useParams();

  const [recordTitleDrawer, setRecordTitleDrawer] = React.useState<string>('');

  const [isFormClosing, setIsFormClosing] = React.useState<boolean>(false);
  const [showConfirmCloseModal, setShowConfirmCloseModal] =
    React.useState<boolean>(false);

  const formInstance = useForm<IIndividualRecordFormValues>({
    defaultValues: {
      individualRecord: {
        authorized: false,
        docNumber: '',
        name: '',
        email: '',
        phone: '',
        periodType: undefined,
        periodInDays: [null, null],
        periodInTime: undefined,
        deviceNumbers: null
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });
  const { watch, setValue, reset, setError } = formInstance;

  const [debouncedDocNumber] = useDebounce(
    watch('individualRecord.docNumber'),
    500
  );

  const getIndividualRecordExists = () => {
    return api.captivePortal.individualRecord.getIndividualRecordExits(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      watch('individualRecord.docNumber')
    );
  };

  const {
    refetch: refetchCheckIfIndividualRecordExists,
    isFetching: isFetchingIndividualRecordExists
  } = useQuery('individualRecordExists', getIndividualRecordExists, {
    onSuccess: ({ data }) => {
      const { individual_record_exists: hasIndividualRecordWithDocNumber } =
        data;

      if (hasIndividualRecordWithDocNumber) {
        setError('individualRecord.docNumber', {
          types: {
            request: t('Nº de documento já cadastrado')
          }
        });
      }
    },
    enabled: false,
    cacheTime: 0
  });

  const verifyIfDocNumberExists = () => {
    if (
      watch('individualRecord.docNumber') &&
      !isFetchingIndividualRecordExists &&
      watch('individualRecord.docNumber') !== selectedRecordToUpdate?.docNumber
    ) {
      refetchCheckIfIndividualRecordExists();
    }
  };

  const hasNotFormIndividualRecordChange = useFormCompare({
    initialValues:
      selectedRecordToUpdate || ({} as IIndividualRecordFormValues),
    currentValues: watch('individualRecord')
  });

  const hasNotPeriodOfIndividualRecordChange = useFormCompare({
    initialValues: {
      periodType: selectedRecordToUpdate?.periodType,
      periodInDays: selectedRecordToUpdate?.periodInDays,
      periodInTime: selectedRecordToUpdate?.periodInTime
    },
    currentValues: {
      periodType: watch('individualRecord.periodType'),
      periodInDays: watch('individualRecord.periodInDays'),
      periodInTime: watch('individualRecord.periodInTime')
    }
  });

  const closeDrawer = () => {
    reset();
    onClose();
  };

  const handleOnClose = () => {
    if (type === 'edit' && !hasNotFormIndividualRecordChange) {
      setShowConfirmCloseModal(true);
      return;
    }

    closeDrawer();
  };

  React.useEffect(() => {
    if (type === 'add') {
      setRecordTitleDrawer(t('ADICIONAR CADASTRO INDIVIDUAL'));
    } else if (type === 'edit') {
      setRecordTitleDrawer(t('EDITAR CADASTRO INDIVIDUAL'));
    } else {
      setRecordTitleDrawer(t('CADASTRO INDIVIDUAL'));
    }
  }, [type, t]);

  React.useEffect(
    () => {
      if (type === 'edit' && selectedRecordToUpdate) {
        setValue('individualRecord', selectedRecordToUpdate);
        setValue(
          'individualRecord.authorized',
          selectedRecordToUpdate.authorized
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRecordToUpdate, type]
  );

  React.useEffect(() => {
    verifyIfDocNumberExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDocNumber]);

  React.useEffect(() => {
    setIsFormClosing(!show);
  }, [show]);

  const onConfirmCloseModal = () => {
    setShowConfirmCloseModal(false);
    closeDrawer();
  };

  return (
    <>
      {type === 'edit' && (
        <ConfirmModal
          isLoadingAction={false}
          showModal={showConfirmCloseModal}
          onConfirm={onConfirmCloseModal}
          typeButtonToConfirm="error"
          confirmLabel={t('SAIR')}
          cancelLabel={t('VOLTAR')}
          onCancel={() => setShowConfirmCloseModal(false)}>
          {`${t(
            'Tem certeza que deseja sair? As alterações não serão salvas'
          )}.`}
        </ConfirmModal>
      )}
      <Drawer
        title={recordTitleDrawer}
        show={show}
        onClose={handleOnClose}
        responsiveWidth={{ lg: '50vw' }}
        id="individual-record-form-drawer">
        <FormAddIndividualRecord
          type={type}
          formInstance={formInstance}
          isRecordAuthorizedPreviously={
            selectedRecordToUpdate?.authorized || false
          }
          isFetchingIndividualRecordExists={isFetchingIndividualRecordExists}
          isFormClosing={isFormClosing}
          hasNotFormIndividualRecordChange={hasNotFormIndividualRecordChange}
          hasNotPeriodOfIndividualRecordChange={
            hasNotPeriodOfIndividualRecordChange
          }
          onSubmitForm={onSubmit}
        />
      </Drawer>
    </>
  );
};

IndividualRecordFormDrawer.defaultProps = {
  individualRecordsToCompare: []
};

export default IndividualRecordFormDrawer;
