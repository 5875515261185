import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDropDown, MdSettings } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import classNames from 'classnames';
import { RoleMember } from '../ModalCreateMembers/types';
import styles from './SiteMenu.module.css';
import { ISiteMenuAndCardProps } from '../../pages/Sites/SiteCard/types';
import { ISiteItemPage, ModalsTypesOfSites } from '../../pages/Sites/types';

const SiteMenu = ({
  site,
  isSharedSite,
  openModalByType,
  hasMoreThanOneSite,
  siteIdBeingEditedOrDeleted
}: ISiteMenuAndCardProps) => {
  const environment = process.env.REACT_APP_TYPE_ENV;

  const navigate = useNavigate();

  const { t } = useTranslation('translations', { keyPrefix: 'site' });

  const [selectShowMenuOfSite, setSelectShowMenuOfSite] = React.useState({
    showMenu: false,
    idSite: site.id
  });

  const selectOption = (
    selectedSite: ISiteItemPage,
    type: ModalsTypesOfSites
  ) => {
    setSelectShowMenuOfSite({ showMenu: false, idSite: site.id });
    openModalByType(selectedSite, type);
  };

  return (
    <Menu
      menuId={`menu-site-actions-${site.name}`}
      key={`menu-site-actions-${site.name}`}
      items={
        <>
          {site.member.role !== RoleMember.GUEST && (
            <MenuItem
              disabled={siteIdBeingEditedOrDeleted === site.id}
              onClick={() => {
                selectOption(site, 'edit');
              }}
              aria-hidden="true"
              id={`btn-edit-site-${site.name}`}>
              <span className="text-sm-sm">{t('Editar local')}</span>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              selectOption(site, 'members');
            }}
            aria-hidden="true"
            id={`btn-members-site-${site.name}`}>
            <span className="text-sm-sm">{t('Gerenciar membros')}</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`/sites/${site.id}/connection-log`);
            }}
            aria-hidden="true"
            id={`btn-connections-log-site-${site.name}`}>
            <span className="text-sm-sm">{t('Registro de conexões')}</span>
          </MenuItem>
          {environment === 'development' && (
            <MenuItem
              onClick={() => {
                navigate(`/sites/${site.id}/access-lists`);
              }}
              aria-hidden="true"
              id={`btn-acls-site-${site.name}`}>
              <span className="text-sm-sm">{t('Listas de acesso (ACL)')}</span>
            </MenuItem>
          )}
          {isSharedSite && (
            <MenuItem
              onClick={() => {
                selectOption(site, 'exitSharedSit');
              }}
              aria-hidden="true"
              id={`btn-exit-site-${site.name}`}>
              <span
                className={classNames(['text-sm-sm', styles.colorExitSite])}>
                {t('Sair do local')}
              </span>
            </MenuItem>
          )}
          {!isSharedSite && hasMoreThanOneSite && (
            <MenuItem
              disabled={siteIdBeingEditedOrDeleted === site.id}
              onClick={() => {
                selectOption(site, 'delete');
              }}
              aria-hidden="true"
              id={`btn-delete-site-${site.name}`}>
              <span
                className={classNames(['text-sm-sm', styles.colorExitSite])}>
                {t('Excluir local')}
              </span>
            </MenuItem>
          )}
        </>
      }
      positionX="end"
      width="200px"
      onClose={() =>
        setSelectShowMenuOfSite({ showMenu: false, idSite: site.id })
      }
      show={
        selectShowMenuOfSite.idSite === site.id && selectShowMenuOfSite.showMenu
      }>
      <div
        id={`btn-menu-site-actions-${site.name}`}
        className={classNames(['d-flex align-center', styles.menuActions])}
        aria-hidden="true"
        onClick={() =>
          setSelectShowMenuOfSite({ showMenu: true, idSite: site.id })
        }>
        <span
          className={classNames(['d-flex align-center', styles.colorIconMenu])}>
          <MdSettings size={26} />
        </span>

        <span
          className={classNames([
            'text-uppercase text-sm-sm text-bold',
            styles.colorMenu
          ])}>
          {t('Opções')}
        </span>

        <MdArrowDropDown size={18} />
      </div>
    </Menu>
  );
};

export { SiteMenu };
