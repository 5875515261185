import React, { HTMLAttributes } from 'react';
import {
  Control,
  Controller,
  FieldError,
  MultipleFieldErrors,
  UseControllerProps
} from 'react-hook-form';
import { MdCancel } from 'react-icons/md';
import InputHelper from 'ui-components/InputHelper';
import { InputWrapper } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import { InputTime } from '../InputTime';

interface IInputTimeWithValidationProps {
  errors: FieldError | undefined;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, unknown>;
  controllerProps: UseControllerProps;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  placeholder?: string;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  id?: string;
  stepMinute?: number;
}

const InputTimeWithValidation = ({
  label,
  controllerProps,
  placeholder,
  control,
  wrapperProps,
  errors,
  stepMinute,
  id
}: IInputTimeWithValidationProps) => {
  const getInputErrorMessage = (
    errorTypes: MultipleFieldErrors | undefined
  ) => {
    if (!errorTypes) {
      return null;
    }

    let message;

    if (errorTypes.required) {
      message = errorTypes.required;
    } else if (errorTypes.notWhitespaceOnly) {
      message = errorTypes.notWhitespaceOnly;
    } else if (errorTypes.notStartsOrEndsWithWhitespace) {
      message = errorTypes.notStartsOrEndsWithWhitespace;
    } else if (errorTypes.notWhitespace) {
      message = errorTypes.notWhitespace;
    } else if (errorTypes.notSpecialCharacters) {
      message = errorTypes.notSpecialCharacters;
    } else if (errorTypes.pattern) {
      message = errorTypes.pattern;
    } else if (errorTypes.maxLength) {
      message = errorTypes.maxLength;
    } else if (errorTypes.minLength) {
      message = errorTypes.minLength;
    } else if (errorTypes.request) {
      message = errorTypes.request;
    } else if (errorTypes.min) {
      message = errorTypes.min;
    } else if (errorTypes.max) {
      message = errorTypes.max;
    } else if (errorTypes.notSelectedTime) {
      message = errorTypes.notSelectedTime;
    } else if (errorTypes.notValidTime) {
      message = errorTypes.notValidTime;
    }

    return (
      <InputHelper
        id="error-message"
        icon={<MdCancel />}
        value={String(message)}
      />
    );
  };

  return (
    <InputWrapper invalid={Boolean(errors)} {...wrapperProps}>
      <Label>{label}</Label>
      <Controller
        control={control}
        name={controllerProps.name}
        rules={controllerProps.rules}
        render={({ field: { onChange, value } }) => (
          <InputTime
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            stepMinute={stepMinute}
            id={id || controllerProps.name}
            isInvalid={Boolean(errors)}
          />
        )}
      />
      {getInputErrorMessage(errors?.types)}
    </InputWrapper>
  );
};

InputTimeWithValidation.defaultProps = {
  label: '',
  placeholder: '',
  wrapperProps: {},
  inputProps: {},
  disabled: false,
  id: '',
  stepMinute: 5
};

export default InputTimeWithValidation;
