import classNames from 'classnames';
import React from 'react';
import { Col, Row, Tooltip } from 'inmaster-ui';
import { useTranslation } from 'react-i18next';
import {
  MdAdd,
  MdArticle,
  MdDelete,
  MdEdit,
  MdMoreHoriz,
  MdSearch,
  MdUpdate
} from 'react-icons/md';
import { EmptyMessage } from 'src/components/EmptyMessage';
import { ResultsNotFoundMessage } from 'src/components/ResultsNotFoundMessage';
import TableWithPagination from 'src/components/TableWithPagination';
import { Button, ButtonIcon, ButtonTextOutside } from 'ui-components/Button';
import Card from 'ui-components/Card';
import { Input } from 'ui-components/Input';
import { InputIconInside } from 'ui-components/InputWrapper';
import Label from 'ui-components/Label';
import Loading from 'ui-components/Loading';
import Menu from 'ui-components/Menu';
import MenuItem from 'ui-components/Menu/MenuItem';
import {
  IPaginationRequestData,
  IRowsPerPageLoadingTypeObject
} from 'src/pages/Devices/types';

import { generateColumns } from 'src/utils/tableUtils';
import Badge from 'src/components/Badge';
import { useDebounce } from 'use-debounce';
import { useToast } from 'src/hooks/useToast';
import { useMutation, useQuery } from 'react-query';
import api from 'src/services/api';
import { useAuth } from 'src/hooks/useAuth';
import {
  ICreateIndividualRecord,
  IDeleteIndividualRecord,
  IGetActiveIndividualRecords,
  IRenewActiveIndividualRecords,
  IUpdateIndividualRecord
} from 'src/services/api/urls/captivePortal/types';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
  IIndividualRecordValue,
  IIndividualRecordFormValues
} from 'src/components/FormAddIndividualRecord/types';
import { IOption } from 'src/components/InputTime/types';
import { generateIndividualRecordsItemsOfPagination } from 'src/utils/CaptivePortal/individualRecords';
import moment from 'moment';
import { getExpiresAtDaysOrTime } from 'src/utils/CaptivePortal/captiveDates';
import { DateConverter } from 'src/utils/Dates/DateConverter';
import styles from './ActiveRecordTab.module.css';
import {
  IIndividualRecord,
  IIndividualRecordRow,
  IPaginationIndividualRecord
} from '../types';
import { ImportRecordsModal } from './ImportRecordsModal';
import { DeleteIndividualRecordModal } from '../DeleteIndividualRecordModal';
import { UpdateRecordModal } from '../UpdateRecordModal';
import { MoreInformationModal } from '../MoreInformationModal';
import IndividualRecordFormDrawer from './IndividualRecordFormDrawer';
import { PreViewCaptiveModal } from '../../PreViewCaptiveModal';
import { ICaptivePortal } from '../../types';

const ActiveRecordTab = ({
  defaultCaptivePortal
}: {
  defaultCaptivePortal: ICaptivePortal;
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord'
  });

  const { addToast } = useToast();

  const { cp_id: captiveId } = useParams();

  const { accessToken, siteTimezone } = useAuth();

  const [showMenu, setShowMenu] = React.useState(false);

  const [selectedRecords, setSelectedRecords] = React.useState<
    IIndividualRecordRow[]
  >([]);

  const [selectedAuthorizedRecords, setSelectedAuthorizedRecords] =
    React.useState<IIndividualRecordRow[]>([]);

  const [paginationActiveRecordObject, setPaginationActiveRecordObject] =
    React.useState<IPaginationIndividualRecord>(
      {} as IPaginationIndividualRecord
    );

  const [
    isInitialLoadingCompleteOfActiveRecords,
    setIsInitialLoadingCompleteOfActiveRecords
  ] = React.useState(false);

  const [isChangingPage, setIsChangingPage] = React.useState(false);

  const [loadingTypeOfRowsPerPage, setLoadingTypeOfRowsPerPage] =
    React.useState<IRowsPerPageLoadingTypeObject>(
      {} as IRowsPerPageLoadingTypeObject
    );

  const [paginationRequestData, setPaginationRequestData] =
    React.useState<IPaginationRequestData>({
      page_number: 1,
      page_size: 10,
      search_param: ''
    });

  const [paginationRequestDataDebounce] = useDebounce(
    paginationRequestData,
    500
  );

  const [
    oldPaginationRequestDataDebounce,
    setOldPaginationRequestDataDebounce
  ] = React.useState(paginationRequestDataDebounce);

  const [searchActiveRecords, setSearchActiveRecords] = React.useState('');

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [showUpdateRecordModal, setShowUpdateRecordModal] =
    React.useState(false);

  const [selectedRecordToShowInformation, setSelectedRecordToShowInformation] =
    React.useState<IIndividualRecord>({} as IIndividualRecord);

  const [showMoreInformationModal, setShowMoreInformationModal] =
    React.useState(false);

  const [showImportModal, setShowImportModal] = React.useState(false);

  const [showIndividualRecordFormDrawer, setShowIndividualRecordFormDrawer] =
    React.useState(false);

  const [isDeletingAllAvailableRecords, setIsDeletingAllAvailableRecords] =
    React.useState(false);

  const [createOrUpdateForm, setCreateOrUpdateForm] = React.useState<
    'add' | 'edit'
  >('add');

  const [selectedRecordToUpdate, setSelectedRecordToUpdate] = React.useState<
    IIndividualRecordValue | undefined
  >(undefined);

  const getActiveRecords = () => {
    return api.captivePortal.individualRecord.getActiveHistoric(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      paginationRequestDataDebounce
    );
  };

  const {
    isFetching: isFetchingActiveIndividualRecords,
    refetch: refetchActiveIndividualRecords
  } = useQuery(
    ['getActiveRecords', captiveId, paginationRequestDataDebounce],
    getActiveRecords,
    {
      onSuccess: ({
        data: responseIndividualRecordHistoric
      }: {
        data: IGetActiveIndividualRecords;
      }) => {
        if (!isInitialLoadingCompleteOfActiveRecords) {
          setIsInitialLoadingCompleteOfActiveRecords(true);
        }

        setPaginationActiveRecordObject({
          individualRecord: generateIndividualRecordsItemsOfPagination(
            'active',
            responseIndividualRecordHistoric.individual_records,
            siteTimezone
          ),
          pagination: { ...responseIndividualRecordHistoric.pagination }
        });
      },
      onError: () => {
        if (!isInitialLoadingCompleteOfActiveRecords) {
          setIsInitialLoadingCompleteOfActiveRecords(true);
        }

        setPaginationActiveRecordObject({
          individualRecord: [],
          pagination: {
            total_items: 0,
            total_filtered_items: 0,
            total_pages: 0,
            current_page: 0
          }
        });
      }
    }
  );

  const postAddIndividualRecord = (
    dataCreateIndividualRecord: ICreateIndividualRecord
  ) => {
    return api.captivePortal.individualRecord.postCreateIndividualRecord(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      dataCreateIndividualRecord
    );
  };

  const addIndividualRecordMutation = useMutation(postAddIndividualRecord, {
    onSuccess: () => {
      refetchActiveIndividualRecords();
      addToast('success', t('Cadastro adicionado com sucesso'));
    },
    onError: () => {
      addToast('error', t('Erro ao adicionar cadastro'));
    }
  });

  const putEditIndividualRecord = (
    dataEditIndividualRecord: IUpdateIndividualRecord
  ) => {
    return api.captivePortal.individualRecord.putEditIndividualRecord(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      selectedRecordToUpdate?.id || '',
      dataEditIndividualRecord
    );
  };

  const editIndividualRecordMutation = useMutation(putEditIndividualRecord, {
    onSuccess: () => {
      refetchActiveIndividualRecords();
      addToast('success', t('Cadastro editado com sucesso'));
      setSelectedRecordToUpdate(undefined);
    },
    onError: () => {
      addToast('error', t('Erro ao editar o cadastro'));
    }
  });

  const deleteAvailableRecords = () => {
    return api.captivePortal.individualRecord.deleteAvailableRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || ''
    );
  };

  const deleteAvailableRecordsMutation = useMutation(deleteAvailableRecords, {
    onSuccess: () => {
      refetchActiveIndividualRecords();
      addToast('success', t('Cadastro(s) excluído(s) com sucesso'));
    },
    onError: () => {
      addToast('error', t('Erro ao remover cadastro(s)'));
    },
    onSettled: () => {
      setSelectedRecords([]);
      setShowDeleteModal(false);
      setIsDeletingAllAvailableRecords(false);
    }
  });

  const deleteRemoveIndividualRecord = (
    individualRecordsIds: IDeleteIndividualRecord
  ) => {
    return api.captivePortal.individualRecord.deleteActiveRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      individualRecordsIds
    );
  };

  const deleteIndividualRecordMutation = useMutation(
    deleteRemoveIndividualRecord,
    {
      onSuccess: () => {
        refetchActiveIndividualRecords();

        addToast(
          'success',
          t('{{qtySelectedRecords}} Cadastro(s) excluído(s) com sucesso', {
            qtySelectedRecords: selectedRecords.length
          })
        );
      },
      onError: () => {
        addToast('error', t('Erro ao remover cadastro(s)'));
      },
      onSettled: () => {
        setSelectedRecords([]);
        setShowDeleteModal(false);
        setIsDeletingAllAvailableRecords(false);
      }
    }
  );

  const renewSelectedIndividualRecords = () => {
    return api.captivePortal.individualRecord.renewActiveRecords(
      accessToken?.site_id || '',
      accessToken?.place_id || '',
      captiveId || '',
      {
        captive_portal_id: captiveId || '',
        individual_records_ids: selectedAuthorizedRecords.map(
          (record) => record.id || ''
        ),
        new_started_at: new DateConverter(
          undefined,
          siteTimezone
        ).getDateWithDefaultFormat()
      } as IRenewActiveIndividualRecords
    );
  };

  const renewSelectedIndividualRecordsMutation = useMutation(
    renewSelectedIndividualRecords,
    {
      onSuccess: () => {
        refetchActiveIndividualRecords();
        addToast(
          'success',
          t(
            '{{qtySelectedIndividualRecords}} Cadastro(s) renovado(s) com sucesso',
            { qtySelectedIndividualRecords: selectedAuthorizedRecords.length }
          )
        );
      },
      onError: () => {
        addToast('error', t('Não foi possível renovar'));
      },
      onSettled: () => {
        setSelectedAuthorizedRecords([]);
        setSelectedRecords([]);
        setShowUpdateRecordModal(false);
      }
    }
  );

  const onCloseUpdateRecordModal = () => {
    setShowUpdateRecordModal(false);
  };

  const onConfirmUpdateRecords = () => {
    renewSelectedIndividualRecordsMutation.mutate();
  };

  const onClickToOpenShowMoreInformationModal = (
    selectedRecord: IIndividualRecord
  ) => {
    setShowMoreInformationModal(true);
    setSelectedRecordToShowInformation(selectedRecord);
  };

  const onCloseMoreInformationModal = () => {
    setShowMoreInformationModal(false);
    setSelectedRecordToShowInformation({} as IIndividualRecord);
  };

  const onCloseDeleteModal = () => {
    setIsDeletingAllAvailableRecords(false);
    setShowDeleteModal(false);
  };

  const onConfirmToDeleteRecord = () => {
    if (isDeletingAllAvailableRecords) {
      deleteAvailableRecordsMutation.mutate();
      return;
    }

    deleteIndividualRecordMutation.mutate({
      individual_records_ids: selectedRecords.map((record) => record.id || '')
    });
  };

  const onCloseImportModal = () => {
    setShowImportModal(false);
  };

  const generateStartedAtAndExpiresAtDate = (
    periodType: string,
    periodInDays: [Date | null, Date | null],
    periodInTime?: IOption,
    startedAt?: string,
    isAuthorized?: boolean
  ) => {
    return {
      started_at: isAuthorized
        ? new DateConverter(
            startedAt || undefined,
            siteTimezone
          ).getDateWithDefaultFormat()
        : null,
      expires_at: isAuthorized
        ? getExpiresAtDaysOrTime(
            periodType,
            periodInDays,
            periodInTime,
            createOrUpdateForm === 'edit' && !_.isUndefined(startedAt),
            siteTimezone,
            startedAt
          )
        : null
    };
  };

  const convertsDataIndividualRecordFormIndividualRecord = (
    individualRecordForm: IIndividualRecordValue
  ) => {
    return {
      id: individualRecordForm.id,
      document: individualRecordForm.docNumber,
      name: individualRecordForm.name,
      email: individualRecordForm.email,
      phone: individualRecordForm.phone,
      ...generateStartedAtAndExpiresAtDate(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        individualRecordForm.periodType!,
        individualRecordForm.periodInDays,
        individualRecordForm.periodInTime,
        individualRecordForm.startedAt,
        individualRecordForm.authorized
      )
    } as IIndividualRecord;
  };

  const onSubmitAddOrEditIndividualRecordForm = (
    valuesIndividualRecordForm: IIndividualRecordFormValues
  ) => {
    const { individualRecord: individualRecordForm } =
      valuesIndividualRecordForm;

    const recordConverted: IIndividualRecord =
      convertsDataIndividualRecordFormIndividualRecord(individualRecordForm);

    const dataToCreateIndividualRecord: ICreateIndividualRecord = {
      individual_records: [
        {
          ...recordConverted
        }
      ]
    } as ICreateIndividualRecord;

    if (createOrUpdateForm === 'add') {
      addIndividualRecordMutation.mutate(dataToCreateIndividualRecord);
    } else {
      const datoToEditIndividualRecord: IUpdateIndividualRecord = {
        individual_record_data: {
          ...recordConverted,
          status: undefined
        }
      };
      editIndividualRecordMutation.mutate(datoToEditIndividualRecord);
    }
    setShowIndividualRecordFormDrawer(false);
  };

  const onClickToDeleteAvailableRecords = () => {
    setIsDeletingAllAvailableRecords(true);
    setShowDeleteModal(true);
  };

  const onClickToAddNewRecord = () => {
    setCreateOrUpdateForm('add');
    setSelectedRecordToUpdate(undefined);
    setShowIndividualRecordFormDrawer(true);
  };

  const onClickToEditRecord = (record: IIndividualRecord) => {
    setCreateOrUpdateForm('edit');

    const getMomentOfDateFormatted = (date: string | undefined) => {
      const dateWithoutFormatted = moment.tz(
        date || 'Invalid date',
        `DD/MM/YYYY [${t('às')}] HH:mm`,
        siteTimezone || 'America/Sao_Paulo'
      );

      if (dateWithoutFormatted.isValid()) {
        return dateWithoutFormatted;
      }

      return moment(date).tz(siteTimezone || 'America/Sao_Paulo');
    };

    const getPeriodOfIndividualRecord = () => {
      if (record.authorizedStatus) {
        const momentStartedAt = getMomentOfDateFormatted(record.started_at);
        const momentExpiresAt = getMomentOfDateFormatted(record.expires_at);

        if (record.time?.includes(t('dia'))) {
          return {
            periodType: 'date',
            periodInDays: [momentStartedAt.toDate(), momentExpiresAt.toDate()]
          };
        }

        const getMomentOfDifferenceBetweenExpiresAndStartedTime =
          momentExpiresAt.subtract({
            hours: momentStartedAt.hours(),
            minutes: momentStartedAt.minutes()
          });

        return {
          periodType: 'time',
          periodInTime: {
            label: record.time,
            value: new Date(
              0,
              0,
              0,
              getMomentOfDifferenceBetweenExpiresAndStartedTime.hours(),
              getMomentOfDifferenceBetweenExpiresAndStartedTime.minutes()
            ).getTime()
          }
        };
      }

      return {
        periodType: undefined,
        periodInDays: [null, null],
        periodInTime: undefined
      };
    };

    setSelectedRecordToUpdate({
      id: record.id,
      authorized: record.authorizedStatus,
      docNumber: record.document,
      name: record.name,
      email: record.email,
      phone: record.phone,
      startedAt: getMomentOfDateFormatted(
        record.authorizedStatus ? record.started_at : undefined
      ).format(),
      ...getPeriodOfIndividualRecord()
    } as IIndividualRecordValue);
    setShowIndividualRecordFormDrawer(true);
  };

  const isFetchingAndNotInitialLoadingCompleteActiveIndividualRecords =
    !isInitialLoadingCompleteOfActiveRecords &&
    isFetchingActiveIndividualRecords;

  const isNotFetchingAndInitialLoadingCompleteActiveIndividualRecords =
    isInitialLoadingCompleteOfActiveRecords &&
    !isFetchingActiveIndividualRecords;

  const isEmptyRecords =
    isNotFetchingAndInitialLoadingCompleteActiveIndividualRecords &&
    paginationActiveRecordObject.pagination.total_items === 0;

  const isResultNotFound =
    isNotFetchingAndInitialLoadingCompleteActiveIndividualRecords &&
    paginationActiveRecordObject.pagination.total_filtered_items === 0;

  const isSearchingRecords =
    isFetchingActiveIndividualRecords &&
    isInitialLoadingCompleteOfActiveRecords &&
    !isChangingPage &&
    loadingTypeOfRowsPerPage.type === 'success';

  const checkIfHaveUsedRecordsSelected =
    paginationActiveRecordObject.individualRecord &&
    paginationActiveRecordObject.individualRecord.some(
      (record) =>
        record.status === t('Utilizado') &&
        selectedRecords.find(
          (selectedRecord) => selectedRecord.id === record.id
        )
    );

  const columnsTableIndividualRecord = [
    generateColumns('id', 'ID', 'left', 0, true),
    generateColumns('name', t('Nome'), 'left', 0),
    generateColumns('document', t('Nº Documento'), 'left', 0),
    generateColumns('authorizedStatus', t('Autorizado'), 'center', 0),
    generateColumns('time', t('Tempo'), 'left', 0),
    generateColumns('expires_at', t('Data de Expiração'), 'left', 0),
    generateColumns('status', t('Status'), 'left', 0),
    generateColumns('actions', t('Ações'), 'left', 0)
  ];

  const generateRows = (recordsList: IIndividualRecord[]) => {
    return recordsList.map((recordItem: IIndividualRecord) => {
      const isAuthorized = recordItem.authorizedStatus;

      return {
        id: recordItem.id,
        created_at: recordItem.created_at,
        started_at: isAuthorized ? recordItem.started_at : '--',
        expires_at: isAuthorized ? recordItem.expires_at : '--',
        time: recordItem.time,
        document: recordItem.document,
        name: recordItem.name,
        authorizedStatus: isAuthorized ? t('Sim') : t('Não'),
        status:
          recordItem.status !== '--' ? (
            <div className={classNames(styles.statusContent)}>
              <Badge
                title={recordItem.status}
                color={
                  recordItem.status === t('Utilizado')
                    ? 'var(--color-status-ok-darkest)'
                    : 'var(--color-status-alert-darkest)'
                }
                backgroundColor={
                  recordItem.status === t('Utilizado')
                    ? 'var(--color-status-ok-lightest)'
                    : 'var(--color-status-alert-lightest)'
                }
              />
            </div>
          ) : (
            <div>--</div>
          ),
        actions: (
          <div className={classNames('d-flex justify-between')}>
            <Tooltip
              id="tooltip-more-information"
              content={t('Mais informações')}
              place="bottom">
              <ButtonIcon
                onClick={() => {
                  onClickToOpenShowMoreInformationModal(recordItem);
                }}>
                <MdArticle size={18} />
              </ButtonIcon>
            </Tooltip>
            <Tooltip id="tooltip-edit" content={t('Edição')} place="bottom">
              <ButtonIcon onClick={() => onClickToEditRecord(recordItem)}>
                <MdEdit size={18} />
              </ButtonIcon>
            </Tooltip>
          </div>
        )
      } as IIndividualRecordRow;
    });
  };

  const [showPreViewModal, setShowPreViewModal] = React.useState(false);

  const renderMenu = () => {
    return (
      <Menu
        menuId="menu-active-individual-record-actions"
        width="160px"
        positionX="end"
        show={showMenu}
        onClose={() => setShowMenu(false)}
        items={
          <>
            <MenuItem
              id="btn-pre-view-captive"
              onClick={() => setShowPreViewModal(true)}>
              <span className={classNames('text-sm-xs')}>
                {t('Visualizar Captive')}
              </span>
            </MenuItem>
            <MenuItem
              id="btn-clear-available-records"
              onClick={onClickToDeleteAvailableRecords}>
              <span className={classNames('text-sm-xs')}>
                {t('Limpar disponíveis')}
              </span>
            </MenuItem>
            {/* <MenuItem
              id="btn-export-table"
              onClick={() => {
                addToast(
                  'success',
                  `(${
                    paginationActiveRecordObject.individualRecord.length
                  }) ${t('Cadastro(s) exportado(s) com sucesso')}`
                );
              }}>
              <span className={classNames('text-sm-xs')}>
                {t('Exportar tabela')}
              </span>
            </MenuItem> */}
            {/* <MenuItem
              id="btn-import-table"
              onClick={() => {
                setShowImportModal(true);
              }}>
              <span className={classNames('text-sm-xs')}>
                {t('Importar tabela')}
              </span>
            </MenuItem> */}
          </>
        }>
        <div
          id="btn-menu-actions"
          className={styles.menuActions}
          onClick={() => setShowMenu(true)}
          aria-hidden="true">
          <MdMoreHoriz size={24} />
        </div>
      </Menu>
    );
  };

  const renderSkeletonsOfTable = (numberOfSkeletons: number) => {
    const skeletons = [];

    for (let i = 0; i < numberOfSkeletons; i += 1) {
      skeletons.push(
        <div
          className="d-flex justify-between my-4 align-center py-5"
          key={`${i}-skeleton-individual-record-table`}>
          <Skeleton count={1} height={25} width={25} className="ml-2" />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={100} />
          <Skeleton count={1} height={30} width={78} borderRadius={100} />
          <div className="d-flex align-center mr-2">
            <Skeleton count={1} height={35} width={35} className="mr-2" />
            <Skeleton count={1} height={35} width={35} />
          </div>
        </div>
      );
    }

    return skeletons;
  };

  const renderSkeletonsOfCompleteTable = (numberOfSkeletons: number) => {
    const skeletonsOfTable = renderSkeletonsOfTable(numberOfSkeletons);

    const skeletonsOfCompleteTable = [
      <div key="header-skeleton-individual-record-table" className="fit-width">
        <Skeleton height={50} />
      </div>,
      ...skeletonsOfTable
    ];

    return <Card>{skeletonsOfCompleteTable}</Card>;
  };

  const renderSkeletonsTab = () => {
    return (
      <div>
        <Card className="px-7 py-6 mb-5">
          <Row between="md" className="mb-1">
            <Col xs={12}>
              <Label>
                <Skeleton width={120} height={20} />
              </Label>
            </Col>
            <Col xs={12} className="mt-1">
              <Row className="d-flex align-center">
                <Col xs={6} className="mr-7">
                  <Skeleton height={50} width={550} />
                </Col>

                <Col className="d-flex align-center">
                  <div className="mr-7">
                    <Skeleton height={50} width={200} />
                  </div>
                  <Skeleton height={50} width={50} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card>{renderSkeletonsOfCompleteTable(10)}</Card>
      </div>
    );
  };

  React.useEffect(() => {
    setPaginationRequestData((prev) => ({
      ...prev,
      search_param: searchActiveRecords,
      page_number: 1
    }));
  }, [searchActiveRecords]);

  React.useEffect(() => {
    const debounceChanged = !_.isEqual(
      oldPaginationRequestDataDebounce,
      paginationRequestDataDebounce
    );

    if (debounceChanged) {
      refetchActiveIndividualRecords();
      setOldPaginationRequestDataDebounce(paginationRequestDataDebounce);
    }
  }, [
    oldPaginationRequestDataDebounce,
    paginationRequestDataDebounce,
    refetchActiveIndividualRecords
  ]);

  return (
    <div>
      {isFetchingAndNotInitialLoadingCompleteActiveIndividualRecords ? (
        renderSkeletonsTab()
      ) : (
        <div>
          <PreViewCaptiveModal
            methodCaptive="individual_record"
            defaultCaptivePortal={defaultCaptivePortal}
            showPreViewModal={showPreViewModal}
            isFetchedCaptivePortal
            onClose={() => {
              setShowPreViewModal(false);
            }}
          />
          <IndividualRecordFormDrawer
            show={showIndividualRecordFormDrawer}
            onSubmit={onSubmitAddOrEditIndividualRecordForm}
            onClose={() => setShowIndividualRecordFormDrawer(false)}
            type={createOrUpdateForm}
            selectedRecordToUpdate={selectedRecordToUpdate}
          />
          {isEmptyRecords ? (
            <EmptyMessage
              id="empty-active-individual-record"
              title={t('Nenhum cadastro adicionado')}
              subtitle={`${t(
                'Ainda não há nenhum cadastro individual disponível neste ambiente. Experimente adicionar um novo'
              )}.`}
              buttonText={t('Adicionar novo')}
              action={() => setShowIndividualRecordFormDrawer(true)}
              minHeight="190px"
              height="calc(100vh - 500px)"
            />
          ) : (
            <>
              <ImportRecordsModal
                showImportModal={showImportModal}
                onCloseImportModal={() => {
                  onCloseImportModal();
                }}
                onSubmitImportModal={() => {
                  setShowImportModal(false);
                }}
              />
              <DeleteIndividualRecordModal
                onCloseDeleteModal={onCloseDeleteModal}
                countSelectedRecords={selectedRecords.length}
                hasUsedRecords={checkIfHaveUsedRecordsSelected}
                isLoadingDeleteMutation={
                  deleteIndividualRecordMutation.isLoading ||
                  deleteAvailableRecordsMutation.isLoading
                }
                onDeleteRecord={onConfirmToDeleteRecord}
                showDeleteModal={showDeleteModal}
                isDeletingAllAvailableRecords={isDeletingAllAvailableRecords}
              />

              <UpdateRecordModal
                isLoadingUpdateRecord={
                  renewSelectedIndividualRecordsMutation.isLoading
                }
                showUpdateRecordModal={showUpdateRecordModal}
                qtySelectedRecords={selectedAuthorizedRecords.length}
                onCloseUpdateRecordModal={onCloseUpdateRecordModal}
                onConfirmUpdateRecords={onConfirmUpdateRecords}
              />

              <MoreInformationModal
                selectedIndividualRecord={selectedRecordToShowInformation}
                onCloseMoreInformationModal={() =>
                  onCloseMoreInformationModal()
                }
                onClickToEditRecord={() => {
                  onClickToEditRecord(selectedRecordToShowInformation);
                  onCloseMoreInformationModal();
                }}
                showMoreInformationModal={showMoreInformationModal}
              />

              <Card className="px-7 py-6 mb-5">
                {selectedRecords.length === 0 ? (
                  <Row between="md" className="mb-1">
                    <Col xs={12}>
                      <Label>{t('Pesquisar na lista')}:</Label>
                    </Col>
                    <Col xs={12} className="mt-1">
                      <Row className="align-center">
                        <Col xs={6}>
                          <InputIconInside
                            iconLeft={
                              isSearchingRecords ? (
                                <Loading value={40} indeterminate />
                              ) : (
                                <MdSearch />
                              )
                            }>
                            <Input
                              id="search-input"
                              value={searchActiveRecords}
                              iconInsideLeft
                              placeholder={t(
                                'Pesquise pelo nº documento, nome, telefone ou e-mail'
                              )}
                              onChange={(
                                eventInput: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setSearchActiveRecords(eventInput.target.value);
                              }}
                            />
                          </InputIconInside>
                        </Col>

                        <Col className="pl-7 d-flex align-center">
                          <ButtonTextOutside
                            id="text-btn-open-modal-to-add-individual-record"
                            value={t('Adicionar novo')}
                            className="text-uppercase mr-9">
                            <Button
                              id="btn-open-modal-to-add-individual-record"
                              disabled={false}
                              onClick={onClickToAddNewRecord}
                              rounded>
                              <MdAdd size={18} />
                            </Button>
                          </ButtonTextOutside>

                          {renderMenu()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mb-1">
                    <Col xs={12} className="d-flex">
                      <ButtonTextOutside
                        value={`${t('Excluir')} (${selectedRecords.length})`}
                        className="text-uppercase mt-6 mb-6 ml-7">
                        <Button
                          onClick={() => setShowDeleteModal(true)}
                          id="button-delete-record"
                          rounded
                          color="var(--color-status-critical-base)">
                          <MdDelete size={22} />
                        </Button>
                      </ButtonTextOutside>
                      <Tooltip
                        disabled={selectedAuthorizedRecords.length > 0}
                        id="tooltip-renew-individual-record"
                        content={t(
                          'Selecione cadastros autorizados para renovar'
                        )}
                        place="bottom">
                        <ButtonTextOutside
                          value={`${t('RENOVAR')} (${
                            selectedAuthorizedRecords.length
                          })`}
                          className="text-uppercase mt-6 mb-6 ml-7">
                          <Button
                            onClick={() => setShowUpdateRecordModal(true)}
                            disabled={selectedAuthorizedRecords.length === 0}
                            id="button-update-record"
                            rounded
                            ghost>
                            <MdUpdate size={18} />
                          </Button>
                        </ButtonTextOutside>
                      </Tooltip>

                      {/* <ButtonTextOutside
                        value={`${t('Exportar')} (${selectedRecords.length})`}
                        className="text-uppercase mt-6 mb-6 ml-7">
                        <Button
                          onClick={() => {
                            addToast(
                              'success',
                              `(${selectedRecords.length}) ${t(
                                'Cadastro(s) exportado(s) com sucesso'
                              )}`
                            );
                            setSelectedRecords([]);
                          }}
                          id="button-update-record"
                          rounded
                          ghost>
                          <MdFileDownload size={18} />
                        </Button>
                      </ButtonTextOutside> */}
                    </Col>
                  </Row>
                )}
              </Card>
              {isSearchingRecords ? (
                renderSkeletonsOfCompleteTable(10)
              ) : (
                <div>
                  {isResultNotFound ? (
                    <ResultsNotFoundMessage
                      id="result-not-found-individual-record"
                      minHeight="190px"
                      height="calc(100vh - 680px)"
                    />
                  ) : (
                    <Card>
                      <TableWithPagination<IIndividualRecord>
                        isChangingPage={isChangingPage}
                        setPaginationObject={setPaginationActiveRecordObject}
                        loadingTypeOfRowsPerPage={loadingTypeOfRowsPerPage}
                        columns={columnsTableIndividualRecord}
                        rows={generateRows(
                          paginationActiveRecordObject.individualRecord
                        )}
                        isFetchingData={isFetchingActiveIndividualRecords}
                        paginationData={paginationActiveRecordObject.pagination}
                        firstFetchWasMade={
                          isInitialLoadingCompleteOfActiveRecords
                        }
                        paginationRequestData={paginationRequestDataDebounce}
                        setIsChangingPage={setIsChangingPage}
                        setLoadingTypeOfRowsPerPage={
                          setLoadingTypeOfRowsPerPage
                        }
                        setPaginationRequestData={setPaginationRequestData}
                        minRowsToShowPagination={10}
                        keyOfItemsOfPaginationTable="individualRecord"
                        selectedRows={selectedRecords}
                        renderSkeletonsOfTable={renderSkeletonsOfTable}
                        onSelectRows={(
                          selectedRows: IIndividualRecordRow[]
                        ) => {
                          setSelectedRecords(selectedRows);
                          setSelectedAuthorizedRecords([
                            ...selectedRows.filter(
                              (record) => record.authorizedStatus === t('Sim')
                            )
                          ]);
                        }}
                      />
                    </Card>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { ActiveRecordTab };
