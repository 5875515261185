import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'ui-components/Drawer';
import FormAddIndividualRecord from 'src/components/FormAddIndividualRecord';
import { IIndividualRecordFormValues } from 'src/components/FormAddIndividualRecord/types';
import { useForm } from 'react-hook-form';
import { IIndividualRecord } from 'src/services/api/urls/captivePortal/types';
import _ from 'lodash';
import { useDebounce } from 'use-debounce';
import { IIndividualRecordFormDrawerProps } from './types';

const IndividualRecordFormWizardDrawer = ({
  show,
  onClose,
  onSubmit,
  type,
  individualRecordsListInWizard,
  selectedRecordToUpdate,
  individualRecordsExpired
}: IIndividualRecordFormDrawerProps) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'captivePortal.individualRecord.individualRecordFormDrawer'
  });

  const [isImportedOfExpiredRecords, setIsImportedOfExpiredRecords] =
    React.useState(false);

  const formInstance = useForm<IIndividualRecordFormValues>({
    defaultValues: {
      individualRecord: {
        authorized: false,
        docNumber: '',
        name: '',
        email: '',
        phone: '',
        periodType: undefined,
        periodInDays: [null, null],
        periodInTime: undefined,
        deviceNumbers: null
      }
    },
    mode: 'all',
    criteriaMode: 'all'
  });

  const { watch, setValue, reset, setError, clearErrors } = formInstance;

  const [debouncedDocNumber] = useDebounce(
    watch('individualRecord.docNumber'),
    500
  );

  const getDocInIndividualRecordsListInWizard = (docNumber: string) => {
    if (!individualRecordsListInWizard) return null;
    return individualRecordsListInWizard.find(
      (individualRecord) => individualRecord.docNumber === docNumber
    );
  };

  const getDocumentInExpiredRecords = (docNumber: string) => {
    if (
      _.isUndefined(individualRecordsExpired) ||
      individualRecordsExpired.length === 0
    ) {
      return null;
    }

    if (getDocInIndividualRecordsListInWizard(docNumber)) {
      return null;
    }

    const docNumberExists = individualRecordsExpired.find(
      (individualRecord) => individualRecord.document === docNumber
    );

    if (!docNumberExists) return null;

    return docNumberExists;
  };

  const setExistentDocNumber = (
    individualRecordExistent: IIndividualRecord
  ) => {
    setValue('individualRecord.name', individualRecordExistent.name);
    setValue('individualRecord.email', individualRecordExistent.email);
    setValue('individualRecord.phone', individualRecordExistent.phone);
  };

  const importExistentDocumentOfExpiredRecords = (
    docInExpiredRecords: IIndividualRecord
  ) => {
    setExistentDocNumber(docInExpiredRecords);
    setIsImportedOfExpiredRecords(true);
    clearErrors('individualRecord');
  };

  const clearInfosOfImportedIndividualRecord = () => {
    setValue('individualRecord.name', '');
    setValue('individualRecord.email', '');
    setValue('individualRecord.phone', '');
  };

  const clearInfosIfDocumentDoNotMatchWithExpiredRecords = () => {
    if (!isImportedOfExpiredRecords) return;

    clearInfosOfImportedIndividualRecord();
    clearErrors('individualRecord');
    setIsImportedOfExpiredRecords(false);
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const verifyIfDocNumberExists = () => {
    if (individualRecordsListInWizard) {
      const docNumberExistsInIndividualRecordListInWizard =
        getDocInIndividualRecordsListInWizard(
          watch('individualRecord.docNumber')
        );

      const isDocNumberDifferentOfSelectedRecordToUpdate =
        selectedRecordToUpdate?.docNumber !==
        watch('individualRecord.docNumber');

      if (
        docNumberExistsInIndividualRecordListInWizard &&
        isDocNumberDifferentOfSelectedRecordToUpdate
      ) {
        setError('individualRecord.docNumber', {
          types: {
            request: t('Nº de documento já cadastrado')
          }
        });
      }
    }
  };

  const hasDocInExpiredRecords =
    getDocumentInExpiredRecords(watch('individualRecord.docNumber')) !== null;

  React.useEffect(() => {
    const docInExpiredRecords = getDocumentInExpiredRecords(debouncedDocNumber);

    if (docInExpiredRecords) {
      importExistentDocumentOfExpiredRecords(docInExpiredRecords);
      return;
    }

    clearInfosIfDocumentDoNotMatchWithExpiredRecords();
    verifyIfDocNumberExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDocNumber]);

  React.useEffect(
    () => {
      if (type === 'edit_wizard' && selectedRecordToUpdate) {
        setValue('individualRecord', selectedRecordToUpdate);
        setValue(
          'individualRecord.authorized',
          selectedRecordToUpdate.authorized
        );
      } else {
        reset();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRecordToUpdate, type]
  );

  return (
    <Drawer
      title={t('CADASTRO INDIVIDUAL')}
      show={show}
      onClose={handleOnClose}
      responsiveWidth={{ lg: '50vw' }}
      id="individual-record-form-drawer">
      <FormAddIndividualRecord
        type={type}
        formInstance={formInstance}
        onSubmitForm={onSubmit}
        hasDocInExpiredRecords={hasDocInExpiredRecords}
      />
    </Drawer>
  );
};

IndividualRecordFormWizardDrawer.defaultProps = {
  individualRecordsListInWizard: []
};

export default IndividualRecordFormWizardDrawer;
