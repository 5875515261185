import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from 'react';
import MobileWarning from 'src/components/MobileWarning';

export type TemplateType =
  | 'noBars'
  | 'multiLanguageNavbar'
  | 'fullNavbar'
  | 'fullNavbarWithPlacesSelector'
  | 'menuAndFullNavbar'
  | 'avatarNavbar';

export type TemplatesMap = {
  [key in TemplateType]: JSX.Element;
};

type TemplatesContextProviderProps = {
  children: ReactNode;
};

type TemplatesContextType = {
  template: TemplateType;
  setTemplate: React.Dispatch<SetStateAction<TemplateType>>;
};

export const TemplatesContext = createContext({} as TemplatesContextType);

export const TemplatesContextProvider = ({
  children
}: TemplatesContextProviderProps) => {
  const [template, setTemplate] = useState<TemplateType>('noBars');
  // Previne re renderizações desnecessárias
  const contextValue = useMemo(() => ({ template, setTemplate }), [template]);
  return (
    <TemplatesContext.Provider value={contextValue}>
      <MobileWarning />
      {children}
    </TemplatesContext.Provider>
  );
};
